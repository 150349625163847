import React from 'react';
import { images } from '../../core/contants/images';

interface Props {
  className?: string;
}

export const VnPayIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <div
      style={{
        borderRadius: 4,
        border: '1px solid #EDEDED',
        height: 24,
        width: 24,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: 2,
      }}
    >
      <img src={images.vnpay} width="24px" height="24px" />
    </div>
  );
});
