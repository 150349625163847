import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';

export const useStyles = makeStyles((theme: any) => ({
  buttonBackgroundContainerDesktop: {
    position: 'relative',
    marginTop: 24,
    // paddingBottom: 10
  },
  buttonBackgroundContainer: {
    // width: '100%',
    margin: '12px 16px',
    position: 'relative',
  },
  buttonBackground: {
    width: '100%',
    height: '55px',
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    justifyContent: 'space-between',
    transform: 'translateY(-50%)',
    width: '100%',
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: '0 8px',
    },

    padding: '0 16px',
    alignItems: 'center',
  },
  text: {
    color: '#000',
    fontWeight: 500,
    fontSize: 13
  }
}));