import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  container: {
    padding: '12px 16px 0px 16px',
    maxHeight: 'calc(100vh - 240px)',
    overflowY: 'auto',
  },
  addressBoxContainer: {
    padding: '16px',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.04)',
    backdropFilter: 'blur(12px)',
    borderRadius: '12px',
    display: 'flex',
    marginBottom: '12px',
  },
  addressText: {
    color: colors.textSecondaryColor,
    fontSize: '14px',
    fontWeight: 500,
    marginTop: '8px',
  },
  nameAndPhoneNumberText: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 500,
  },
  defaultText: {
    color: colors.primaryColor,
    fontSize: '14px',
    fontWeight: 500,
  },
  nameAndPhoneNumberContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoContainer: {
    flex: 1,
    marginLeft: '8px',
  },
  addNewAddressText: {
    color: colors.accentColor,
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '8px',
  },
  addNewAddressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${colors.accentColor}`,
    borderRadius: '8px',
    padding: '16px 0',
    margin: '0 16px',
    marginTop: '5px',
  },
  continueButton: {
    margin: '28px 16px 8px 16px',
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    // marginTop: 18,
    margin: '0px 16px 16px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: '#000',
    textAlign: 'center',
  },
  blankView: {
    width: 16,
    height: 16,
  },
  modalCloseIcon: {
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
