import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    padding: '0 16px'
  },
  inputTitle: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#6B6B6B',
    marginTop: '16px',
    marginBottom: '8px'
  },
  colorRed: {
    color: "#f04a49"
  },
  updateButtonContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    marginBottom: '32px'
  },
  arrowDownIcon: {
    fill: '#000'
  }
});