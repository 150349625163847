import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useBrandFilterStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
  },
  container: {
    padding: 16,
    backgroundColor: '#fff',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderRadius: 6,
      padding: 0,
      // boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
      minWidth: 350,
      maxHeight: 450,
      overflow: 'auto',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 200px)',
    },
  },
  provinceText: {
    fontWeight: 500,
    color: colors.textColor,
  },
  provinceContainer: {
    padding: '10px 0px',
    // borderBottomWidth: 1,
    // borderBottomColor: colors.dividerColor,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: '8px 0px',
      borderBottomWidth: 0,
    },
  },
  textInputContainer: {
    height: 44,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  searchTextInput: {
    fontSize: 14,
    fontWeight: 300,
  },
});
