import { createModel } from '@rematch/core';
import axios, { AxiosError } from 'axios';
import { cloneDeep, flattenDeep, values } from 'lodash';
import { RootModel } from '.';
import { IApiResponse } from '../../core/models/api-response';
import { ICart, ICartData, ICartItem, ICartTotal } from '../../core/models/cart';
import { IVoucher } from '../../core/models/ticket';
import { cartService } from '../../services/cart-service';

export interface ICartState {
  cartItems: ICartItem[];
  totals: ICartTotal;
  cartData: ICartData;
  selectedVouchers: IVoucher[];
}

const initialState: ICartState = {
  cartItems: [],
  totals: {
    shippingFee: 0,
    total: 0,
    totalPrice: 0,
    totalPriceWithoutShipping: 0,
  },
  selectedVouchers: [],
  cartData: {} as any,
};

export const cart = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    reset(state, _) {
      return cloneDeep(initialState);
    },
    getCartDetailSuccess(state, payload: ICart) {
      return {
        ...state,
        cartItems: payload.items,
        selectedVouchers: payload.selectedVouchers,
        totals: payload.totals,
      };
    },
    setCartData(state, payload: ICartData) {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          ...payload,
        },
      };
    },
    setCartItems(state, payload: ICartItem[]) {
      return {
        ...state,
        cartItems: payload,
      };
    },
    setTotals(state, payload: ICartTotal) {
      return {
        ...state,
        totals: payload,
      };
    },
  },
  selectors: (slice, createSelector, hasProps) => ({
    cartLength() {
      return slice((cart) =>
        cart.cartItems.reduce((cartLength, item) => cartLength + item.quantity, 0),
      );
    },
  }),
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async quickAddToCart(
      payload: {
        productId: string;
        override: boolean;
        modifier?: { categoryId: number; data: { id: number }[] }[];
        toppingIds?: number[];
        lineItemId?: string;
      },
      state,
    ) {
      try {
        const response = await cartService.quickAddToCart({
          ...payload,
          latitude: state.appContext.latitude,
          longitude: state.appContext.longitude,
        });
        if (response?.data.messageCode === 200) {
          dispatch.appContext.getSelectedBrand();
          dispatch.cart.getCartDetailSuccess({
            items: response?.data.result?.items || [],
            totals: response?.data.result?.totals || cloneDeep(initialState.totals),
            selectedVouchers: response?.data?.result?.selectedVouchers || [],
          });
        } else if (response?.data?.messageCode !== 409) {
          dispatch.appContext.setError(response?.data?.message || '');
        }

        return response;
      } catch (e) {}
    },
    async addToCart(
      payload: {
        productId: string;
        quantity: number;
        override?: boolean;
        modifier?: { categoryId: number; data: { id: number }[] }[];
        toppingIds?: number[];
      },
      state,
    ) {
      try {
        const response = await cartService.addToCart({
          ...payload,
          latitude: state.appContext.latitude,
          longitude: state.appContext.longitude,
        });
        if (response.data.messageCode === 200) {
          dispatch.appContext.getSelectedBrand();
          dispatch.cart.getCartDetailSuccess({
            items: response.data.result?.items || [],
            totals: response.data.result?.totals || cloneDeep(initialState.totals),
            selectedVouchers: response?.data?.result?.selectedVouchers || [],
          });
        } else if (response.data.messageCode === 400) {
          const message = flattenDeep(values(response.data.result || {})).join('\n ');
          dispatch.appContext.setError(message || response.data.message || '');
        } else {
          dispatch.appContext.setError(response.data?.message || '');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async removeItem(payload: { productId?: string; lineItemId?: string }, state) {
      try {
        const response = await cartService.removeItem(payload);
        if (response.data.messageCode === 200) {
          dispatch.appContext.getSelectedBrand();
          dispatch.cart.getCartDetailSuccess({
            items: response.data.result?.items || [],
            totals: response.data.result?.totals || cloneDeep(initialState.totals),
            selectedVouchers: response?.data?.result?.selectedVouchers || [],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async quickRemoveFromCart(
      payload: {
        productId?: string;
        lineItemId?: string;
        modifier?: { categoryId: number; data: { id: number }[] }[];
        toppingIds?: number[];
      },
      state,
    ) {
      const response = await cartService.quickRemoveFromCart(payload);
      dispatch.cart.getCartDetailSuccess({
        items: response.data.result?.items || [],
        totals: response.data.result?.totals || cloneDeep(initialState.totals),
        selectedVouchers: response?.data?.result?.selectedVouchers || [],
      });
    },
    async getCartDetail(payload: { paymentMethod?: string }, state) {
      const { paymentMethod } = payload;
      const cartDetailResponse = await cartService.getDetail(
        payload ? { paymentMethod: paymentMethod } : {},
      );
      dispatch.cart.getCartDetailSuccess({
        items: cartDetailResponse?.data?.result?.items || [],
        totals: cartDetailResponse?.data?.result?.totals || cloneDeep(initialState.totals),
        selectedVouchers: cartDetailResponse?.data?.result?.selectedVouchers || [],
      });
      return cartDetailResponse;
      // try {
      // } catch (e) {}
    },
  }),
});
