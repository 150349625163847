import { makeStyles } from "@material-ui/styles";
import React from "react";
import { colors } from "../core/contants/colors";
import { images } from "../core/contants/images";

interface Props {
  text?: string;
  containerStyle?: any;
  onClick?: () => void;
  type?: string;
}

const useStyles = makeStyles({
  buttonContainer: {
    cursor: 'pointer',
    background: colors.primaryColor,
    padding: '13px 0',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 900,
    borderRadius: '10px',
    margin: '0 16px'
  }
});

export const CartButton = React.memo((props: Props) => {
  const classes = useStyles();

  return (
    <div  className={`${classes.buttonContainer} ${props.containerStyle}`} {...props}>
      {props.text ?? ''}
    </div>
  );
});
