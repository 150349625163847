import clsx from 'clsx';
import React from 'react';
import Slider from 'react-slick';
import { ArrowLeftIcon } from '../../../../components/icons/arrow-left';
import { ArrowRightIcon } from '../../../../components/icons/arrow-right';
import { IBanner } from '../../../../core/models/banner';
import { useBrandSliderStyles } from './brand-slider.style';

interface Props {
  banners: IBanner[];
}

const NextArrow = (props) => {
  const classes = useBrandSliderStyles();
  const { className, style, onClick } = props;

  return (
    <div
      className={clsx(className, classes.arrowContainer)}
      style={{ right: 16 }}
      onClick={onClick}
    >
      <ArrowRightIcon />
    </div>
  );
};

const PrevArrow = (props) => {
  const classes = useBrandSliderStyles();
  const { className, style, onClick } = props;

  return (
    <div className={clsx(className, classes.arrowContainer)} style={{ left: 16 }} onClick={onClick}>
      <ArrowLeftIcon />
    </div>
  );
};

export const BrandSlider = React.memo((props: Props) => {
  const classes = useBrandSliderStyles();
  const redirectToUrl = (url) => {
    if (!url) {
      return;
    }
    window.location.href = url;
  };
  return (
    <div className={classes.slider}>
      <Slider
        adaptiveHeight
        infinite
        autoplay
        speed={500}
        slidesToShow={1}
        dots
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        dotsClass={`slick-dots ${classes.dots}`}
        appendDots={(dots) => (
          <div
            style={{
              borderRadius: '10px',
              bottom: 8,
            }}
          >
            <ul style={{ margin: '0px' }}> {dots} </ul>
          </div>
        )}
      >
        {props.banners.map((banner) => (
          <img src={banner.desktopImage}
               className={classes.banner}
               alt={banner.name}
               key={banner.id}
               onClick={() => redirectToUrl(banner.linkTarget)}
          />
        ))}
      </Slider>
    </div>
  );
});
