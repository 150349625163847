import { AxiosResponse } from "axios";
import axios from "../core/http";
import { IApiResponse } from "../core/models/api-response";
import { IBrand } from "../core/models/brand";
import { IProduct } from "../core/models/product";

const addProduct = (productId: string) => axios.post<{ productId: string }, AxiosResponse<IApiResponse<null>>>("/favorite/product", { productId });
const addBrand = (brandId: string) => axios.post<IApiResponse<null>>("/favorite/brand", { brandId } as any);
const getProducts = (params?: { perPage?: number }) => axios.get<IApiResponse<IProduct[]>>("/favorite/product", { params });
const getBrands = (params?: { perPage?: number }) => axios.get<IApiResponse<IBrand[]>>("/favorite/brand", { params });
const deleteProduct = (productId: string) => axios.delete<IApiResponse<null>>(`/favorite/product/${productId}`);
const deleteBrand = (brandId: string) => axios.delete<IApiResponse<null>>(`/favorite/brand/${brandId}`);

export const favoriteService = {
  addProduct,
  getProducts,
  getBrands,
  addBrand,
  deleteBrand,
  deleteProduct,
};
