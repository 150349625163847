import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppRadioBox } from '../../../../components/radio';
import { ArrowDownIcon } from '../../../../components/icons/arrow-down';
import { BrandFilterIcon } from '../../../../components/icons/brand-filter';
import { FilterIcon } from '../../../../components/icons/filter';
import { ProductItem } from '../../../../components/product-item';
import { Spinner } from '../../../../components/spinner';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { IBrand } from '../../../../core/models/brand';
import { IProduct } from '../../../../core/models/product';
import { ITag } from '../../../../core/models/tag';
import { BrandFilterBottomSheet, BrandFilterPopOver } from '../brand-filter/brand-filter.component';
import { OrderByFilterBottomSheet } from '../order-by-filter/order-by-filter.component';
import { useSuggestionStyles } from './suggestion.style';
import { Empty } from '../../../../components/empty/empty.component';

interface Props {
  onPressProduct: (product: IProduct) => void;
  products: IProduct[];
  onScrollToEnd: () => void;
  isEndOfList: boolean;
  tags: ITag[];
  selectedTag: ITag | undefined;
  onChangedTag: (tag: ITag) => void;
  onChangedOrderBy: (value: string) => void;
  onChangedBrand: (brand: IBrand) => void;
  orderBy: string | null;
  filteredBrand: IBrand;
  brandsByProvince: IBrand[];
}

export const Suggestion = React.memo((props: Props) => {
  const classes = useSuggestionStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);

  const [isVisibleOrderByFilter, setIsVisibleOrderByFilter] = useState(false);
  const [isVisibleBrandFilter, setIsVisibleBrandFilter] = useState(false);

  const items = [
    {
      value: 'discount',
      label: 'Giảm giá nhiều nhất',
    },
    {
      value: 'best-seller',
      label: 'Bán chạy nhất',
    },
    {
      value: 'latest',
      label: 'Món mới nhất',
    },
    {
      value: 'price-low-to-high',
      label: 'Giá thấp đến cao',
    },
  ];

  const onChangedOrderBy = (value: string) => {
    props.onChangedOrderBy(value);
    setIsVisibleOrderByFilter(false);
  };

  const onChangedBrand = (brand: IBrand) => {
    props.onChangedBrand(brand);
    setIsVisibleBrandFilter(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.titleText}>{t('suggestion')}</div>
        {/* <div className={classes.showAllText}>Tất cả</div> */}
      </div>

      <div className={classes.tagContainer}>
        {props.tags.map((tag) => (
          <div
            className={clsx(classes.tag, tag.id === props.selectedTag?.id && classes.activeTag)}
            onClick={() => props.onChangedTag(tag)}
            key={tag.id}
          >
            #{tag.name}
          </div>
        ))}
      </div>

      {isDesktop ? (
        <div className={classes.filterRow}>
          <div className={classes.orderByContainer}>
            {items.map((i) => (
              <AppRadioBox
                isChecked={i.value === props.orderBy}
                onChanged={(_) => {
                  onChangedOrderBy(i.value);
                }}
                key={i.value}
              >
                <span className={classes.filterText}>{i.label}</span>
              </AppRadioBox>
            ))}
          </div>
          <BrandFilterPopOver
            brands={[{ id: 'all', name: 'Tất cả', brandId: 'all' }, ...props.brandsByProvince]}
            selectedId={props.filteredBrand?.brandId}
            isVisible={isVisibleBrandFilter && isDesktop}
            onVisibleChanged={setIsVisibleBrandFilter}
            onChanged={onChangedBrand}
          >
            <div
              className={classes.brandFilterContainer}
              onClick={() => {
                setIsVisibleBrandFilter(true);
              }}
            >
              <span>{props.filteredBrand ? props.filteredBrand.name : 'Chọn thương hiệu'}</span>
              <ArrowDownIcon fill="#000" />
            </div>
          </BrandFilterPopOver>
        </div>
      ) : (
        <div className={classes.filterRow}>
          <div
            className={classes.filterContainer}
            onClick={() => {
              setIsVisibleOrderByFilter(true);
            }}
          >
            <FilterIcon />
            <span className={classes.filterText}>Bộ lọc & Sắp xếp</span>
          </div>
          <div
            className={classes.filterContainer}
            onClick={() => {
              setIsVisibleBrandFilter(true);
            }}
          >
            <BrandFilterIcon />
            <span className={classes.filterText}>Thương hiệu</span>
          </div>
        </div>
      )}

      {props.products.length > 0 ? (
        <InfiniteScroll
          className={classes.productItemContainer}
          dataLength={props.products.length}
          next={props.onScrollToEnd}
          hasMore={!props.isEndOfList}
          loader={
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          }
        >
          {props.products.map((product) => (
            <ProductItem
              key={product.id}
              product={product}
              onClick={() => props.onPressProduct(product)}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Empty message="Không có kết quả tìm kiếm phù hợp với yêu cầu của bạn." />
      )}
      <OrderByFilterBottomSheet
        value={props.orderBy}
        onChanged={onChangedOrderBy}
        isVisible={isVisibleOrderByFilter}
        onVisibleChanged={setIsVisibleOrderByFilter}
      />
      <BrandFilterBottomSheet
        brands={[{ id: 'all', name: 'Tất cả', brandId: 'all' }, ...props.brandsByProvince]}
        selectedId={props.filteredBrand?.brandId}
        isVisible={isVisibleBrandFilter && !isDesktop}
        onVisibleChanged={setIsVisibleBrandFilter}
        onChanged={onChangedBrand}
      />
    </div>
  );
});
