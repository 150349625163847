import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../core/contants/images';
import { I18N_NAMESPACES } from '../../core/i18n';
import { useOrderEmptyStyles } from './empty.style';

interface Props {
  message: string;
}

export const Empty = React.memo((props: Props) => {
  const classes = useOrderEmptyStyles();
  const { t } = useTranslation([I18N_NAMESPACES.PROFILE]);

  return (
    <div className={classes.container}>
      <img src={images.empty} alt="" />
      <div className={classes.text}>{props.message}</div>
    </div>
  );
});
