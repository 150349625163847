import { NET_CORE_INFO } from "../../core/environments/env";

interface NCBeginOrder {
    brandName: string;
    restaurantName: string;
}

const ncBeginOrder = (data: NCBeginOrder) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech']('dispatch', 'Begin Order', {
            'BRAND': data.brandName,
            'restaurant_name': data.restaurantName,
        });
}

interface NCAddToCart {
    productUrl: string;
    productId: number;
    productName: string;
    brandName: string;
    category: string; // Nhóm sản phẩm
    variant?: string; // Nhóm sản phẩm
    price: number;
    currency: string;
    productPrice: number;
    quantity: number;
    productImage: string;
    productRegularPrice: number;
    productSalePrice: number;
}

const ncAddToCart = (data: NCAddToCart) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech'](
            'dispatch',
            'Add to Cart',
            {
                'purl': data.productUrl,
                'prid': data.productId,
                'name': data.productName,
                'brand': data.brandName,
                'variant': data.variant || '',
                'category': data.category,
                'price': Math.round(data.price),
                'prqt': data.quantity,
                'currency': data.currency,
                'product_price': Math.round(data.productPrice),
                'product_name': data.productName,
                'product_quantity': data.quantity,
                'product_img': data.productImage,
                'product_regular_price': Math.round(data.productRegularPrice),
                'product_sale_price': Math.round(data.productSalePrice),
            }
        );
}

const ncLoginSuccess = (data: { userId: string; mobile: string; }) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech']('identify', data.mobile);
        window['smartech']('dispatch', 'nc_login_success', {
            'NC_USER_ID': data.userId,
            'MOBILE': data.mobile,
        });
}

interface NCCheckOut {
    brandName: string;
    restaurantName: string;
    restaurantTelephone: string;
    orderTotal: number;
}

const ncCheckout = (data: NCCheckOut) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech']('dispatch', 'Checkout', {
                'BRAND': data.brandName,
                'restaurant_name': data.restaurantName,
                'restaurant_phone': data.restaurantTelephone,
                'order_value': Math.round(data.orderTotal),
            }
        );
}

interface NCProductPurchase {
    orderTotal: number;
    orderTotal2: number;
    isVoucherUse: boolean;
    voucherCode: string;
    pickupTime: string;
    pickupAtStore: boolean;
    paymentMethod: string;
    campaignCode: string;
    brandName: string;
    restaurantName: string;
    restaurantTelephone: string;
    restaurantAddress: string;
    customerAddress: string;
    orderStatus: string;
}

const ncProductPurchase = (data: NCProductPurchase) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech'](
            'dispatch',
            'Product Purchase',
            {
                'order_value': Math.round(data.orderTotal),
                'order_value_2': Math.round(data.orderTotal2),
                'is_voucher_use': String(data.isVoucherUse),
                'nc_voucher_code': data.voucherCode,
                'pick_up_time': data.pickupTime,
                'pick_up_at_store': data.pickupAtStore,
                'payment_method': data.paymentMethod,
                'nc_campaign_code': data.campaignCode,
                'BRAND': data.brandName,
                'restaurant_name': data.restaurantName,
                'restaurant_phone': data.restaurantTelephone,
                'restaurant_address': data.restaurantAddress,
                'customer_address': data.customerAddress,
                'order_status': 'Chờ thanh toán',
            }
        );
}

interface NCOrderSuccess {
    orderTotal: number;
    orderTotal2: number;
    isVoucherUse: boolean;
    voucherCode: string;
    pickupTime: string;
    voucherValue: number; //Số tiền được giảm
    orderCode: string;
}

const ncOrderSuccess = (data: NCOrderSuccess) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech'](
            'dispatch',
            'Order Success',
            {
                'order_value': Math.round(data.orderTotal),
                'order_value_2': Math.round(data.orderTotal2),
                'is_voucher_use': String(data.isVoucherUse),
                'nc_voucher_code': data.voucherCode,
                'pick_up_time': data.pickupTime,
                'voucher_value': Math.round(data.voucherValue),
                'order_code': data.orderCode,
            }
        );
}

interface NCAddToWishList {
    productPrice: number;
    productName: string;
    brandName: string;
    category: string;
}

const ncAddToWishList = (data: NCAddToWishList) => {
    if (typeof window['smartech'] !== 'undefined')
        window['smartech'](
            'dispatch',
            'Add to Wishlist',
            {
                'product_price': Math.round(data.productPrice),
                'product_name': data.productName,
                'brand': data.brandName,
                'category': data.category,
            }
        );
}

interface UserInfo {
    userId?: string;
    mobile?: string;
    firstName?: string;
    lastName?: string;
    userLocation?: string;
    taxCode?: string;
    companyName?: string;
    companyAddress?: string;
    companyEmail?: string;
    phoneNumber?: string;
    email?: string;
}

const ncContact = (contact: UserInfo) => {
    if (typeof window['smartech'] !== 'undefined') {
        let sendData = {
            'pk^mobile': contact.mobile
        };

        if (typeof contact.email !== 'undefined') {
            sendData['NC_USER_EMAIL'] = contact.email;
        }
        if (typeof contact.firstName !== 'undefined') {
            sendData['NC_USER_FIRSTNAME'] = contact.firstName;
        }
        if (typeof contact.lastName !== 'undefined') {
            sendData['NC_USER_LASTNAME'] = contact.lastName;
        }
        if (typeof contact.userLocation !== 'undefined') {
            sendData['NC_USER_LOCATION'] = contact.userLocation;
        }
        if (typeof contact.phoneNumber !== 'undefined') {
            sendData['NC_USER_PHONENUMBER'] = contact.phoneNumber;
        }
        let eventId = '' as any;
        if (NET_CORE_INFO.env === 'PROD') {
            eventId = 62;
        }

        window['smartech']('contact', eventId, sendData);
    }
}

interface IVoucher {
    campaignCode: string;
}

const ncVoucherUsage = (data: IVoucher) => {
    if (typeof window['smartech'] !== 'undefined') {
        let sendData = {
            'nc_campaign_code': data.campaignCode,
        };

        window['smartech']('dispatch', 'Voucher usage', sendData);
    }

}

const netCoreCommon = (name, activityName, data) => {
    if (typeof window['smartech'] !== 'undefined') {
        let sendData = {};
        Object.keys(data).map(function (key, index) {
            sendData[key] = data[key];
        });

        window['smartech'](name, activityName, sendData);
    }
}

export const NetCore = {
    ncBeginOrder,
    ncAddToCart,
    ncLoginSuccess,
    ncCheckout,
    ncProductPurchase,
    ncOrderSuccess,
    ncAddToWishList,
    ncContact,
    ncVoucherUsage,
    netCoreCommon,
};
