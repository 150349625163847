import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const LocationIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 6.90654C2.5 3.91663 4.99852 1.5 8.02074 1.5C11.0515 1.5 13.55 3.91663 13.55 6.90654C13.55 8.4132 13.0021 9.81196 12.1002 10.9975C11.1052 12.3053 9.8789 13.4447 8.49855 14.3391C8.18263 14.5458 7.89752 14.5614 7.55079 14.3391C6.16258 13.4447 4.93626 12.3053 3.94982 10.9975C3.04729 9.81196 2.5 8.4132 2.5 6.90654ZM6.20147 7.07489C6.20147 8.07651 7.0188 8.86429 8.02096 8.86429C9.02378 8.86429 9.84897 8.07651 9.84897 7.07489C9.84897 6.08107 9.02378 5.25494 8.02096 5.25494C7.0188 5.25494 6.20147 6.08107 6.20147 7.07489Z"
        fill={color || "white"}
      />
    </svg>
  );
});
