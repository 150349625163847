import { makeStyles } from '@material-ui/styles';
import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';
import { RaisedButton } from './raised-button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  btn: {
    width: 200,
    marginTop: 16,
  },
  content: {
    marginTop: 4,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
});

export const ErrorModal = React.memo(() => {
  const appContext = useSelector((state: RootState) => state.appContext);
  const dispatch = useDispatch<Dispatch>();
  const classes = useStyles();

  const onCancel = () => {
    dispatch.appContext.setError(null);
  };

  return (
    <Modal
      afterClose={() => {
        setTimeout(() => {
          document.body.style.overflow = 'unset';
        }, 0);
      }}
      style={{ zIndex: 9999 }}
      title=""
      visible={!!appContext.error}
      footer={null}
      onCancel={onCancel}
      closeIcon={null}
      centered
      width={327}
      bodyStyle={{
        padding: 16,
        zIndex: 9999,
      }}
      closable={false}
      zIndex={9999}
    >
      <div className={classes.container}>
        <div className={classes.title}>Thông báo</div>
        <div className={classes.content}>{appContext.error}</div>
        <RaisedButton className={classes.btn} onClick={onCancel}>
          Đồng ý
        </RaisedButton>
      </div>
    </Modal>
  );
});
