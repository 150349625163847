import { getPersistor } from '@rematch/persist';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/lib/integration/react';
import './core/i18n';
import './index.less';
import { App } from './navigation/router';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const persistor = getPersistor();

ReactDOM.render(
  <React.StrictMode>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <CookiesProvider>
          <BrowserRouter keyLength={12}>
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
