import React from 'react';

interface Props {
  className?: string;
  color?: string;
}

export const ArrowRightIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.961689 9.12302C1.02716 9.13365 1.09341 9.13855 1.1597 9.13769L12.4474 9.13769L12.2013 9.25217C11.9607 9.36604 11.7418 9.52102 11.5544 9.71009L8.3891 12.8754C7.97221 13.2734 7.90217 13.9136 8.2231 14.3923C8.59662 14.9024 9.31294 15.0132 9.82309 14.6396C9.8643 14.6094 9.90348 14.5765 9.94029 14.5411L15.6642 8.81715C16.1116 8.37032 16.112 7.64549 15.6651 7.19816C15.6649 7.19788 15.6645 7.19756 15.6642 7.19727L9.94029 1.47331C9.4926 1.02688 8.76777 1.02788 8.3213 1.47556C8.28617 1.5108 8.25337 1.54829 8.2231 1.58779C7.90217 2.06649 7.97221 2.70668 8.3891 3.10464L11.5487 6.27571C11.7167 6.44385 11.9098 6.58484 12.1211 6.69356L12.4646 6.84811L1.2227 6.84811C0.637892 6.82639 0.12481 7.2349 0.0149456 7.80973C-0.0862612 8.43382 0.337599 9.02178 0.961689 9.12302Z"
          fill={color || "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
});
