import { ICartData } from './../core/models/cart';
import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IOrder, IOrderItem, IOrderStatus } from '../core/models/order';

const getOrders = (params?: {
  status?: string;
  perPage?: number;
  fromDate?: string;
  toDate?: string;
  page?: number;
}) => axios.get<IApiResponse<IOrderItem[]>>('/order', { params });

const getDetail = (id: string) => axios.get<IApiResponse<IOrder>>(`/order/${id}`);

const createOrder = (body: ICartData) => axios.post<IApiResponse<any>>('order/create', body as any);

const checkPayment = (orderId: number, params?: { requestId?: string }) =>
  axios.get<IApiResponse<any>>(`order/${orderId}/check-payment`, {
    params,
  });

const calculateOrderTotal = (
  orderId: string,
  params: {
    pickupAtRestaurant: number;
    recalculateShippingFee: boolean;
    shippingVendor: string;
    paymentMethod: string;
  },
) =>
  axios.get<IApiResponse<any>>(`order/calculate/${orderId}`, {
    params,
  });

const requestPayment = (
  orderId: string,
  body: {
    paymentMethod: string;
    requestAmount: number;
    note: string;
    deliveryInfo: {
      pickupAtRestaurant: number;
      deliveryAddress: string;
      deliveryTime: string;
      deliveryDate: string;
      recipientName: string;
      recipientCellphone: string;
    };
    invoice?: {
      info: number;
      name: string;
      number: string;
      address: string;
      email: string;
    };
    // rkPaymentCode :
  },
) => axios.post<IApiResponse<any>>(`order/${orderId}/request-payment`, body as any);

const getStatus = () => axios.get<IApiResponse<IOrderStatus[]>>(`/config/order-status`);

const getRecommendRatingOrders = () =>
  axios.get<IApiResponse<IOrderItem[]>>(`/order/ratting/recommend`);
export const orderService = {
  getOrders,
  getDetail,
  createOrder,
  checkPayment,
  requestPayment,
  getStatus,
  calculateOrderTotal,
  getRecommendRatingOrders,
};
