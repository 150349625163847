import React from "react";

interface Props {
  className?: string;
}

export const GoldenGateIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <img src="https://s3-alpha-sig.figma.com/img/5907/8f0e/f9f844cf43e1721e01b0ebedf68d66a6?Expires=1632096000&Signature=H50xsrPAXT67QHpQ2B1LIP4ZxZ1d0QUCd6BEE2CDnAspAxJAJ8hdSZRJCX7XMKD3arNoay22aT70CR~nLNS9s7vvx7ypeg97zXG7UvfKFxTz-Vvn7NDrXzCC2P~O2v1UzyOIxqcIPn29svn3FnXbcCwsKGVo7NDzUDIuetfZxgtYH0hN80WkO96kPmQOEjud6vI~X~tF6JNZx1v2OEjW-KK2xunll78cN2-TDZT0L6qWpmpXRG0ru8G4triSqf0UY2776GIW-gwD8qL70N9w~EmlBa1OhhJ0aQ-72kD55VHq13RXRrj-vyl2MQpGc8tEP6ydYsB09~Mrpcd6xs4yvA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" width="74px" height="24px"/>
    );
  }
);
