import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCloseIcon } from '../../components/icons/modal-close';
import { I18N_NAMESPACES } from '../../core/i18n';
import { IAddress } from '../../core/models/customer';
import { AddressForm } from './components/address-form/address-form.component';
import { useEditAddressStyles } from './edit-address.style';

interface Props {
  isVisible: boolean;
  address: IAddress | null;
  close: (e) => void;
  onSubmitSuccess: (address?: IAddress) => void;
}

export const EditAddressModal = React.memo((props: Props) => {
  const classes = useEditAddressStyles();
  const { t } = useTranslation([I18N_NAMESPACES.PROFILE]);

  return (
    <Modal
      visible={props.isVisible}
      onCancel={props.close}
      title=""
      footer={null}
      closeIcon={<div />}
      centered
      bodyStyle={{ padding: 14 }}
    >
      <div>
        <div className={classes.header}>
          <div className={classes.iconPlaceholder} />
          <span className={classes.title}>
            {props.address ? 'Chỉnh sửa địa chỉ' : 'Thêm địa chỉ mới'}
          </span>
          <div className={classes.closeIcon} onClick={props.close}>
            <ModalCloseIcon color="#7A7A7A" />
          </div>
        </div>
        <AddressForm defaultAddress={props.address} onDone={props.onSubmitSuccess} />
      </div>
    </Modal>
  );
});
