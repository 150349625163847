import React from "react";

interface Props {
  className?: string;
  fill?: string
}

export const ArrowDownIcon = React.memo(({ className, fill }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20921 5.49492C3.48816 5.21598 3.94042 5.21598 4.21936 5.49492L8 9.27556L11.7806 5.49492C12.0596 5.21598 12.5118 5.21598 12.7908 5.49492C13.0697 5.77387 13.0697 6.22613 12.7908 6.50508L8.50508 10.7908C8.22613 11.0697 7.77387 11.0697 7.49492 10.7908L3.20921 6.50508C2.93026 6.22613 2.93026 5.77387 3.20921 5.49492Z"
        fill={fill ? fill : 'white'}
      />
    </svg>
  );
});
