import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useBrandBestSellersStyles = makeStyles({
  container: {
    marginTop: 8,
    padding: 16,
    backgroundColor: '#fff',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 40,
      paddingTop: 0,
      backgroundColor: 'transparent',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  showAllText: {
    color: colors.accentColor,
    fontWeight: 500,
    fontSize: 15,
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 12,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: 30,
      marginTop: 20,
    },
  },
  thumbnail: {
    width: '100%',
  },
  itemContainer: {
    borderRadius: 8,
    padding: 8,
    boxShadow: '0px 8px 50px rgba(0, 0, 0, 0.06)',
  },
  couponContainer: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
});
