import React from 'react';

interface Props {
  className?: string;
}

export const SuccessIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0Z"
        fill="#47C751"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.8443 42.8698L24.824 40.8495L44.4012 21.2009C45.0707 20.5314 46.1561 20.5314 46.8256 21.2009L47.6337 22.0089C48.3032 22.6784 48.3032 23.7639 47.6337 24.4334L29.2687 42.8698C28.5993 43.5393 27.5138 43.5393 26.8443 42.8698Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.2856 40.8498L29.2653 42.8701C28.5958 43.5396 27.5103 43.5396 26.8409 42.8701L18.7962 34.8254C18.1267 34.1559 18.1267 33.0705 18.7962 32.401L19.6043 31.5929C20.2738 30.9234 21.3592 30.9234 22.0287 31.5929L31.2856 40.8498Z"
        fill="white"
      />
    </svg>
  );
});
