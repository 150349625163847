import { useMediaQuery } from '@material-ui/core';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { IBanner } from '../../../../core/models/banner';
import { useCouponPopupStyles } from './coupon-popup.style';
import { Link } from 'react-router-dom';
import Slider from "@ant-design/react-slick";

interface Props {
  isVisible: boolean;
  close: () => void;
  banners: IBanner[];
}

export const CouponPopup = React.memo((props: Props) => {
  const classes = useCouponPopupStyles();
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);

  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const redirectToUrl = (url) => {
    if (!url) {
      return;
    }
    window.location.href = url;
  };

  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.close}
      closeIcon={null}
      centered
      bodyStyle={{
        padding: 0,
        backgroundColor: 'transparent',
      }}
      style={{
        backgroundColor: 'transparent',
      }}
      closable={false}
    >
      <div className={classes.container}>
        <div className={classes.bannerContainer}>
          <Slider arrows={true} infinite slidesToShow={1}>
            {props.banners?.map((banner) => (
              <img src={isDesktop ? banner.desktopImage : banner.mobileImage}
                   alt=""
                   onClick={() => redirectToUrl(banner.linkTarget)}
              />
            ))}
          </Slider>
          <div className={classes.closeIconContainer} onClick={props.close}>
            <ModalCloseIcon color="#fff" />
          </div>
        </div>
      </div>
    </Modal>
  );
});
