import { Modal } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { images } from '../../../../core/contants/images';
import { IOrderItem } from '../../../../core/models/order';
import { formatCurrency } from '../../../../core/ultis/currency';
import { useTrackingOrdersPopupStyles } from './tracking-orders-popup.style';

interface Props {
  isVisible: boolean;
  close: () => void;
  orders: IOrderItem[];
}

export const TrackingOrdersPopup = React.memo((props: Props) => {
  const classes = useTrackingOrdersPopupStyles();
  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.close}
      closeIcon={null}
      centered
      bodyStyle={{
        padding: 0,
        backgroundColor: 'transparent',
      }}
      style={{
        backgroundColor: 'transparent',
      }}
      closable={false}
    >
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div className={classes.blankView}></div>

          <span className={classes.title}>Theo dõi đơn hàng</span>
          <div className={classes.modalCloseIcon} onClick={props.close}>
            <ModalCloseIcon />
          </div>
        </div>
        <div className={classes.description}>Chọn một đơn hàng để theo dõi</div>

        <hr className={classes.seperator} />
        {props.orders.map((order) => (
          <Link
            className={classes.card}
            to={`/order/${order.orderId}/tracking`}
            key={order.orderId}
          >
            <img
              src={
                order.items && order.items.length > 0
                  ? order.items[0].thumbnail
                  : images.foodThumbnail
              }
              alt=""
              className={classes.thumbnail}
            />
            <div className={classes.contentContainer}>
              <div className={classes.row}>
                <div className={classes.secondaryText}>{order.orderCode}</div>
                <div className={classes.secondaryText}>{order.orderTime}</div>
              </div>

              <div className={classes.orderTitle}>{order.orderName}</div>

              <div className={classes.row}>
                <div className={classes.priceText}>{formatCurrency(order.orderTotal)}</div>
                <div className={`${classes.statusText}`}>Theo dõi</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Modal>
  );
});
