import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CartButton } from "../../../../components/cart-button";
import { CartDiscountIcon } from "../../../../components/icons/cart-discount";
import { images } from "../../../../core/contants/images";
import { useStyles } from "./total-money-field.style";
interface Props {
  total: string;
  onContinueClick: () => void;
}
export const TotalMoneyField = React.memo((props: Props) => {
  const classes = useStyles();
  const {total, onContinueClick} = props;
  return (
    <div className={classes.totalMoneyField}>
      <div className={classes.totalMoneyContainer}>
        <div className={classes.totalMoneyTitle}>Tạm tính</div>
        <div className={classes.totalMoneyValue}>{total}</div>
      </div>
        <CartButton text={"Tiếp tục"} containerStyle={classes.continueButton} onClick={onContinueClick}/>
    </div>
  );
});
