import { Col, Row } from 'antd';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartButton } from '../../../components/cart-button';
import { CartDivider } from '../../../components/cart-divider';
import { Header } from '../../../components/header';
import { useStyles } from './verify-info.style';
import { AppRadioBox } from '../../../components/radio';
import { AtmIcon } from '../../../components/icons/atm-icon';
import { VisaIcon } from '../../../components/icons/visa-icon';
import { CashIcon } from '../../../components/icons/cash-icon';
import { MomoIcon } from '../../../components/icons/momo-icon';
import { VnPayIcon } from '../../../components/icons/vnpay-icon';
import { ZaloPayIcon } from '../../../components/icons/zalo-pay-icon';
import { CouponModal } from '../coupon-modal/coupon-modal.component';
import { formatCurrency } from '../../../core/ultis/currency';
import { voucherService } from '../../../services/voucher-service';
import { cartService } from '../../../services/cart-service';
import { paymentMethods, paymentMethodValues } from '../../../core/contants/paymentMethodValues';
import { ShopeePayIcon } from '../../../components/icons/shopee-pay';
import { VoucherCloseIcon } from '../../../components/icons/voucher-close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, RootState} from '../../../store';
import {BRAND_IDS} from "../../../core/contants/brand";
import {IGBizAccount} from "../../../core/models/user";
import {userService} from "../../../services/user-service";

interface Props {
  onClickBillingText: () => void;
  onQrCodeClick: () => void;
  cartData: any;
  voucherData: any;
  myVoucherData: any;
  currentMethod: string;
  couponModalVisible: boolean;
  cartState: any;
  setCurrentMethod: (value: string) => void;
  onSubmitClick: () => void;
  setIsLoading: (value: boolean) => void;
  onApplyVoucherClick: (value: any) => void;
  onApplyCoupon: (value: any) => void;
  setCouponModalVisible: (value: boolean) => void;
  onCancelCoupon: (value: any) => void;
  onSubmitCodeInput: (code: string) => void;
  getVoucherData: () => void;
  setCartData: (data: any) => void;
  disableGBizOption: boolean;
  setDisableGBizOption: (data: any) => void;
  gBizAccount?: IGBizAccount;
  isVisibleChooseVoucher: boolean;
  setIsVisibleChooseVoucher: (data: any) => void;
}
export const VerifyInfo = React.memo((props: Props) => {
  const classes = useStyles();
  const formRef = useRef(null) as any;
  const dispatch = useDispatch<Dispatch>();

  const appContext = useSelector((state: RootState) => state.appContext);

  const {
    onQrCodeClick,
    cartData,
    voucherData,
    myVoucherData,
    currentMethod,
    setCartData,
    setCurrentMethod,
    onSubmitClick,
    onApplyVoucherClick,
    setCouponModalVisible,
    couponModalVisible,
    onCancelCoupon,
    onSubmitCodeInput,
    cartState,
    setIsLoading,
    onApplyCoupon,
    getVoucherData,
    disableGBizOption,
    setDisableGBizOption,
    gBizAccount,
    isVisibleChooseVoucher,
    setIsVisibleChooseVoucher
  } = props;

  const VerifyInfoSchema = Yup.object().shape({
    currentMethod: Yup.string().required('Vui lòng chọn phương thức thanh toán'),
  });
  const discounts = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type !== 1),
    [cartState?.selectedVouchers],
  );

  const vouchers = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type === 1),
    [cartState?.selectedVouchers],
  );

  useEffect(() => {
    formRef?.current?.setFieldValue('currentMethod', currentMethod);
  }, [currentMethod]);
  const onCloseCouponModal = () => {
    setCouponModalVisible(false);
  };

  const onOpenCouponModal = () => {
    setCouponModalVisible(true);
    getVoucherData();
  };

  const onSelectPaymentMethodClick = async (value: string) => {
    if (value == paymentMethodValues.G_BUSINESS) {
      setIsVisibleChooseVoucher(false);
      // Set invoice info
      dispatch.cart.setCartData({
        hasInvoice: 1,
        invoiceName: gBizAccount?.company?.name,
        invoiceNumber: gBizAccount?.company?.taxCode,
        invoiceAddress: gBizAccount?.company?.address,
        invoiceEmail: gBizAccount?.company?.email,
      });
    } else {
      setIsVisibleChooseVoucher(true);
      dispatch.cart.setCartData({
        hasInvoice: 0,
        invoiceName: '',
        invoiceNumber: '',
        invoiceAddress: '',
        invoiceEmail: '',
      });
    }

    setCurrentMethod(value);
    formRef?.current?.setFieldValue('currentMethod', value);
    setIsLoading(true);
    const result = await dispatch.cart.getCartDetail({ paymentMethod: value });
    setCartData(result?.data?.result);
    setIsLoading(false);
  };

  const renderPaymentMethods = () => {
    return paymentMethods.map((value: any, index: number) => {
      if (value?.value == paymentMethodValues.G_BUSINESS) {
        if (gBizAccount) {
          const balanceAvail = gBizAccount.balance;
          if (balanceAvail < Math.round(cartData?.totals?.totalPaySum ?? 0) ||
              cartState?.selectedVouchers?.length > 0 ||
              gBizAccount.employee?.status !== 1) {
            setDisableGBizOption(true);
          }

          return (
              <Col
                  span={24}
                  key={index}
                  className={`${classes.paymentMethodItem} ${disableGBizOption && classes.disableGBiz}`}
                  onClick={() => {
                    if (!disableGBizOption) {
                      onSelectPaymentMethodClick(value?.value);
                    }
                  }}
              >
                <AppRadioBox isChecked={currentMethod === value?.value ? true : false} disabled={disableGBizOption || false}>
                  <div className={classes.checkBoxValue}>
                    {value.icon()}
                    <div className={classes.paymentMethodName}>
                      <div>{value.name}</div>
                      <div className={classes.gBizBalanceText}>Hạn mức còn lại {formatCurrency(balanceAvail)}</div>
                    </div>
                  </div>
                </AppRadioBox>
              </Col>
          );
        }
      } else {
        return (
            <Col
                span={12}
                key={index}
                className={classes.paymentMethodItem}
                onClick={() => onSelectPaymentMethodClick(value?.value)}
            >
              <AppRadioBox isChecked={currentMethod === value?.value ? true : false}>
                <div className={classes.checkBoxValue}>
                  {value.icon()}
                  <div className={classes.paymentMethodName}>{value.name}</div>
                </div>
              </AppRadioBox>
            </Col>
        );
      }
    });
  };
  return (
    <div>
      <Formik
        validateOnChange={false}
        innerRef={formRef}
        initialValues={{
          currentMethod: '',
        }}
        validationSchema={VerifyInfoSchema}
        onSubmit={(values) => {
          onSubmitClick();
        }}
      >
        {({ errors, touched, setFieldValue, values, handleSubmit, setValues }) => {
          return (
            <Fragment>
              <CouponModal
                onApplyVoucherClick={onApplyVoucherClick}
                isVisible={couponModalVisible}
                onDismiss={onCloseCouponModal}
                onQrCodeClick={onQrCodeClick}
                voucherData={voucherData}
                myVoucherData={myVoucherData}
                selectedVouchers={cartState?.selectedVouchers}
                onCancelCoupon={onCancelCoupon}
                onSubmitCodeInput={onSubmitCodeInput}
                onApplyCoupon={onApplyCoupon}
              />

              <div
                style={
                  couponModalVisible
                    ? {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        zIndex: 3,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }
                    : { overflow: 'auto' }
                }
              ></div>

              <Header title="Xác nhận thanh toán" />
              <CartDivider />
              <div className={classes.paymentMethodsContainer}>
                <div className={classes.title}>Phương thức thanh toán</div>
                <Row className={classes.paymentMethodsListContainer}>{renderPaymentMethods()}</Row>
                {errors?.currentMethod ? (
                  <div style={{ color: 'red', marginTop: 5 }}>{errors?.currentMethod}</div>
                ) : (
                  <></>
                )}
              </div>
              <CartDivider />
              {appContext.selectedBrand?.brandId != BRAND_IDS.ICOOK && (
                <>
                  <div className={classes.discountContainer}>
                    <div className={classes.discountTitleContainer}>
                      <div className={classes.title}>Mã giảm giá</div>
                      {isVisibleChooseVoucher && (
                          <div className={classes.selectDiscountCodeText} onClick={onOpenCouponModal}>
                            Chọn mã
                          </div>
                      )}
                    </div>
                    <div className={classes.voucherListContainer}>
                      {cartData?.selectedVouchers?.length ? (
                          cartData?.selectedVouchers.map((value: any, index: number) => {
                            return (
                                <div className={classes.voucherItem} key={index}>
                                  <span>{value?.code}</span>
                                  <div
                                      onClick={() => {
                                        onCancelCoupon(value);
                                      }}
                                      className={classes.voucherCloseIcon}
                                  >
                                    <VoucherCloseIcon />
                                  </div>
                                </div>
                            );
                          })
                      ) : (
                          <div className={classes.noDiscountCodeText}>Bạn chưa thêm mã giảm giá nào</div>
                      )}
                    </div>
                  </div>
                  <CartDivider />
                </>
              )}
              <div className={classes.priceContainer}>
                <div className={classes.priceMainRow}>
                  <div className={classes.priceMainItemTitle}>Tạm tính</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(Math.round(cartData?.totals?.totalPriceWithoutShipping ?? 0))}
                  </div>
                </div>
                {cartData?.totals?.totalDiscount ? (
                  <div className={classes.priceMainRow}>
                    <div className={classes.priceMainItemTitle}>Giảm giá</div>
                    <div className={classes.priceMainItemValue}>
                      - {formatCurrency(Math.round(cartData?.totals?.totalDiscount ?? 0))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {discounts.map((discount) => (
                  <div className={classes.secondaryRow} key={`discount-${discount.code}`}>
                    <div className={classes.secondaryTitleText}>
                      <div className={classes.circle}></div>
                      <span>{discount.code}</span>
                    </div>
                    <div className={classes.secondaryValueText}>
                      -{formatCurrency(discount.denominationValue)}
                    </div>
                  </div>
                ))}
                {/* <div className={classes.priceSubRow}> */}
                {/* <div className={classes.priceSubItemTitle}>
            Giảm giá chương trình A
          </div>
          <div className={classes.priceSubItemValue}>- 99.000đ</div>
        </div>
        <div className={classes.priceSubRow}>
          <div className={classes.priceSubItemTitle}>Giảm giá campaign B</div>
          <div className={classes.priceSubItemValue}>- 100.000đ</div>
        </div> */}
                <div className={classes.priceMainRow}>
                  <div className={classes.priceMainItemTitle}>Phí vận chuyển</div>
                  <div className={classes.priceMainItemValue}>
                    + {formatCurrency(Math.round(cartData?.totals?.shipping?.price ?? 0))}
                  </div>
                </div>
                <div className={classes.priceMainRow}>
                  <div className={classes.priceMainItemTitle}>Tổng tiền trước VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(
                      Math.round(cartData?.totals?.totalPriceWithoutShipping ?? 0) -
                        Math.round(cartData?.totals?.totalDiscount ?? 0) +
                        Math.round(cartData?.totals?.shipping?.price ?? 0),
                    )}
                  </div>
                </div>
                <div className={classes.priceMainRow}>
                  <div className={classes.priceMainItemTitle}>VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(Math.round(cartData?.totals?.totalTax ?? 0))}
                  </div>
                </div>
                <div className={classes.priceMainRow}>
                  <div className={classes.priceMainItemTitle}>Tổng tiền sau VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(
                      Math.round(cartData?.totals?.totalPriceWithoutShipping ?? 0) -
                        Math.round(cartData?.totals?.totalDiscount ?? 0) +
                        Math.round(cartData?.totals?.shipping?.price ?? 0) +
                        Math.round(cartData?.totals?.totalTax ?? 0),
                    )}
                  </div>
                </div>
                <hr className={classes.priceDivider} />
                {cartData?.totals?.totalCashVoucher ? (
                  <div className={classes.priceMainRow}>
                    <div className={classes.priceMainItemTitle}>Voucher</div>
                    <div className={classes.priceMainItemValue}>
                      - {formatCurrency(Math.round(cartData?.totals?.totalCashVoucher ?? 0))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {vouchers.map((voucher) => (
                  <div className={classes.secondaryRow} key={`voucher-${voucher.code}`}>
                    <div className={classes.secondaryTitleText}>
                      <div className={classes.circle}></div>
                      <span>{voucher.code}</span>
                    </div>
                    <div className={classes.secondaryValueText}>
                      -{formatCurrency(voucher.denominationValue)}
                    </div>
                  </div>
                ))}

                <div className={classes.totalPriceRow}>
                  <div className={classes.totalPriceTitle}>Tổng tiền phải trả</div>
                  <div className={classes.totalPriceValue}>
                    {formatCurrency(Math.round(cartData?.totals?.totalPaySum ?? 0))}
                  </div>
                </div>
              </div>
              <CartDivider />
              <div className={classes.discountContainer}>
                <div className={classes.discountTitleContainer}>
                  <div className={classes.title}>Nhận hóa đơn điện tử</div>
                  <div
                    className={classes.selectDiscountCodeText}
                    onClick={props.onClickBillingText}
                  >
                    Chi tiết
                  </div>
                </div>
              </div>
              <CartDivider />
              {gBizAccount && (
                  <div className={classes.noteForGBiz}>
                    * Thanh toán bằng tài khoản doanh nghiệp sẽ không được áp dụng các chương trình giảm giá cho KH cá nhân và không được tích lũy.
                  </div>
              )}
              <div className={classes.submitButtonContainer}>
                <CartButton
                  text={'Thanh toán & Đặt hàng'}
                  containerStyle={classes.submitButton}
                  onClick={handleSubmit}
                />
              </div>
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
});
