import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const RadioUncheckedIcon = React.memo(
  ({ className, color }: React.PropsWithChildren<Props>) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="9.5"
          fill={color || "white"}
          stroke="#C0C0C0"
        />
      </svg>
    );
  }
);
