import React from "react";
interface Props {
  className?: any;
  onClick?: () => void;
}
export const PreviousIcon = React.memo((props: Props) => {
  const { onClick } = props;
  return (
    <svg onClick={onClick} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.26825 13.8889L7.8942 13.2629C8.04235 13.1148 8.04235 12.8746 7.8942 12.7264L2.18141 7L7.8942 1.27359C8.04235 1.12544 8.04235 0.885234 7.8942 0.737054L7.26825 0.111111C7.12011 -0.0370369 6.8799 -0.0370369 6.73172 0.111111L0.111111 6.73175C-0.0370369 6.8799 -0.0370369 7.12011 0.111111 7.26829L6.73172 13.8889C6.8799 14.037 7.12011 14.037 7.26825 13.8889Z" fill="black"/>
    </svg>
    
  );
});
