import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useQuantityControlStyles = makeStyles({
  quantityEditorContainer: {
    backgroundColor: '#EDEDED',
    borderRadius: 8,
    padding: 20,
    width: '100%',
    marginTop: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: 0,
      marginTop: 0,
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
    },
  },
  minusIconContainer: {
    height: 36,
    width: 36,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #C0C0C0',
    cursor: 'pointer',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 32,
      width: 32,
      border: '1px solid #D8D8D8',
    },
  },
  minusIcon: {
    fontSize: 16,
    color: '#7A7A7A',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      color: colors.primaryColor,
    },
  },
  plusIconContainer: {
    height: 36,
    width: 36,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primaryColor,
    cursor: 'pointer',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 32,
      width: 32,
      border: '1px solid #D8D8D8',
      backgroundColor: 'transparent',
    },
  },
  plusIcon: {
    fontSize: 16,
    color: '#fff',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      color: colors.primaryColor,
    },
  },
  quantityText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 20,
    margin: '0px 24px',
    userSelect: 'none',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      margin: '0px 16px',
    },
  },
});
