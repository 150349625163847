import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useHomeHeaderStyles = makeStyles({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: 1,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  headerBackgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: 95,
  },
  backIcon: {
    objectFit: 'contain',
    color: '#fff',
    fontSize: 20,
  },
  headerContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    position: 'relative',
  },
  deliveryContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deliveryText: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 300,
  },
  locationIcon: {
    height: 16,
    width: 16,
    objectFit: 'contain',
    color: '#fff',
    marginRight: 4,
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  locationText: {
    color: '#fff',
    fontWeight: 700,
  },
  delieveryInfoContainer: {
    marginLeft: 26,
    flex: 1,
  },
  headerActionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionIcon: {
    height: 24,
    width: 24,
    marginLeft: 20,
  },
  searchTextInputContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: '0px 12px',
    marginTop: 12,
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
  },
  searchTextInput: {
    flex: 1,
    marginLeft: 8,
    padding: '16px 0px',
    fontSize: 15,
    fontWeight: 300,
    color: '#A5A5A5',
    lineHeight: '19px',
  },
  arrowDownIcon: {
    height: 16,
    width: 16,
    objectFit: 'contain',
    color: '#fff',
    marginLeft: 20,
  },

  headerOverlayContainer: {
    backgroundColor: '#fff',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    padding: '16px 16px',
    zIndex: 100,
    transition: 'top 0.3s ease',
  },
  black: {
    color: colors.textColor,
  },
  overlayLocationText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  logo: {
    width: 54,
    height: 36,
    objectFit: 'contain',
  },
  cartIconContainer: {
    position: 'relative',
  },
  badge: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: colors.brandYellow,
    position: 'absolute',
    top: -10,
    right: -10,
    color: '#fff',
    textAlign: 'center',
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: 12,
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
