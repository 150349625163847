import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const ModalCloseIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14.2219" y="0.000244141" width="2.51412" height="20.113" rx="1.25706" transform="rotate(45 14.2219 0.000244141)" fill={color || "#221F20"} />
      <rect x="16.0007" y="14.222" width="2.51412" height="20.113" rx="1.25706" transform="rotate(135 16.0007 14.222)" fill={color || "#221F20"} />
    </svg>
  );
});
