import React from "react";

interface Props {
  className?: string;
}

export const CashIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6 14.6667H6.8C6.69391 14.6667 6.59217 14.6152 6.51716 14.5235C6.44214 14.4318 6.4 14.3074 6.4 14.1778V10.011L5.2456 10.5756C4.95959 10.7158 4.64041 10.7158 4.3544 10.5756L3.2 10.011V11.2444C3.2 11.3741 3.15786 11.4985 3.08284 11.5901C3.00783 11.6818 2.90609 11.7333 2.8 11.7333H0.4C0.293913 11.7333 0.192171 11.6818 0.117157 11.5901C0.0421429 11.4985 0 11.3741 0 11.2444V0.488889C0 0.359227 0.0421429 0.234877 0.117157 0.143192C0.192171 0.0515078 0.293913 0 0.4 0H2.8C2.90609 0 3.00783 0.0515078 3.08284 0.143192C3.15786 0.234877 3.2 0.359227 3.2 0.488889V0.977778H11.6C11.7061 0.977805 11.8078 1.02933 11.8828 1.12102L13.3656 2.93333H23.6C23.7061 2.93333 23.8078 2.98484 23.8828 3.07653C23.9579 3.16821 24 3.29256 24 3.42222V14.1778C24 14.3074 23.9579 14.4318 23.8828 14.5235C23.8078 14.6152 23.7061 14.6667 23.6 14.6667ZM2.4 0.977778H0.799999V10.7556H2.4V0.977778ZM11.4344 1.95556H3.2V8.95791L4.6512 9.66729C4.74654 9.71424 4.85306 9.71424 4.9484 9.66729L6.6512 8.83618C6.69849 8.81276 6.74898 8.80049 6.8 8.8H10.4C10.6122 8.8 10.8157 8.69698 10.9657 8.51362C11.1157 8.33025 11.2 8.08154 11.2 7.82222C11.2 7.5629 11.1157 7.3142 10.9657 7.13083C10.8157 6.94746 10.6122 6.84444 10.4 6.84444H5.2V5.86667H6.4V3.42222C6.4 3.29256 6.44214 3.16821 6.51716 3.07653C6.59217 2.98484 6.69391 2.93333 6.8 2.93333H12.2344L11.4344 1.95556ZM23.2 3.91111H7.2V5.86667H10.4C10.8243 5.86667 11.2313 6.0727 11.5314 6.43944C11.8314 6.80617 12 7.30358 12 7.82222C12 8.34087 11.8314 8.83827 11.5314 9.20501C11.2313 9.57175 10.8243 9.77778 10.4 9.77778H7.2V13.6889H23.2V3.91111Z" fill="#221F20"/>
      <path d="M22.4001 5.37774V7.3333H21.6001V5.86663H20.4001V4.88885H22.0001C22.1062 4.88885 22.2079 4.94036 22.2829 5.03205C22.3579 5.12373 22.4001 5.24808 22.4001 5.37774Z" fill="#221F20"/>
      <path d="M21.6001 10.2667H22.4001V12.2222C22.4001 12.3519 22.3579 12.4762 22.2829 12.5679C22.2079 12.6596 22.1062 12.7111 22.0001 12.7111H20.4001V11.7333H21.6001V10.2667Z" fill="#221F20"/>
      <path d="M8.8 11.7333H10V12.7111H8.4C8.29391 12.7111 8.19217 12.6596 8.11716 12.5679C8.04214 12.4762 8 12.3519 8 12.2222V10.2667H8.8V11.7333Z" fill="#221F20"/>
      <circle cx="15.3333" cy="8.66665" r="3.33333" fill="#E96E34"/>
      </svg>      
    );
  }
);
