import { useMediaQuery } from '@material-ui/core';
import { Carousel } from 'antd';
import { startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { CartFooter } from '../../components/cart-footer';
import { Container } from '../../components/container';
import { Header } from '../../components/header';
import { ArrowLeftIcon } from '../../components/icons/arrow-left';
import { CircleGreenTickFilledIcon } from '../../components/icons/circle-green-tick-filled';
import { FavoriteIcon } from '../../components/icons/favorite';
import { FavoriteOutlinedIcon } from '../../components/icons/favorite-outlined';
import { ModalCloseIcon } from '../../components/icons/modal-close';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';
import { images } from '../../core/contants/images';
import { IBanner } from '../../core/models/banner';
import { IBrand, IOpenTime, IRestaurant } from '../../core/models/brand';
import { IProduct } from '../../core/models/product';
import { ITag } from '../../core/models/tag';
import { ITicket } from '../../core/models/ticket';
import { timeStringToSeconds } from '../../core/ultis/date-time';
import { bannerService } from '../../services/banner-service';
import { brandService } from '../../services/brand-service';
import { productService } from '../../services/product-service';
import { tagService } from '../../services/tag-service';
import { voucherService } from '../../services/voucher-service';
import { Dispatch, RootState, store } from '../../store';
import { OVERLAY_MODAL } from '../../store/models/app-context';
import { CouponPopup } from '../home/components/coupon-popup/coupon-popup.component';
import { useBrandDetailStyles } from './brand-detail.style';
import { BrandBestSellers } from './components/brand-best-sellers/brand-best-sellers.component';
import { BrandGeneralInfo } from './components/brand-general-info/brand-general-info.component';
import { BrandHotDeal } from './components/brand-hot-deal/brand-hot-deal.component';
import { BrandNewProducts } from './components/brand-new-products/brand-new-products.component';
import { BrandSlider } from './components/brand-slider/brand-slider.component';
import { ProductByCategories } from './components/product-by-categories/product-by-categories.component';
import { RestaurantClosePopup } from './components/restaurant-close-popup/restaurant-close-popup.component';
import { RestaurantSelector } from './components/restaurant-selector/restaurant-selector.component';
import { TopFavourite } from './components/top-favorrite/top-favourite.component';
import { BrandTabItem } from './models/tabs';
import "./custom.css";
import {useCookies} from "react-cookie";
import moment from "moment";
import {CouponList} from "./components/coupon-list/coupon-list.component";

export const BrandDetail = React.memo(() => {
  const classes = useBrandDetailStyles();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const { id } = useParams<{ id: string }>();

  const [isVisibleBrandSelector, setIsVisibleBrandSelector] = useState(false);
  const hotDealRef = useRef<HTMLDivElement>(null);
  const topFavoriteRef = useRef<HTMLDivElement>(null);
  const productByCategoriesRef = useRef<HTMLDivElement>(null);
  const newProductRef = useRef<HTMLDivElement>(null);
  const bestSellerRef = useRef<HTMLDivElement>(null);

  const pageSize = 8;
  const [isVisibleProductDetail, setIsVisibleProductDetail] = useState(false);
  const [isVisibleRestaurantClosePopup, setIsVisibleRestaurantClosePopup] = useState(false);
  const [brandInfo, setBrandInfo] = useState<IBrand>();
  const [openTime, setOpenTime] = useState<IOpenTime>();
  const [hotDealProducts, setHotDealProducts] = useState<IProduct[]>([]);
  const [newProducts, setNewProducts] = useState<IProduct[]>([]);
  const [bestSellers, setBestSellers] = useState<IProduct[]>([]);
  const [productsByCategory, setProductsByCategory] = useState<IProduct[]>([]);
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState<IRestaurant>();
  const [tags, setTags] = useState<ITag[]>([]);
  const [tickets, setTicktes] = useState<ITicket[]>([]);
  const [selectedTag, setSelectedTag] = useState<ITag>();
  const [currentTag, setCurrentTag] = useState<ITag>();
  const { hash } = useLocation();

  const [page, setPage] = useState(1);
  const [initCompleted, setInitCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const appContext = useSelector((state: RootState) => state.appContext);
  const user = useSelector((state: RootState) => state.user);
  const favoriteBrands = useSelector((state: RootState) => state.brand.favorite);
  const isFavorite = useMemo(
    () => favoriteBrands.allIds.some((id) => id === brandInfo?.brandId),
    [brandInfo, favoriteBrands],
  );
  const [popupBanner, setPopupBanner] = useState<IBanner[]>([]);
  const [isVisibleCouponPopup, setIsVisibleCouponPopup] = useState(false);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const cartLength = useSelector((state: RootState) => store.select.cart.cartLength(state));

  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const tabs: BrandTabItem[] = useMemo(
    () =>
      [
        {
          id: 'hot-deal',
          label: 'Hot deal',
          visible: hotDealProducts.length > 0,
          icon: images.premiumQuality,
        },
        {
          id: 'best-seller',
          label: 'Best seller',
          visible: bestSellers.length > 0,
          icon: images.bestSeller,
        },
        { id: 'new-product', label: 'Món mới', visible: newProducts.length > 0, icon: images.new1 },
        {
          id: 'product-by-categories',
          label: 'TẤT CẢ SẢN PHẨM',
          visible: tags.length > 0,
          icon: images.clipboard,
        },
      ].filter((i) => i.visible),
    [hotDealProducts, bestSellers, newProducts],
  );
  const [activeTabId, setActiveTabId] = useState(tabs[0]?.id);

  const subizElement = document.getElementById('wpwidget');

  const scrollToHash = () => {
    if (hash) {
      let element = document.querySelector(hash);
      element?.scrollIntoView();
    }
  };

  const [cookies, setCookies] = useCookies([
    'coupon_brand_showed',
  ]);

  useEffect(() => {
    if (isDesktop) return;
    subizElement?.style?.setProperty('display', 'none', 'important');

    return () => {
      subizElement?.style?.setProperty('display', 'block', 'important');
    };
  }, [isDesktop, subizElement]);

  useEffect(() => {
    async function getSelectedRestaurant() {
      const response = await brandService.getSelectedRestaurant();
      if (response?.data?.result && response?.data?.result?.restaurant?.brandId === id) {
        setSelectedRestaurant(response?.data?.result);
      }
    }

    getSelectedRestaurant();
  }, []);

  async function getTickets() {
    const response = await voucherService.getTickets({
      brandId: id,
      provinceId: appContext.selectedProvince?.id,
    });
    setTicktes(response?.data?.result || []);
  }

  useEffect(() => {
    async function getBrandInfo() {
      const response = await brandService.getBrandInfo({
        brandId: id,
        provinceId: appContext?.selectedProvince?.id,
      });

      setBrandInfo(response?.data?.result?.category);
      setOpenTime(response?.data?.result?.openTime);
    }

    async function getHotDealProducts() {
      const response = await productService.getProducts({
        provinceId: appContext.selectedProvince?.id,
        group: 'hot-deal',
        brandId: id,
        perPage: -1,
      });
      setHotDealProducts(response.data.result || []);
      scrollToHash();
    }

    async function getNewProducts() {
      const response = await productService.getProducts({
        provinceId: appContext.selectedProvince?.id,
        group: 'mon-moi',
        brandId: id,
        perPage: -1,
      });
      setNewProducts(response.data?.result || []);
      scrollToHash();
    }

    async function getBestSellers() {
      const response = await productService.getProducts({
        provinceId: appContext.selectedProvince?.id,
        group: 'best-seller',
        brandId: id,
        perPage: -1,
      });
      setBestSellers(response.data.result || []);
      scrollToHash();
    }

    async function getGroups() {
      // const response = await tagService.getTags({ provinceId: appContext.selectedProvince?.id });
      const response = await brandService.getGroups({
        provinceId: appContext.selectedProvince?.id,
        brandId: id,
      });
       let data = response?.data?.result || [],
         listTag;
       if (data) {
         listTag = data.filter((value) => {
           return !['hot-deal', 'best-seller', 'mon-moi'].includes(value.slug as string);
         });
       }
      setTags(listTag || []);
    }

    async function getRestaurants() {
      const response = await brandService.getRestaurants({
        brandId: id,
        provinceId: appContext.selectedProvince?.id,
        latitude: appContext.latitude,
        longitude: appContext.longitude,
      });
      setRestaurants(response?.data?.result || []);

      if (!selectedRestaurant && response?.data?.result) {
        onChangedRestaurant(response.data.result[0]);
      }
    }

    async function getBanners() {
      const response = await bannerService.getBanners({
        position: 'brand-sliders',
        // position: 'home-sliders',
        provinceId: appContext.selectedProvince?.id,
        brandId: id,
      });

      setBanners(response?.data?.result || []);
    }

    getBrandInfo();
    getHotDealProducts();
    getGroups();
    getRestaurants();
    getTickets();
    getBanners();
    getNewProducts();
    getBestSellers();
  }, [appContext.selectedProvince, id]);

  useEffect(() => {
    async function getPopupBanner() {
      if (appContext.selectedProvince?.id) {
        const response = await bannerService.getBanners({
          position: 'home',
          provinceId: appContext.selectedProvince?.id,
          brandId: id,
        });

        if (
          response.data.result &&
          response.data.result.length > 0 &&
          !appContext.isSeenPopupBannerByBrand[id]
        ) {
          if (!cookies['coupon_brand_showed']) {
            setPopupBanner(response.data.result ? response?.data?.result : []);
            setIsVisibleCouponPopup(true);
            if (response?.data?.result) {
              setCookies('coupon_brand_showed', true, {
                expires: moment().add(6, 'h').toDate(),
              });
            }
          }
        }
      }
    }

    if (!appContext.isSeenPopupBannerByBrand[id]) {
      getPopupBanner();
    }
  }, [appContext.selectedProvince, id, appContext.isSeenPopupBannerByBrand]);

  useEffect(() => {
    setSelectedTag(tags[0]);
  }, [tags]);

  useEffect(() => {
    setCurrentTag(selectedTag);
  }, [selectedTag]);

  useEffect(() => {
    async function getProductsByTag() {
      const response = await productService.getProducts({
        provinceId: appContext.selectedProvince?.id,
        brandId: id,
        page,
        perPage: pageSize,
        group: selectedTag?.slug,
      });
      setIsEndOfList(!response.data.result || (response.data?.result?.length || 0) < pageSize);

      if (page > 1) {
        setProductsByCategory((value) => [...value, ...(response?.data?.result || [])]);
      } else {
        setProductsByCategory(response?.data?.result || []);
        scrollToHash();
      }
      setInitCompleted(true);
      setIsLoading(false);
    }

    if (selectedTag && (page > 1 || (page == 1 && selectedTag.slug != currentTag?.slug)))
      setIsLoading(true);
      getProductsByTag();
  }, [appContext.selectedProvince, id, selectedTag, page]);

  useEffect(() => {
    if (appContext.addedFromHome) {
      dispatch.appContext.setFlagAddProductSuccess(true);
      dispatch.appContext.setAddedFromHome(false);
    }
  }, [cartLength, appContext.addedFromHome]);

  const onScrollToEnd = useCallback(() => {
    if (initCompleted) {
      setPage((value) => value + 1);
    }
  }, [page, initCompleted]);

  const onPressProduct = useCallback(
    (product: IProduct) => {
      setIsVisibleProductDetail(true);
    },
    [history, isDesktop],
  );

  const onCloseRestaurantSelector = () => {
    setIsVisibleBrandSelector(false);
    setTimeout(() => {
      document.body.style.overflow = 'unset';
    }, 100);
  };

  const openBrandSeletor = () => {
    setIsVisibleBrandSelector(true);
  };

  const onPressHotDeal = useCallback(() => {
    if (!hotDealRef.current) return;

    hotDealRef.current.scrollIntoView(true);
  }, []);

  const onPressTopFavorite = useCallback(() => {
    if (!topFavoriteRef.current) return;

    topFavoriteRef.current.scrollIntoView(true);
  }, []);

  const onPressProductByCategories = useCallback(() => {
    if (!productByCategoriesRef.current) return;

    productByCategoriesRef.current.scrollIntoView(true);
  }, []);

  const onPressBestSeller = useCallback(() => {
    if (!bestSellerRef.current) return;

    bestSellerRef.current.scrollIntoView(true);
  }, []);

  const onPressNewProduct = useCallback(() => {
    if (!newProductRef.current) return;

    newProductRef.current.scrollIntoView(true);
  }, []);

  const onSelectTab = useCallback(
    (tab: BrandTabItem) => {
      setActiveTabId(tab.id);

      const tabActionDictionary: any = {
        'hot-deal': onPressHotDeal,
        'top-favorite': onPressTopFavorite,
        'product-by-categories': onPressProductByCategories,
        'new-product': onPressNewProduct,
        'best-seller': onPressBestSeller,
      };

      const action = tabActionDictionary[tab.id];
      if (action) action();
    },
    [
      onPressHotDeal,
      onPressProductByCategories,
      onPressTopFavorite,
      onPressBestSeller,
      onPressNewProduct,
    ],
  );

  const onPressFavorite = useCallback(
    async (e) => {
      if (brandInfo) {
        if (!user?.authentication?.token) {
          dispatch.appContext.setOverlayModal(OVERLAY_MODAL.LOGIN);
        } else if (isFavorite) {
          dispatch.brand.deleteFavorite({ brand: brandInfo });
        } else {
          dispatch.brand.addFavorite({ brand: brandInfo });
        }
      }
    },
    [brandInfo, isFavorite],
  );

  const onChangedRestaurant = useCallback(
    async (restaurant: IRestaurant) => {
      const isInBusinessHour = () => {
        const openTimeInSeconds = timeStringToSeconds(
          restaurant?.restaurant?.openTime || '00:00:00',
        );
        const closeTimeInSeconds = timeStringToSeconds(
          restaurant?.restaurant?.closeTime || '00:00:00',
        );
        const now = Date.now();
        const currentTimeInSeconds = (now.valueOf() - startOfDay(now).valueOf()) / 1000;
        return (
          openTimeInSeconds <= currentTimeInSeconds && currentTimeInSeconds <= closeTimeInSeconds
        );
      };

      setSelectedRestaurant(restaurant);
      onCloseRestaurantSelector();

      if (!isInBusinessHour()) {
        setTimeout(() => {
          setIsVisibleRestaurantClosePopup(true);
        }, 500);
      }

      const response = await brandService.changedRestaurant(restaurant.restaurantCode, {
        longitude: appContext.longitude,
        latitude: appContext.latitude,
      });
      if (response?.data?.messageCode === 200) {
        setSelectedRestaurant(response?.data?.result);
      }
    },
    [appContext.latitude, appContext.longitude],
  );

  const onSaveGift = async (ticket: ITicket) => {
    if (!user?.authentication?.token) {
      dispatch.appContext.setOverlayModal(OVERLAY_MODAL.LOGIN);
      return;
    }
    const response = await voucherService.saveGift({
      voucherCode: ticket.voucherCode,
      value: 1,
      promotionId: ticket.promotionId,
    });

    if (response?.data?.messageCode === 200) {
      getTickets();
      toast.success('Đã lấy mã và lưu vào ví của bạn', {
        position: 'bottom-center',
        className: 'success-toast-container',
        bodyStyle: {
          color: '#fff',
        },
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        icon: <CircleGreenTickFilledIcon />,
        closeButton: (
          <div style={{ margin: 'auto', marginRight: 8 }}>
            <ModalCloseIcon color="#fff" />
          </div>
        ),
      });
    }
  };

  const scrollToContentCategory = () => {
    let offsetTop = document.getElementById("product-categories-content");
    let offsetHeight = document.getElementById("category-header");
    if (offsetTop && offsetHeight) {
      let scrollDiv = offsetTop.offsetTop - offsetHeight.offsetHeight;
      if (!isDesktop) {
        scrollDiv -= 30;
      }
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }
  };

  return (
    <Container className={classes.container} backgroundColor="#f1f1f1">
      <Header
        title={brandInfo?.name || ''}
        surfix={
          <div onClick={onPressFavorite}>
            {isFavorite ? (
              <FavoriteIcon color={colors.brandYellow} />
            ) : (
              <FavoriteOutlinedIcon color="#ddd" />
            )}
          </div>
        }
        onBackClick={() => history.push('/')}
      />

      <BrandGeneralInfo
        tickets={tickets}
        selectedRestaurant={selectedRestaurant}
        brandInfo={brandInfo}
        openBrandSelector={openBrandSeletor}
        tabs={tabs}
        activeTabId={activeTabId}
        onSelectTab={onSelectTab}
        onPressFavorite={onPressFavorite}
        isFavorite={isFavorite}
        openTime={openTime}
        onSaveGift={onSaveGift}
      />

      <div className={classes.contentContainer}>
        {isDesktop && banners.length > 0 && <BrandSlider banners={banners} />}
        <CouponList
            tickets={tickets}
            containerClassName={classes.couponContainer}
            onSaveGift={onSaveGift}
        />
        {hotDealProducts.length > 0 && (
          <BrandHotDeal
            tickets={tickets}
            products={hotDealProducts}
            containerRef={hotDealRef}
            onPressProduct={onPressProduct}
            onSaveGift={onSaveGift}
          />
        )}
        {bestSellers.length > 0 && (
          <BrandBestSellers
            products={bestSellers}
            containerRef={bestSellerRef}
            onPressProduct={onPressProduct}
          />
        )}
        {newProducts.length > 0 && (
          <BrandNewProducts
            products={newProducts}
            containerRef={newProductRef}
            onPressProduct={onPressProduct}
          />
        )}
        {tags.length > 0 && (
          <ProductByCategories
            onScrollToEnd={onScrollToEnd}
            isEndOfList={isEndOfList}
            products={productsByCategory}
            tags={tags}
            selectedTag={selectedTag}
            isLoading={isLoading}
            onChangedTag={(tag) => {
              if (tag.id != selectedTag?.id) {
                setInitCompleted(false);
                scrollToContentCategory();
                setPage(1);
                setSelectedTag(tag);
                setProductsByCategory([]);
                setIsEndOfList(false);
              }
            }}
            containerRef={productByCategoriesRef}
            onPressProduct={onPressProduct}
          />
        )}
      </div>
      <CartFooter />

      <RestaurantSelector
        selectedRestaurant={selectedRestaurant}
        onChangedRestaurant={onChangedRestaurant}
        restaurants={restaurants}
        onDismiss={onCloseRestaurantSelector}
        isVisible={isVisibleBrandSelector}
      />
      <RestaurantClosePopup
        isVisible={isVisibleRestaurantClosePopup}
        onClose={() => setIsVisibleRestaurantClosePopup(false)}
      />

      <CouponPopup
        isVisible={isVisibleCouponPopup}
        banners={popupBanner}
        close={() => {
          setIsVisibleCouponPopup(false);
          dispatch.appContext.setIsSeenBannerOfBrand(id);
        }}
      />
    </Container>
  );
});
