import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './verify-order.style';
import { DeliveryInfo } from './components/delivery-info/delivery-info.component';
import { ChangeAddress } from './components/change-address/change-address.component';
import { brandService } from '../../services/brand-service';
import { customerService } from '../../services/customer-service';
import { cartService } from '../../services/cart-service';
import moment from 'moment';
import { ModalSpinner } from '../../components/modal-spinner';
import { useMediaQuery } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { Col, Row } from 'antd';
import { Container } from '../../components/container';
import { formatCurrency } from '../../core/ultis/currency';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { voucherService } from '../../services/voucher-service';
import { CouponModal } from '../verify-payment/coupon-modal/coupon-modal.component';
import { CartStep2 } from '../../components/icons/cart-step-2';
import { Dispatch, RootState } from '../../store';
import _ from 'lodash';
import { CustomDatePickerModal } from './components/custom-datepicker-modal/custom-datepicker-modal.component';
import { CustomTimePickerModal } from './components/custom-timepicker-modal/custom-timepicker-modal.component';
import { TextInput } from '../../components/text-input';
import { CalendarIcon } from '../../components/icons/calendar';
import { TimeIcon } from '../../components/icons/time';
import { paymentMethods, paymentMethodValues } from '../../core/contants/paymentMethodValues';
import { AtmIcon } from '../../components/icons/atm-icon';
import { VisaIcon } from '../../components/icons/visa-icon';
import { MomoIcon } from '../../components/icons/momo-icon';
import { VnPayIcon } from '../../components/icons/vnpay-icon';
import { CashIcon } from '../../components/icons/cash-icon';
import { ZaloPayIcon } from '../../components/icons/zalo-pay-icon';
import { ShopeePayIcon } from '../../components/icons/shopee-pay';
import { AppRadioBox } from '../../components/radio';
import { CartTextInput } from '../update-address/components/cart-text-input/cart-text-input.component';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ChangeAddressModal } from './components/change-address-modal/change-address-modal.component';
import { ArrowDownIcon } from '../../components/icons/arrow-down';
import { ProvinceSelectorBottomSheet } from '../../components/province-selector';
import { AppSelect } from '../../components/select';
import { VerifyPaymentModal } from '../verify-payment/verify-payment-modal/verify-payment-modal.component';
import { orderService } from '../../services/order-service';
import { NotificationModal } from '../verify-payment/notification-modal/notification-modal.component';
import { CartButton } from '../../components/cart-button';
import { VoucherCloseIcon } from '../../components/icons/voucher-close';
import { DiscountFoodModal } from './components/discount-food-modal/discount-food-modal.component';
import { DiscountGroupModal } from './components/discount-group-modal/discount-group-modal.component';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { GG_API_KEY } from '../../core/environments/env';
import clsx from 'clsx';
import { RestaurantSelector } from '../brand-detail/components/restaurant-selector/restaurant-selector.component';
import { IRestaurant } from '../../core/models/brand';
import { useCookies } from 'react-cookie';
import { EditAddressModal } from '../edit-address/edit-address.dialog';
import { IAddress } from '../../core/models/customer';
import { IFramePayment } from '../verify-payment/iframe-payment/iframe-payment.component';
import { IVoucher } from '../../core/models/ticket';
import { BRAND_IDS } from '../../core/contants/brand';
import { NetCore } from "../../assets/netcore/netcore";
import {slice} from "lodash-es";
import {NoCartData} from "../cart/components/no-data/no-data.component";
import {userService} from "../../services/user-service";
import {IGBizAccount} from "../../core/models/user";
import {GBusinessIcon} from "../../components/icons/g-business";

interface Props {
  cartState: any;
  cartReducer: any;
}

let isEmptyContentOrder = true;

const Verify = React.memo((props: Props) => {
  const classes = useStyles();
  const { cartState, cartReducer } = props;

  const appContext = useSelector((state: RootState) => state.appContext);
  const [cookies, setCookies, removeCookies] = useCookies([
    'traffic-id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_location',
    'utm_term'
  ]);
  const [isGetData, setIsGetData] = useState(false);
  const [currentStage, setCurrentStage] = useState('delivery');
  const [selectedAddress, setSelectedAddress] = useState({} as any);
  const [customerAddresses, setCustomerAddresses] = useState([] as any);
  const [selectedRestaurant, setSelectedRestaurant] = useState() as any;
  const [cartData, setCartData] = useState({} as any);
  const [blockTimes, setBlockTimes] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [cartTotalData, setCartTotalData] = useState({} as any);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const [voucherData, setVoucherData] = useState([] as any);
  const [myVoucherData, setMyVoucherData] = useState([] as any);
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [cartNote, setCartNote] = useState('');
  const [haveBill, setHaveBill] = useState(false);
  const [changeAddressVisible, setChangeAddressVisible] = useState(false);
  const [restaurantSelectorVisible, setRestaurantSelectorVisible] = useState(false);
  //old
  const [isPlastic, setIsPlastic] = useState(false);
  const [customDatePickerVisible, setCustomDatePickerVisible] = useState(false);
  const [customTimePickerVisible, setCustomTimePickerVisible] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(moment());
  const [timePickerValue, setTimePickerValue] = useState('');
  const [currentMethod, setCurrentMethod] = useState('');
  const [isVisibleCountrySelector, setIsVisibleCountrySelector] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  const [currentPaymentRequest, setCurrentPaymentRequest] = useState({} as any);
  const [orderData, setOrderData] = useState({} as any);
  const [verifyPaymentVisible, setVerifyPaymentVisible] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({}) as any;
  const [voucherModalType, setVoucherModalType] = useState<string>();
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [isVisibleAddAddress, setIsVisibleAddAddress] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [iframePaymentSrc, setIframePaymentSrc] = useState('');
  const [gBizAccount, setGBizAccount] = useState<IGBizAccount>();
  const [disableGBizOption, setDisableGBizOption] = useState(false);
  const [isVisibleChooseVoucher, setIsVisibleChooseVoucher] = useState(true);
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const discounts = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type !== 1),
    [cartState?.selectedVouchers],
  );

  async function getBizAcc() {
    const getBizAcc = await userService.gBizAccountInfo();
    if (getBizAcc?.data?.messageCode === 200) {
      setGBizAccount(getBizAcc?.data?.result);
    }
  }

  useEffect(() => {
    if (couponModalVisible) {
      afterClose();
    }
  }, [couponModalVisible]);

  useEffect(() => {
    if (restaurantSelectorVisible) {
      afterClose();
    }
  }, [restaurantSelectorVisible]);

  useEffect(() => {
    if (changeAddressVisible) {
      afterClose();
    }
  }, [changeAddressVisible]);

  useEffect(() => {
    if (isDesktop && appContext.goToCheckout) {
      let data = {
        brandName: appContext.selectedBrand?.name || '',
        restaurantName: '',
        restaurantTelephone: '',
        orderTotal: Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0),
      };
      NetCore.ncCheckout(data);
      dispatch.appContext.setGoToCheckout(false);
    }
  }, [isDesktop]);

  const vouchers = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type === 1),
    [cartState?.selectedVouchers],
  );
  const [selectedPlace, setSelectedPlace] = useState();
  const formRef = useRef(null) as any;
  const user = useSelector((state: RootState) => state.user);

  const autoCompleteRef = useRef(null) as any;
  useEffect(() => {
    getData();
    getBizAcc();
  }, []);
  useEffect(() => {
    getRestaurants();
  }, [appContext.selectedBrand?.brandId]);
  useEffect(() => {
    formRef?.current?.validateField('currentMethod');
  }, [currentMethod]);
  useEffect(() => {
    formRef?.current?.setFieldValue('onPickUpRestaurant', cartData?.pickupAtRestaurant);
  }, [cartData?.pickupAtRestaurant]);
  useEffect(() => {
    setCartTotalData(cartState?.totals);
  }, [cartState.totals]);

  useEffect(() => {
    if (gBizAccount) {
      const balanceAvail = gBizAccount.balance;
      if (balanceAvail < Math.round(cartState?.total?.totalPaySum ?? 0) || cartState?.selectedVouchers?.length > 0) {
        setDisableGBizOption(true);
        if (currentMethod == paymentMethodValues.G_BUSINESS) {
          setCurrentMethod('');
          setIsVisibleChooseVoucher(true);
          setHaveBill(true);
          formRef?.current?.setValues({
            ...formRef?.current?.values,
            companyName: '',
            taxCode: '',
            invoiceAddress: '',
            invoiceEmail: '',
          });
        }
      } else {
        setDisableGBizOption(false);
      }
    }
  }, [cartState]);

  useEffect(() => {
    setIsPlastic(cartState?.cartData?.cutleryTool === '1' ? true : false);
  }, [cartState?.cartData?.cutleryTool]);

  // useEffect(() => {
  //   if (cartState?.cartData?.deliveryDate) {
  //     setDatePickerValue(cartState?.cartData?.deliveryDate as any);
  //     formRef?.current?.setFieldValue('deliveryDate', cartState?.cartData?.deliveryDate);
  //   }
  // }, [cartState?.cartData?.deliveryDate]);
  useEffect(() => {
    if (timePickerValue) {
      setTimePickerValue(timePickerValue);
      formRef?.current?.setFieldValue('deliveryTime', timePickerValue);
      formRef?.current?.validateField('deliveryTime');
    }
  }, [timePickerValue]);
  const VerifyOrderSchema = Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập').min(3, 'Tên phải có ít nhất 3 kí tự'),
    phone: Yup.string()
      .required('Số điện thoại là bắt buộc')
      .length(10, 'Số điện thoại cần có 10 số'),

    // provinceName: Yup.string().required('Vui lòng nhập'),
    // districtName: Yup.string().required('Vui lòng nhập'),
    // wardName: Yup.string().required('Vui lòng nhập'),
    addressLine1: Yup.string().when('onPickUpRestaurant', {
      is: false,
      then: Yup.string().required('Vui lòng nhập địa chỉ nhận hàng'),
    }),
    companyName: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập tên công ty'),
    }),
    taxCode: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string()
        .required('Vui lòng nhập mã số thuế'),
    }),
    invoiceAddress: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập địa chỉ hóa đơn'),
    }),
    invoiceEmail: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập email hóa đơn').email('Email không hợp lệ'),
    }),
    haveBill: Yup.boolean(),
    deliveryTime: Yup.string().required('Vui lòng chọn thời gian'),
    deliveryDate: Yup.string().required('Vui lòng chọn thời gian'),
    currentMethod: Yup.string().required('Vui lòng chọn phương thức thanh toán'),
    onPickUpRestaurant: Yup.boolean(),
    googleMapPlaceId: Yup.string().nullable(),
  });
  useEffect(() => {
    if (selectedAddress.googleMapPlaceId) {
      formRef?.current?.setFieldValue('googleMapPlaceId', selectedAddress.googleMapPlaceId);
    }

    if (!_.isEmpty(selectedAddress) && isGetData) {
      getNearestRestaurant(selectedAddress);
    }
  }, [selectedAddress]);
  useEffect(() => {
    dispatch.cart.setCartData({
      ...cartState.cartData,
      hasInvoice: haveBill ? 1 : 0,
    });
  }, [haveBill]);

  useEffect(() => {
    if (!selectedPlace) return;
    const place = selectedPlace as any;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    let wardName, provinceName, districtName, address;
    const splittedAddress = (autoCompleteRef.current.value || '').split(', ');

    if (splittedAddress.length == 6) {
      wardName = splittedAddress[splittedAddress.length - 4];
      districtName = splittedAddress[splittedAddress.length - 3];
      provinceName = splittedAddress[splittedAddress.length - 2];
      address = slice(splittedAddress, 0, splittedAddress.length - 4).join(', ');
    } else if (splittedAddress.length == 5) {
      wardName = splittedAddress[splittedAddress.length - 4];
      districtName = splittedAddress[splittedAddress.length - 3];
      provinceName = splittedAddress[splittedAddress.length - 2];
      address = slice(splittedAddress, 0, splittedAddress.length - 4).join(', ');
    } else if (splittedAddress.length == 4) {
      wardName = '';
      districtName = splittedAddress[splittedAddress.length - 3];
      provinceName = splittedAddress[splittedAddress.length - 2];
      address = splittedAddress[0];
    } else {
      alert('Vui lòng nhập địa chỉ cụ thể hơn');
      return;
    }

    getNearestRestaurant({
      addressLine1: address,
      latitude: lat,
      longitude: lng,
    });

    formRef?.current?.setValues({
      ...formRef?.current?.values,
      addressLine1: address,
      district: {
        value: '',
        label: districtName,
      },
      ward: {
        value: '',
        label: wardName,
      },
      province: {
        value: '',
        label: provinceName,
      },
      latitude: lat,
      longitude: lng,
      googleMapPlaceId: place?.place_id,
    });
  }, [selectedPlace]);

  useEffect(() => {
    formRef?.current?.setValues({
      ...formRef?.current?.values,
      companyName: cartState?.cartData?.invoiceName,
      taxCode: cartState?.cartData?.invoiceNumber,
      address: cartState?.cartData?.invoiceAddress,
      email: cartState?.cartData?.invoiceEmail,
    });
  }, [cartState?.cartData?.hasInvoice]);
  useEffect(() => {
    if (!_.isEmpty(selectedAddress)) {
      const addressLine1 = [
        selectedAddress?.addressLine1,
        selectedAddress?.wardName,
        selectedAddress?.districtName,
        selectedAddress?.provinceName,
      ]
        .filter((i) => !!i)
        .join(', ');
      if (autoCompleteRef.current) {
        autoCompleteRef.current.value = [
          selectedAddress?.addressLine1,
          selectedAddress?.wardName,
          selectedAddress?.districtName,
          selectedAddress?.provinceName,
        ]
          .filter((i) => !!i)
          .join(', ');
      }
      formRef?.current?.setValues({
        ...formRef?.current?.values,
        name: selectedAddress?.name,
        phone: selectedAddress?.phone,
        province: {
          value: selectedAddress?.provinceId?.toString() || '',
          label: selectedAddress?.provinceName || '',
        },
        district: {
          value: selectedAddress?.districtId?.toString() || '',
          label: selectedAddress?.districtName || '',
        },
        ward: {
          value: selectedAddress?.wardId?.toString() || '',
          label: selectedAddress?.wardName || '',
        },
        addressLine1: selectedAddress?.addressLine1,
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
      });
    }
  }, [selectedAddress]);
  useEffect(() => {
    // if (!cartState?.cartData?.deliveryTime && !timePickerValue) {
    if (blockTimes?.length) {
      setTimePickerValue(blockTimes[0]);
      formRef?.current?.setFieldValue('deliveryTime', blockTimes[0]);
    }
    // }
  }, [blockTimes]);
  const afterClose = () => {
    setTimeout(() => {
      document.body.style.overflow = 'unset';
    }, 1000);
  };
  async function getRestaurants() {
    if (appContext.selectedBrand?.brandId) {
      const response = await brandService.getRestaurants({
        brandId: appContext.selectedBrand?.brandId,
        provinceId: appContext.selectedProvince?.id,
        latitude: appContext.latitude,
        longitude: appContext.longitude,
      });
      setRestaurants(response?.data?.result || []);
    }
    //   if (!selectedRestaurant && response?.data?.result) {
    //     onChangedRestaurant(response?.data?.result[0]);
    //   }
    // }
  }
  const getNearestRestaurant = async (selectedAddress: any) => {
    try {
      setIsLoading(true);
      const result = (await cartService.getNearestRestaurant({
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
        categoryId: appContext?.selectedBrand?.id,
        brandId: appContext.selectedBrand?.brandId,
        provinceId: selectedAddress?.provinceId,
        wardId: selectedAddress?.wardId,
        districtId: selectedAddress?.districtId,
      })) as any;
      if (result?.data?.result) {
        await onChangedRestaurant(result?.data?.result, true);
        await calculateShippingData(selectedAddress, result?.data?.result?.restaurant?.code);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const calculateShippingData = async (selectedAddress: any, restaurantCode?: any) => {
    try {
      setIsLoading(true);
      const result = await cartService.changeAddress({
        // restaurantCode: restaurantCode ? restaurantCode : selectedRestaurant?.restaurant.code,
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
        address: selectedAddress?.addressLine1,
        // shippingVendor: "grab_express"
      });
      // if (isGetData) {
      const cartRes = await cartReducer.getCartDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      setIsLoading(false);
      // }
    } catch (err) {
      setIsLoading(false);
    } finally {
      document.body.style.overflow = 'unset';
    }
  };
  const onChangeAddressClick = () => {
    setCurrentStage('change-address');
  };
  const onDatePickerInputClick = () => {
    setCustomDatePickerVisible(true);
  };
  const onTimePickerInputClick = () => {
    setCustomTimePickerVisible(true);
  };
  const onCloseCustomDatePicker = () => {
    setCustomDatePickerVisible(false);
  };
  const onSubmitCustomDatePicker = (value: any) => {
    setCustomDatePickerVisible(false);
    setDatePickerValue(value);
    setTimeout(() => {
      onChangeDatePickerValue(value);
    }, 500);
  };
  const onCloseCustomTimePicker = () => {
    setCustomTimePickerVisible(false);
  };
  const onSubmitCustomTimePicker = (value: any) => {
    setCustomTimePickerVisible(false);
    setTimePickerValue(value);
  };
  const onSubmitClick = async (formValues: any) => {
    if (datePickerValue && timePickerValue) {
      // await cartService.changeAddress({
      //   address: formValues?.address ? formValues?.address : selectedAddress.addressLine1,
      //   latitude: formValues?.latitude ? formValues?.latitude : selectedAddress.latitude,
      //   longitude: formValues?.longitude ? formValues?.longitude : selectedAddress.longitude,
      // });
      let wardName, provinceName, districtName, address;
      if (formValues?.latitude) {
        const splittedAddress = (formValues?.address ?? '').split(', ');
        if (splittedAddress.length === 6) {
          wardName = splittedAddress[2];
          districtName = splittedAddress[3];
          provinceName = splittedAddress[4];
          address = `${splittedAddress[0]} ${splittedAddress[1]}`;
        } else if (splittedAddress.length === 5) {
          wardName = splittedAddress[1];
          districtName = splittedAddress[2];
          provinceName = splittedAddress[3];
          address = splittedAddress[0];
        } else if (splittedAddress.length === 4) {
          wardName = splittedAddress[0];
          districtName = splittedAddress[1];
          provinceName = splittedAddress[2];
        }
      }
      dispatch.cart.setCartData({
        addressId: cartData?.pickupAtRestaurant ? selectedRestaurant?.id : selectedAddress?.id,
        deliveryTime: timePickerValue as any,
        deliveryDate: datePickerValue as any,
        pickupAtRestaurant: cartData?.pickupAtRestaurant as any,
        cutleryTool: isPlastic ? '1' : '0',
        recipientName: formValues?.name ? formValues?.name : selectedAddress?.name,
        recipientCellphone: formValues?.phone ? formValues?.phone : selectedAddress?.phone,
        deliveryAddress: address ? address : selectedAddress?.addressLine1,
        wardId: selectedAddress?.wardId ?? '',
        wardName: wardName ? wardName : selectedAddress?.wardName,
        districtId: selectedAddress?.districtId ?? '',
        districtName: districtName ? districtName : selectedAddress?.districtName,
        provinceId: (appContext.selectedProvince?.id as any) ?? '',
        provinceName: provinceName ? provinceName : appContext.selectedProvince?.name,
        latitude: formValues?.latitude ? formValues?.latitude : selectedAddress?.latitude,
        longitude: formValues?.longitude ? formValues?.longitude : selectedAddress?.longitude,
        googleMapPlaceId: formValues?.googleMapPlaceId ? formValues?.googleMapPlaceId : selectedAddress?.googleMapPlaceId,
        selectedRestaurant: selectedRestaurant,
      });
      dispatch.appContext.setGoToCheckout(true);
      history.push('/verify-payment');
    }
  };
  const onSelectPaymentMethodClick = async (value: string) => {
    if (value == paymentMethodValues.G_BUSINESS) {
      setIsVisibleChooseVoucher(false);
      setHaveBill(true);
      // Set invoice info
      setTimeout(function () {
        formRef?.current?.setValues({
          ...formRef?.current?.values,
          companyName: gBizAccount?.company?.name,
          taxCode: gBizAccount?.company?.taxCode,
          invoiceAddress: gBizAccount?.company?.address,
          invoiceEmail: gBizAccount?.company?.email,
        });
      }, 1000);
    } else {
      setIsVisibleChooseVoucher(true);
      formRef?.current?.setValues({
        ...formRef?.current?.values,
        companyName: '',
        taxCode: '',
        invoiceAddress: '',
        invoiceEmail: '',
      });
    }

    setCurrentMethod(value);
    formRef?.current?.setFieldValue('currentMethod', value);
    setIsLoading(true);
    const result = await dispatch.cart.getCartDetail({ paymentMethod: value });
    setCartData(result?.data?.result);
    setIsLoading(false);
  };

  const renderPaymentMethods = () => {
    return paymentMethods.map((value: any, index: number) => {
      if (value?.value == paymentMethodValues.G_BUSINESS) {
        if (gBizAccount) {
          const balanceAvail = gBizAccount.balance;
          if (balanceAvail < Math.round(cartTotalData?.totalPaySum ?? 0) ||
              cartState?.selectedVouchers?.length > 0 ||
              gBizAccount.employee?.status !== 1) {
            setDisableGBizOption(true);
          }

          return (
              <Col
                  span={24}
                  key={index}
                  className={`${classes.paymentMethodItem} ${disableGBizOption && classes.disableGBiz}`}
                  onClick={() => {
                    if (!disableGBizOption) {
                      onSelectPaymentMethodClick(value?.value);
                    }
                  }}
              >
                <AppRadioBox isChecked={currentMethod === value?.value ? true : false} disabled={disableGBizOption || false}>
                  <div className={classes.checkBoxValue}>
                    {value.icon()}
                    <div className={classes.paymentMethodName}>
                      <div>{value.name}</div>
                      <div className={classes.gBizBalanceText}>Hạn mức còn lại {formatCurrency(balanceAvail)}</div>
                    </div>
                  </div>
                </AppRadioBox>
              </Col>
          );
        }
      } else {
        return (
            <Col
                span={12}
                key={index}
                className={classes.paymentMethodItem}
                onClick={() => onSelectPaymentMethodClick(value?.value)}
            >
              <AppRadioBox isChecked={currentMethod === value?.value ? true : false}>
                <div className={classes.checkBoxValue}>
                  {value.icon()}
                  <div className={classes.paymentMethodName}>{value.name}</div>
                </div>
              </AppRadioBox>
            </Col>
        );
      }
    });
  };
  const onPickUpRestaurant = async (value: boolean) => {
    try {
      if (cartData?.pickupAtRestaurant !== value) {
        setIsLoading(true);
        const res = await cartService.setPickUpAtRestaurant({ pickupAtRestaurant: value });
        if (res.status === 200) {
          const cartRes = await cartService.getDetail(
            currentMethod ? { paymentMethod: currentMethod } : {},
          );
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          setCartData((prevState: any) => {
            return {
              ...prevState,
              pickupAtRestaurant: value,
              ...cartRes?.data?.result,
            };
          });
          if (cartRes?.data?.messageCode != 200) {
            dispatch.appContext.setError(cartRes?.data?.message || '');
          }
        }
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  //new
  const getVoucherData = async () => {
    setIsLoading(true);
    const voucherRes = await voucherService.getVouchersList();
    let mappedArray = [] as any;
    voucherRes?.data?.result?.forEach((value: any) => {
      value?.gifts.forEach((giftValue: any) => {
        delete value['gifts'];
        mappedArray.push({
          ...value,
          giftValue: giftValue,
        });
      });
    });
    setVoucherData(mappedArray);
    const myVoucherRes = await voucherService.getSavedVouchers();
    setMyVoucherData(myVoucherRes?.data?.result);
    setIsLoading(false);
  };
  const getData = async () => {
    setIsLoading(true);
    const selectedRestaurantRes = await brandService.getSelectedRestaurant();
    if (selectedRestaurantRes?.status === 200) {
      getBlockTimesDefault(selectedRestaurantRes?.data?.result?.id);
    }
    setSelectedRestaurant(selectedRestaurantRes?.data?.result);
    const customerAddressRes = (await customerService.getAddressList()) as any;
    const getCustomerAddressDefault = customerAddressRes?.data?.result?.filter((value: any) => {
      return value?.isDefault;
    });
    let customerAddressDefault = {} as any;
    if (getCustomerAddressDefault?.length) {
      customerAddressDefault = getCustomerAddressDefault[0];
    } else {
      if (customerAddressRes?.data?.result?.length) {
        customerAddressDefault = customerAddressRes?.data?.result[0];
      }
    }
    setCustomerAddresses(customerAddressRes?.data?.result || []);
    setSelectedAddress(customerAddressDefault);
    if (!_.isEmpty(customerAddressDefault)) {
      getNearestRestaurant(customerAddressDefault);
    } else {
      await calculateShippingData(
        selectedAddress,
        selectedRestaurantRes?.data?.result?.restaurant?.code,
      );
    }
    setIsLoading(false);
    setIsGetData(true);
  };
  const getBlockTimesDefault = async (restaurantCode: any) => {
    setIsLoading(true);
    const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
      restaurantCode: restaurantCode,
      orderTime: moment().format('HH:mm:ss') as any,
    });
    if (
      allowBlockTimeRes?.status === 200 &&
      (allowBlockTimeRes?.data?.result as any)?.length === 0
    ) {
      const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
        restaurantCode: restaurantCode,
        orderTime: moment().add(1, 'days').startOf('day').format('HH:mm:ss') as any,
      });
      setBlockTimes(allowBlockTimeRes?.data?.result);
      setDatePickerValue(moment().add(1, 'days').startOf('day'));
      formRef?.current?.setFieldValue('deliveryDate', moment().add(1, 'days').startOf('day'));
    } else {
      setBlockTimes(allowBlockTimeRes?.data?.result);
    }
    setIsLoading(false);
  };
  const onChangeDatePickerValue = async (dateValue: Date) => {
    setIsLoading(true);
    const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
      restaurantCode: selectedRestaurant?.id as any,
      orderTime: moment(dateValue).isAfter(moment(), 'day')
        ? '00:00:00'
        : (moment().format('HH:mm:ss') as any),
    });
    setBlockTimes(allowBlockTimeRes?.data?.result);
    setIsLoading(false);
  };
  const renderStage = () => {
    switch (currentStage) {
      case 'delivery':
        return (
          <DeliveryInfo
            setCartData={setCartData}
            selectedAddress={selectedAddress}
            selectedRestaurant={selectedRestaurant}
            blockTimes={blockTimes}
            onChangeDatePickerValue={onChangeDatePickerValue}
            onChangeAddressClick={onChangeAddressClick}
            onDatePickerInputClick={onDatePickerInputClick}
            onTimePickerInputClick={onTimePickerInputClick}
            onCloseCustomDatePicker={onCloseCustomDatePicker}
            onSubmitCustomDatePicker={onSubmitCustomDatePicker}
            onCloseCustomTimePicker={onCloseCustomTimePicker}
            onSubmitCustomTimePicker={onSubmitCustomTimePicker}
            onSubmitClick={onSubmitClick}
            onPickUpRestaurant={onPickUpRestaurant}
            customDatePickerVisible={customDatePickerVisible}
            datePickerValue={datePickerValue}
            isPlastic={isPlastic}
            setIsPlastic={setIsPlastic}
            timePickerValue={timePickerValue}
            cartData={cartData}
            customTimePickerVisible={customTimePickerVisible}
            onOpenChangeRestaurantModal={onOpenChangeRestaurantModal}
            getNearestRestaurant={getNearestRestaurant}
          />
        );
      case 'change-address':
        return (
          <ChangeAddress
            selectedAddress={selectedAddress}
            customerAddresses={customerAddresses}
            setSelectedAddress={setSelectedAddress}
            setCurrentStage={setCurrentStage}
            onAddAddressPress={() => setIsVisibleAddAddress(true)}
          />
        );
      default:
        return <Fragment></Fragment>;
    }
  };

  const onApplyCoupon = async (voucher: IVoucher) => {
    try {
      setIsLoading(true);
      const result = await voucherService.applyVoucher({
        voucherCode: voucher.seriNo,
        partnerId: 0,
      });
      if (result?.data?.messageCode === 200) {
        NetCore.ncVoucherUsage({
          campaignCode: result?.data?.result?.code,
        });
        const cartRes = await cartService.getDetail(
          currentMethod ? { paymentMethod: currentMethod } : {},
        );
        setCartData(cartRes?.data?.result);

        if (cartRes?.data?.result) {
          dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
        }
        setTimeout(() => {
          setCouponModalVisible(false);
        }, 500);
        checkAffiliate(result.data);
      } else {
        dispatch.appContext.setError(result?.data?.message || '');
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onApplyVoucherClick = async (value: any) => {
    setIsLoading(true);
    try {
      if (value?.giftValue?.seriNo) {
        const result = await voucherService.applyVoucher({
          voucherCode: value?.giftValue?.seriNo,
          partnerId: value?.giftValue?.partnerId,
        });
        if (result?.data?.messageCode === 200) {
          NetCore.ncVoucherUsage({
            campaignCode: result?.data?.result?.code,
          });
          const cartRes = await cartService.getDetail(
            currentMethod ? { paymentMethod: currentMethod } : {},
          );
          setCartData(cartRes?.data?.result);
          setCouponModalVisible(false);
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          checkAffiliate(result.data);
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
    } catch (err: any) {
    }
    setIsLoading(false);
  };

  const onSubmitCodeInput = async (codeInput: any) => {
    try {
      setIsLoading(true);
      if (codeInput) {
        const result = await voucherService.applyVoucher({
          voucherCode: codeInput,
          // partnerId: 0,
        });
        if (result?.data?.messageCode === 200) {
          NetCore.ncVoucherUsage({
            campaignCode: result?.data?.result?.code,
          });
          const cartRes = await cartService.getDetail(
            currentMethod ? { paymentMethod: currentMethod } : {},
          );
          setCartData(cartRes?.data?.result);
          setCouponModalVisible(false);
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          checkAffiliate(result.data);
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onPickItemForVoucher = async (item: any) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      productId: item.wooId,
    });
    if (result?.data?.messageCode === 200) {
      NetCore.ncVoucherUsage({
        campaignCode: result?.data?.result?.code,
      });
      const cartRes = await cartService.getDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);

      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      checkAffiliate(result.data);
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onApplyBuyAGetBVoucher = async (value) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      ...value,
    });
    if (result?.data?.messageCode === 200) {
      NetCore.ncVoucherUsage({
        campaignCode: result?.data?.result?.code,
      });
      const cartRes = await cartService.getDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      checkAffiliate(result.data);
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onCloseCouponModal = () => {
    setCouponModalVisible(false);
  };

  const onCancelCoupon = async (value: any) => {
    setIsLoading(true);
    if (value?.giftValue?.seriNo || value?.code) {
      const result = await voucherService.removeVoucher(value?.giftValue?.seriNo || value?.code);
      const cartRes = await cartService.getDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      setCouponModalVisible(false);
    }
    setIsLoading(false);
  };

  const reference = useSelector((state: RootState) => state.reference);

  const provinces = useMemo(
    () => reference.provinces.map((p: any) => ({ label: p.name, value: p.id.toString() })),
    [],
  );

  const getDistricts = (provinceId: string) => {
    const selectedProvince = reference.provinces.find((p) => p.id.toString() === provinceId);
    if (!selectedProvince) return [];

    return selectedProvince.districts.map((d) => ({ label: d.name, value: d.id.toString() }));
  };

  const getWards = (provinceId: string, districtId: string) => {
    const selectedProvince = reference.provinces.find((p) => p.id.toString() === provinceId);
    if (!selectedProvince) return [];

    const selectedDistrict = selectedProvince.districts.find((d) => d.id.toString() === districtId);
    if (!selectedProvince) return [];

    return (selectedDistrict?.wards || []).map((w) => ({ label: w.name, value: w.id.toString() }));
  };

  const onSubmitOrderDesktopClick = async (values: any) => {
    if (
      haveBill &&
      values?.companyName &&
      values?.taxCode &&
      values?.invoiceAddress &&
      values?.invoiceEmail
    ) {
      createOrder(values);
    } else if (!haveBill) {
      createOrder(values);
    } else {
    }
  };

  const createOrder = async (values: any) => {
    setIsLoading(true);

    let address;
    const splittedAddress = (values?.addressLine1 || '').split(', ');

    if (splittedAddress.length === 6) {
      address = `${splittedAddress[0]} ${splittedAddress[1]}`;
    } else if (splittedAddress.length === 5) {
      address = splittedAddress[0];
    } else {
      address = values.addressLine1;
    }

    const result = await orderService.createOrder({
      recipientCellphone: values?.phone,
      recipientName: values?.name,
      deliveryTime: timePickerValue,
      pickupAtRestaurant: cartData?.pickupAtRestaurant as any,
      note: cartNote,
      cutleryTool: isPlastic ? '1' : '0',
      deliveryAddress: address,
      wardId: values?.ward?.value,
      wardName: values?.ward?.label,
      districtId: values?.district?.value,
      districtName: values?.district?.label,
      provinceId: appContext.selectedProvince?.id as any,
      provinceName: appContext.selectedProvince?.name,
      hasInvoice: haveBill ? 1 : 0,
      invoiceName: values?.companyName,
      invoiceNumber: values?.taxCode,
      invoiceAddress: values?.invoiceAddress,
      invoiceEmail: values?.invoiceEmail,
      deliveryDate: moment(values?.deliveryDate).format('DD/MM/YYYY'),
      paymentMethod: currentMethod,
      addressId: cartData?.pickupAtRestaurant ? selectedRestaurant?.id : selectedAddress?.id,
      mo_traffic_id: cookies['traffic-id'],
      latitude: values?.latitude,
      longitude: values?.longitude,
      googleMapPlaceId: values?.googleMapPlaceId ? values?.googleMapPlaceId : selectedAddress?.googleMapPlaceId,
      utm_source: cookies['utm_source'],
      utm_medium: cookies['utm_medium'],
      utm_campaign: cookies['utm_campaign'],
      utm_content: cookies['utm_content'],
      utm_location: cookies['utm_location'],
      utm_term: cookies['utm_term'],
    });
    if (result?.data?.messageCode === 200) {
      let voucherCode = cartState?.selectedVouchers
              .map(e => e.code)
              .join(),
          campaignCode = cartState?.selectedVouchers
              .filter((value, index, self) => {
                return value.campaign !== null;
              })
              .map(e => e.campaign.id)
              .filter((value, index, self) => self.indexOf(value) === index)
              .join();
      let userInfo = {
        mobile: user.profile.cellphone,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        userLocation: address,
        taxCode: values?.taxCode,
        companyName: values?.companyName,
        companyAddress: values?.invoiceAddress,
        companyEmail: values?.invoiceEmail,
        phoneNumber: user.profile.cellphone,
        email: user.profile.email,
      };
      NetCore.ncContact(userInfo);
      let dataPurchase = {
        orderTotal: result?.data?.result.totals.totalPriceWithoutShipping,
        orderTotal2: result?.data?.result.totals.totalPaySum,
        isVoucherUse: voucherCode !== '',
        voucherCode: voucherCode,
        pickupTime: moment(values?.deliveryDate).format('DD/MM/YYYY') + ' ' + timePickerValue,
        pickupAtStore: cartData?.pickupAtRestaurant || false,
        paymentMethod: currentMethod,
        campaignCode: campaignCode,
        brandName: appContext.selectedBrand?.name || '',
        restaurantName: selectedRestaurant?.restaurant?.name,
        restaurantTelephone: selectedRestaurant?.restaurant?.telephone,
        restaurantAddress: selectedRestaurant?.restaurant?.address,
        customerAddress: address,
        orderStatus: '',
      };
      NetCore.ncProductPurchase(dataPurchase);
      if (currentMethod == 'COD') {
        let dataOrderSuccess = {
          orderTotal: result?.data?.result.totals.totalPriceWithoutShipping,
          orderTotal2: result?.data?.result.totals.totalPaySum,
          isVoucherUse: voucherCode !== '',
          voucherCode: voucherCode,
          pickupTime: moment(cartData.deliveryDate).format('DD/MM/YYYY') + ' ' + cartData.deliveryTime,
          voucherValue: result?.data?.result.totals.totalCashVoucher + result?.data?.result.totals.totalDiscount,
          orderCode: result?.data?.result.id,
        };
        NetCore.ncOrderSuccess(dataOrderSuccess);
      }

      dispatch.cart.setCartData({});
      dispatch.cart.setCartItems([]);
      dispatch.cart.setTotals({
        shippingFee: 0,
        total: 0,
        totalPrice: 0,
        totalPriceWithoutShipping: 0,
      });
      setOrderData(result.data.result);
      if (cookies['traffic-id']) {
        removeCookies('traffic-id');
      }
      if (cookies['utm_source']) {
        removeCookies('utm_source');
      }
      if (cookies['utm_medium']) {
        removeCookies('utm_medium');
      }
      if (cookies['utm_campaign']) {
        removeCookies('utm_campaign');
      }
      if (cookies['utm_content']) {
        removeCookies('utm_content');
      }
      if (cookies['utm_location']) {
        removeCookies('utm_location');
      }
      if (cookies['utm_term']) {
        removeCookies('utm_term');
      }
      switch (currentMethod) {
        case paymentMethodValues.COD:
          setNotificationModalVisible(true);
          setNotificationType('success-tracking');
          break;
        case paymentMethodValues.G_BUSINESS:
          let bizAcc = await userService.gBizAccountInfo();
          let messageNotice = result?.data?.message ?? '';
          if (bizAcc?.data?.messageCode === 200) {
            setGBizAccount(bizAcc?.data?.result);
            messageNotice += `<div style="margin-top: 20px; color: #1A1A1A; font-weight: 700;">`;
            messageNotice += `<div>Đã thanh toán: ${formatCurrency(result?.data?.result.totals.totalPaySum)}</div>`;
            messageNotice += `<div>Hạn mức còn lại: ${formatCurrency(bizAcc?.data?.result?.balance || 0)}</div>`;
            messageNotice += `</div>`
          }
          setNotificationModalVisible(true);
          setNotificationType('success-tracking');
          setNotificationMessage(messageNotice);
          break;
        case paymentMethodValues.ZALOPAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.VNPAY_BANK_ONLINE:
          if (result?.data?.result?.requestPayment?.result?.response) {
            window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPAY_BANK_ONLINE_INTERNATIONAL_CARD:
          if (result?.data?.result?.requestPayment?.result?.response) {
            window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPT_EPAY_BANK_ONLINE:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setIframePaymentSrc(result?.data?.result?.requestPayment?.result?.response);
            // window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.VINID:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
            // window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.SHOPEE_PAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.MOMO:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        default:
        // code block
      }
    } else if (result.data.messageCode === 301) {
      setNotificationModalVisible(true);
      setNotificationType('invalid-time');
      setNotificationMessage(result?.data?.message ?? '');
    } else if (result.data.messageCode === 406) {
      setNotificationModalVisible(true);
      setNotificationType('redirect-cart');
      setNotificationMessage(
          Object.values((result?.data?.result as any) ?? {})?.[0] ??
          result?.data?.message ??
          ('' as any),
      );
    } else if (result.data.messageCode === 419) {
      setNotificationModalVisible(true);
      setNotificationType('gbiz-failed');
      setOrderData(result.data.result);
      setNotificationMessage(result?.data?.message ?? ('' as any));
    } else {
      setNotificationModalVisible(true);
      setNotificationType('failed');
      setNotificationMessage(result?.data?.message ?? ('' as any));
    }
    setIsLoading(false);
  };
  const onSuccessCartButtonClick = (toTrackingScreen?: any) => {
    dispatch.cart.reset();
    if (toTrackingScreen == 're-pay') {
      history.push(`/request-payment/${orderData.id}`);
    } else if (toTrackingScreen) {
      if (isDesktop) {
        history.push(`/order/${orderData.id}`);
      } else {
        if (isDesktop) {
          history.push(`/order/${orderData.id}`);
        } else {
          history.push(`/order/${orderData.id}/tracking`);
        }
      }
    } else {
      history.push(`/order/${orderData.id}`);
    }
  };

  const onFailedCartButtonClick = () => {
    setNotificationModalVisible(false);
  };

  const onChangedRestaurant = async (
    restaurant: IRestaurant,
    noRequestChangeRestaurant?: boolean,
  ) => {
    setIsLoading(true);
    if (!noRequestChangeRestaurant) {
      const response = await brandService.changedRestaurant(restaurant.restaurantCode, {
        longitude: selectedAddress?.longitude ? selectedAddress.longitude : appContext?.longitude,
        latitude: selectedAddress?.latitude ? selectedAddress.latitude : appContext?.latitude,
      });
      if (response?.data?.messageCode === 200) {
        setSelectedRestaurant(response?.data?.result);
        await calculateShippingData(selectedAddress, restaurant.restaurantCode);
        // const cartRes = await cartService.getDetail(currentMethod ? { paymentMethod: currentMethod } : {});
        // setCartData(cartRes?.data?.result);
        setRestaurantSelectorVisible(false);
      }
    } else {
      setSelectedRestaurant(restaurant);
    }

    setIsLoading(false);
  };
  const onOpenChangeRestaurantModal = () => {
    setRestaurantSelectorVisible(true);
  };
  const getAddressList = async () => {
    setIsLoading(true);
    const response = await customerService.getAddressList();
    setCustomerAddresses(response.data?.result || []);
    setIsLoading(false);
  };

  const onCouponTextClick = () => {
    setCouponModalVisible(true);
    getVoucherData();
  };
  const onFailedInvalidTimeClick = () => {
    getBlockTimesDefault(selectedRestaurant?.id);
    setNotificationModalVisible(false);
  };

  const onShowErrorMessage = (validateResult: any) => {
    if (!_.isEmpty(validateResult)) {
      const errorsArray = Object.values(validateResult);
      if (errorsArray?.length) {
        setNotificationModalVisible(true);
        setNotificationType('failed');
        setNotificationMessage(errorsArray[0] as any);
      }
    }
  };

  const checkAffiliate = (dataObj) => {
    if (dataObj.result.denominationValue == 0 && dataObj.result.partnerId == 999) {
      dispatch.appContext.setSuccess(dataObj.message);
      setTimeout(function () {
        dispatch.appContext.setSuccess(null);
      }, 3000);
    }
  }

  const renderContent = () => {
    if (cartState?.cartItems.length == 0 && isEmptyContentOrder) {
      return (
          <Container className={classes.desktopContainer} backgroundColor="#EDEDED">
            <NoCartData />
          </Container>
      );
    } else {
      isEmptyContentOrder = false;
      return (
          <Fragment>
            {iframePaymentSrc ? (
                <IFramePayment src={iframePaymentSrc} />
            ) : (
                <Fragment>
                  <EditAddressModal
                      isVisible={isVisibleAddAddress}
                      close={(e) => {
                        e.stopPropagation();
                        setIsVisibleAddAddress(false);
                      }}
                      address={null}
                      onSubmitSuccess={(address?: IAddress) => {
                        setIsVisibleAddAddress(false);
                        getAddressList();
                      }}
                  />
                  <RestaurantSelector
                      isVisible={restaurantSelectorVisible}
                      onDismiss={() => setRestaurantSelectorVisible(false)}
                      restaurants={restaurants}
                      selectedRestaurant={selectedRestaurant}
                      onChangedRestaurant={(value) => onChangedRestaurant(value)}
                  />
                  <DiscountFoodModal
                      isVisible={
                        (selectedVoucher?.applyForItems || []).length > 0 &&
                        voucherModalType === 'pick-product'
                      }
                      items={selectedVoucher?.applyForItems || []}
                      onClickItem={onPickItemForVoucher}
                      onClose={() => setSelectedVoucher({})}
                      denominationValue={selectedVoucher?.denominationValue}
                      type={selectedVoucher?.type}
                  />
                  <DiscountGroupModal
                      isVisible={
                        (selectedVoucher?.applyForItems || []).length > 0 &&
                        voucherModalType === 'buy-a-get-b'
                      }
                      items={selectedVoucher?.applyForItems || []}
                      isGetAllProductDiscount={selectedVoucher.isGetAllProductDiscount}
                      isParallelCondition={selectedVoucher.isParallelCondition}
                      // onClickItem={onPickItemForVoucher}
                      onClose={() => setSelectedVoucher({})}
                      onApply={onApplyBuyAGetBVoucher}
                  />
                  <NotificationModal
                      visible={notificationModalVisible}
                      type={notificationType}
                      onSuccessButtonClick={onSuccessCartButtonClick}
                      onFailedButtonClick={onFailedCartButtonClick}
                      notificationMessage={notificationMessage}
                      onFailedInvalidTimeClick={onFailedInvalidTimeClick}
                  />
                  <VerifyPaymentModal
                      visible={verifyPaymentVisible}
                      type={currentMethod}
                      requestId={orderData?.id}
                      qrCode={currentPaymentRequest?.result?.response}
                      onCloseModal={() => setVerifyPaymentVisible(false)}
                      setNotificationModalVisible={setNotificationModalVisible}
                      setNotificationType={setNotificationType}
                  />
                  <ChangeAddressModal
                      isVisible={changeAddressVisible}
                      selectedAddress={selectedAddress}
                      customerAddresses={customerAddresses}
                      setSelectedAddress={setSelectedAddress}
                      setCurrentStage={setCurrentStage}
                      onCancelChangeAddress={() => setChangeAddressVisible(false)}
                      onAddAddressPress={() => setIsVisibleAddAddress(true)}
                  />
                  <CouponModal
                      onApplyVoucherClick={onApplyVoucherClick}
                      isVisible={couponModalVisible}
                      onDismiss={onCloseCouponModal}
                      onQrCodeClick={() => { }}
                      voucherData={voucherData}
                      myVoucherData={myVoucherData}
                      selectedVouchers={cartState?.selectedVouchers}
                      onCancelCoupon={onCancelCoupon}
                      onSubmitCodeInput={onSubmitCodeInput}
                      onApplyCoupon={onApplyCoupon}
                  />
                  <CustomDatePickerModal
                      visible={customDatePickerVisible}
                      onCancelModal={onCloseCustomDatePicker}
                      value={datePickerValue}
                      onSubmitModal={(value: any) => {
                        formRef?.current?.setFieldValue('deliveryDate', value);
                        onSubmitCustomDatePicker(value);
                      }}
                  />
                  <CustomTimePickerModal
                      visible={customTimePickerVisible}
                      onCancelModal={onCloseCustomTimePicker}
                      value={timePickerValue}
                      onSubmitModal={(value: any) => {
                        formRef?.current?.setFieldValue('deliveryTime', value);
                        onSubmitCustomTimePicker(value);
                      }}
                      blockTimes={blockTimes}
                  />
                  <ModalSpinner visible={isLoading} />
                  {isDesktop ? (
                      <Formik
                          validateOnChange={false}
                          innerRef={formRef}
                          initialValues={{
                            haveBill: false,
                            companyName: '',
                            taxCode: '',
                            invoiceAddress: '',
                            invoiceEmail: '',
                            name: user?.profile?.fullName ?? '',
                            phone: user?.profile?.cellphone ?? '',
                            addressLine1: '',
                            deliveryTime: '',
                            deliveryDate: moment(),
                            currentMethod: '',
                            // province: {
                            //   value: selectedAddress?.provinceId?.toString() || '',
                            //   label: selectedAddress?.provinceName || '',
                            // },
                            // district: {
                            //   value: selectedAddress?.districtId?.toString() || '',
                            //   label: selectedAddress?.districtName || '',
                            // },
                            // ward: {
                            //   value: selectedAddress?.wardId?.toString() || '',
                            //   label: selectedAddress?.wardName || '',
                            // },
                            latitude: null,
                            longitude: null,
                            onPickUpRestaurant: false,
                            googleMapPlaceId: '',
                          }}
                          validationSchema={VerifyOrderSchema}
                          onSubmit={(values) => {
                            onSubmitOrderDesktopClick(values);
                          }}
                      >
                        {({
                            errors,
                            touched,
                            setFieldValue,
                            values,
                            handleSubmit,
                            setValues,
                            validateField,
                            validateForm,
                          }) => {
                          return (
                              <div className={classes.desktopLayout}>
                                <Container className={classes.desktopContainer} backgroundColor="#EDEDED">
                                  <Row>
                                    <Col md={16}>
                                      <CartStep2 className={classes.step} />
                                      <Row className={classes.infoContainer}>
                                        <Col md={12}>
                                          <div className={classes.title}>Thời gian nhận hàng</div>
                                          <Row>
                                            <Col md={12}>
                                              <TextInput
                                                  containerClassname={classes.dateInput}
                                                  textInputClassname={classes.textInput}
                                                  onClick={onDatePickerInputClick}
                                                  value={
                                                    datePickerValue
                                                        ? moment(datePickerValue).format('DD/MM/YYYY')
                                                        : ''
                                                  }
                                                  surfix={<CalendarIcon />}
                                                  placeholder={'Chọn ngày giao'}
                                              />
                                              {errors?.deliveryDate ? (
                                                  <div className={classes.colorRed}>{errors?.deliveryDate}</div>
                                              ) : (
                                                  <Fragment></Fragment>
                                              )}
                                            </Col>
                                            <Col md={12}>
                                              <TextInput
                                                  containerClassname={classes.timeInput}
                                                  textInputClassname={classes.textInput}
                                                  onClick={onTimePickerInputClick}
                                                  value={timePickerValue}
                                                  surfix={<TimeIcon />}
                                                  placeholder={'Chọn giờ giao'}
                                              />
                                              {errors?.deliveryTime ? (
                                                  <div className={classes.colorRed}>{errors?.deliveryTime}</div>
                                              ) : (
                                                  <Fragment></Fragment>
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={12}>
                                          <div className={classes.title}>Ghi chú đơn hàng</div>
                                          <TextInput
                                              containerClassname={classes.noteInput}
                                              textInputClassname={classes.textInput}
                                              onChange={(e: any) => setCartNote(e)}
                                              value={cartNote}
                                              placeholder={'Nhập ghi chú của bạn ở đây ...'}
                                          />
                                        </Col>

                                        <Col md={24} className={classes.addressContainer}>
                                          <div className={classes.title}>Địa chỉ nhận hàng</div>
                                          <Row
                                              align="middle"
                                              style={{ marginTop: 16 }}
                                              onClick={() => {
                                                setFieldValue('onPickUpRestaurant', false);
                                                onPickUpRestaurant(false);
                                              }}
                                          >
                                            <AppRadioBox
                                                isChecked={!cartData?.pickupAtRestaurant}
                                            ></AppRadioBox>
                                            <div className={`${classes.title}`}>Giao hàng tới</div>
                                            <div
                                                className={classes.editAddressText}
                                                onClick={() => setChangeAddressVisible(true)}
                                            >
                                              Thay đổi
                                            </div>
                                          </Row>
                                        </Col>

                                        <Col md={12} style={{ paddingRight: 16 }}>
                                          <div className={classes.addressInputTitle}>Tên người nhận</div>
                                          <CartTextInput
                                              onChange={(value: any) => setFieldValue('name', value)}
                                              value={values.name}
                                              error={errors.name}
                                          />
                                          {/* <div className={classes.addressInputTitle}>Tỉnh/Thành phố</div>
                        <AppSelect
                          options={provinces}
                          value={values.province}
                          onChanged={(value: any) => setFieldValue('province', value)}
                          containerClassname={classes.textInputContainer}
                          error={errors.province?.value as any}
                        />
                        <div className={classes.addressInputTitle}>Phường/Xã</div>
                        <AppSelect
                          value={values.ward}
                          onChanged={(value: any) => setFieldValue('ward', value)}
                          options={getWards(values.province.value, values.district.value)}
                          containerClassname={classes.textInputContainer}
                          error={errors.ward?.value as any}
                        /> */}
                                        </Col>
                                        <Col md={12}>
                                          <div className={classes.addressInputTitle}>Số điện thoại</div>
                                          <CartTextInput
                                              onChange={(value: any) => setFieldValue('phone', value)}
                                              value={values.phone}
                                              error={errors.phone}
                                          />
                                          {/* <div className={classes.addressInputTitle}>Quận/Huyện</div>
                        <AppSelect
                          options={getDistricts(values.province.value)}
                          value={values.district}
                          onChanged={(value: any) => setFieldValue('district', value)}
                          containerClassname={classes.textInputContainer}
                          error={errors.district?.value as any}
                        />
                        <div className={classes.addressInputTitle}>Địa chỉ cụ thể</div>
                        <CartTextInput
                          onChange={(value: any) => setFieldValue('addressLine1', value)}
                          value={values.addressLine1}
                          error={errors.addressLine1}
                        /> */}
                                        </Col>
                                        <Col md={24}>
                                          <div className={classes.addressInputTitle}>Địa chỉ</div>
                                          <Autocomplete
                                              ref={autoCompleteRef}
                                              apiKey={GG_API_KEY}
                                              onPlaceSelected={(place) => {
                                                setSelectedPlace(place);
                                              }}
                                              className={clsx(classes.textInputContainer)}
                                              style={{
                                                width: '100%',
                                                borderRadius: 8,
                                                borderWidth: 1,
                                                borderColor: '#D8D8D8',
                                                padding: '17px 12px',
                                              }}
                                              defaultValue={values.addressLine1}
                                              options={{
                                                types: [],
                                                componentRestrictions: { country: 'vn' },
                                              }}
                                              inputAutocompleteValue={values.addressLine1}
                                              placeholder="Nhập địa chỉ"
                                          />
                                          {errors?.addressLine1 ? (
                                              <div className={classes.colorRed} style={{ marginTop: 8 }}>
                                                {errors?.addressLine1}
                                              </div>
                                          ) : (
                                              <></>
                                          )}
                                        </Col>
                                        <Col md={24} className={classes.pickUpResContainer}>
                                          <Row
                                              align="middle"
                                              onClick={() => {
                                                setFieldValue('onPickUpRestaurant', true);
                                                onPickUpRestaurant(true);
                                              }}
                                          >
                                            <AppRadioBox isChecked={cartData?.pickupAtRestaurant}></AppRadioBox>
                                            <div className={classes.title}>Nhận tại nhà hàng</div>
                                            <div className={classes.noFee}>Không mất phí</div>
                                          </Row>
                                          <div
                                              className={classes.resAddress}
                                              onClick={() => onOpenChangeRestaurantModal()}
                                          >
                                            {!_.isEmpty(selectedRestaurant)
                                                ? `${selectedRestaurant?.restaurant?.name} - ${selectedRestaurant?.restaurant?.address}`
                                                : ''}
                                            <div style={{ marginLeft: 8 }}>
                                              <ArrowDownIcon fill="#6B6B6B" />
                                            </div>
                                          </div>
                                        </Col>
                                        <div className={classes.billBoxContainer}>
                                          <div className={classes.title}>
                                            Bạn có cần dụng cụ ăn uống nhựa không?
                                          </div>
                                          <Row>
                                            <Col
                                                span={6}
                                                className={classes.paymentMethodItem}
                                                onClick={() => setIsPlastic(true)}
                                            >
                                              <AppRadioBox isChecked={isPlastic}>
                                                <div className={classes.billBoxValue}>
                                                  <div className={classes.paymentMethodName}>Có</div>
                                                </div>
                                              </AppRadioBox>
                                            </Col>
                                            <Col
                                                span={12}
                                                className={classes.paymentMethodItem}
                                                onClick={() => setIsPlastic(false)}
                                            >
                                              <AppRadioBox isChecked={!isPlastic}>
                                                <div className={classes.billBoxValue}>
                                                  <div className={classes.paymentMethodName}>Không</div>
                                                </div>
                                              </AppRadioBox>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row style={{ maxWidth: '100%', marginTop: 32 }}>
                                            <Col span={24} className={classes.title}>
                                              Phương thức thanh toán
                                            </Col>
                                            {renderPaymentMethods()}
                                            {errors?.currentMethod ? (
                                                <div className={classes.colorRed} style={{ marginTop: 8 }}>
                                                  {errors?.currentMethod}
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                          </Row>
                                        </div>
                                        <div className={classes.billBoxContainer}>
                                          <div className={classes.title}>Nhận hoá đơn điện tử</div>
                                          <Row>
                                            <Col
                                                span={6}
                                                className={classes.paymentMethodItem}
                                                onClick={() => {
                                                  formRef?.current?.setFieldValue('haveBill', true);
                                                  setHaveBill(true);
                                                }}
                                            >
                                              <AppRadioBox isChecked={haveBill}>
                                                <div className={classes.billBoxValue}>
                                                  <div className={classes.paymentMethodName}>Có</div>
                                                </div>
                                              </AppRadioBox>
                                            </Col>
                                            <Col
                                                span={12}
                                                className={classes.paymentMethodItem}
                                                onClick={() => {
                                                  if (currentMethod !== paymentMethodValues.G_BUSINESS) {
                                                    formRef?.current?.setFieldValue('haveBill', false);
                                                    setHaveBill(false);
                                                  }
                                                }}
                                            >
                                              <AppRadioBox isChecked={!haveBill} disabled={currentMethod === paymentMethodValues.G_BUSINESS}>
                                                <div className={classes.billBoxValue}>
                                                  <div className={classes.paymentMethodName}>Không</div>
                                                </div>
                                              </AppRadioBox>
                                            </Col>
                                          </Row>
                                        </div>

                                        {haveBill ? (
                                            <Fragment>
                                              <Row className={classes.billContainer}>
                                                <Col md={12} style={{ paddingRight: 16 }}>
                                                  <div className={classes.inputTitle}>
                                                    Mã số thuế <span className={classes.colorRed}>*</span>
                                                  </div>
                                                  <CartTextInput
                                                      placeholder={'1234567890'}
                                                      onChange={(value: any) => {
                                                        setFieldValue('taxCode', value);
                                                        validateField('taxCode');
                                                      }}
                                                      value={values.taxCode}
                                                      error={errors.taxCode}
                                                      disabled={currentMethod === paymentMethodValues.G_BUSINESS}
                                                  />
                                                </Col>
                                                <Col md={12} style={{ paddingLeft: 16 }}>
                                                  <div className={classes.inputTitle}>
                                                    Tên tổ chức cá nhân nộp thuế{' '}
                                                    <span className={classes.colorRed}>*</span>
                                                  </div>
                                                  <CartTextInput
                                                      placeholder={'Công ty TNHH ABC'}
                                                      onChange={(value: any) => {
                                                        setFieldValue('companyName', value);
                                                        validateField('companyName');
                                                      }}
                                                      value={values.companyName}
                                                      error={errors.companyName}
                                                      disabled={currentMethod === paymentMethodValues.G_BUSINESS}
                                                  />
                                                </Col>
                                                <Col md={12} style={{ paddingRight: 16 }}>
                                                  <div className={classes.inputTitle}>
                                                    Địa chỉ <span className={classes.colorRed}>*</span>
                                                  </div>
                                                  <CartTextInput
                                                      placeholder={'315 Trường Chinh'}
                                                      onChange={(value: any) => {
                                                        setFieldValue('invoiceAddress', value);
                                                        validateField('invoiceAddress');
                                                      }}
                                                      value={values.invoiceAddress}
                                                      error={errors.invoiceAddress}
                                                      disabled={currentMethod === paymentMethodValues.G_BUSINESS}
                                                  />
                                                </Col>
                                                <Col md={12} style={{ paddingLeft: 16 }}>
                                                  <div className={classes.inputTitle}>
                                                    Email nhận hóa đơn điện tử{' '}
                                                    <span className={classes.colorRed}>*</span>
                                                  </div>
                                                  <CartTextInput
                                                      placeholder={'abc@gmail.com'}
                                                      onChange={(value: any) => {
                                                        setFieldValue('invoiceEmail', value);
                                                        validateField('invoiceEmail');
                                                      }}
                                                      value={values.invoiceEmail}
                                                      error={errors.invoiceEmail}
                                                      disabled={currentMethod === paymentMethodValues.G_BUSINESS}
                                                  />
                                                </Col>
                                              </Row>
                                            </Fragment>
                                        ) : (
                                            <Fragment></Fragment>
                                        )}
                                      </Row>
                                    </Col>
                                    <Col md={1}></Col>
                                    <Col md={7} className={classes.voucherContainer}>
                                      {appContext.selectedBrand?.brandId != BRAND_IDS.ICOOK && (
                                          <>
                                            <div className={`${classes.voucherItem} ${classes.noMargin}`}>
                                              <div className={classes.voucherTitle}>Mã giảm giá, voucher</div>
                                              {isVisibleChooseVoucher && (
                                                  <div
                                                      className={classes.chooseVoucher}
                                                      onClick={() => onCouponTextClick()}
                                                  >
                                                    Chọn mã
                                                  </div>
                                              )}
                                            </div>
                                            <div className={classes.voucherListContainer}>
                                              {cartState?.selectedVouchers?.length ? (
                                                  cartState?.selectedVouchers.map((value: any, index: number) => {
                                                    return (
                                                        <div className={classes.voucherAppliedItem} key={index}>
                                                          <span>{value?.code}</span>
                                                          <div
                                                              onClick={() => {
                                                                onCancelCoupon(value);
                                                              }}
                                                              className={classes.voucherCloseIcon}
                                                          >
                                                            <VoucherCloseIcon />
                                                          </div>
                                                        </div>
                                                    );
                                                  })
                                              ) : (
                                                  <div className={classes.noDiscountCodeText}>
                                                    Bạn chưa thêm mã giảm giá nào
                                                  </div>
                                              )}
                                            </div>
                                            <hr style={{ border: '1px dashed #E4E4E4' }} />
                                          </>
                                      )}

                                      <div className={`${classes.voucherItem}`}>
                                        <div className={classes.priceMainItemTitle}>Tạm tính</div>
                                        <div className={classes.priceMainItemValue}>
                                          {formatCurrency(
                                              Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0),
                                          )}
                                        </div>
                                      </div>
                                      {!!cartTotalData?.totalDiscount && (
                                          <div className={`${classes.voucherItem}`}>
                                            <div className={classes.priceMainItemTitle}>Giảm giá</div>
                                            <div className={classes.priceMainItemValue}>
                                              - {formatCurrency(Math.round(cartTotalData?.totalDiscount ?? 0))}
                                            </div>
                                          </div>
                                      )}
                                      {discounts.map((discount) => (
                                          <div className={classes.secondaryRow} key={`campaign-${discount.code}`}>
                                            <div className={classes.secondaryTitleText}>
                                              <div className={classes.circle}></div>
                                              <span>{discount.code}</span>
                                            </div>
                                            <div className={classes.secondaryValueText}>
                                              -{formatCurrency(discount.denominationValue)}
                                            </div>
                                          </div>
                                      ))}
                                      <div className={`${classes.voucherItem}`}>
                                        <div className={classes.priceMainItemTitle}>Phí vận chuyển</div>
                                        <div className={classes.priceMainItemValue}>
                                          {formatCurrency(Math.round(cartTotalData?.shipping?.price ?? 0))}
                                        </div>
                                      </div>
                                      <div className={`${classes.voucherItem}`}>
                                        <div className={classes.priceMainItemTitle}>Tổng tiền trước VAT</div>
                                        <div className={classes.priceMainItemValue}>
                                          {formatCurrency(
                                              Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0) -
                                              Math.round(cartTotalData?.totalDiscount ?? 0) +
                                              Math.round(cartTotalData?.shipping?.price ?? 0),
                                          )}
                                        </div>
                                      </div>
                                      <div className={`${classes.voucherItem}`}>
                                        <div className={classes.priceMainItemTitle}>VAT</div>
                                        <div className={classes.priceMainItemValue}>
                                          {formatCurrency(Math.round(cartTotalData?.totalTax ?? 0))}
                                        </div>
                                      </div>
                                      <div className={`${classes.voucherItem}`}>
                                        <div className={classes.priceMainItemTitle}>Tổng tiền sau VAT</div>
                                        <div className={classes.priceMainItemValue}>
                                          {formatCurrency(
                                              Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0) -
                                              Math.round(cartTotalData?.totalDiscount ?? 0) +
                                              Math.round(cartTotalData?.shipping?.price ?? 0) +
                                              Math.round(cartTotalData?.totalTax ?? 0),
                                          )}
                                        </div>
                                      </div>
                                      {!!cartTotalData?.totalCashVoucher && (
                                          <>
                                            <hr style={{ border: '1px dashed #E4E4E4', marginTop: 18 }} />
                                            <div className={`${classes.voucherItem}`}>
                                              <div className={classes.priceMainItemTitle}>Voucher</div>
                                              <div className={classes.priceMainItemValue}>
                                                -{' '}
                                                {formatCurrency(Math.round(cartTotalData?.totalCashVoucher ?? 0))}
                                              </div>
                                            </div>
                                          </>
                                      )}
                                      {vouchers.map((voucher) => (
                                          <div className={classes.secondaryRow} key={`voucher-${voucher.code}`}>
                                            <div className={classes.secondaryTitleText}>
                                              <div className={classes.circle}></div>
                                              <span>{voucher.code}</span>
                                            </div>
                                            <div className={classes.secondaryValueText}>
                                              -{formatCurrency(voucher.denominationValue)}
                                            </div>
                                          </div>
                                      ))}
                                      <hr style={{ border: '1px dashed #E4E4E4', marginTop: 18 }} />
                                      <div className={`${classes.voucherItem} ${classes.totalContainer}`}>
                                        <div className={classes.priceTotalTitle}>Tổng tiền phải trả</div>
                                        <div className={classes.priceTotalValue}>
                                          {formatCurrency(Math.round(cartTotalData?.totalPaySum ?? 0))}
                                        </div>
                                      </div>
                                      {gBizAccount && (
                                          <div className={classes.noteForGBiz}>
                                            * Thanh toán bằng tài khoản doanh nghiệp sẽ không được áp dụng các chương trình giảm giá cho KH cá nhân và không được tích lũy.
                                          </div>
                                      )}
                                      <CartButton
                                          containerStyle={classes.buttonContinue}
                                          type="submit"
                                          onClick={async () => {
                                            const validateResult = await validateForm(values);
                                            if (!_.isEmpty(validateResult)) {
                                              onShowErrorMessage(validateResult);
                                            } else {
                                              handleSubmit();
                                            }
                                          }}
                                          text="Thanh toán & Đặt hàng"
                                      />
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                          );
                        }}
                      </Formik>
                  ) : (
                      renderStage()
                  )}
                </Fragment>
            )}
          </Fragment>
      );
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
});

const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({
  cartReducer: rootReducer.cart,
});

const VerifyOrderScreen = React.memo(connect(mapState, mapDispatch)(Verify));
export { VerifyOrderScreen };
