import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const ChervonLeftIcon = React.memo(({ className,color  }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill={color || "none"} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2081 4.33474C16.6545 4.78105 16.6545 5.50467 16.2081 5.95098L10.1591 12L16.2081 18.049C16.6545 18.4953 16.6545 19.219 16.2081 19.6653C15.7618 20.1116 15.0382 20.1116 14.5919 19.6653L7.73476 12.8081C7.28845 12.3618 7.28845 11.6382 7.73476 11.1919L14.5919 4.33474C15.0382 3.88842 15.7618 3.88842 16.2081 4.33474Z"
        fill={color ||"white"}
      />
    </svg>
  );
});
