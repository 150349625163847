import React from 'react';

interface Props {
  className?: string;
}

export const FailedIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0Z" fill="#EF4949"/>
<path d="M28.0001 44C28.0001 41.7909 29.7841 40 31.9848 40C34.1855 40 35.9695 41.7909 35.9695 44C35.9695 46.2091 34.1855 48 31.9848 48C29.7841 48 28.0001 46.2091 28.0001 44Z" fill="white"/>
<path d="M28.3905 19.9802C28.1782 17.8491 29.8452 16 31.9787 16C34.1121 16 35.7791 17.8491 35.5668 19.9801L34.1692 34.0099C34.0567 35.1397 33.1097 36 31.9787 36C30.8476 36 29.9006 35.1397 29.7881 34.0099L28.3905 19.9802Z" fill="white"/>
</svg>

  );
});
