import { useMediaQuery } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { BrandItem } from '../../../../components/brand-item';
import { ArrowLeftIcon } from '../../../../components/icons/arrow-left';
import { ArrowRightIcon } from '../../../../components/icons/arrow-right';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { IBrand } from '../../../../core/models/brand';
import { brandService } from '../../../../services/brand-service';
import { brand } from '../../../../store/models/brand';
import { brandItemSmallSide, useBrandListStyles } from './brand-list.style';

interface Props {
  brands: IBrand[];
  onClickBrand: (brand: IBrand) => void;
}

export const BrandList = React.memo((props: Props) => {
  const classes = useBrandListStyles();
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);
  const sliderRef = useRef<any>(null);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.titleText}>{t('outstandingBrand')}</div>
        {/* <Link to="/brand">
          <div className={classes.showAllText}>Tất cả</div>
        </Link> */}
      </div>

      {isDesktop ? (
        <div className={classes.brandContainer}>
          <div className={classes.arrowContainer} onClick={() => sliderRef.current?.slickPrev()}>
            <ArrowLeftIcon />
          </div>
          <Slider
            ref={sliderRef}
            slidesPerRow={9}
            slidesToScroll={1}
            infinite
            arrows={false}
            className={classes.sliderContainer}
            swipe={false}
          >
            {props.brands.map((brand) => (
              <Link
                to={`/brand/${brand.brandId}`}
                onClick={() => props.onClickBrand(brand)}
                className={classes.brandItem}
                key={brand.id}
              >
                <BrandItem brand={brand} />
              </Link>
            ))}
          </Slider>
          <div className={classes.arrowContainer} onClick={() => sliderRef.current?.slickNext()}>
            <ArrowRightIcon />
          </div>
        </div>
      ) : (
        <div
          className={classes.brandContainer}
          style={{
            gridTemplateRows:
              props.brands.length > 4
                ? `${brandItemSmallSide}px ${brandItemSmallSide}px`
                : `${brandItemSmallSide}px`,
          }}
        >
          {props.brands.map((brand) => (
            <Link
              to={`/brand/${brand.brandId}`}
              onClick={() => props.onClickBrand(brand)}
              key={brand.id}
            >
              <BrandItem brand={brand} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
});
