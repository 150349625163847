import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../../core/contants/colors";

export const useRestaurantClosePopupStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: "#14142B",
    lineHeight: "32px",
  },
  description: {
    color: colors.textSecondaryColor,
    marginTop: 4,
    textAlign: "center",
  },
  btn: {
    marginTop: 24,
    width: 239,
  },
});
