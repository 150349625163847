import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IAddress } from '../core/models/customer';

const getAddressList = () => axios.get<IApiResponse<IAddress[]>>('/customer/address');

const getDefaultAddress  = () => axios.get<IApiResponse<IAddress>>('/customer/address/default');
const getAddressDetail = (id: string) =>
  axios.get<IApiResponse<IAddress>>(`/customer/${id}/address`);

const updateAddress = (id: string, body: any) =>
  axios.put<IApiResponse<IAddress>>(`/customer/${id}/address`, body);
const addAddress = (body: any) => axios.post<IApiResponse<IAddress>>(`/customer/address`, body);

const deleteAddress = (id: string) => axios.delete<IApiResponse<null>>(`/customer/${id}/address`);

export const customerService = {
  getAddressList,
  getAddressDetail,
  updateAddress,
  addAddress,
  deleteAddress,
  getDefaultAddress
};
