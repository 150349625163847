import React from 'react';
import { images } from '../../core/contants/images';

interface Props {
  className?: string;
}

export const ZaloPayIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return <img src={images.zalo} width="24px" height="24px" />;
});
