import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useAdvantageListStyles = makeStyles({
  container: {
    display: 'none',
    borderBottomWidth: 1,
    borderBottomColor: '#EBEBEB',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
    },
  },
  itemContainer: {
    padding: 22,
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    flex: 1,
  },
  seperator: {
    height: 24,
    width: 1,
    backgroundColor: '#ebebeb',
  },
  label: {
    color: colors.textSecondaryColor,
  },
  description: {
    color: '#000',
    fontWeight: 500,
    fontSize: 14,
  },
  icon: {
    height: 36,
    width: 36,
    objectFit: 'contain',
    marginRight: 16,
  },
});
