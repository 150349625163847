import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Coupon } from '../../../components/coupon';
import { NoVoucherDataIcon } from '../../../components/icons/no-voucher-data';
import { images } from '../../../core/contants/images';
import { IVoucher } from '../../../core/models/ticket';
import { formatCurrency } from '../../../core/ultis/currency';
import { voucherService } from '../../../services/voucher-service';
import { useStyles } from './my-wallet-tab.style';

interface Props {
  data: any;
  onApplyCoupon: (value: any) => void;
  onCouponClick: (value: any) => void;
}

export const MyWalletTab = React.memo((props: Props) => {
  const [searchText, setSearchText] = useState('');
  const { data, onCouponClick } = props;
  const classes = useStyles();

  const renderData = () => {
    if (data?.length) {
      return data.map((v: any, index: number) => (
        <Coupon
        onClick={() => onCouponClick(v)}
          onApply={() => props.onApplyCoupon(v)}
          couponVM={{
            title: v.promotion?.title || '',
            from: v.promotion?.startTime
              ? moment(new Date(v.promotion.startTime)).format('DD/MM/yyyy')
              : '',
            to: v.promotion?.endTime
              ? moment(new Date(v.promotion.endTime)).format('DD/MM/yyyy')
              : '',
            status: 'active',
            imgSrc: v.promotion?.icon || images.couponThumbnail,
          }}
          key={index}
        />
      ));
    } else {
      return (
        <div className={classes.noDataIconContainer}>
          <NoVoucherDataIcon />
        </div>
      );
    }
  };
  return <Fragment>{renderData()}</Fragment>;
});
