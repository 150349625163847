import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useTrackingOrdersPopupStyles = makeStyles({
  container: {
    background: '#fff',
    padding: '17px 12px',
    borderRadius: 8,
  },
  header: {
    fontSize: 18,
    color: '#000',
    fontWeight: 700,
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankView: {
    width: 32,
    height: 32,
  },

  modalCloseIcon: {
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  description: {
    color: '#6B6B6B',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
  },
  seperator: {
    marginTop: 24,
    marginBottom: 16,
  },
  card: {
    padding: '8px 0px',
    display: 'flex',
  },
  thumbnail: {
    height: 75,
    width: 75,
    borderRadius: 3,
  },
  contentContainer: {
    marginLeft: 10,
    flex: 1,
    minWidth: 100,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondaryText: {
    color: '#6B6C6F',
    fontSize: 12,
    lineHeight: '15px',
  },
  orderTitle: {
    color: colors.textColor,
    textOverflow: 'ellipsis',
    display: 'block',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
    margin: '10px 0px 8px 0px',
  },
  priceText: {
    fontWeight: 700,
    fontSize: 14,
    color: colors.primaryColor,
  },
  statusText: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 500,
    color: colors.accentColor,
  },
});
