import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const useStyles = makeStyles({
  desktopLayout: {
    display: 'inline',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  voucherItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: 14,
    alignItems: 'center',
  },
  voucherAppliedItem: {
    padding: '6px 12px',
    color: colors.primaryColor,
    background: '#EDEDED',
    borderRadius: 100,
    marginRight: 15,
    marginBottom: 16,
    fontWeight: 500,
    position: 'relative',
  },
  voucherContainer: {
    background: '#fff',
    padding: '26px 14px',
    height: '100%',
  },
  desktopContainer: {
    padding: '40px 0',
  },
  voucherTitle: {
    fontWeight: 700,
    color: '#000',
    fontSize: 16,
  },
  chooseVoucher: {
    color: '#248FED',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
  priceMainItemTitle: {
    fontSize: '14px',
    fontWeight: 400,
    alignSelf: 'center',
  },
  priceMainItemValue: {
    fontSize: '16px',
    fontWeight: 500,
  },
  priceTotalTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  priceTotalValue: {
    color: colors.primaryColor,
    fontWeight: 900,
    fontSize: 20,
  },
  totalContainer: {},
  buttonOrderOther: {
    border: '1px solid #E96E34',
    width: '100%',
    padding: '14px 0',
    borderRadius: 6,
    color: colors.primaryColor,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 22,
    cursor: 'pointer',
  },
  buttonContinue: {
    width: '100%',
    padding: '14px 0',
    borderRadius: 6,
    background: colors.primaryColor,
    color: '#fff',
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 12,
    cursor: 'pointer',
    margin: 0,
  },
  noMargin: {
    marginTop: 0,
  },
  infoContainer: {
    padding: '16px 20px',
    background: '#fff',
    marginTop: 20,
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  colorPrice: {
    fontSize: 14,
    fontWeight: 700,
    color: '#000',
    textAlign: 'left',
  },
  lineThroughPrice: {
    fontSize: 10,
    color: colors.textSecondaryColor,
    textDecorationLine: 'line-through',
    textAlign: 'left',
  },
  priceHeaderContainer: {
    alignItems: 'flex-start',
  },
  cartTotalPrice: {
    color: colors.primaryColor,
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
  },
  closeIconContainer: {
    background: '#F8F9FA',
    width: 32,
    height: 32,
    borderRadius: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  closeIconCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  step: {
    width: '100%',
  },
  voucherListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  voucherCloseIcon: {
    position: 'absolute',
    top: -5,
    right: 0,
    cursor: 'pointer',
    zIndex: 0,
  },
  voucherDetailItem: {
    background: '#EDEDED',
    padding: '8px 12px',
    color: colors.primaryColor,
    borderRadius: 100,
    marginRight: 15,
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 15,
  },
  noDiscountCodeText: {
    color: '##6B6B6B',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '18px',
    marginBottom: '18px',
  },

  dateInput: {
    marginTop: 12,
    width: '90%',
  },
  timeInput: {
    marginTop: 12,
    width: '90%',
  },
  noteInput: {
    marginTop: 12,
    width: '100%',
  },
  textInput: {
    fontSize: 15,
    fontWeight: 500,
    width: '100%',
  },
  title: {
    color: '#000000',
    fontWeight: 700,
    fontSize: 16,
  },
  checkBoxValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '14px',
  },
  billBoxValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 16,
  },
  paymentMethodName: {
    marginLeft: '11px',
    fontWeight: 500,
  },
  paymentMethodItem: {
    marginTop: 16,
    // flexBasis: 1,
  },
  billBoxContainer: {
    marginTop: 30,
    width: '100%',
  },
  billContainer: {},
  inputTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#000',
    marginTop: '16px',
    marginBottom: '8px',
  },
  colorRed: {
    color: '#f04a49',
  },
  updateButtonContainer: {
    bottom: 0,
    width: '100%',
    marginBottom: '32px',
    marginTop: '28px',
  },
  addressContainer: {
    marginTop: 32,
  },
  editAddressText: {
    color: '#248FED',
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 12,
    cursor: 'pointer',
  },
  addressInputTitle: {
    color: '#000',
    fontSize: 14,
    fontWeight: 500,
    marginTop: 12,
    marginBottom: 8,
  },
  pickUpResContainer: {
    marginTop: 20,
  },
  noFee: {
    color: '#47C751',
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 12,
  },
  resAddress: {
    color: '#6B6B6B',
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  textInputContainer: {
    // marginTop: 16,
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 100,
    backgroundColor: '#7A7A7A',
    display: 'inline-block',
    alignSelf: 'center',
    marginRight: 8,
  },
  secondaryTitleText: {
    color: colors.textSecondaryColor,
    fontSize: 12,
    marginLeft: 12,
    fontWeight: 400,
  },
  secondaryValueText: {
    color: colors.textSecondaryColor,
    fontSize: 14,
    fontWeight: 500,
  },
  secondaryRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 0px',
  },
  gBizBalanceText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    color: '#6B6B6B'
  },
  disableGBiz: {
    opacity: 0.5,
  },
  noteForGBiz: {
    background: "rgba(239, 73, 73, 0.1)",
    borderRadius: 6,
    color: "#EF4949",
    padding: 8,
    marginTop: 14,
  },
});
