import { Modal } from 'antd';
import React, { Fragment } from 'react';
import { CartDivider } from '../../../components/cart-divider';
import { ModalCloseIcon } from '../../../components/icons/modal-close';
import { VerifyPaymentWarningIcon } from '../../../components/icons/verify-payment-warning';
import { paymentMethodValues } from '../../../core/contants/paymentMethodValues';
import { useInterval } from '../../../core/hooks/useInterval';
import { orderService } from '../../../services/order-service';
import { useStyles } from './verify-payment-modal.style';
import { useHistory } from 'react-router';
import { VnpayQrcode } from '../../../components/icons/vnpay-qrcode';

interface Props {
  visible: boolean;
  type: string;
  qrCode: string;
  requestId: number;
  notShowSuccessModelAfterClose?: boolean;
  onCloseModal: () => void;
  setNotificationModalVisible: (value: boolean) => void;
  setNotificationType: (value: string) => void;
}

export const VerifyPaymentModal = React.memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    visible,
    qrCode,
    type,
    onCloseModal,
    requestId,
    setNotificationModalVisible,
    setNotificationType,
    notShowSuccessModelAfterClose
  } = props;
  useInterval(async () => {
    if (requestId && visible) {
      const result = await orderService.checkPayment(requestId);
      if (result?.data?.result?.isPaid === 1) {
        onCloseModal();
        setNotificationModalVisible(true);
        setNotificationType('success');
      }
    }
  }, 5000);
  const renderType = () => {
    switch (type) {
      case paymentMethodValues.ZALOPAY:
        return 'ZaloPay';
      case paymentMethodValues.VNPAY:
        return 'VnPay';
      case paymentMethodValues.VINID:
        return 'VinID';
      case paymentMethodValues.SHOPEE_PAY:
        return 'ShopeePay';
      case paymentMethodValues.MOMO:
        return 'MOMO';
      default:
      // code block
    }
  };
  const onCloseModalClick = () => {
    onCloseModal();
    if (!notShowSuccessModelAfterClose) {
      setNotificationModalVisible(true);
      setNotificationType('success');
    }

  }
  const renderContent = () => {
    return (
      <Fragment>
        <div className={classes.header}>
          <div></div>
          <span>Thanh toán {renderType()}</span>
          <div onClick={onCloseModalClick}>
            <ModalCloseIcon color={'#7F7F7F'} />
          </div>
        </div>

        <div className={classes.contentContainer}>
          <div className={classes.qrCodeContainer}>
            <img src={`data:image/png;base64, ${qrCode}`} className={classes.qrCodeIcon} />
          </div>
          <div className={classes.title}>Hướng dẫn thanh toán</div>
          <div className={classes.step}>
            <span className={classes.stepTitle}>Bước 1:</span> Chụp màn hình hoặc lưu hình ảnh
            QRcode về điện thoại
          </div>
          <div className={classes.step}>
            <span className={classes.stepTitle}>Bước 2:</span> Mở ứng dụng để thanh toán
          </div>
          <div className={classes.step}>
            <span className={classes.stepTitle}>Bước 3:</span> Chọn
            <span className={classes.stepTitle}> “QRpay” <VnpayQrcode className={classes.qrCode}/> </span>và chọn hình ảnh QRcode đã lưu ở
            bước 1
          </div>
          <div className={classes.step}>
            <span className={classes.stepTitle}>Bước 4:</span> Hoàn thành các bước thanh toán tiếp
            theo
          </div>
          <div className={classes.noteContainer}>
            <span>
              <VerifyPaymentWarningIcon />
            </span>
            <span className={classes.note}>
              Vui lòng không đóng trình duyệt khi bạn đang thực hiện thanh toán, trình duyệt sẽ tự
              động chuyển tiếp sau khi hoàn thành.
            </span>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Modal
      title=""
      visible={visible}
      footer={null}
      // onCancel={props.onCancelCoupon}
      closeIcon={null}
      centered
      width={340}
      bodyStyle={{
        padding: '24px 16px 16px 16px',
      }}
      closable={false}
      afterClose={() => {
        setTimeout(() => {
          document.body.style.overflow = 'unset';
        }, 0);
      }}
    >
      <div className={classes.modalContent}>{renderContent()}</div>
    </Modal>
  );
});
