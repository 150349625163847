import React from 'react';
import { Link } from 'react-router-dom';
import { ProductItem } from '../../../../components/product-item';
import { IProduct } from '../../../../core/models/product';
import { useDynamicProductStyles } from './dynamic-product.style';

interface Props {
  listDynamic: {
    tabSlug: string,
    tabName: string,
     listProductSorted: IProduct[],
  }[];
  onPressProduct: (product: IProduct) => void;
}

export const DynamicProducts = React.memo((props: Props) => {
  const classes = useDynamicProductStyles();

  return (
    <>
      {props.listDynamic.map((data) => {
        return (
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <div className={classes.titleText}>{data.tabName}</div>
              <Link to={`/danh-sach-san-pham/${data.tabSlug}`}>
                <div className={classes.showAllText}>Tất cả</div>
              </Link>
            </div>

            <div className={classes.contentContainer}>
              {data?.listProductSorted?.map((product) => (
                <ProductItem
                  key={product.id}
                  product={product}
                />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
});
