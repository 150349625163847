import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { BREAK_POINTS } from '../core/contants/break-points';
import { colors } from '../core/contants/colors';
import { images } from '../core/contants/images';

const useCouponStyles = makeStyles({
  imgTitle: {
    width: '72px',
    height: '72px',
    borderRadius: 12,
  },
  couponItem: {
    display: 'flex',
    padding: '16px 0',
    borderBottom: `1px solid ${colors.dividerColor}`,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderBottom: 'none',
    },
  },
  rowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  couponInfoContainer: {
    marginLeft: '12px',
    width: '100%',
    display: 'grid',
    alignItems: 'stretch',
  },
  couponItemCount: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 10,
    fontWeight: 400,
    borderRadius: 100,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
  date: {
    fontSize: 12,
    color: '#6B6B6B',
    fontWeight: 400,
  },
  title: {
    color: '#000',
    fontWeight: 700,
    fontSize: 15,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridRowStart: 1,
      gridColumnStart: 1,
    },
  },
  cancelText: {
    color: colors.dangerColor,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
  },
  activeText: {
    color: colors.accentColor,
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
  },
});

export interface ICouponViewModel {
  imgSrc?: string;
  title: string;
  from: string;
  to: string;
  status: string;
}

interface Props {
  couponVM: ICouponViewModel;
  onApply?: (e) => void;
  onClick?: (e) => void;
  disableApply?: boolean;
}

export const Coupon = React.memo(({ couponVM, onApply, onClick, disableApply }: Props) => {
  const classes = useCouponStyles();

  const convertCurrentStatus = () => {
    switch (couponVM?.status) {
      case 'cancel':
        return <div className={classes.cancelText}>Hủy áp dụng</div>;
      case 'active':
        return (
          <div
            className={classes.activeText}
            onClick={(e) => {
              e.stopPropagation();

              if (!onApply) return;
              onApply(e);
            }}
          >
            Sử dụng ngay
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div className={classes.couponItem} onClick={onClick}>
      <img src={couponVM.imgSrc || images.couponThumbnail} className={classes.imgTitle} alt="" />
      <div className={classes.couponInfoContainer}>
        <div className={classes.date}>
          Áp dụng {couponVM.from} - {couponVM.to}
        </div>
        <div className={classes.title}>{couponVM?.title ?? ''}</div>
        {!disableApply && convertCurrentStatus()}
      </div>
    </div>
  );
});
