import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartCloseIcon } from '../../../../components/icons/cart-close';
import { CartPlusIcon } from '../../../../components/icons/cart-plus';
import { CartSubtractIcon } from '../../../../components/icons/cart-subtract';
import { formatCurrency } from '../../../../core/ultis/currency';
import { RootState } from '../../../../store';
import { useStyles } from './cart-item.style';
interface Props {
  item: any;
  onPlusItem: (item: any) => void;
  onSubtractItem: (item: any) => void;
  onDeleteItem: (item: any) => void;
}
export const CartItem = React.memo((props: Props) => {
  const { item, onPlusItem, onSubtractItem, onDeleteItem } = props;
  const { name, regularPrice, salePrice, quantity } = item;
  const appContext = useSelector((state: RootState) => state.appContext);
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{name}</div>
          <div className={classes.closeIconContainer} onClick={() => onDeleteItem(item)}>
            <CartCloseIcon />
          </div>
        </div>
        <div className={classes.brandContainer}>
          <img src={appContext.selectedBrand?.logo} className={classes.brandLogo} alt="" />
          <div className={classes.brandText}>{appContext?.selectedBrand?.name}</div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.priceContainer}>
            <div className={classes.colorPrice}>{formatCurrency(salePrice || regularPrice)}</div>
            {regularPrice && salePrice ? (
              <div className={classes.lineThroughPrice}>{formatCurrency(regularPrice)}</div>
            ) : (
              <Fragment></Fragment>
            )}
          </div>

          <div className={classes.quantityContainer}>
            <div onClick={() => onSubtractItem(item)}>
              <CartSubtractIcon />
            </div>
            <div className={classes.quantityText}>{quantity}</div>
            <div onClick={() => onPlusItem(item)}>
              <CartPlusIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.horizontalRule}></div>
    </Fragment>
  );
});
