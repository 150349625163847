import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';

export const useOrderEmptyStyles = makeStyles({
  text: {
    fontSize: 16,
    lineHeight: '32px',
    color: colors.textSecondaryColor,
    marginTop: 20,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100%',
    // flex: 1,
    margin: '20px 0px',
  },
});
