import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import viCommon from "./vi/common.json";
import viHome from "./vi/home.json";
import viProfile from "./vi/profile.json";
import viOrder from "./vi/order.json";
import viRating from "./vi/rating.json";
import viFooter from "./vi/footer.json";
import enCommon from "./en/common.json";
import enHome from "./en/home.json";
import enProfile from "./en/profile.json";
import enOrder from "./en/order.json";
import enRating from "./en/rating.json";
import enFooter from "./en/footer.json";

export enum I18N_NAMESPACES {
  COMMON = "common",
  HOME = "home",
  PROFILE = "profile",
  ORDER = "order",
  RATING = "rating",
  FOOTER = "footer",
}

i18n.use(initReactI18next).init({
  fallbackLng: "vi",
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    vi: {
      [I18N_NAMESPACES.COMMON]: viCommon,
      [I18N_NAMESPACES.HOME]: viHome,
      [I18N_NAMESPACES.PROFILE]: viProfile,
      [I18N_NAMESPACES.ORDER]: viOrder,
      [I18N_NAMESPACES.RATING]: viRating,
      [I18N_NAMESPACES.FOOTER]: viFooter,
    },

    en: {
      [I18N_NAMESPACES.COMMON]: enCommon,
      [I18N_NAMESPACES.HOME]: enHome,
      [I18N_NAMESPACES.PROFILE]: enProfile,
      [I18N_NAMESPACES.ORDER]: enOrder,
      [I18N_NAMESPACES.RATING]: enRating,
      [I18N_NAMESPACES.FOOTER]: enFooter,
    },
  },
});

export default i18n;
