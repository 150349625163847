import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../../core/contants/colors";

export const useLoginSecondStepStyles = makeStyles({
  phoneText: {
    color: "#000",
    fontWeight: 500,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0px",
  },
  actionText: {
    color: colors.accentColor,
    cursor: "pointer",
    fontWeight: 400,
  },
  actionTextDisable: {
    color: colors.textGray,
  },
  confirmBtn: {
    marginTop: 24,
    marginBottom: 12
  },

  confirmNoteText: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.3,
    textAlign: "center",
    color: colors.textSecondaryColor,
  },
});
