import { Calendar, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NextIcon } from '../../../../components/icons/next-icon';
import { PreviousIcon } from '../../../../components/icons/previous-icon';
import './custom-datepicker-modal.css';
import { useStyles } from './custom-datepicker-modal.style';
interface Props {
  visible: boolean;
  onCancelModal: () => void;
  onSubmitModal: (value: any) => void;
  value?: any;
  isSelectFullDate?: boolean;
}
export const CustomDatePickerModal = React.memo((props: Props) => {
  const classes = useStyles();
  const { visible, onCancelModal, onSubmitModal, value, isSelectFullDate } = props;
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentValue, setCurrentValue] = useState(moment());
  const onCancelButtonClick = () => {
    setCurrentMonth(moment());
    setCurrentValue(moment());
    onCancelModal();
  };

  useEffect(() => {
    if (!visible && value) {
      setCurrentValue(moment(value));
      setCurrentMonth(moment(value));
    }
  }, [value, visible]);

  useEffect(() => {
    if (value) {
      setCurrentValue(moment(value));
      setCurrentMonth(moment(value));
    }
  }, [value]);

  const onSubmitButtonClick = () => {
    onSubmitModal(currentValue);
  };

  return (
    <div className="custom-date-picker-modal">
      <Modal
        title=""
        visible={visible}
        footer={null}
        onCancel={onCancelModal}
        closeIcon={null}
        closable={false}
      >
        <Calendar
          fullscreen={false}
          onPanelChange={() => {}}
          mode="month"
          value={currentValue}
          onSelect={(cellValue: any) => {
            const isValidSelectDate = cellValue.startOf('day').diff(moment().startOf('day')) >= 0;
            if (isValidSelectDate || isSelectFullDate) {
              setCurrentValue(cellValue);
            }
          }}
          dateFullCellRender={(cellValue: any) => {
            const isSelected =
              cellValue.isSame(currentValue, 'days') && cellValue.isSame(currentValue, 'month');
            const notInCurrentMonth = !cellValue.isSame(currentMonth, 'month');
            const isValidSelectDate = cellValue.startOf('day').diff(moment().startOf('day')) >= 0;
            return (
              <div
                className={
                  notInCurrentMonth
                    ? classes.inActiveDate
                    : isSelected
                    ? classes.activeDate
                    : isValidSelectDate || isSelectFullDate
                    ? classes.dateInMonth
                    : classes.dateInMonthDisabled
                }
              >
                {moment(cellValue).get('date')}
              </div>
            );
          }}
          headerRender={({ value, type, onChange, onTypeChange }: any) => {
            const month = value.month();
            return (
              <div className={classes.selectMonthContainer}>
                <div className={classes.icon}>
                  <PreviousIcon
                    onClick={() => {
                      const newValue = value.clone();
                      newValue.month(parseInt(String(month - 1), 10));
                      onChange(newValue);
                      setCurrentMonth(newValue);
                    }}
                  />
                </div>
                <div className={classes.monthText}>
                  Tháng {month + 1 > 9 ? String(month + 1) : `0${String(month + 1)}`} /{' '}
                  {value.year()}
                </div>
                <div className={classes.icon}>
                  <NextIcon
                    onClick={() => {
                      const newValue = value.clone();
                      newValue.month(parseInt(String(month + 1), 10));
                      onChange(newValue);
                      setCurrentMonth(newValue);
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
        <div className={classes.buttonContainer}>
          <div className={classes.cancelButton} onClick={onCancelButtonClick}>
            Hủy bỏ
          </div>
          <div className={classes.okButton} onClick={onSubmitButtonClick}>
            Đồng ý
          </div>
        </div>
      </Modal>
    </div>
  );
});
