import Slider from '@ant-design/react-slick';
import { useMediaQuery } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AppFooter } from '../../components/app-footer';
import { Container } from '../../components/container';
import { ModalCloseIcon } from '../../components/icons/modal-close';
import { ProvinceSelectorBottomSheet } from '../../components/province-selector';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { images } from '../../core/contants/images';
import { UNSUPPORTED_PROVINCES } from '../../core/environments/env';
import { IBanner } from '../../core/models/banner';
import { IBrand } from '../../core/models/brand';
import { IOrderItem } from '../../core/models/order';
import { IProduct } from '../../core/models/product';
import { IProvince } from '../../core/models/province';
import { IRate } from '../../core/models/rating';
import { ITag } from '../../core/models/tag';
import { bannerService } from '../../services/banner-service';
import { brandService } from '../../services/brand-service';
import { orderService } from '../../services/order-service';
import { productService } from '../../services/product-service';
import { ratingService } from '../../services/rating-service';
import { tagService } from '../../services/tag-service';
import { Dispatch, RootState } from '../../store';
import { OVERLAY_MODAL } from '../../store/models/app-context';
import { LoginScreen } from '../login/login.screen';
import { AdvantageList } from './components/advantage-list/advantage-list.component';
import { BrandList } from './components/brand-list/brand-list.component';
import { CouponPopup } from './components/coupon-popup/coupon-popup.component';
import { HomeHeader } from './components/header/header.component';
import { HotDeal } from './components/hot-deal/hot-deal.component';
import { Suggestion } from './components/suggestion/suggestion.component';
import { TrackingOrdersPopup } from './components/tracking-orders-popup/tracking-orders-popup.component';
import { useHomeStyles } from './home.style';
import {DynamicProducts} from "./components/dynamic-product/dynamic-product.component";
import {useCookies} from "react-cookie";
import moment from "moment";
import {useBrandSliderStyles} from "../brand-detail/components/brand-slider/brand-slider.style";
import clsx from "clsx";
import {ArrowRightIcon} from "../../components/icons/arrow-right";
import {ArrowLeftIcon} from "../../components/icons/arrow-left";
import "./custom.css";
import { NetCore } from "../../assets/netcore/netcore";

export const HomeScreen = React.memo(() => {
  const classes = useHomeStyles();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const [isVisibleCountrySelector, setIsVisibleCountrySelector] = useState(false);
  const [isVisibleCouponPopup, setIsVisibleCouponPopup] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState<IBrand>({
    id: 'all',
    name: 'Tất cả',
    brandId: 'all',
  });
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [brandsByProvince, setBrandsByProvince] = useState<IBrand[]>([]);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [popupBanner, setPopupBanner] = useState<IBanner[]>([]);
  const [hotDealProducts, setHotDealProducts] = useState<IProduct[]>([]);
  const [dynamicProducts, setDynamicProducts] = useState<any>([]);
  const [suggestedProducts, setSuggestedProducts] = useState<IProduct[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [selectedTag, setSelectedTag] = useState<ITag>({ id: 'all', name: 'tất cả', slug: '' });
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const [ongoingOrders, setOngoingOrders] = useState<IOrderItem[]>([]);
  const [rattingOrders, setRattingOrders] = useState<IOrderItem[]>([]);
  const [rates, setRates] = useState<IRate[]>([]);

  const [isVisibleTrackingDetail, setIsVisibleTrackingDetail] = useState(false);

  const [page, setPage] = useState(1);
  const pageSize = 8;
  const appContext = useSelector((state: RootState) => state.appContext);
  const user = useSelector((state: RootState) => state.user);
  const [cookies, setCookies] = useCookies([
    'coupon_home_showed',
  ]);

  const loadMoreSuggestedProducts = async () => {
    setPage((value) => value + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [appContext.selectedProvince]);

  useEffect(() => {
    async function getSuggestedProducts() {
      if (appContext.selectedProvince?.id) {
        const response = await productService.getProducts({
          provinceId: appContext.selectedProvince?.id,
          page,
          tag: selectedTag?.slug,
          perPage: pageSize,
          orderBy,
          brandId: selectedBrand?.brandId !== 'all' ? selectedBrand.brandId : null,
          position: 'home',
        });
        setIsEndOfList(!response?.data?.result || (response.data?.result?.length || 0) < pageSize);

        if (page > 1) {
          setSuggestedProducts((value) => [...value, ...(response?.data?.result || [])]);
        } else {
          setSuggestedProducts(response?.data?.result || []);
        }
      }
    }

    getSuggestedProducts();
  }, [appContext.selectedProvince, selectedTag, page, orderBy, selectedBrand]);

  useEffect(() => {
    async function getDynamicProducts() {
      if (appContext.selectedProvince?.id) {
        const response = await productService.getDynamicProducts({
          provinceId: appContext.selectedProvince?.id,
          position: 'home-page',
          perPage: 8,
          page: 1,
        });
        setDynamicProducts(response?.data?.result || []);
      }
    }

    getDynamicProducts();
  }, [appContext.selectedProvince]);

  useEffect(() => {
    async function getHotDealProducts() {
      if (appContext.selectedProvince?.id) {
        const response = await productService.getProducts({
          group: 'hot-deal',
          provinceId: appContext.selectedProvince?.id,
          page: 1,
          perPage: isDesktop ? 8 : 4,
          position: 'home',
        });
        setHotDealProducts(response?.data?.result || []);
      }
    }

    getHotDealProducts();
  }, [appContext.selectedProvince, isDesktop]);

  useEffect(() => {
    async function getBrandsByProvince() {
      if (appContext.selectedProvince?.id) {
        const response = await brandService.getBrandByProvince(appContext.selectedProvince?.id);
        setBrandsByProvince(response?.data?.result || []);
      }
    }

    async function getBannersByProvinces() {
      if (appContext.selectedProvince?.id) {
        const response = await bannerService.getBanners({
          position: 'home-sliders',
          provinceId: appContext.selectedProvince?.id,
        });
        setBanners(response?.data?.result || []);
      }
    }

    async function getTags() {
      if (appContext.selectedProvince?.id) {
        const response = await tagService.getTags({ provinceId: appContext.selectedProvince?.id });
        setTags([{ id: 'all', name: 'tất cả', slug: '' }, ...(response?.data?.result || [])]);
      }
    }

    async function getPopupBanner() {
      if (appContext.selectedProvince?.id) {
        const response = await bannerService.getBanners({
          position: 'home',
          provinceId: appContext.selectedProvince?.id,
        });

        if (
          response.data?.result &&
          response.data.result.length > 0 &&
          !appContext.isSeenHomePopupBanner
        ) {
          if (!cookies['coupon_home_showed']) {
            setPopupBanner(response.data.result ? response?.data?.result : []);
            setIsVisibleCouponPopup(true);
            if (response?.data?.result) {
              setCookies('coupon_home_showed', true, {
                expires: moment().add(6, 'h').toDate(),
              });
            }
          }
        }
      }
    }

    setPage(1);
    getBrandsByProvince();
    getBannersByProvinces();
    getTags();
    getPopupBanner();
  }, [appContext.selectedProvince]);

  useEffect(() => {
    async function getRecommendRatingOrders() {
      const response = await orderService.getRecommendRatingOrders();

      const orders = response.data?.result || [];
      setRattingOrders(orders);

      if (orders.length === 0) {
        getOngoingOrders();
      }
    }

    async function getOngoingOrders() {
      const response = await orderService.getOrders({ status: 'trans-processing', perPage: 3 });
      setOngoingOrders(response.data?.result || []);
    }

    async function getRateList() {
      const response = await ratingService.getRates();
      setRates(response.data?.result || []);
    }

    if (user.authentication.token) {
      getRateList();
      getRecommendRatingOrders();
    }
  }, [user.authentication.token]);

  const openCountrySeletor = () => {
    dispatch.appContext.setOverlayModal(OVERLAY_MODAL.SELECT_PROVINCE);
  };

  const onPressProduct = useCallback(
    (product) => {
      setSelectedProduct(product);
    },
    [history, isDesktop],
  );

  const onOpenSearchField = () => {
    history.push('/search');
  };

  const onClickProfile = useCallback(() => {
    if (!user.authentication.token) {
      dispatch.appContext.setOverlayModal(OVERLAY_MODAL.LOGIN);
    } else {
      history.push('/profile');
    }
  }, [user]);

  const onClickBrand = async (brand: IBrand) => {
    let data = {
      brandName: brand.name,
      restaurantName: `${brand.name} ${appContext.selectedProvince?.name || ''}`
    }
    NetCore.ncBeginOrder(data);
    dispatch.appContext.changeBrand({ brand });
  };

  const redirectToUrl = (url) => {
    if (!url) {
      return;
    }
    window.location.href = url;
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <div
            className={clsx(className, classes.arrowContainer)}
            style={{ right: 16 }}
            onClick={onClick}
        >
          <ArrowRightIcon color="white"/>
        </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <div className={clsx(className, classes.arrowContainer)} style={{ left: 16 }} onClick={onClick}>
          <ArrowLeftIcon color="white"/>
        </div>
    );
  };

  return (
    <>
      {isDesktop && (
        <Slider
          adaptiveHeight
          // arrows={false}
          infinite
          autoplay
          speed={500}
          slidesToShow={1}
          autoplaySpeed={5000}
          dots
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          dotsClass={`slick-dots desktop ${classes.dots}`}
          appendDots={(dots) => (
              <div
                  style={{
                    borderRadius: '10px',
                    bottom: 8,
                  }}
              >
                <ul style={{ margin: '0px' }}> {dots} </ul>
              </div>
          )}
        >
          {banners.map((banner) => (
            <img src={banner.desktopImage.toString()}
                 className={classes.banner}
                 alt={banner.name}
                 key={banner.id}
                 onClick={() => redirectToUrl(banner.linkTarget)}
            />
          ))}
        </Slider>
      )}
      <Container className={classes.container}>
        <HomeHeader
          onClickProfile={onClickProfile}
          selectedProvince={appContext.selectedProvince}
          openCountrySeletor={openCountrySeletor}
          onSearch={onOpenSearchField}
        />
        <div className={classes.contentContainer}>
          <AdvantageList />
          {!isDesktop && (
            <Slider
                adaptiveHeight
                arrows={false}
                infinite
                autoplay
                speed={500}
                slidesToShow={1}
                dots
                dotsClass={`slick-dots mobile ${classes.dots}`}
                appendDots={(dots) => (
                    <div
                        style={{
                          borderRadius: '10px',
                          bottom: 8,
                        }}
                    >
                      <ul style={{ margin: '0px' }}> {dots} </ul>
                    </div>
                )}
            >
              {banners.map((banner) => (
                <img src={banner.desktopImage.toString()}
                     className={classes.banner}
                     alt={banner.name}
                     key={banner.id}
                     onClick={() => redirectToUrl(banner.linkTarget)}
                />
              ))}
            </Slider>
          )}

          <BrandList brands={brandsByProvince} onClickBrand={onClickBrand} />
          {dynamicProducts.length > 0 && (
            <DynamicProducts listDynamic={dynamicProducts} onPressProduct={onPressProduct} />
          )}
          {hotDealProducts.length > 0 && (
            <HotDeal products={hotDealProducts} onPressProduct={onPressProduct} />
          )}
          <Suggestion
            tags={tags}
            selectedTag={selectedTag}
            onChangedTag={(tag) => {
              setSelectedTag(tag);
              setPage(1);
            }}
            isEndOfList={isEndOfList}
            onScrollToEnd={loadMoreSuggestedProducts}
            products={suggestedProducts}
            onPressProduct={onPressProduct}
            onChangedOrderBy={(value) => {
              setOrderBy(value);
              setPage(1);
            }}
            onChangedBrand={(brand) => {
              setSelectedBrand(brand);
              setPage(1);
            }}
            orderBy={orderBy}
            filteredBrand={selectedBrand}
            brandsByProvince={brandsByProvince}
          />
        </div>

        <CouponPopup
          isVisible={isVisibleCouponPopup}
          banners={popupBanner}
          close={() => {
            setIsVisibleCouponPopup(false);
            dispatch.appContext.setIsSeenHomePagePopupBanner(true);
          }}
        />

        {rattingOrders.length > 0 ? (
          <div
            className={classes.ongoingFooter}
            onClick={(e) => {
              history.push(`order/${rattingOrders[0].orderId}/rating`);
            }}
          >
            <div
              className={classes.closeIconContainer}
              onClick={(e) => {
                e.stopPropagation();
                setRattingOrders([]);
              }}
            >
              <ModalCloseIcon className={classes.closeIcon} color="#7A7A7A" />
            </div>
            <img src={images.logoSquare} className={classes.logoSquare} />
            <div style={{ flex: 1, marginRight: 12 }}>
              <div className={classes.ongoingText}>Hãy đánh giá đơn hàng gần đây của bạn</div>
            </div>
            <div className={classes.ratingContainer}>
              {rates.map((r) => (
                <img src={r.inactiveIconUrl} className={classes.ratingIcon} key={r.id} />
              ))}
            </div>
          </div>
        ) : (
          ongoingOrders.length > 0 && (
            <div className={classes.ongoingFooter} onClick={() => setIsVisibleTrackingDetail(true)}>
              <div
                className={classes.closeIconContainer}
                onClick={(e) => {
                  e.stopPropagation();
                  setOngoingOrders([]);
                }}
              >
                <ModalCloseIcon className={classes.closeIcon} color="#7A7A7A" />
              </div>
              <img src={images.logoSquare} className={classes.logoSquare} />
              <div>
                <div className={classes.ongoingText}>
                  Bạn đang có {ongoingOrders.length} đơn hàng đang giao
                </div>
                <div className={classes.trackingText}>Theo dõi đơn hàng</div>
              </div>
            </div>
          )
        )}

        <TrackingOrdersPopup
          isVisible={isVisibleTrackingDetail}
          orders={ongoingOrders}
          close={() => {
            setIsVisibleTrackingDetail(false);
          }}
        />
      </Container>
    </>
  );
});
