import { makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../core/contants/colors';

interface Props {
  //   size?: number;
  color?: string;
  borderBottomColor?: string;
}

const useStyles = makeStyles<{}, Props>({
  spinner: {
    animation: '$spinEffect 2s 0s infinite linear',
    background: 'transparent !important',
    width: 25,
    height: 25,
    borderRadius: '100%',
    border: '3px solid',
    borderColor: (props) => props.color || colors.primaryColor,
    borderBottomColor: (props) => props.borderBottomColor || '#ededed',
    display: 'inline-block',
    animationFillMode: 'both',
    userSelect: 'none',
  },

  '@keyframes spinEffect': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(180deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

export const Spinner = React.memo((props: Props) => {
  const classes = useStyles(props);

  return <span className={classes.spinner} />;
});
