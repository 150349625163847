import React from "react";

interface Props {
  className?: string;
}

export const SearchIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.6644" cy="11.6644" r="7.66439" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.9951 17.3931L20 20.3901" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});
