import { createModel } from '@rematch/core';
import { cloneDeep, remove, uniq } from 'lodash';
import { RootModel } from '.';
import { IBrand } from '../../core/models/brand';
import { favoriteService } from '../../services/favorite-service';

export interface IBrandStore {
  favorite: {
    byId: { [key: string]: IBrand };
    allIds: string[];
  };
}

const initialState: IBrandStore = {
  favorite: {
    byId: {},
    allIds: [],
  },
};

export const brand = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    reset(_, __) {
      return cloneDeep(initialState);
    },
    getFavoriteBrandsSuccess(state, payload: { brands: IBrand[] }) {
      return {
        ...state,
        favorite: {
          byId: payload.brands.reduce((acc, brand) => ({ ...acc, [brand.id]: brand }), {}),
          allIds: payload.brands.map((p) => p.id),
        },
      };
    },
    addFavoriteSuccess(state, payload: { brand: IBrand }) {
      return {
        ...state,
        favorite: {
          byId: {
            ...state.favorite.byId,
            [payload.brand.brandId]: payload.brand,
          },
          allIds: uniq([...state.favorite.allIds, payload.brand.brandId]),
        },
      };
    },
    deleteFavoriteSuccess(state, payload: { brand: IBrand }) {
      const {
        [payload.brand.brandId]: {},
        ...rest
      } = state.favorite.byId;
      return {
        ...state,
        favorite: {
          byId: rest,
          allIds: remove(state.favorite.allIds, (id) => id !== payload.brand.brandId),
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getFavoriteBrands(_, state) {
      try {
        const response = await favoriteService.getBrands({ perPage: 1000000 });
        dispatch.brand.getFavoriteBrandsSuccess({ brands: response?.data?.result || [] });
      } catch (e) {}
    },
    async addFavorite(payload: { brand: IBrand }, state) {
      const response = await favoriteService.addBrand(payload.brand.brandId);
      if (response.data.messageCode === 200) {
        dispatch.brand.addFavoriteSuccess({ brand: payload.brand });
      }
    },
    async deleteFavorite(payload: { brand: IBrand }, state) {
      const response = await favoriteService.deleteBrand(payload.brand.brandId);
      if (response.data.messageCode === 200) {
        dispatch.brand.deleteFavoriteSuccess({ brand: payload.brand });
      }
    },
  }),
});
