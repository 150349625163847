import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { CheckboxCheckedIcon } from './icons/checkbox-checked';
import { CheckboxUncheckedIcon } from './icons/checkbox-unchecked';
import { RadioCheckedIcon } from './icons/radio-checked';
import { RadioUncheckedIcon } from './icons/radio-unchecked';

interface Props {
  isChecked: boolean;
  onChanged?: (value: boolean) => void;
  containerClassname?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    userSelect: 'none',
  },
  checkboxContainer: {
    marginRight: 8,
  },
});

export const AppCheckbox = React.memo((props: React.PropsWithChildren<Props>) => {
  const classes = useStyles();

  const click = () => {
    if (!props.onChanged) return;
    props.onChanged(!props.isChecked);
  };

  return (
    <div className={clsx(classes.container, props.containerClassname)} onClick={click}>
      <div className={classes.checkboxContainer}>
        {props.isChecked ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
      </div>
      {props.children}
    </div>
  );
});
