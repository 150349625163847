import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const useHomeStyles = makeStyles({
  container: {
    minHeight: '100vh',
  },
  contentContainer: {
    padding: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: 0,
      paddingBottom: 40,
    },
  },
  banner: {
    cursor: 'pointer',
    borderRadius: 8,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderRadius: 0,
    },
  },
  placeholder: {
    height: 80,
  },

  sliderMobile: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },

  sliderDesktop: {
    display: 'none',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'block',
    },
  },
  ongoingFooter: {
    margin: 16,
    background: '#fff',
    borderRadius: 6,
    position: 'sticky',
    bottom: 12,
    left: 0,
    padding: 16,
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    alignItems: 'center',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  logoSquare: {
    height: 36,
    width: 36,
    marginRight: 12,
  },
  ongoingText: {
    color: '#000',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '18px',
  },
  trackingText: {
    color: colors.accentColor,
    fontWeight: 700,
    fontSize: 13,
  },
  ratingContainer: {
    display: 'flex',
    gap: 8,
  },

  ratingIcon: {
    height: 20,
    width: 20,
  },
  closeIconContainer: {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer'
  },
  closeIcon: {
    height: 12,
    width: 12,
  },

  arrowContainer: {
    width: 40,
    height: 40,
    borderRadius: 100,
    background: '#0000004a',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,

    '&::before': {
      content: "''",
    },

    '&:hover': {
      background: '#0000005a',
    },
  },

  dots: {
    '& li.slick-active button::before': {
      color: '#fff',
    },
    '& li': {
      '& button::before': {
        fontSize: 10,
        color: '#fff',
      },
    },
  },
});
