import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowDownIcon } from '../../../../components/icons/arrow-down';
import { CartFilledIcon } from '../../../../components/icons/cart-filled';
import { ChervonLeftIcon } from '../../../../components/icons/chervon-left';
import { DocumentPrimaryIcon } from '../../../../components/icons/document-primary';
import { FavoritePrimaryIcon } from '../../../../components/icons/favourite-primay';
import { LocationIcon } from '../../../../components/icons/locations';
import { ProfileFilledIcon } from '../../../../components/icons/profile-filled';
import { SearchIcon } from '../../../../components/icons/search';
import { SearchPrimaryIcon } from '../../../../components/icons/search-primary';
import { colors } from '../../../../core/contants/colors';
import { images } from '../../../../core/contants/images';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { IProvince } from '../../../../core/models/province';
import { RootState, store } from '../../../../store';
import { useHomeHeaderStyles } from './header.style';

export interface Props {
  openCountrySeletor: () => void;
  onSearch: () => void;
  onClickProfile: () => void;
  selectedProvince: IProvince | null;
}

export const HomeHeader = React.memo((props: Props) => {
  const classes = useHomeHeaderStyles();
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);
  const cartLength = useSelector((state: RootState) => store.select.cart.cartLength(state));
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <img src={images.homeHeaderMask} alt="" className={classes.headerBackgroundImage} />
        <div className={classes.deliveryContainer}>
          <img src={images.logo} alt="" className={classes.logo} />

          <div className={classes.delieveryInfoContainer}>
            <div className={classes.deliveryText}>{t('deliveryTo')}</div>
            <div className={classes.locationContainer} onClick={props.openCountrySeletor}>
              <LocationIcon className={classes.locationIcon} />
              <div className={classes.locationText}>{props.selectedProvince?.name}</div>
              <ArrowDownIcon className={classes.arrowDownIcon} />
            </div>
          </div>

          <div className={classes.headerActionContainer}>
            <Link to="/cart" className={classes.cartIconContainer}>
              <CartFilledIcon className={classes.actionIcon} />
              {cartLength ? <div className={classes.badge}>{cartLength}</div> : <div />}
            </Link>
            <div className={classes.profileContainer} onClick={props.onClickProfile}>
              <ProfileFilledIcon className={classes.actionIcon} />
              {!!user.authentication.token && <ArrowDownIcon />}
            </div>
          </div>
        </div>

        <div className={classes.searchTextInputContainer} onClick={props.onSearch}>
          <SearchIcon />
          <div className={classes.searchTextInput}>{t('searchPlaceholder')}</div>
          {/* <input className={classes.searchTextInput} placeholder="Nhập từ khoá tìm kiếm ..." /> */}
        </div>
      </div>

      <div className={classes.headerOverlayContainer} style={{ top: showStickyHeader ? 0 : -70 }}>
        <ChervonLeftIcon
          className={`${classes.backIcon} ${classes.black}`}
          color={colors.textColor}
        />
        <div
          className={`${classes.locationContainer} flex-1 ml-3`}
          onClick={props.openCountrySeletor}
        >
          <LocationIcon
            className={`${classes.locationIcon}  ${classes.black}`}
            color={colors.textColor}
          />
          <div className={`${classes.overlayLocationText}  ${classes.black}`}>Hà Nội</div>
          <ArrowDownIcon className={`${classes.arrowDownIcon}  ${classes.black}`} />
        </div>

        <div className={classes.headerActionContainer}>
          <SearchPrimaryIcon className={classes.actionIcon} />
          <FavoritePrimaryIcon className={classes.actionIcon} />
          <DocumentPrimaryIcon className={classes.actionIcon} />
        </div>
      </div>
    </div>
  );
});
