import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BREAK_POINTS } from '../core/contants/break-points';
import { colors } from '../core/contants/colors';
import { formatCurrency } from '../core/ultis/currency';
import { RootState, store } from '../store';

const useStyles = makeStyles({
  webkitContainer: {
    position: '-webkit-sticky',
  },
  container: {
    backgroundColor: '#fff',
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    bottom: 0,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  cartContainer: {
    backgroundColor: colors.primaryColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 20px',
    flex: 1,
    borderRadius: 10,
  },
  cartText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  priceText: {
    fontSize: 20,
    color: '#fff',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'grid',
    gridGap: 12,
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  cartLengthContainer: {
    backgroundColor: '#fff',
    width: 24,
    height: 24,
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: 1000,
    color: colors.primaryColor,
    fontWeight: 'bold',
  },
});

export const CartFooter = React.memo(() => {
  const classes = useStyles();
  const cart = useSelector((state: RootState) => state.cart);
  const cartLength = useSelector((state: RootState) => store.select.cart.cartLength(state));

  if (cartLength < 1) {
    return <></>;
  }

  return (
    <Link to="/cart" className={clsx(classes.container)}>
      <div className={classes.cartContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.cartText}>Giỏ hàng</div>
          <div className={classes.cartLengthContainer}>{cartLength}</div>
        </div>
        <div className={classes.priceText}>
          {formatCurrency(cart.totals.totalPriceWithoutShipping)}
        </div>
      </div>
    </Link>
  );
});
