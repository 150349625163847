import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useProductByCategoriesStyles = makeStyles({
  container: {
    marginTop: 8,
    padding: 16,
    backgroundColor: '#fff',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 40,
      paddingTop: 0,
      backgroundColor: 'transparent',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 4,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 10,
    },
  },
  tag: {
    fontSize: 13,
    color: '#000',
    borderWidth: 1,
    borderColor: '#D8D8D8',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 12px',
    marginTop: 8,
    marginRight: 8,
    cursor: 'pointer',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      backgroundColor: '#fff',
      border: 'none',
    },
  },

  activeTag: {
    backgroundColor: colors.primaryColor,
    color: '#fff',
    borderColor: colors.primaryColor,
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 12,
    // marginTop: 12,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: 30,
      // marginTop: 30,
    },
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 24,
    gridColumnStart: 1,
    gridColumnEnd: 3,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridColumnEnd: 5,
    },
  },
});
