import { makeStyles } from "@material-ui/styles";
import { BREAK_POINTS } from "../../../../core/contants/break-points";
import { colors } from "../../../../core/contants/colors";

export const useDynamicProductStyles = makeStyles({
  container: {
    marginTop: 8,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 40,
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleText: {
    fontWeight: 700,
    color: "#161616",
    fontSize: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 24,
    },
  },
  showAllText: {
    color: colors.accentColor,
    fontWeight: 500,
    fontSize: 15,
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 12,
    minWidth: 0,
    minHeight: 0,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: "repeat(4,1fr)",
      gridGap: 30,
      marginTop: 10,
    },
  },
  thumbnail: {
    width: "100%",
  },
  itemContainer: {
    borderRadius: 8,
    padding: 8,
    boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.06)",
    minWidth: 0,
  },
});
