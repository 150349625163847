import React from "react";

interface Props {
  className?: string;
  color?: string;
  onClick: () => void;
}

export const QrClose = React.memo(
  ({ className, color, onClick }: React.PropsWithChildren<Props>) => {
    return (
      <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="18" y="5" width="2" height="18" rx="1" transform="rotate(45 18 5)" fill="white"/>
      <rect x="19" y="18" width="2" height="18" rx="1" transform="rotate(135 19 18)" fill="white"/>
      </svg>
    );
  }
);
