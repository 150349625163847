import React from 'react';

interface Props {
  className?: string;
}

export const CircleGreenTickFilledIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0C8.05885 0 0 8.05886 0 18C0 27.9411 8.05885 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05886 27.9411 0 18 0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1 24.1143L13.9636 22.9779L24.9758 11.9255C25.3524 11.5489 25.9629 11.5489 26.3395 11.9255L26.7941 12.3801C27.1706 12.7566 27.1706 13.3672 26.7941 13.7438L16.4637 24.1143C16.0872 24.4909 15.4766 24.4909 15.1 24.1143Z"
          fill="#47C751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5982 22.9782L16.4618 24.1146C16.0852 24.4912 15.4747 24.4912 15.0981 24.1146L10.573 19.5895C10.1964 19.2129 10.1964 18.6023 10.573 18.2258L11.0275 17.7712C11.4041 17.3946 12.0147 17.3946 12.3913 17.7712L17.5982 22.9782Z"
          fill="#47C751"
        />
      </svg>
    );
  },
);
