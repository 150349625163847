import React, {useState} from 'react';
import { CartCloseIcon } from '../../../../components/icons/cart-close';
import { RaisedButton } from '../../../../components/raised-button';
import { TextInput } from '../../../../components/text-input';
import { useLoginStyles } from '../../login.style';
import { useLoginSecondStepStyles } from './second-step.style';

interface Props {
  close?: () => void;
  onBack?: () => void;
  phoneNumber: string;
  otp: string;
  onChangeOtp: (phone: string) => void;
  onLogin: (e) => void;
  isLoading?: boolean;
  onResendOtp?: any;
  isCountDown: number;
}

export const LoginSecondStep = React.memo((props: Props) => {
  const classes = { ...useLoginStyles(), ...useLoginSecondStepStyles() };

  return (
    <form className={classes.container} onSubmit={props.onLogin}>
      <div className={classes.header}>
        <div className={`${classes.iconContainer} ${classes.whiteBackground}`}></div>
        <div className={classes.title}>Xác thực tài khoản</div>
        <div className={classes.iconContainer} onClick={props.close}>
          <CartCloseIcon />
        </div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.instructionText}>
          OTP đã được gửi đến số điện thoại <br />{' '}
          <span className={classes.phoneText}>{props.phoneNumber}</span>
        </div>

        <hr className={classes.seperator} />

        <TextInput
          placeholder={'Nhập OTP để xác thực SĐT nhận hàng'}
          value={props.otp}
          onChange={props.onChangeOtp}
          type="number"
        />

        <div className={classes.actionContainer}>
          <div className={`${classes.actionText} ${props.isCountDown > 0 && classes.actionTextDisable}`} onClick={props.onResendOtp}>Gửi lại mã OTP {props.isCountDown > 0 && `(${props.isCountDown})`}</div>
          <div className={classes.actionText} onClick={props.onBack}>
            Nhập lại số điện thoại
          </div>
        </div>

        <div className={classes.confirmNoteText}>
          Bằng việc xác thực, bạn xác nhận mình đã đủ 18 tuổi khi mua các sản phẩm có nhãn Rượu-bia.
        </div>

        <RaisedButton
          type="submit"
          className={classes.confirmBtn}
          disabled={props.otp.length !== 4}
          isLoading={props.isLoading}
        >
          <span className={classes.buttonText}>Xác thực</span>
        </RaisedButton>
      </div>
    </form>
  );
});
