import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    alignSelf: 'center',
    marginBottom: 24,
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 17,
  },
  description: {
    textAlign: 'center',
    padding: '10px',
    fontWeight: 700,
    fontSize: 18,
    color: '#000',
  },
  footer: {
    display: 'flex',
    marginTop: 8,
  },
  btn: {
    flex: 1,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D8D8D8',
    color: '#000',
    padding: '10px 0px',
    width: '100%',
  },
  btn2: {
    flex: 1,
    borderRadius: 8,
    color: '#fff',
    padding: '10px 0px',
    width: '100%',
  },
});
