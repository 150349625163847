import { MinusOutlined, PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';
import { images } from '../../core/contants/images';
import { IProduct, IProductVariation } from '../../core/models/product';
import { formatCurrency } from '../../core/ultis/currency';
import { ProductDetailBottomSheet } from '../../screens/product-detail/product-detail.screen';
import { cartService } from '../../services/cart-service';
import { Dispatch, RootState, store } from '../../store';
import { OVERLAY_MODAL } from '../../store/models/app-context';
import { BrandConfirmDialog } from '../brand-confirm/brand-confirm.component';
import { FavoriteIcon } from '../icons/favorite';
import { FavoriteOutlinedIcon } from '../icons/favorite-outlined';
import { Spinner } from '../spinner';
import { useProductItemStyles } from './style';
import { NetCore } from "../../assets/netcore/netcore";

interface Props {
  layout?: 'vertical' | 'horizontal' | 'search';
  hideBrandName?: boolean;
  className?: string;
  onClick?: () => void;
  product: IProduct;
  isNewProduct?: boolean;
  isBestSeller?: boolean;
  isHotDeal?: boolean;
}

export const ProductItem = React.memo((props: Props) => {
  const classes = useProductItemStyles();
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const favoriteProductEntities = useSelector((state: RootState) => state.product.favorite);
  const isFavorite = useMemo(
    () =>
      favoriteProductEntities.allIds.some(
        (id) =>
          id === props.product.parentId || id === props.product.wooId || id === props.product.id,
      ),
    [favoriteProductEntities, props.product],
  );

  const discountPercent = useMemo(
    () =>
      100 - Math.ceil(((props.product.salePrice || 1) / (props.product.regularPrice || 1)) * 100),
    [props.product],
  );
  const [isVisibleBrandConfirm, setIsVisibleBrandConfirm] = useState(false);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname, hash } = useLocation();
  const isGoToBrand = ['/', '/hot-deal', '/search-result'].includes(pathname);
  const appContext = useSelector((state: RootState) => state.appContext);
  const user = useSelector((state: RootState) => state.user);
  const [selectedVariant, setSelectedVariant] = useState<IProductVariation | undefined>(
    (props?.product?.availableVariations || []).find((v) => v.variationId === props.product.id),
  );
  const cart = useSelector((state: RootState) => state.cart);
  const cartItem = useMemo(
    () =>
      cart.cartItems.find((i) =>
        selectedVariant
          ? i.productId === selectedVariant.variationId
          : [props.product?.id, props.product.wooId].includes(i.productId),
      ),
    [cart, selectedVariant, props.product],
  );
  const cartLength = useSelector((state: RootState) => store.select.cart.cartLength(state));
  const location = useLocation();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const [selectedModifiersById, setSelectedModifiersById] = useState<{ [key: string]: string[] }>(
    {},
  );
  const [selectedToppingIds, setSelectedToppingIds] = useState<string[]>([]);

  const onPressFavorite = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!user?.authentication?.token) {
        dispatch.appContext.setOverlayModal(OVERLAY_MODAL.LOGIN);
      } else if (isFavorite) {
        dispatch.product.deleteFavorite({ product: props.product });
      } else {
        dispatch.product.addFavorite({ product: props.product });
      }
    },
    [props.product, isFavorite, user.authentication.token],
  );

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const quickAddToCart =
    ({ override }) =>
    async (e) => {
      e.stopPropagation();
      if (
        !isVisibleDetail &&
        ((props.product?.modifier || []).length > 0 ||
          (props.product?.availableVariations || []).length > 0 ||
          (props.product?.topping?.data || []).length > 0)
      ) {
        toggleDetail(true)(e);
      } else {
        dispatch.appContext.adjustDelayAddProductSeconds(1);
        setIsLoading(true);
        if (appContext.delayAddProductSeconds) {
          await delay(appContext.delayAddProductSeconds * 1000);
        }
        setIsVisibleBrandConfirm(false);
        const response = await cartService.quickAddToCart({
          productId: selectedVariant?.variationId || props.product.wooId || props.product.id,
          override: override,
          modifier: Object.entries(selectedModifiersById).map(([key, value]) => ({
            categoryId: +key,
            data: value.map((id) => ({ id: +id })),
          })),
          latitude: appContext.latitude,
          longitude: appContext.longitude,
          toppingIds: (selectedToppingIds || []).map((id) => +id),
        });
        if (response?.data.messageCode === 200) {
          let dataProduct = {
            productUrl: '',
            productId: Number(selectedVariant?.variationId || props.product.wooId || props.product.id),
            productName: props.product.name,
            brandName: props.product.brand?.name,
            price: props.product.regularPrice,
            category: props.product.group?.name || '',
            currency: props.product?.currency || '',
            productPrice: props.product.regularPrice,
            quantity: 1,
            productImage: props.product.thumbnail,
            productRegularPrice: props.product.regularPrice,
            productSalePrice: props.product.salePrice,
          };
          NetCore.ncAddToCart(dataProduct);
          dispatch.appContext.getSelectedBrand();
          dispatch.cart.getCartDetailSuccess({
            items: response?.data.result?.items || [],
            totals: response?.data.result?.totals || {
              shippingFee: 0,
              total: 0,
              totalPrice: 0,
              totalPriceWithoutShipping: 0,
            },
            selectedVouchers: response?.data?.result?.selectedVouchers || [],
          });

          // if (isVisibleDetail) toggleDetail(false)(e);
          if (isGoToBrand) {
            window.location.href = `/brand/${props.product.brandId}#product-${props.product.wooId}`;
            dispatch.appContext.setAddedFromHome(true);
          } else {
            dispatch.appContext.setFlagAddProductSuccess(true);
          }
        } else if (response?.data?.messageCode === 409) {
          setIsVisibleBrandConfirm(true);
        } else {
          dispatch.appContext.setError(response?.data?.message || '');
        }

        dispatch.appContext.adjustDelayAddProductSeconds(-1);
        setIsLoading(false);
      }
    };

  const addToCart =
    ({ override }) =>
    (e, quantity) => {
      e.stopPropagation();

      if (
        !appContext.selectedBrand ||
        !props.product?.brandId ||
        appContext.selectedBrand?.brandId?.toString() === props.product?.brandId?.toString() ||
        override ||
        cartLength == 0
      ) {
        setIsLoading(true);
        dispatch.cart
          .addToCart({
            productId: selectedVariant?.variationId || props.product.wooId || props.product.id,
            quantity,
            override: override || cartLength == 0,
            modifier: Object.entries(selectedModifiersById).map(([key, value]) => ({
              categoryId: +key,
              data: value.map((id) => ({ id: +id })),
            })),
            toppingIds: (selectedToppingIds || []).map((id) => +id),
          })
          .then((res) => {
            let dataProduct = {
              productUrl: '',
              productId: Number(selectedVariant?.variationId || props.product.wooId || props.product.id),
              productName: props.product.name,
              brandName: props.product.brand?.name,
              category: props.product.group?.name || '',
              price: props.product.regularPrice,
              currency: props.product?.currency || '',
              productPrice: props.product.regularPrice,
              quantity: quantity,
              productImage: props.product.thumbnail,
              productRegularPrice: props.product.regularPrice,
              productSalePrice: props.product.salePrice,
            };
            NetCore.ncAddToCart(dataProduct);
            setIsVisibleBrandConfirm(false);
            if (isVisibleDetail) toggleDetail(false)(e);
            setIsLoading(false);
            dispatch.appContext.setIsDisableWindowScroll(false);
            if (isGoToBrand) {
              window.location.href = `/brand/${props.product.brandId}#product-${props.product.wooId}`;
              dispatch.appContext.setAddedFromHome(true);
            } else {
              dispatch.appContext.setFlagAddProductSuccess(true);
            }
          });
      } else {
        setIsVisibleBrandConfirm(true);
      }
    };

  const quickRemoveFromCart = useCallback(
    async (e) => {
      e.stopPropagation();
      setIsLoading(true);
      dispatch.cart
        .quickRemoveFromCart({
          productId: selectedVariant?.variationId || props.product.wooId || props.product.id,
          modifier: Object.entries(selectedModifiersById).map(([key, value]) => ({
            categoryId: +key,
            data: value.map((id) => ({ id: +id })),
          })),
          toppingIds: (selectedToppingIds || []).map((id) => +id),
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [props.product, selectedVariant],
  );

  const toggleDetail = (value: boolean) => (e) => {
    e?.stopPropagation();
    if (value) {
      history.push('#');
    }
    setIsVisibleDetail(value);
    // dispatch.appContext.setIsDisableWindowScroll(value);
  };

  if (props.layout === 'search') {
    return (
      <div className={classes.searchItemContainer} onClick={toggleDetail(true)}>
        <img
          src={props.product?.thumbnail || props.product?.image || images.foodThumbnail}
          className={classes.searchThumnail}
          alt=""
        />
        <div>
          <div className={classes.title}>{props.product?.name}</div>
          <div>
            {props.product?.salePrice ? (
              <div className={classes.discountPriceText} style={{ marginTop: 0, marginBottom: 4 }}>
                {formatCurrency(props.product?.regularPrice)}
              </div>
            ) : (
              <></>
            )}
            <div className={classes.priceContainer}>
              <div className={classes.price}>
                {formatCurrency(
                  props.product?.salePrice ? props.product.salePrice : props.product?.regularPrice,
                )}
              </div>
            </div>
          </div>
        </div>

        {isVisibleBrandConfirm && (
          <BrandConfirmDialog
            isVisible={isVisibleBrandConfirm}
            onClose={(e) => {
              e.stopPropagation();
              setIsVisibleBrandConfirm(false);
            }}
            overrideBrand={props.product.brand}
            onOverride={quickAddToCart({ override: true })}
          />
        )}
        <ProductDetailBottomSheet
          isVisible={isVisibleDetail}
          onClose={toggleDetail(false)}
          product={props.product}
          addToCart={addToCart({ override: false })}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
          quickAddToCart={quickAddToCart({ override: false })}
          quickRemoveFromCart={quickRemoveFromCart}
          isLoading={isLoading}
          setSelectedModifiersById={setSelectedModifiersById}
          selectedModifiersById={selectedModifiersById}
          selectedToppingIds={selectedToppingIds}
          setSelectedToppingIds={setSelectedToppingIds}
        />
      </div>
    );
  }

  if (props.layout === 'horizontal') {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className={clsx(classes.itemContainer, classes.itemRowContainer, props.className)}
          onClick={toggleDetail(true)}
        >
          <div className={classes.thumbnailContainer}>
            <img
              src={props.product?.thumbnail || props.product?.image || images.foodThumbnail}
              className={`${classes.thumbnail} ${classes.thumbnailHorizontal}`}
              alt=""
            />
            <div onClick={onPressFavorite}>
              {isFavorite ? (
                <FavoriteIcon className={classes.favoriteIcon} color={colors.brandYellow} />
              ) : (
                <FavoriteOutlinedIcon className={classes.favoriteIcon} />
              )}
            </div>
          </div>

          <div className={classes.contentContainer}>
            <div>
              <div className={classes.title}>{props.product?.name}</div>
              {!props.hideBrandName ? (
                <div className={classes.brandContainer}>
                  <img
                    src={props.product?.brand?.minimizeLogo}
                    className={classes.brandLogo}
                    alt=""
                  />
                  <div className={classes.brandText}>{props.product?.brand.name}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {props.product?.salePrice ? (
                <div
                  className={classes.discountPriceText}
                  style={{ marginTop: 0, marginBottom: 4 }}
                >
                  {formatCurrency(props.product?.regularPrice)}
                </div>
              ) : (
                <></>
              )}
              <div className={classes.priceContainer}>
                <div className={classes.price}>
                  {formatCurrency(
                    props.product?.salePrice
                      ? props.product.salePrice
                      : props.product?.regularPrice,
                  )}
                </div>
              </div>
            </div>
            {cartItem?.quantity ? (
              <div className={classes.quantityContainer}>
                <div className={classes.quantityIconContainer} onClick={quickRemoveFromCart}>
                  <MinusOutlined className={classes.quantityIcon} />
                </div>
                <div className={classes.quantity}>{cartItem.quantity}</div>
                <div
                  className={classes.quantityIconContainer}
                  onClick={quickAddToCart({ override: false })}
                >
                  <PlusOutlined className={classes.quantityIcon} />
                </div>
              </div>
            ) : (
              <div onClick={quickAddToCart({ override: false })}>
                <PlusCircleFilled className={classes.addIcon} />
              </div>
            )}
          </div>
          {isVisibleBrandConfirm && (
            <BrandConfirmDialog
              isVisible={isVisibleBrandConfirm}
              onClose={(e) => {
                e.stopPropagation();
                setIsVisibleBrandConfirm(false);
                dispatch.appContext.setIsShowBrandConfirm(false);
              }}
              overrideBrand={props.product.brand}
              onOverride={quickAddToCart({ override: true })}
            />
          )}
          <ProductDetailBottomSheet
            isVisible={isVisibleDetail}
            onClose={toggleDetail(false)}
            product={props.product}
            addToCart={addToCart({ override: false })}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            quickAddToCart={quickAddToCart({ override: false })}
            quickRemoveFromCart={quickRemoveFromCart}
            isLoading={isLoading}
            setSelectedModifiersById={setSelectedModifiersById}
            selectedModifiersById={selectedModifiersById}
            selectedToppingIds={selectedToppingIds}
            setSelectedToppingIds={setSelectedToppingIds}
          />
        </div>
        {isLoading && (
          <div className={classes.loadingOverlay}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <span id={`product-${props.product?.wooId}`} className={classes.productId}>
        {' '}
      </span>
      <div className={clsx(classes.itemContainer, props.className)} onClick={toggleDetail(true)}>
        <div className={classes.thumbnailContainer}>
          <img
            src={props.product?.thumbnail || props.product?.image || images.foodThumbnail}
            className={classes.thumbnail}
            alt=""
          />
          <div onClick={onPressFavorite}>
            {isFavorite ? (
              <FavoriteIcon className={classes.favoriteIcon} color={colors.brandYellow} />
            ) : (
              <FavoriteOutlinedIcon className={classes.favoriteIcon} />
            )}
          </div>
          <div
            className={clsx(
              classes.label,
              props.isBestSeller
                ? classes.bestSellerLabel
                : props.isNewProduct
                ? classes.newProductLabel
                : props.product.salePrice || props.isHotDeal
                ? classes.hotDealLabel
                : '',
            )}
          >
            {props.isBestSeller
              ? 'BEST SELLER'
              : props.isNewProduct
              ? 'MÓN MỚI'
              : props.product.salePrice
              ? `-${discountPercent}%`
              : props.isHotDeal
              ? 'HOT'
              : ''}
          </div>
        </div>
        <div className={classes.contentContainer}>
          {!props.hideBrandName ? (
            <div className={classes.brandContainer}>
              <img src={props.product?.brand?.minimizeLogo} className={classes.brandLogo} alt="" />
              <div className={classes.brandText}>{props.product?.brand?.name}</div>
            </div>
          ) : (
            <div className={classes.brandPlaceholder} />
          )}

          <div className={`${classes.title} ${classes.verticalTitleContainer}`}>
            {props.product?.name}
          </div>
          <div className="mt-2">
            <div className={classes.discountPriceText}>
              {props.product.salePrice ? formatCurrency(props.product.regularPrice) : ''}
            </div>
            <div className={classes.priceContainer}>
              <div className={classes.price}>
                {formatCurrency(
                  props.product?.salePrice ? props.product.salePrice : props.product?.regularPrice,
                )}
              </div>
              {cartItem?.quantity ? (
                <div className={classes.quantityContainer}>
                  <div className={classes.quantityIconContainer} onClick={quickRemoveFromCart}>
                    <MinusOutlined className={classes.quantityIcon} />
                  </div>
                  <div className={classes.quantity}>{cartItem.quantity}</div>
                  <div
                    className={classes.quantityIconContainer}
                    onClick={quickAddToCart({ override: false })}
                  >
                    <PlusOutlined className={classes.quantityIcon} />
                  </div>
                </div>
              ) : (
                <div onClick={quickAddToCart({ override: false })}>
                  <PlusCircleFilled className={classes.addIcon} />
                </div>
              )}
            </div>
          </div>
        </div>

        {isVisibleBrandConfirm && (
          <BrandConfirmDialog
            isVisible={isVisibleBrandConfirm}
            onClose={(e) => {
              e.stopPropagation();
              setIsVisibleBrandConfirm(false);
              dispatch.appContext.setIsShowBrandConfirm(false);
            }}
            overrideBrand={props.product.brand}
            onOverride={quickAddToCart({ override: true })}
          />
        )}
        <ProductDetailBottomSheet
          isVisible={isVisibleDetail}
          onClose={toggleDetail(false)}
          product={props.product}
          addToCart={addToCart({ override: false })}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
          quickAddToCart={quickAddToCart({ override: false })}
          quickRemoveFromCart={quickRemoveFromCart}
          isLoading={isLoading}
          setSelectedModifiersById={setSelectedModifiersById}
          selectedModifiersById={selectedModifiersById}
          selectedToppingIds={selectedToppingIds}
          setSelectedToppingIds={setSelectedToppingIds}
        />
      </div>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <Spinner />
        </div>
      )}
    </div>
  );
});
