import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RaisedButton } from '../raised-button';
import { IBrand } from '../../core/models/brand';
import { RootState } from '../../store';
import { useConfirmModalStyles } from './confirm-modal.style';

interface Props {
  isVisible: boolean;
  onClose: (e) => void;
  onConfirm: (e) => void;
  message: string;
}

export const ConfirmModal = React.memo((props: Props) => {
  const classes = useConfirmModalStyles();
  const appContext = useSelector((state: RootState) => state.appContext);

  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.onClose}
      closeIcon={null}
      centered
      closable={false}
    >
      <div>
        <div className={classes.title}>Thông báo</div>
        <div className={classes.description}>{props.message}</div>
        <div className={classes.footer}>
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn} onClick={props.onClose}>
              Không
            </RaisedButton>
          </div>
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn2} onClick={props.onConfirm}>
              Có
            </RaisedButton>
          </div>
        </div>
      </div>
    </Modal>
  );
});
