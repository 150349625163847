import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  headerContainer: {
    paddingBottom: 16,
    borderBottom: '1px solid #EDF2F9',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      marginBottom: 14,
    },
  },
  headerTextContainer: {
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      textAlign: 'center',
    },
  },
  headerTitleText: {
    fontWeight: 700,
    fontSize: 16,
    color: '#000',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 18,
    },
  },
  headerDescriptionText: {
    color: '#6B6B6B',
    fontSize: 14,
    fontWeight: 400,
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 8,
    },
  },
  modalCloseIcon: {
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: 17,
    right: 12,
  },
  titleImage: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
    marginTop: 16,
    borderRadius: 5,
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 0,
    },
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      height: 150,
      marginTop: 0,
    },
  },
  brandContainer: {
    display: 'flex',
    marginTop: 9,
  },
  logoImage: {
    height: 16,
    width: 16,
  },
  brandName: {
    color: '#000',
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 4,
  },
  foodName: {
    fontSize: 13,
    fontWeight: 500,
    color: '#000',
    marginTop: 8,
    height: 32,
    lineHeight: '16px',
  },
  quantityContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexBasis: '50%',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      flexBasis: '47%',
    },
  },
  quantityText: {
    // margin: '0px 6px',
    color: colors.textSecondaryColor,
    fontSize: 16,
    fontWeight: 700,
  },
  adjustQuantityIcon: {
    height: 24,
    width: 24,
  },
  priceContainer: {
    flexBasis: '50%',
  },
  priceAndQuantityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {},
  },
  colorPrice: {
    fontSize: 14,
    fontWeight: 700,
    color: colors.primaryColor,
    textAlign: 'left',
  },
  lineThroughPrice: {
    fontSize: 10,
    color: colors.textSecondaryColor,
    textDecorationLine: 'line-through',
    textAlign: 'left',
  },
  itemContainer: {
    marginTop: 20,
  },
  itemInfoContainer: {
    marginTop: 16,

    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 0,
    },
  },
  foodItemContainer: {
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {},
  },
  buttonContainer: {
    marginTop: 32,
  },
  cancelButton: {
    width: '100%',
    padding: '16px 0',
    border: '1px solid #D8D8D8',
    color: '#000',
    borderRadius: 8,
    textAlign: 'center',
    marginRight: 6,
    fontWeight: 700,
  },
  applyButton: {
    width: '100%',
    padding: '8px 8px',
    color: '#fff',
    background: colors.primaryColor,
    borderRadius: 8,
    textAlign: 'center',
    // marginLeft: 6,
    fontWeight: 500,
  },
  submitButton: {
    background: colors.primaryColor,
    width: '100%',
    padding: '16px 0',
    border: '1px solid #D8D8D8',
    color: '#fff',
    borderRadius: 8,
    textAlign: 'center',
    // marginRight: 6,
    fontWeight: 700,

    // [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
    //   marginTop: 16,
    // },
  },
  foodItem: {
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      // display: 'flex',
      marginTop: 20,
    },
  },
  foodItemMobile: {
    marginTop: 10,
    width: '100%',
  },
  foodItemMobileNameContainer: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  foodItemMobileSubmitContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  itemCheckBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBoxTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: '#000',
  },
  pickBtn: {
    color: '#000',
    fontWeight: 700,
    padding: '10px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    borderRadius: 4,
    marginTop: 12,
    cursor: 'pointer',
    gap: 2,
  },
  activePickBtn: {
    background: colors.primaryColor,
    color: '#fff',
    borderColor: colors.primaryColor,
  },
});
