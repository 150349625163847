


import React from 'react';

interface Props {
  className?: string;
}

export const VnpayQrcode = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
<svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.3728 1.34375H4.00952V0.464844H0.4646V3.98047H1.3728V1.34375Z" fill="#E96E34"/>
<path d="M11.9197 0.464844V1.34375H14.5564V3.98047H15.4646V0.464844H11.9197Z" fill="#E96E34"/>
<path d="M14.5564 14.5273H11.9197V15.4648H15.4646V11.8906H14.5564V14.5273Z" fill="#E96E34"/>
<path d="M1.3728 11.8906H0.4646V15.4648H4.00952V14.5273H1.3728V11.8906Z" fill="#E96E34"/>
<path d="M2.25171 2.22266V6.61719H6.64624V3.98047H7.52515V3.10156H6.64624V2.22266H2.25171ZM4.88843 4.85938H4.00952V3.98047H4.88843V4.85938Z" fill="#E96E34"/>
<path d="M13.6775 13.6484V9.25391H11.9197V8.375H11.0408V9.25391H8.40405V10.1328H9.28296V11.0117H8.40405V11.8906H9.28296V13.6484H13.6775ZM11.0408 11.0117H11.9197V11.8906H11.0408V11.0117Z" fill="#E96E34"/>
<path d="M4.88843 8.375H6.64624V7.49609C6.32573 7.49609 2.44835 7.49609 2.25171 7.49609V8.375H4.00952V9.25391H3.13062V10.1328H4.00952V11.8906H3.13062V12.7695H4.00952V13.6484H8.40405V12.7695H7.52515V11.8906H6.64624V12.7695H4.88843V11.8906H5.76733V11.0117H4.88843V8.375Z" fill="#E96E34"/>
<path d="M11.9197 7.49609V6.61719H13.6775V2.22266H9.28296V6.61719H11.0408V7.49609H11.9197ZM11.0408 3.98047H11.9197V4.85938H11.0408V3.98047Z" fill="#E96E34"/>
<path d="M13.6775 8.375V7.49609C13.5849 7.49609 12.0035 7.49609 11.9197 7.49609V8.375H13.6775Z" fill="#E96E34"/>
<path d="M7.52515 10.1328H8.40405V11.0117H7.52515V10.1328Z" fill="#E96E34"/>
<path d="M5.76733 10.1328V11.0117H6.64624V9.25391H5.76733V10.1328Z" fill="#E96E34"/>
<path d="M6.64624 8.375H7.52515V9.25391H6.64624V8.375Z" fill="#E96E34"/>
<path d="M2.25171 10.1328V11.8906H3.13062C3.13062 11.798 3.13062 10.2166 3.13062 10.1328H2.25171Z" fill="#E96E34"/>
<path d="M2.25171 12.7695H3.13062V13.6484H2.25171V12.7695Z" fill="#E96E34"/>
<path d="M7.52515 2.22266H8.40405V3.10156H7.52515V2.22266Z" fill="#E96E34"/>
<path d="M8.40405 5.73828C8.40405 5.6457 8.40405 4.06426 8.40405 3.98047H7.52515V5.73828H8.40405Z" fill="#E96E34"/>
<path d="M8.40405 7.49609V6.61719H7.52515V8.375H11.0408V7.49609H8.40405Z" fill="#E96E34"/>
</svg>
  );
});
