import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CartButton } from "../../../../components/cart-button";
import { NoCartDataIcon } from "../../../../components/icons/no-cart-data";
import { images } from "../../../../core/contants/images";
import { useStyles } from "./no-data.style";
interface Props {
}
export const NoCartData = React.memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
        <NoCartDataIcon className={classes.icon} />
        <div className={classes.text}>Chưa có sản phẩm nào trong giỏ hàng của bạn.</div>
        <CartButton text={"Tiếp tục mua sắm"} 
        containerStyle={classes.continueButton} 
        onClick={() => {history.push(`/`);}}
        />
    </div>
  );
});
