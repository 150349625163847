import { makeStyles } from '@material-ui/core';
import { Popover } from 'antd';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowContainer } from 'react-tiny-popover';
import { colors } from '../../core/contants/colors';
import { I18N_NAMESPACES } from '../../core/i18n';
import { Dispatch } from '../../store';

const useStyles = makeStyles({
  item: {
    width: 270,
    padding: '12px 16px',
    fontWeight: 700,
    color: '#000',
    cursor: 'pointer',
    borderRadius: 6,

    '&:hover': {
      color: colors.primaryColor,
      backgroundColor: '#FCF0ED',
    },
  },

  logoutItem: {
    color: colors.brandRed,
  },
});

export const ProfileMenuPopOver = React.memo((props: React.PropsWithChildren<{}>) => {
  const { t } = useTranslation([I18N_NAMESPACES.PROFILE]);
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const items = useMemo(
    () => [
      {
        title: t('personalInfo'),
        route: '/profile/info',
      },
      {
        title: t('addressSetting'),
        route: '/profile/address',
      },
      {
        title: t('orderManagemet'),
        route: '/profile/order',
      },
      {
        title: t('myFavorite'),
        route: '/profile/favorite',
      },
      {
        title: t('myCoupon'),
        route: '/profile/coupon',
      },
    ],
    [t],
  );
  const dispatch = useDispatch<Dispatch>();

  const onLogout = async () => {
    dispatch.user.logout();
  };

  const handleVisibleChange = (visible) => {
    setIsVisible(visible);
  };

  const onPressItem = (item) => () => {
    history.push(item.route);
    handleVisibleChange(false);
  };

  return (
    <Popover
      content={() => (
        <div>
          {items.map((i) => (
            <div className={classes.item} key={i.route} onClick={onPressItem(i)}>
              {i.title}
            </div>
          ))}
          <div className={clsx(classes.item, classes.logoutItem)} onClick={onLogout}>
            Đăng xuất
          </div>
        </div>
      )}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      placement="bottomRight"
    >
      {props.children as any}
    </Popover>
  );
});
