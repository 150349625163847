import React from 'react';

interface Props {
  className?: string;
}

export const CartSubtractIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D8D8D8" />
      <path
        d="M17 12H7"
        stroke="#E96E34"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
