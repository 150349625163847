
import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const VerifyPaymentWarningIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.03125 0C3.63719 0 0 3.57469 0 7.96875C0 12.3628 3.63719 16 8.03125 16C12.4253 16 16 12.3628 16 7.96875C16 3.57469 12.4253 0 8.03125 0ZM8.96875 12.25C8.96875 12.7669 8.54781 13.1875 8.03125 13.1875C7.51437 13.1875 7.09375 12.7669 7.09375 12.25V7.5C7.09375 6.98312 7.51437 6.5625 8.03125 6.5625C8.54781 6.5625 8.96875 6.98312 8.96875 7.5V12.25ZM8.03125 4.6875C7.51437 4.6875 7.09375 4.26688 7.09375 3.75C7.09375 3.23312 7.51437 2.8125 8.03125 2.8125C8.54781 2.8125 8.96875 3.23312 8.96875 3.75C8.96875 4.26688 8.54781 4.6875 8.03125 4.6875Z" fill="#E96E34"/>
    </svg>
  );
});
