import { Col, Row } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container } from '../../../components/container';
import { useStyles } from './request-payment-desktop.style';
import { TextInput } from '../../../components/text-input';
import { CalendarIcon } from '../../../components/icons/calendar';
import { TimeIcon } from '../../../components/icons/time';
import { paymentMethods, paymentMethodValues } from '../../../core/contants/paymentMethodValues';
import { AtmIcon } from '../../../components/icons/atm-icon';
import { VisaIcon } from '../../../components/icons/visa-icon';
import { MomoIcon } from '../../../components/icons/momo-icon';
import { VnPayIcon } from '../../../components/icons/vnpay-icon';
import { CashIcon } from '../../../components/icons/cash-icon';
import { ZaloPayIcon } from '../../../components/icons/zalo-pay-icon';
import { ShopeePayIcon } from '../../../components/icons/shopee-pay';
import { AppRadioBox } from '../../../components/radio';
import { CartTextInput } from '../../update-address/components/cart-text-input/cart-text-input.component';
import { CartStep2 } from '../../../components/icons/cart-step-2';
import { AppSelect } from '../../../components/select';
import { formatCurrency } from '../../../core/ultis/currency';
import { CartButton } from '../../../components/cart-button';
import moment from 'moment';
import _ from 'lodash';
import { Formik } from 'formik';
import { CustomDatePickerModal } from '../../verify-order/components/custom-datepicker-modal/custom-datepicker-modal.component';
import { CustomTimePickerModal } from '../../verify-order/components/custom-timepicker-modal/custom-timepicker-modal.component';
import { cartService } from '../../../services/cart-service';
import { orderService } from '../../../services/order-service';
import {IGBizAccount} from "../../../core/models/user";

interface Props {
  onSelectPaymentMethodClick: (value: string) => void;
  onRequestPayment: (data: any) => void;
  data: any;
  currentMethod: string;
  setIsLoading: (value: boolean) => void;
  setData: (value: any) => void;
  customDatePickerVisible: boolean;
  onCloseCustomDatePicker: () => void;
  onSubmitCustomDatePicker: (value: any) => void;
  customTimePickerVisible: boolean;
  onCloseCustomTimePicker: () => void;
  onSubmitCustomTimePicker: (value: any) => void;
  blockTimes: string[];
  onDatePickerInputClick: () => void;
  onTimePickerInputClick: () => void;
  onPickUpRestaurant: (value: boolean) => void;
  values: any;
  errors: any;
  setFieldValue: any;
  handleSubmit: any;
  validateField: any;
  gBizAccount: any;
  setDisableGBizOption: (value: boolean) => void;
  disableGBizOption: boolean;
}

const RequestPayment = (props: Props): JSX.Element => {
  const {
    onSelectPaymentMethodClick,
    data,
    currentMethod,
    onRequestPayment,
    setIsLoading,
    setData,
    customDatePickerVisible,
    onCloseCustomDatePicker,
    onSubmitCustomDatePicker,
    customTimePickerVisible,
    onCloseCustomTimePicker,
    onSubmitCustomTimePicker,
    blockTimes,
    onDatePickerInputClick,
    onTimePickerInputClick,
    onPickUpRestaurant,
    values,
    errors,
    setFieldValue,
    handleSubmit,
    validateField,
    gBizAccount,
    setDisableGBizOption,
    disableGBizOption
  } = props;
  const classes = useStyles();
  const [isDisableBill, setIsDisableBill] = useState(false);

  useEffect(() => {
    if (currentMethod === paymentMethodValues.G_BUSINESS) {
      setIsDisableBill(true);
    } else {
      setIsDisableBill(false);
    }
  }, [currentMethod]);

  const renderPaymentMethods = () => {
    return paymentMethods.map((value: any, index: number) => {
      if (value?.value == paymentMethodValues.G_BUSINESS) {
        if (gBizAccount) {
          const balanceAvail = gBizAccount.balance;
          if (balanceAvail < Math.round(data?.totalOrder ?? 0) || data?.campaignDetail?.length > 0 || data?.voucherDetail?.length > 0) {
            setDisableGBizOption(true);
          }

          return (
              <Col
                  span={24}
                  key={index}
                  className={`${classes.paymentMethodItem} ${disableGBizOption && classes.disableGBiz}`}
                  onClick={() => {
                    if (!disableGBizOption) {
                      setFieldValue('paymentMethod', value?.value);
                      onSelectPaymentMethodClick(value?.value);
                    }
                  }}
              >
                <AppRadioBox isChecked={currentMethod === value?.value ? true : false} disabled={disableGBizOption || false}>
                  <div className={classes.checkBoxValue}>
                    {value.icon()}
                    <div className={classes.paymentMethodName}>
                      <div>{value.name}</div>
                      <div className={classes.gBizBalanceText}>Hạn mức còn lại {formatCurrency(balanceAvail)}</div>
                    </div>
                  </div>
                </AppRadioBox>
              </Col>
          );
        }
      } else {
        return (
            <Col
                span={12}
                key={index}
                className={classes.paymentMethodItem}
                onClick={() => {
                  setFieldValue('paymentMethod', value?.value);
                  onSelectPaymentMethodClick(value?.value);
                }}
            >
              <AppRadioBox isChecked={currentMethod === value?.value ? true : false}>
                <div className={classes.checkBoxValue}>
                  {value.icon()}
                  <div className={classes.paymentMethodName}>{value.name}</div>
                </div>
              </AppRadioBox>
            </Col>
        );
      }
    });
  };
  return (
    <div>
      <div className={classes.desktopLayout}>
        <CustomDatePickerModal
          visible={customDatePickerVisible}
          onCancelModal={onCloseCustomDatePicker}
          value={values?.deliveryDate}
          onSubmitModal={(value: any) => {
            setFieldValue('deliveryDate', value);
            onSubmitCustomDatePicker(value);
          }}
        />
        <CustomTimePickerModal
          visible={customTimePickerVisible}
          onCancelModal={onCloseCustomTimePicker}
          value={values?.deliveryTime}
          onSubmitModal={(value: any) => {
            setFieldValue('deliveryTime', value);
            onSubmitCustomTimePicker(value);
          }}
          blockTimes={blockTimes}
        />
        <Container className={classes.desktopContainer} backgroundColor="#EDEDED">
          <Row>
            <Col md={16}>
              <CartStep2 className={classes.step} />
              <Row className={classes.infoContainer}>
                <Col md={12}>
                  <div className={classes.title}>Thời gian nhận hàng</div>
                  <Row>
                    <Col md={12}>
                      <TextInput
                        containerClassname={classes.dateInput}
                        textInputClassname={classes.textInput}
                        onClick={onDatePickerInputClick}
                        value={
                          values?.deliveryDate
                            ? moment(values?.deliveryDate).format('DD/MM/YYYY')
                            : moment().format('DD/MM/YYYY')
                        }
                        surfix={<CalendarIcon />}
                        placeholder={'Chọn ngày giao'}
                      />
                      {errors?.deliveryDate ? (
                        <div style={{ color: 'red', marginTop: 5 }}>{errors?.deliveryDate}</div>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col md={12}>
                      <TextInput
                        containerClassname={classes.timeInput}
                        textInputClassname={classes.textInput}
                        onClick={onTimePickerInputClick}
                        value={values?.deliveryTime}
                        surfix={<TimeIcon />}
                        placeholder={'Chọn giờ giao'}
                      />
                      {errors?.deliveryTime ? (
                        <div style={{ color: 'red', marginTop: 5 }}>{errors?.deliveryTime}</div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <div className={classes.title}>Ghi chú đơn hàng</div>
                  <TextInput
                    containerClassname={classes.noteInput}
                    textInputClassname={classes.textInput}
                    onChange={(v: any) => {
                      setFieldValue('note', v);
                    }}
                    value={values?.note ?? ''}
                    // disabled
                    placeholder={'Nhập ghi chú của bạn ở đây ...'}
                  />
                </Col>
                <Col md={24} className={classes.addressContainer}>
                  <div className={classes.title}>Địa chỉ nhận hàng</div>
                  {!values?.onPickUpRestaurant && (
                      <Row
                          align="middle"
                          style={{ marginTop: 16 }}
                          onClick={() => onPickUpRestaurant(false)}
                      >
                        {/* <AppCheckBox isChecked={!cartData?.pickupAtRestaurant}></AppCheckBox> */}
                        <AppRadioBox isChecked={!values?.onPickUpRestaurant}></AppRadioBox>
                        <div className={`${classes.title}`}>Giao hàng tới</div>
                        {/* <div
                      className={classes.editAddressText}
                      // onClick={() => setChangeAddressVisible(true)}
                    >
                      Thay đổi
                    </div> */}
                      </Row>
                  )}
                </Col>
                {!values?.onPickUpRestaurant && (
                    <Col md={24} style={{ paddingRight: 16 }}>
                      <div className={classes.resAddress}>
                        {data?.delivery?.recipientName ?? ''} {data?.delivery?.recipientCellphone ?? ''}{' '}
                        -{' '}
                        {[data?.delivery?.address, data?.delivery?.address2]
                            .filter((i) => !!i)
                            .join(', ')}
                      </div>
                    </Col>
                )}
                <Col md={12}></Col>
                <Col md={24} className={classes.pickUpResContainer}>
                  <Row align="middle" onClick={() => onPickUpRestaurant(true)}>
                    <AppRadioBox isChecked={values?.onPickUpRestaurant}></AppRadioBox>
                    <div className={classes.title}>Nhận tại nhà hàng</div>
                    <div className={classes.noFee}>Không mất phí</div>
                  </Row>
                  <div className={classes.resAddress}>
                    {!_.isEmpty(data?.restaurant)
                      ? `${data?.restaurant?.restaurant?.name} - ${data?.restaurant?.restaurant?.address}`
                      : ''}
                  </div>
                </Col>
                <div className={classes.billBoxContainer}>
                  <div className={classes.title}>Bạn có cần dụng cụ ăn uống nhựa không?</div>
                  <Row>
                    <Col
                      span={6}
                      className={classes.paymentMethodItem}
                      // onClick={() => setIsPlastic(true)}
                    >
                      <AppRadioBox isChecked={data?.allowCutleryTool ?? false}>
                        <div className={classes.billBoxValue}>
                          <div className={classes.paymentMethodName}>Có</div>
                        </div>
                      </AppRadioBox>
                    </Col>
                    <Col
                      span={12}
                      className={classes.paymentMethodItem}
                      // onClick={() => setIsPlastic(false)}
                    >
                      <AppRadioBox isChecked={!data?.allowCutleryTool ?? false}>
                        <div className={classes.billBoxValue}>
                          <div className={classes.paymentMethodName}>Không</div>
                        </div>
                      </AppRadioBox>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row style={{ maxWidth: '100%', marginTop: 32 }}>
                    <Col span={24} className={classes.title}>
                      Phương thức thanh toán
                    </Col>
                    {renderPaymentMethods()}
                  </Row>
                </div>
                <div className={classes.billBoxContainer}>
                  <div className={classes.title}>Nhận hoá đơn điện tử</div>
                  <Row>
                    <Col
                      span={6}
                      className={classes.paymentMethodItem}
                      onClick={() => setFieldValue('haveBill', true)}
                    >
                      <AppRadioBox isChecked={values.haveBill}>
                        <div className={classes.billBoxValue}>
                          <div className={classes.paymentMethodName}>Có</div>
                        </div>
                      </AppRadioBox>
                    </Col>
                    <Col
                      span={12}
                      className={classes.paymentMethodItem}
                      onClick={() => {
                        if (!isDisableBill) {
                          setFieldValue('haveBill', false)
                        }
                      }}
                    >
                      <AppRadioBox isChecked={!values.haveBill} disabled={isDisableBill}>
                        <div className={classes.billBoxValue}>
                          <div className={classes.paymentMethodName}>Không</div>
                        </div>
                      </AppRadioBox>
                    </Col>
                  </Row>
                </div>
                {values?.haveBill ? (
                  <Fragment>
                    <Row className={classes.billContainer}>
                      <Col md={12} style={{ paddingRight: 16 }}>
                        <div className={classes.inputTitle}>
                          Mã số thuế <span className={classes.colorRed}>*</span>
                        </div>
                        <CartTextInput
                          // placeholder={'1234567890'}
                          onChange={(value: any) => {
                            setFieldValue('taxCode', value);
                            validateField('taxCode');
                          }}
                          value={values?.taxCode ?? ''}
                          error={errors.taxCode}
                          disabled={isDisableBill}
                        />
                      </Col>
                      <Col md={12} style={{ paddingLeft: 16 }}>
                        <div className={classes.inputTitle}>
                          Tên tổ chức cá nhân nộp thuế <span className={classes.colorRed}>*</span>
                        </div>
                        <CartTextInput
                          // placeholder={'Công ty TNHH ABC'}
                          onChange={(value: any) => {
                            setFieldValue('companyName', value);
                            validateField('companyName');
                          }}
                          value={values?.companyName ?? ''}
                          error={errors.companyName}
                          disabled={isDisableBill}
                        />
                      </Col>
                      <Col md={12} style={{ paddingRight: 16 }}>
                        <div className={classes.inputTitle}>
                          Địa chỉ <span className={classes.colorRed}>*</span>
                        </div>
                        <CartTextInput
                          // placeholder={'315 Trường Chinh'}
                          onChange={(value: any) => {
                            setFieldValue('invoiceAddress', value);
                            validateField('invoiceAddress');
                          }}
                          value={values?.invoiceAddress ?? ''}
                          error={errors.invoiceAddress}
                          disabled={isDisableBill}
                        />
                      </Col>
                      <Col md={12} style={{ paddingLeft: 16 }}>
                        <div className={classes.inputTitle}>
                          Email nhận hóa đơn điện tử <span className={classes.colorRed}>*</span>
                        </div>
                        <CartTextInput
                          // placeholder={'abc@gmail.com'}
                          onChange={(value: any) => {
                            setFieldValue('invoiceEmail', value);
                            validateField('invoiceEmail');
                          }}
                          value={values?.invoiceEmail ?? ''}
                          error={errors.invoiceEmail}
                          disabled={isDisableBill}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
              </Row>
            </Col>
            <Col md={1}></Col>
            <Col md={7} className={classes.voucherContainer}>
              <div className={`${classes.voucherItem} ${classes.noMargin}`}>
                <div className={classes.voucherTitle}>Mã giảm giá, voucher</div>
                {/* <div
                className={classes.chooseVoucher}
                onClick={() => setCouponModalVisible(true)}
              >
                Chọn mã
              </div> */}
              </div>
              <div className={classes.voucherListContainer}>
                {data?.voucherDetail?.length ? (
                  data?.voucherDetail.map((value: any, index: number) => {
                    return (
                      <div className={classes.voucherAppliedItem} key={index}>
                        <span>{value?.name}</span>
                        {/* <div
                        onClick={() => {
                          onCancelCoupon(value);
                        }}
                        className={classes.voucherCloseIcon}
                      >
                        <VoucherCloseIcon />
                      </div> */}
                      </div>
                    );
                  })
                ) : (
                  <></>
                  // <div className={classes.noDiscountCodeText}>Bạn chưa thêm mã giảm giá nào</div>
                )}
              </div>
              <hr style={{ borderTop: '1px dashed #E4E4E4' }} />

              <div className={`${classes.voucherItem}`}>
                <div className={classes.priceMainItemTitle}>Tạm tính</div>
                <div className={classes.priceMainItemValue}>
                  {formatCurrency(Math.round(data?.totalPriceWithoutShipping ?? 0))}
                </div>
              </div>
              {data?.totalDiscount ? (
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>Giảm giá</div>
                  <div className={classes.priceMainItemValue}>
                    - {formatCurrency(Math.round(data?.totalDiscount ?? 0))}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {data?.voucherDetail?.length ? (
                <Fragment>
                  {data?.voucherDetail?.map((voucher) => (
                    <div className={classes.secondaryRow} key={`campaign-${voucher.code}`}>
                      <div className={classes.secondaryTitleText}>
                        <div className={classes.circle}></div>
                        <span>{voucher.name}</span>
                      </div>
                      <div className={classes.secondaryValueText}>
                        -{formatCurrency(voucher.discount)}
                      </div>
                    </div>
                  ))}
                  <hr style={{ borderTop: '1px dashed #E4E4E4', marginTop: 18 }} />
                </Fragment>
              ) : (
                <></>
              )}
              {/* {discounts.map((discount) => (
              <div className={classes.secondaryRow} key={`campaign-${discount.code}`}>
                <div className={classes.secondaryTitleText}>
                  <div className={classes.circle}></div>
                  <span>{discount.code}</span>
                </div>
                <div className={classes.secondaryValueText}>
                  -{formatCurrency(discount.denominationValue)}
                </div>
              </div>
            ))} */}
              <div className={`${classes.voucherItem}`}>
                <div className={classes.priceMainItemTitle}>Phí vận chuyển</div>
                <div className={classes.priceMainItemValue}>
                  {formatCurrency(Math.round(data?.shipping?.price ?? 0))}
                </div>
              </div>
              <div className={`${classes.voucherItem}`}>
                <div className={classes.priceMainItemTitle}>Tổng tiền trước VAT</div>
                <div className={classes.priceMainItemValue}>
                  {formatCurrency(Math.round(data?.totalBeforeVAT ?? 0))}
                </div>
              </div>
              <div className={`${classes.voucherItem}`}>
                <div className={classes.priceMainItemTitle}>VAT</div>
                <div className={classes.priceMainItemValue}>
                  {formatCurrency(Math.round(data?.totalVAT ?? 0))}
                </div>
              </div>
              <div className={`${classes.voucherItem}`}>
                <div className={classes.priceMainItemTitle}>Tổng tiền sau VAT</div>
                <div className={classes.priceMainItemValue}>
                  {formatCurrency(Math.round(data?.totalAfterVAT ?? 0))}
                </div>
              </div>
              <hr style={{ borderTop: '1px dashed #E4E4E4', marginTop: 18 }} />
              {data?.totalDiscountVoucher ? (
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>Voucher</div>
                  <div className={classes.priceMainItemValue}>
                    - {formatCurrency(Math.round(data?.totalDiscountVoucher ?? 0))}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {data?.campaignDetail?.length ? (
                <Fragment>
                  {data?.campaignDetail?.map((voucher) => (
                    <div className={classes.secondaryRow} key={`voucher-${voucher.code}`}>
                      <div className={classes.secondaryTitleText}>
                        <div className={classes.circle}></div>
                        <span>{voucher.name}</span>
                      </div>
                      <div className={classes.secondaryValueText}>
                        -{formatCurrency(voucher.discount)}
                      </div>
                    </div>
                  ))}
                  <hr style={{ borderTop: '1px dashed #E4E4E4', marginTop: 18 }} />
                </Fragment>
              ) : (
                <></>
              )}

              <div className={`${classes.voucherItem} ${classes.totalContainer}`}>
                <div className={classes.priceTotalTitle}>Tổng tiền phải trả</div>
                <div className={classes.priceTotalValue}>
                  {formatCurrency(Math.round(data?.totalOrder ?? 0))}
                </div>
              </div>
              {gBizAccount && (
                  <div className={classes.noteForGBiz}>
                    * Thanh toán bằng tài khoản doanh nghiệp sẽ không được áp dụng các chương trình giảm giá cho KH cá nhân và không được tích lũy.
                  </div>
              )}
              <CartButton
                containerStyle={classes.buttonContinue}
                type="submit"
                onClick={() => {
                  handleSubmit();
                  // onSubmitOrderDesktopClick(values);
                }}
                text="Thanh toán lại & Đặt hàng"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const RequestPaymentDesktop = React.memo(RequestPayment);
export { RequestPaymentDesktop };
