import React from 'react';
import { Link } from 'react-router-dom';
import { ProductItem } from '../../../../components/product-item';
import { IProduct } from '../../../../core/models/product';
import { useHotDealStyles } from './hot-deal.style';

interface Props {
  products: IProduct[];
  onPressProduct: (product: IProduct) => void;
}

export const HotDeal = React.memo((props: Props) => {
  const classes = useHotDealStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.titleText}>Hot deal</div>
        <Link to="/hot-deal">
          <div className={classes.showAllText}>Tất cả</div>
        </Link>
      </div>

      <div className={classes.contentContainer}>
        {props.products.map((product) => (
          <ProductItem
            key={product.id}
            onClick={() => props.onPressProduct(product)}
            product={product}
            isHotDeal
          />
        ))}
      </div>
    </div>
  );
});
