import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const useDynamicProductStyles = makeStyles({
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 12,
    padding: 16,
    marginBottom: 24,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: 30,
    },
  },
  titleContainer: {
    display: 'none',
    fontSize: 24,
    color: '#000',
    fontWeight: 700,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'block',
      marginTop: 40,
      marginBottom: 14,
    },
  },
  inkBar: {
    height: 2,
    width: 40,
    backgroundColor: colors.primaryColor,
    borderRadius: 1,
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 24,
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
});
