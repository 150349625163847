import React from "react";

interface Props {
  className?: string;
}

export const DeleteCartItemWarningIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#E9912A"/>
      <rect width="31.8774" height="32" transform="translate(15.9387 16)" fill="#E9912A"/>
      <path d="M28.9995 40.9998C28.9995 39.3429 30.3375 37.9998 31.988 37.9998C33.6385 37.9998 34.9765 39.3429 34.9765 40.9998C34.9765 42.6566 33.6385 43.9998 31.988 43.9998C30.3375 43.9998 28.9995 42.6566 28.9995 40.9998Z" fill="white"/>
      <path d="M29.2923 22.9849C29.1331 21.3866 30.3833 19.9998 31.9834 19.9998C33.5835 19.9998 34.8338 21.3866 34.6746 22.9849L33.6264 33.5072C33.5419 34.3545 32.8317 34.9998 31.9834 34.9998C31.1351 34.9998 30.4249 34.3545 30.3405 33.5072L29.2923 22.9849Z" fill="white"/>
      </svg>
    );
  }
);
