import React from "react";

interface Props {
  className?: string;
}

export const ProfileFilledIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.294 7.29108C17.294 10.2281 14.9391 12.5832 12 12.5832C9.06189 12.5832 6.70599 10.2281 6.70599 7.29108C6.70599 4.35404 9.06189 2 12 2C14.9391 2 17.294 4.35404 17.294 7.29108ZM12 21.9999C7.66239 21.9999 4 21.2949 4 18.5749C4 15.8538 7.6854 15.1738 12 15.1738C16.3387 15.1738 20.0001 15.8788 20.0001 18.5989C20.0001 21.3199 16.3147 21.9999 12 21.9999Z"
        fill="white"
      />
    </svg>
  );
});
