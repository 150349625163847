import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    padding: '16px'
  },
  inputTitle: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#000',
    marginTop: '16px',
    marginBottom: '8px'
  },
  colorRed: {
    color: "#f04a49"
  },
  updateButtonContainer: {
    bottom: 0,
    width: '100%',
    marginBottom: '32px',
    marginTop: '28px'
  }
});