import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  container: {
    padding: '16px 0',
    borderBottom: `1px solid ${colors.dividerColor}`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  colorPrice: {
    fontSize: 14,
    fontWeight: 700,
    color: colors.primaryColor,
  },
  lineThroughPrice: {
    fontSize: 10,
    color: colors.textSecondaryColor,
    textDecorationLine: 'line-through',
    marginLeft: '4px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityText: {
    margin: '0px 6px',
    color: colors.textSecondaryColor,
    fontSize: 12,
    fontWeight: 700,
  },
  horizontalRule: {
    padding: '0px 16px',
  },
  closeIconContainer: {},
  brandLogo: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  brandContainer: {
    margin: '8px 0px',
    display: 'flex',
  },
  brandText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#000',
    wordBreak: 'break-word',
  },
});
