import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const brandItemSmallSide = 64;
const brandItemLargeSide = 96;

export const useBrandListStyles = makeStyles({
  container: {
    marginTop: 20,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 60,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    fontWeight: 700,
    color: '#161616',
    fontSize: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 24,
    },
  },
  showAllText: {
    color: colors.accentColor,
    fontWeight: 500,
    fontSize: 15,
  },
  sliderContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  brandItem: {
    height: '100%',
    width: '100%',
    display: 'inline-flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px',
  },
  arrowContainer: {
    width: 40,
    height: 40,
    borderRadius: 100,
    background: '#fff',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  brandContainer: {
    overflowX: 'auto',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: `${brandItemSmallSide}px ${brandItemSmallSide}px`,
    gridGap: 12,
    marginTop: 10,
    gridAutoColumns: brandItemSmallSide,
    paddingBottom: 12,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 30,
    },
  },
});
