import React, { useEffect, useMemo, useState } from 'react';
import { ModalSpinner } from '../../components/modal-spinner';
import { useInterval } from '../../core/hooks/useInterval';
import { orderService } from '../../services/order-service';
import { useStyles } from './check-payment.style';
import { useHistory } from 'react-router';
import { useQuery } from '../../core/hooks/use-query';
import { NotificationModal } from '../verify-payment/notification-modal/notification-modal.component';
import { useMediaQuery } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';

interface Props {}
const CheckPayment = (props: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery() as any;
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);

  const orderId = useMemo(() => query.get('orderId'), [query]);

  useEffect(() => {
    async function checkPayment() {
      if (orderId) {
        setIsLoading(true);
        const requestId = query.get('requestId');
        const result = await orderService.checkPayment(orderId, { requestId });
        if (result?.data?.result?.isPaid === 1) {
          setIsVisibleSuccess(true);
        } else {
          history.push(`/order/${orderId}`);
        }
        setIsLoading(false);
      }
    }

    checkPayment();
  }, [query]);

  return (
    <div>
      <ModalSpinner visible={isLoading} />
      <NotificationModal
        visible={isVisibleSuccess}
        type={isDesktop ? 'success' : 'success-tracking'}
        onSuccessButtonClick={(isToTracking) => {
          isToTracking
            ? history.push(`/order/${orderId}/tracking`)
            : history.push(`/order/${orderId}`);
        }}
        onFailedButtonClick={() => {}}
        onFailedInvalidTimeClick={() => {}}
      />
    </div>
  );
};

const CheckPaymentScreen = React.memo(CheckPayment);

export { CheckPaymentScreen };
