import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  totalMoneyContainer: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
  },
  totalMoneyTitle: {
    color: '#6B6C6F',
    fontWeight: 400,
    fontSize: '16px',
    alignSelf: 'flex-end'
  },
  totalMoneyValue: {
    color: '#000',
    fontWeight: 900,
    fontSize: '20px'
  },
  continueButton: {
    margin: '0px 16px 30px 16px'
  },
  totalMoneyField: {
    bottom: 0,
    width: '100%',
    background: '#fff',
    zIndex: 10
  }
});