import { makeStyles } from "@material-ui/styles";
import { BREAK_POINTS } from "../../../../core/contants/break-points";
import { colors } from "../../../../core/contants/colors";

export const useCouponListStyles = makeStyles({
  couponListContainer: {
    borderRadius: 3,
    borderStyle: "dashed",
    borderColor: colors.primaryColor,
    borderWidth: 1,
    marginBottom: 16,
    backgroundColor: "rgba(233, 110, 52, 0.08)",
    padding: "0px 12px",

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      backgroundColor: "#fff",
      border: "none",
    },
  },
  couponItem: {
    display: "flex",
    padding: "8px 0px",

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: "12px 0px",
    },
  },
  couponTitleContainer: {
    flex: 1,
    margin: "0px 8px",
  },
  couponTitle: {
    color: "#000",
    fontWeight: 500,
    fontSize: 13,
  },
  couponDescription: {
    color: colors.textSecondaryColor,
    fontSize: 10,
    lineHeight: 1.4,
    fontWeight: 300,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 12,
    },
  },
  couponIconContainer: {
    backgroundColor: colors.primaryColor,
    borderRadius: 3,
    width: 24,
    height: 24,
  },
  couponIcon: {
    width: 24,
    height: 24,
  },
  couponButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  takeVoucherBtn: {
    borderRadius: 4,
    padding: "4px 10px",
    fontSize: 12,
    fontWeight: 500,
  },

  seperator: {
    borderColor: colors.primaryColor,
    opacity: 0.24,
    zIndex: 0,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderColor: "#EDEDED",
      opacity: 1,
    },
  },
});
