import { AtmIcon } from '../../components/icons/atm-icon';
import { CashIcon } from '../../components/icons/cash-icon';
import { MomoIcon } from '../../components/icons/momo-icon';
import { ShopeePayIcon } from '../../components/icons/shopee-pay';
import { VisaIcon } from '../../components/icons/visa-icon';
import { VnPayIcon } from '../../components/icons/vnpay-icon';
import { ZaloPayIcon } from '../../components/icons/zalo-pay-icon';
import {GBusinessIcon} from "../../components/icons/g-business";

export const paymentMethodValues = {
  COD: 'COD',
  VNPAY: 'VNPAY',
  ZALOPAY: 'ZALOPAY',
  VINID: 'VINID',
  SHOPEE_PAY: 'SHOPEE_PAY',
  VNPAY_BANK_ONLINE: 'VNPAY_BANK_ONLINE',
  VNPAY_BANK_ONLINE_INTERNATIONAL_CARD: 'VNPAY_BANK_ONLINE_INTERNATIONAL_CARD',
  VNPT_EPAY_BANK_ONLINE: 'VNPT_EPAY_BANK_ONLINE',
  MOMO: 'MOMO',
  G_BUSINESS: 'BizAccount',
};

export const paymentMethods = [
  // {
  //   name: 'ATM',
  //   icon: () => <AtmIcon />,
  //   value: paymentMethodValues.VNPAY_BANK_ONLINE,
  // },
  // {
  //   name: 'VINID',
  //   icon: () => <img src="" />,
  //   value: paymentMethodValues.VINID,
  // },

  {
    name: 'ATM/Visa',
    icon: () => <AtmIcon />,
    value: paymentMethodValues.VNPT_EPAY_BANK_ONLINE,
  },
  {
    name: 'MOMO',
    icon: () => <MomoIcon />,
    value: paymentMethodValues.MOMO,
  },
  // {
  //   name: 'Visa/Master',
  //   icon: () => <VisaIcon />,
  //   value: paymentMethodValues.VNPAY_BANK_ONLINE_INTERNATIONAL_CARD,
  // },
  {
    name: 'VNPAY',
    icon: () => <VnPayIcon />,
    value: paymentMethodValues.VNPAY,
  },
  {
    name: 'ZaloPay',
    icon: () => <ZaloPayIcon />,
    value: paymentMethodValues.ZALOPAY,
  },
  {
    name: 'Tiền mặt',
    icon: () => <CashIcon />,
    value: paymentMethodValues.COD,
  },
  // {
  //   name: 'ShopeePay',
  //   icon: () => <ShopeePayIcon />,
  //   value: paymentMethodValues.SHOPEE_PAY,
  // },
  {
    name: 'G-Business',
    icon: () => <GBusinessIcon />,
    value: paymentMethodValues.G_BUSINESS,
  },
];
