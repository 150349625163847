import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { RadioCheckedIcon } from './icons/radio-checked';
import { RadioUncheckedIcon } from './icons/radio-unchecked';
import {colors} from "../core/contants/colors";

interface Props {
  isChecked: boolean;
  onChanged?: (value: boolean) => void;
  containerClassname?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    userSelect: 'none',
  },
  checkboxContainer: {
    marginRight: 8,
  },
  disabledBackground: {
    backgroundColor: colors.smoke,
  },
  cursorDefault: {
    cursor: 'default !important',
  },
});

export const AppRadioBox = React.memo((props: React.PropsWithChildren<Props>) => {
  const classes = useStyles();

  const click = () => {
    if (!props.onChanged) return;
    props.onChanged(!props.isChecked);
  };

  return (
    <div className={`${clsx(classes.container, props.containerClassname)} ${props.disabled ? classes.cursorDefault : ''}`} onClick={click}>
      <div className={classes.checkboxContainer}>
        {props.isChecked ? <RadioCheckedIcon /> : <RadioUncheckedIcon color={props.disabled ? colors.smoke : ''} />}
      </div>
      {props.children}
    </div>
  );
});
