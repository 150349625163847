import { useMediaQuery } from '@material-ui/core';
import { Switch } from 'antd';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { Formik, useFormik } from 'formik';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { AppRadioBox } from '../../../../components/radio';
import { RaisedButton } from '../../../../components/raised-button';
import { AppSelect } from '../../../../components/select';
import { TextInput } from '../../../../components/text-input';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { GG_API_KEY } from '../../../../core/environments/env';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { IApiResponse } from '../../../../core/models/api-response';
import { IAddress } from '../../../../core/models/customer';
import { customerService } from '../../../../services/customer-service';
import { RootState } from '../../../../store';
import { useAddressFormStyles } from './address-form.style';
import { slice } from 'lodash-es';

interface Props {
  defaultAddress?: IAddress | null;
  onDone: (address?: IAddress) => void;
}

const AddressFormSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Số điện thoại là bắt buộc')
    .length(10, 'Số điện thoại cần có 10 số'),
  name: Yup.string().required('Tên là bắt buộc').min(3, 'Tên phải có ít nhất 3 kí tự'),
  address: Yup.string().required('Địa chỉ là bắt buộc'),
  longitude: Yup.number().nullable().required('Vui lòng chọn địa chỉ từ danh sách'),
});

export const AddressForm = React.memo((props: Props) => {
  const classes = useAddressFormStyles();
  const { t } = useTranslation([I18N_NAMESPACES.PROFILE]);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const history = useHistory();
  const reference = useSelector((state: RootState) => state.reference);
  const districtRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { ref } = usePlacesWidget({
    apiKey: GG_API_KEY,
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      let wardName, provinceName, districtName, address;
      const splittedAddress = ((ref.current as any).value || '').split(', ');

      wardName = splittedAddress[splittedAddress.length - 4];
      districtName = splittedAddress[splittedAddress.length - 3];
      provinceName = splittedAddress[splittedAddress.length - 2];
      address = slice(splittedAddress, 0, splittedAddress.length - 4).join(', ');

      formik.setFieldValue('latitude', lat);
      formik.setFieldValue('longitude', lng);
      formik.setFieldValue('address', address);
      formik.setFieldValue('province', {
        value: props.defaultAddress?.provinceId?.toString() || '',
        label: provinceName,
      });
      formik.setFieldValue('district', {
        value: props.defaultAddress?.districtId?.toString() || '',
        label: districtName,
      });
      formik.setFieldValue('ward', {
        value: props.defaultAddress?.wardId?.toString() || '',
        label: wardName,
      });
      formik.setFieldValue('placeId', place.place_id);
    },
    options: {
      types: [],
      componentRestrictions: { country: 'vn' },
    },
  });

  const onSubmit = async (values) => {
    const body = {
      name: values.name,
      phone: values.phone,
      provinceName: values.province.label,
      districtName: values.district.label,
      wardName: values.ward.label,
      address: values.address,
      isDefault: values.isDefault ? 1 : 0,
      longitude: values.longitude,
      latitude: values.latitude,
      googleMapPlaceId: values.placeId,
    };

    setIsLoading(true);

    let response: AxiosResponse<IApiResponse<IAddress>>;
    if (props.defaultAddress?.id) {
      response = await customerService.updateAddress(props.defaultAddress.id, body);
    } else {
      response = await customerService.addAddress(body);
    }

    if (response.data.messageCode === 200) {
      props.onDone(response.data.result);
    }

    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: props.defaultAddress?.name || '',
      phone: props.defaultAddress?.phone || '',
      province: {
        value: props.defaultAddress?.provinceId?.toString() || '',
        label: props.defaultAddress?.provinceName || '',
      },
      district: {
        value: props.defaultAddress?.districtId?.toString() || '',
        label: props.defaultAddress?.districtName || '',
      },
      ward: {
        value: props.defaultAddress?.wardId?.toString() || '',
        label: props.defaultAddress?.wardName || '',
      },
      address: props.defaultAddress?.addressLine1,
      isDefault: !!props.defaultAddress?.isDefault,
      longitude: props.defaultAddress?.longitude,
      latitude: props.defaultAddress?.latitude,
      placeId: props.defaultAddress?.googleMapPlaceId,
    },
    validationSchema: AddressFormSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    (ref.current as any).value = [
      props.defaultAddress?.addressLine1,
      props.defaultAddress?.wardName,
      props.defaultAddress?.districtName,
      props.defaultAddress?.provinceName,
    ]
      .filter((i) => !!i)
      .join(', ');
  }, [props.defaultAddress]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.contentContainer}>
        <TextInput
          title={t('fullname')}
          value={formik.values.name}
          error={formik.errors.name}
          onChange={(value: any) => formik.setFieldValue('name', value)}
          containerClassname={classes.textInputContainer}
        />
        <TextInput
          title={t('phoneNumber')}
          value={formik.values.phone}
          error={formik.errors.phone}
          type="tel"
          onChange={(value: any) => formik.setFieldValue('phone', value)}
          containerClassname={classes.textInputContainer}
        />
        <TextInput
          title={'Địa chỉ'}
          // value={formik.values.address}
          error={formik.errors.address || formik.errors.longitude}
          onChange={(value: any) => {
            // formik.setFieldValue('address', value);
            formik.setFieldValue('latitude', null);
            formik.setFieldValue('longitude', null);
          }}
          containerClassname={clsx(classes.textInputContainer, classes.addressDetailContainer)}
          textInputRef={ref}
          placeholder="Nhập địa chỉ"
        />

        <div className={classes.setAddressDefaultContainer}>
          {isDesktop ? (
            <AppRadioBox
              isChecked={formik.values.isDefault}
              onChanged={(value: any) => formik.setFieldValue('isDefault', value)}
            >
              <span className={classes.setAsDefaultText}>{t('setAddressAsDefault')}</span>
            </AppRadioBox>
          ) : (
            <Fragment>
              <div>{t('setAddressAsDefault')}</div>
              <Switch
                defaultChecked={formik.values.isDefault}
                onChange={(value: any) => formik.setFieldValue('isDefault', value)}
              />
            </Fragment>
          )}
        </div>
      </div>
      <RaisedButton className={classes.submitBtn} isLoading={isLoading} type="submit">
        {props.defaultAddress?.id ? t('update') : 'Lưu'}
      </RaisedButton>
    </form>
  );
});
