import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { Fragment, ReactElement } from 'react';
import { colors } from '../core/contants/colors';

interface Props {
  prefix?: ReactElement;
  surfix?: ReactElement;
  placeholder?: string;
  containerClassname?: string;
  textInputClassname?: string;
  textInputContainerClassname?: string;
  onChange?: (text: string) => void;
  value?: string;
  title?: string | ReactElement;
  disabled?: boolean;
  onClick?: () => void;
  error?: string;
  type?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmit?: () => void;
  textInputRef?: any;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D8D8D8',
    padding: '0px 16px',
    alignItems: 'center',
    height: 54,
  },
  input: {
    flex: 1,
  },
  title: {
    color: '#221F20',
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 6,
  },
  disabledBackground: {
    backgroundColor: colors.smoke,
  },
  prefixContainer: {
    marginRight: 8,
  },
  surfixContainer: {
    marginLeft: 8,
  },
  error: {
    fontSize: 12,
    color: colors.dangerColor,
  },
});

export const TextInput = React.memo((props: Props) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    if (!props.onChange) return;
    props.onChange(e.target.value as string);
  };
  return (
    <div className={props.containerClassname}>
      {props.title ? <div className={classes.title}>{props.title}</div> : <Fragment />}
      <div
        className={clsx(
          classes.container,
          props.disabled && classes.disabledBackground,
          props.textInputContainerClassname,
        )}
        onClick={props.onClick}
      >
        {props.prefix ? <div className={classes.prefixContainer}>{props.prefix}</div> : <div />}
        <input
          className={clsx(classes.input, props.textInputClassname)}
          disabled={props.disabled}
          placeholder={props.placeholder}
          onChange={handleChange}
          value={props.value}
          type={props.type}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onSubmit={props.onSubmit}
          ref={props.textInputRef}
        />
        {props.surfix ? <div className={classes.surfixContainer}>{props.surfix}</div> : <div />}
      </div>
      {props.error && <span className={classes.error}>{props.error}</span>}
    </div>
  );
});
