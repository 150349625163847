import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../../../core/contants/break-points';

export const useProductBannerStyles = makeStyles({
  thumbnail: {
    width: '100%',
    height: 250,
    objectFit: 'cover',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderRadius: 12,
    },
  },
  thumbnailContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    borderRadius: 12,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 10,
    backgroundColor: '#fff',
    width: 32,
    height: 32,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});
