import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { CircleGreenTickFilledIcon } from '../../components/icons/circle-green-tick-filled';
import { ModalCloseIcon } from '../../components/icons/modal-close';
import { colors } from '../contants/colors';

export const showSuccess = (message: string) => {
  return toast.success(message, {
    position: 'bottom-center',
    className: 'success-toast-container',
    bodyStyle: {
      color: '#fff',
    },
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    icon: <CircleGreenTickFilledIcon />,
    closeButton: (
      <div style={{ margin: 'auto', marginRight: 8 }}>
        <ModalCloseIcon color="#fff" />
      </div>
    ),
  });
};

export const showError = (message: string | undefined) => {
  return toast.success(message || '', {
    position: 'bottom-center',
    className: 'error-toast-container',
    bodyStyle: {
      color: '#fff',
    },
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    icon: <div />,
    closeButton: (
      <div style={{ margin: 'auto', marginRight: 8 }}>
        <ModalCloseIcon color="#fff" />
      </div>
    ),
  });
};
