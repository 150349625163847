import { Formik } from 'formik';
import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { CartButton } from '../../../components/cart-button';
import { CartDivider } from '../../../components/cart-divider';
import { Header } from '../../../components/header';
import { CartTextInput } from '../../update-address/components/cart-text-input/cart-text-input.component';
import { useStyles } from './billing-info.style';
import {paymentMethodValues} from "../../../core/contants/paymentMethodValues";
interface Props {
  onUpdateBillingClick: (value: any) => void;
  onReturnBilling: () => void;
  cartState?: any;
  currentMethod?: string;
}
const Billing = React.memo((props: Props) => {
  const classes = useStyles();
  const { onReturnBilling, onUpdateBillingClick, cartState, currentMethod } = props;
  const formRef = useRef(null) as any;
  const UpdateAddressSchema = Yup.object().shape({
    companyName: Yup.string().required('Vui lòng nhập'),
    taxCode: Yup.string().required('Vui lòng nhập'),
    address: Yup.string().required('Vui lòng nhập'),
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập'),
  });
  const isDisabled = currentMethod === paymentMethodValues.G_BUSINESS;
  useEffect(() => {
    if (cartState?.cartData?.invoiceName) {
      formRef?.current?.setFieldValue('companyName', cartState?.cartData?.invoiceName)
    }
    if (cartState?.cartData?.invoiceNumber) {
      formRef?.current?.setFieldValue('taxCode', cartState?.cartData?.invoiceNumber)
    }
    if (cartState?.cartData?.invoiceAddress) {
      formRef?.current?.setFieldValue('address', cartState?.cartData?.invoiceAddress)
    }
    if (cartState?.cartData?.invoiceEmail) {
      formRef?.current?.setFieldValue('email', cartState?.cartData?.invoiceEmail)
    }
    setTimeout(() => {
      formRef?.current?.setErrors({})
    }, 100)
  }, [cartState?.data?.hasInvoice]);
  return (
    <div>
      <Header title="Thông tin nhận hóa đơn" onBackClick={onReturnBilling} />
      <CartDivider />
      <Formik
        innerRef={formRef}
        initialValues={{
          companyName: '',
          taxCode: '',
          address: '',
          email: '',
        }}
        validationSchema={UpdateAddressSchema}
        onSubmit={(values) => {
          onUpdateBillingClick(values);
          // same shape as initial values
        }}
      >
        {({ errors, touched, setFieldValue, values, handleSubmit }) => (
          <Fragment>
            <div className={classes.container}>
              <div className={classes.inputTitle}>
                Mã số thuế <span className={classes.colorRed}>*</span>
              </div>
              <CartTextInput
                placeholder={'1234567890'}
                onChange={(value: any) => setFieldValue('taxCode', value)}
                value={values.taxCode}
                error={errors.taxCode}
                disabled={isDisabled}
              />
              <div className={classes.inputTitle}>
                Tên tổ chức cá nhân nộp thuế <span className={classes.colorRed}>*</span>
              </div>
              <CartTextInput
                placeholder={'Công ty TNHH ABC'}
                onChange={(value: any) => setFieldValue('companyName', value)}
                value={values.companyName}
                error={errors.companyName}
                disabled={isDisabled}
              />
              <div className={classes.inputTitle}>
                Địa chỉ <span className={classes.colorRed}>*</span>
              </div>
              <CartTextInput
                placeholder={'315 Trường Chinh'}
                onChange={(value: any) => setFieldValue('address', value)}
                value={values.address}
                error={errors.address}
                disabled={isDisabled}
              />
              <div className={classes.inputTitle}>
                Email nhận hóa đơn điện tử <span className={classes.colorRed}>*</span>
              </div>
              <CartTextInput
                placeholder={'abc@gmail.com'}
                onChange={(value: any) => setFieldValue('email', value)}
                value={values.email}
                error={errors.email}
                disabled={isDisabled}
              />
            </div>
            {isDisabled || (
                <div className={classes.updateButtonContainer}>
                    <CartButton text="Cập nhật" type="submit" onClick={handleSubmit} />
                </div>
            )}
          </Fragment>
        )}
      </Formik>
    </div>
  );
});

const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({});

const BillingInfo = React.memo(connect(mapState, mapDispatch)(Billing));
export { BillingInfo };
