import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { CartDiscountIcon } from "../../../../components/icons/cart-discount";
import { BREAK_POINTS } from "../../../../core/contants/break-points";
import { images } from "../../../../core/contants/images";
import { useStyles } from "./discount-field.style";
interface Props {
  value: string;
}
export const DiscountField = React.memo((props: Props) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  return (
    <div className={isDesktop ? classes.buttonBackgroundContainerDesktop : classes.buttonBackgroundContainer}>
      <img
        src={images.cartDiscountBg}
        className={classes.buttonBackground}
        alt=""
      />
      <div className={classes.textContainer}>
        <div className={classes.text}>
          Bạn đã tiết kiệm được {props?.value ?? 0} từ ưu đãi
        </div>
        <CartDiscountIcon />
      </div>
    </div>
  );
});
