import { useMediaQuery } from '@material-ui/core';
import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { voucherService } from '../../services/voucher-service';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { IVoucher } from '../../core/models/ticket';
import { CouponDiscount } from '../icons/coupon-discount';
import { ModalCloseIcon } from '../icons/modal-close';
import { RaisedButton } from '../raised-button';
import { useCouponDetailStyles } from './coupon-detail.style';

import parse from 'html-react-parser';

interface Props {
  isVisible: boolean;
  coupon: IVoucher;
  onDismiss: () => void;
  onApply: (e) => void;
  hideDiscountIcon?: boolean;
  hideApplyBtn?: boolean;
}

const CouponDetailContent = React.memo((props: Props) => {
  const classes = useCouponDetailStyles();
  useEffect(() => {
    async function getDetail() {
      if (!props.coupon.promotionId) return;
      const result = await voucherService.getTicketDetail(props.coupon.promotionId);
    }

    getDetail();
  }, []);
  return (
    <div className={classes.contentContainer}>
      <div className={classes.couponTitleContainer}>
        {props?.hideDiscountIcon ? (
          <></>
        ) : (
          <div className={classes.couponIcon}>
            <CouponDiscount />
          </div>
        )}

        <div>
          <div className={classes.couponTitle}>{props.coupon.promotion?.title}</div>
          <div>
            {props.coupon.promotion?.endTime
              ? moment(props.coupon.promotion.endTime, 'yyyy-MM-DD HH:mm:ss').format(
                  'DD/MM/yyyy HH:mm',
                )
              : ''}
          </div>
        </div>
      </div>
      <hr className={classes.seperator} />
      <div>
        <div className={classes.conditionTitle}>Điều kiện áp dụng</div>
        <div className={classes.condition}>{parse(props.coupon.promotion?.description || '')}</div>
      </div>

      {!props.hideApplyBtn && (
        <RaisedButton className={classes.applyBtn} onClick={() => props.onApply(props?.coupon)}>
          Sử dụng ngay
        </RaisedButton>
      )}
    </div>
  );
});

export const CouponDetail = React.memo((props: Props) => {
  const classes = useCouponDetailStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  if (isDesktop) {
    return (
      <Modal
        visible={props.isVisible}
        onCancel={props.onDismiss}
        title=""
        closeIcon={<div />}
        footer={null}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
        centered
      >
        <div className={classes.headerContainer}>
          <span className={classes.title}>Chọn mã giảm giá</span>
          <div className={classes.closeIconDesktop} onClick={props.onDismiss}>
            <ModalCloseIcon />
          </div>
        </div>
        <CouponDetailContent {...props} />
      </Modal>
    );
  }

  return (
    <BottomSheet
      onDismiss={props.onDismiss}
      open={props.isVisible}
      header={
        <div className={classes.headerContainer}>
          <span className={classes.title}>Chọn mã giảm giá</span>
          <div className={classes.closeIcon} onClick={props.onDismiss}>
            <ModalCloseIcon color="#7A7A7A" />
          </div>
        </div>
      }
    >
      <CouponDetailContent {...props} />
    </BottomSheet>
  );
});
