import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { Fragment, useState } from 'react';
import { CartDivider } from '../../components/cart-divider';
import { Header } from '../../components/header';
import { useStyles } from './update-address.style';
import { CartTextInput } from './components/cart-text-input/cart-text-input.component';
import { CartButton } from '../../components/cart-button';
import { ProvinceSelectorBottomSheet } from '../../components/province-selector';
import { ArrowDownIcon } from '../../components/icons/arrow-down';
export const UpdateAddressScreen = React.memo(() => {
  const classes = useStyles();
  const UpdateAddressSchema = Yup.object().shape({
    // firstName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    // .required('Required'),
    // lastName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    // .required('Required'),
    // email: Yup.string().email('Invalid email').required('Required'),
  });
  const [isVisibleCountrySelector, setIsVisibleCountrySelector] = useState(false);

  const openCountrySeletor = () => {
    setIsVisibleCountrySelector(true);
  };
  return (
    <div>
      <ProvinceSelectorBottomSheet
        selectedId={null}
        onChanged={(province) => {}}
        onVisibleChanged={setIsVisibleCountrySelector}
        isVisible={isVisibleCountrySelector}
      ></ProvinceSelectorBottomSheet>
      <Header title="Thay đổi địa chỉ" />
      <CartDivider />
      <Formik
        initialValues={{
          name: '',
          phoneNumber: '',
          province: '',
          district: '',
          ward: '',
          address: '',
        }}
        validationSchema={UpdateAddressSchema}
        onSubmit={(values) => {
          // same shape as initial values
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <div className={classes.container}>
            <div className={classes.inputTitle}>
              Họ tên <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'Nguyễn Văn A'}
              onChange={(value: any) => setFieldValue('name', value)}
              value={values.name}
            />
            <div className={classes.inputTitle}>
              Số điện thoại <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'0988988988'}
              onChange={(value: any) => setFieldValue('phoneNumber', value)}
              value={values.phoneNumber}
            />
            <div className={classes.inputTitle}>
              Tỉnh/TP <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'TP Hà Nội'}
              onChange={(value: any) => setFieldValue('province', value)}
              value={values.province}
              onClick={openCountrySeletor}
              rightIcon={() => <ArrowDownIcon fill="#000" />}
            />
            <div className={classes.inputTitle}>
              Quận/Huyện <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'Quận Thanh Xuân'}
              onChange={(value: any) => setFieldValue('district', value)}
              value={values.district}
              onClick={openCountrySeletor}
              rightIcon={() => <ArrowDownIcon fill="#000" />}
            />
            <div className={classes.inputTitle}>
              Phường/Xã <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'Phường Khương Mai'}
              onChange={(value: any) => setFieldValue('ward', value)}
              value={values.ward}
              onClick={openCountrySeletor}
              rightIcon={() => <ArrowDownIcon fill="#000" />}
            />
            <div className={classes.inputTitle}>
              Địa chỉ <span className={classes.colorRed}>*</span>
            </div>
            <CartTextInput
              placeholder={'Toà nhà Toyota 315 Trường Chinh'}
              onChange={(value: any) => setFieldValue('address', value)}
              value={values.address}
            />
          </div>
        )}
      </Formik>
      <div className={classes.updateButtonContainer}>
        <CartButton text="Cập nhật" />
      </div>
    </div>
  );
});
