import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../../../core/contants/break-points';

export const useOrderByFilterStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
  },
  container: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 16,
    backgroundColor: '#fff',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      borderRadius: 6,
      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
      minWidth: 350,
      maxHeight: 450,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0px',
  },
  filterText: {
    fontWeight: 500,
    fontSize: 16,
  },
  filterContainer: {
    padding: '10px 0px',
  },
  filterItemContainer: {
    marginBottom: '10px',
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
  },
});
