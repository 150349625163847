import { makeStyles } from '@material-ui/core';

export const useBrandConfirmStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 17,
  },
  description: {
    textAlign: 'center',
    padding: '10px',
  },
  footer: {
    display: 'flex',
    gap: 20,
    marginTop: 8,
  },
  btn: {
    flex: 1,
    borderRadius: 100,
  },
  btn2: {
    flex: 1,
    backgroundColor: '#3c3c3c',
    borderRadius: 100,
  },
});
