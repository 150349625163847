import { DownOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { startOfDay, subMilliseconds } from 'date-fns';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import Scrollspy from 'react-scrollspy';
import { start } from 'repl';
import { ChervonLeftIcon } from '../../../../components/icons/chervon-left';
import { FavoriteIcon } from '../../../../components/icons/favorite';
import { FavoriteOutlinedIcon } from '../../../../components/icons/favorite-outlined';
import { LocationIcon } from '../../../../components/icons/locations';
import { ScheduleIcon } from '../../../../components/icons/schedule';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';
import { IBrand, IOpenTime, IRestaurant } from '../../../../core/models/brand';
import { ITicket } from '../../../../core/models/ticket';
import { timeStringToSeconds } from '../../../../core/ultis/date-time';
import { BrandTabItem } from '../../models/tabs';
import { CouponList } from '../coupon-list/coupon-list.component';
import { useBrandGeneralInfoStyle } from './brand-general-info.style';

interface Props {
  tabs: BrandTabItem[];
  activeTabId: string;
  tickets: ITicket[];
  onSelectTab: (tab: BrandTabItem) => void;
  openBrandSelector?: () => void;
  onPressFavorite?: (e) => void;
  onSaveGift: (ticket: ITicket) => void;
  brandInfo: IBrand | undefined;
  selectedRestaurant: IRestaurant | undefined;
  isFavorite: boolean;
  openTime: IOpenTime | undefined;
}

export const BrandGeneralInfo = React.memo((props: Props) => {
  const classes = useBrandGeneralInfoStyle();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const history = useHistory();

  const isInBusinessHour = useMemo(() => {
    const openTimeInSeconds = timeStringToSeconds(
      props.selectedRestaurant?.restaurant?.openTime || '00:00:00',
    );
    const closeTimeInSeconds = timeStringToSeconds(
      props.selectedRestaurant?.restaurant?.closeTime || '00:00:00',
    );
    const now = Date.now();
    const currentTimeInSeconds = Math.round((now.valueOf() - startOfDay(now).valueOf()) / 1000);
    return openTimeInSeconds <= currentTimeInSeconds && currentTimeInSeconds <= closeTimeInSeconds;
  }, [props.selectedRestaurant]);

  return (
    <div className={classes.container}>
      <div className={classes.backContainer} onClick={() => history.push('/')}>
        <ChervonLeftIcon className={classes.backIcon} color={'#6b6b6b'} />
        <div className={classes.backText}>Quay lại</div>
      </div>
      <div className={classes.firstBlock}>
        <div
          className={classes.brandInfoContainer}
          onClick={isDesktop ? () => {} : props.openBrandSelector}
        >
          <div className={classes.logoContainer}>
            <img
              src={isDesktop ? props.brandInfo?.logo : props.brandInfo?.logoBW}
              alt={props.brandInfo?.name}
            />
          </div>

          <div>
            <div className={classes.boldText}>
              {isDesktop ? props.brandInfo?.name : 'Nhà hàng giao'}
            </div>
            <div className={classes.addressContainer}>
              <div className={classes.addressText}>
                {props.selectedRestaurant?.restaurant?.address}
              </div>
              <DownOutlined className={classes.arrowIcon} />
            </div>

            <div className={classes.locationInfoContainer}>
              <div className={classes.distanceContainer}>
                <LocationIcon color={colors.accentColor} />
                <div className={classes.distanceText}>
                  {((props.selectedRestaurant?.restaurant?.distance || 0) / 1000).toFixed(1)}km
                </div>
              </div>

              <div className={classes.openHourContainer}>
                <ScheduleIcon
                  className={classes.icon}
                  color={isDesktop ? colors.textSecondaryColor : colors.primaryColor}
                />
                <div className={classes.openHourText}>{props.openTime?.isOpenLabel}</div>
              </div>
            </div>
          </div>

          {isDesktop && (
            <div onClick={props.onPressFavorite}>
              {props.isFavorite ? (
                <FavoriteIcon color={colors.brandYellow} />
              ) : (
                <FavoriteOutlinedIcon color="#ddd" />
              )}
            </div>
          )}
        </div>

        {isDesktop && (
          <div className={classes.tabContainer}>
            <Scrollspy
              items={props.tabs.map((t) => t.id)}
              currentClassName={classes.tabItemActive}
              offset={-150}
              style={{ margin: 0 }}
            >
              {props.tabs.map((tab) => {
                const isSelected = props.activeTabId === tab.id;
                return (
                  <a className={clsx(classes.tabItem)} onClick={() => props.onSelectTab(tab)}>
                    <img
                      src={tab.icon}
                      // className="image"
                      style={{ marginRight: 12, filter: `grayscale(100%)` }}
                    />
                    <div className={classes.inkBar} />
                    {/* {isSelected && <div className={classes.inkBar} />} */}
                    <span style={{ marginTop: 4 }}>{tab.label.toUpperCase()}</span>
                  </a>
                );
              })}
            </Scrollspy>
          </div>
        )}
      </div>

      <CouponList
        tickets={props.tickets}
        containerClassName={classes.couponContainer}
        onSaveGift={props.onSaveGift}
      />
    </div>
  );
});
