import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const useStyles = makeStyles({
  seeMoreText: {
    color: '#007BFF',
    fontWeight: 500,
    fontSize: 14,
    padding: '16px 12px 16px 16px',
  },
  otherButton: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.primaryColor,
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: 'center',
    margin: '0px 16px 16px 16px',
    border: `1px solid ${colors.primaryColor}`,
    borderRadius: 6,
  },
  cartDivider: {
    height: 5,
    width: '100%',
    backgroundColor: colors.dividerColor,
  },
  noteText: {
    color: '#000',
    fontSize: '20px',
    fontWeight: 700,
    marginTop: '16px',
    paddingLeft: '16px',
    marginBottom: '8px',
  },
  noteInput: {
    margin: '0px 16px 16px 16px',
  },
  container: {},
  cartItemsContainer: {
    padding: '0 16px',
  },
  mobileLayout: {
    display: 'none',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'inline',
    },
  },
  desktopLayout: {
    display: 'inline',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  voucherItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: 14,
    alignItems: 'center',
  },
  voucherAppliedItem: {
    padding: '6px 12px',
    color: colors.primaryColor,
    background: '#EDEDED',
    borderRadius: 100,
    marginRight: 15,
    marginBottom: 16,
    position: 'relative',
    fontWeight: 500,
  },
  voucherCloseIcon: {
    position: 'absolute',
    top: -5,
    right: 0,
    cursor: 'pointer',
    zIndex: 10,
  },
  voucherContainer: {
    background: '#fff',
    padding: '0px 14px 26px 14px',
    height: '100%'
  },
  desktopContainer: {
    padding: '40px 0',
  },
  voucherTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  chooseVoucher: {
    color: '#248FED',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
  priceMainItemTitle: {
    fontSize: '14px',
    fontWeight: 400,
    alignSelf: 'center',
    color: '#000',
  },
  priceMainItemValue: {
    fontSize: '20px',
    fontWeight: 900,
  },
  priceTotalTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  priceTotalValue: {
    color: colors.primaryColor,
    fontWeight: 900,
    fontSize: 20,
  },
  totalContainer: {},
  buttonOrderOther: {
    border: '1px solid #E96E34',
    width: '100%',
    padding: '14px 0',
    borderRadius: 6,
    color: colors.primaryColor,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 22,
    cursor: 'pointer',
  },
  buttonContinue: {
    width: '100%',
    padding: '14px 0',
    borderRadius: 6,
    background: colors.primaryColor,
    color: '#fff',
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 12,
    cursor: 'pointer',
  },
  noMargin: {
    marginTop: 0,
  },
  cartListContainer: {
    padding: '16px 20px',
    background: '#fff',
    marginTop: 20,
  },
  cartHeaderTitle: {
    color: '#000',
    fontWeight: 700,
    textAlign: 'center',
  },
  cartName: {
    fontSize: 16,
    fontWeight: 500,
    color: '#000',
  },
  brandLogo: {
    height: 16,
    width: 16,
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 24,
      width: 24,
    },
  },
  cartBrandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  quantityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  quantityText: {
    // margin: '0px 6px',
    color: colors.textSecondaryColor,
    fontSize: 16,
    fontWeight: 700,
  },
  cartItemContainer: {
    background: '#fff',
    padding: '16px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  cartHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  colorPrice: {
    fontSize: 14,
    fontWeight: 700,
    color: '#000',
    textAlign: 'left',
  },
  lineThroughPrice: {
    fontSize: 10,
    color: colors.textSecondaryColor,
    textDecorationLine: 'line-through',
    textAlign: 'left',
  },
  priceHeaderContainer: {
    alignItems: 'flex-start',
  },
  cartTotalPrice: {
    color: colors.primaryColor,
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
  },
  closeIconContainer: {
    background: '#F8F9FA',
    width: 32,
    height: 32,
    borderRadius: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  closeIconCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  step: {
    width: '100%',
  },
  voucherListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  voucherDetailItem: {
    background: '#EDEDED',
    padding: '8px 12px',
    color: colors.primaryColor,
    borderRadius: 100,
    marginRight: 15,
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 15,
  },
  noDiscountCodeText: {
    color: '##6B6B6B',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '18px',
    marginBottom: '18px',
  },
  adjustQuantityIcon: {
    height: 32,
    width: 32,
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 100,
    backgroundColor: '#7A7A7A',
    display: 'inline-block',
    alignSelf: 'center',
    marginRight: 8,
  },
  secondaryTitleText: {
    color: colors.textSecondaryColor,
    fontSize: 12,
    marginLeft: 12,
    fontWeight: 400,
  },
  secondaryValueText: {
    color: colors.textSecondaryColor,
    fontSize: 14,
    fontWeight: 500,
  },
  secondaryRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 0px',
  },
});
