export const colors = {
  primaryColor: "#E96E34",
  accentColor: "#248FED",
  dividerColor: "#EDF2F9",
  textColor: "#221F20",
  textSecondaryColor: "#6B6B6B",
  textGray: "#868686",
  blueBackgroundColor: "#EDF2F7",
  dangerColor: "#EF4949",
  successColor: "#47C751",
  brandYellow: "#F0B71C",
  brandRed: "#D73B50",
  smoke: "#EDEDED",
};
