import React from 'react';
import { ProductItem } from '../../../../components/product-item';
import { IProduct } from '../../../../core/models/product';
import { ITicket } from '../../../../core/models/ticket';
import { useBrandDetailStyles } from '../../brand-detail.style';
import { CouponList } from '../coupon-list/coupon-list.component';
import { useBrandHotDealStyles } from './brand-hot-deal.style';

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
  onPressProduct: (product: IProduct) => void;
  onSaveGift: (ticket: ITicket) => void;
  products: IProduct[];
  tickets: ITicket[];
}

export const BrandHotDeal = React.memo((props: Props) => {
  const classes = { ...useBrandDetailStyles(), ...useBrandHotDealStyles() };
  return (
    <section className={classes.container} ref={props.containerRef} id="hot-deal">
      <div className={classes.titleContainer}>
        <div className={classes.titleText}>Hot deal</div>
        {/* <div className={classes.showAllText}>Tất cả</div> */}
      </div>

      <div className={classes.contentContainer}>
        {props.products.map((item) => (
          <ProductItem
            key={item.id}
            product={item}
            hideBrandName
            onClick={() => props.onPressProduct(item)}
            isHotDeal
          />
        ))}
      </div>
    </section>
  );
});
