import React from "react";

interface Props {
  className?: string;
}

export const CartFilledIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1212 11.2332H16.889C17.3087 11.2332 17.6384 10.8862 17.6384 10.4678C17.6384 10.0392 17.3087 9.70241 16.889 9.70241H14.1212C13.7015 9.70241 13.3718 10.0392 13.3718 10.4678C13.3718 10.8862 13.7015 11.2332 14.1212 11.2332ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42625 18.9081C6.5869 18.9081 5.90743 19.6021 5.90743 20.4593C5.90743 21.3063 6.5869 22.0003 7.42625 22.0003C8.2556 22.0003 8.93507 21.3063 8.93507 20.4593C8.93507 19.6021 8.2556 18.9081 7.42625 18.9081ZM18.6675 18.9081C17.8282 18.9081 17.1487 19.6021 17.1487 20.4593C17.1487 21.3063 17.8282 22.0003 18.6675 22.0003C19.4969 22.0003 20.1763 21.3063 20.1763 20.4593C20.1763 19.6021 19.4969 18.9081 18.6675 18.9081Z"
        fill="white"
      />
    </svg>
  );
});
