import { makeStyles } from "@material-ui/styles";

export const useLoginFirstStepStyles = makeStyles({
  otpReceiveText: {
    color: "#000",
    fontWeight: 700,
    fontSize: 14,
  },
  loginBtn: {
    marginBottom: 12
  }
});
