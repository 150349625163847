import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  container: {
    padding: '0 16px',
  },
  calendarClassName: {
    padding: '0 24px',
  },
  monthTitle: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 700,
  },
  deliveryOptionsContainer: {
    display: 'flex',
    marginTop: '16px',
  },
  deliveryOptionsActive: {
    background: colors.primaryColor,
    width: '50%',
    padding: '11px 0',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deliveryOptionsInactive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#EDEDED',
    width: '50%',
    padding: '11px 0',
    textAlign: 'center',
    color: colors.textSecondaryColor,
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '8px',
  },
  dot: {
    width: '6px',
    height: '6px',
    borderRadius: '6px',
    background: '#fff',
    marginRight: '6px',
  },
  addressContainer: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  addressLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelText: {
    color: '#000',
    fontWeight: 700,
    fontSize: '16px',
  },
  labelTextContainer: {
    display: 'flex'
  },
  addressText: {
    color: colors.textSecondaryColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.5px',
  },
  changeAddressText: {
    color: colors.accentColor,
    fontWeight: 500,
    fontSize: '14px',
    width: 70,
    textAlign: 'right'
  },
  timeContainer: {
    margin: '16px 0px',
  },
  plasticContainer: {
    margin: '16px 0px',
  },
  switch: {
    width: '44px',
    // height: '24px'
  },
  switchContainer: {
    display: 'flex',
    marginTop: '12px',
  },
  switchText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#000',
    marginLeft: '8px',
  },
  updateButtonContainer: {
    bottom: 0,
    width: '100%',
    marginBottom: '32px',
  },
  dateInput: {
    marginTop: 12,
  },
  timeInput: {
    marginTop: 12,
  },
  textInput: {
    fontSize: 16,
    fontWeight: 500,
  },
  noFee: {
    color: colors.successColor,
    fontSize: 14,
    fontWeight: 500,
  },
  restaurantNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  textInputContainer: {
    marginTop: 16,
  },
  addressDetailContainer: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridRowStart: 4,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
});
