import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { AppRadioBox } from '../../../../components/radio';
import { useOrderByFilterStyles } from './order-by-filter.style';

interface Props {
  onVisibleChanged: (value: boolean) => void;
  isVisible: boolean;
  value: string | null;
  onChanged: (value: string) => void;
}

const OrderByFilterContent = React.memo((props: Props) => {
  const classes = useOrderByFilterStyles();
  const items = [
    {
      value: 'discount',
      label: 'Giảm giá nhiều nhất',
    },
    {
      value: 'best-seller',
      label: 'Bán chạy nhất',
    },
    {
      value: 'latest',
      label: 'Món mới nhất',
    },
    {
      value: 'price-low-to-high',
      label: 'Giá thấp đến cao',
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.filterItemContainer}>
        {items.map((i) => (
          <div className={classes.filterContainer} key={i.value}>
            <AppRadioBox
              isChecked={props.value === i.value}
              onChanged={(_) => props.onChanged(i.value)}
            >
              <span className={classes.filterText}>{i.label}</span>
            </AppRadioBox>
          </div>
        ))}
      </div>
    </div>
  );
});

export const OrderByFilterBottomSheet = React.memo((props: Props) => {
  const classes = useOrderByFilterStyles();
  return (
    <BottomSheet
      onDismiss={() => props.onVisibleChanged(false)}
      open={props.isVisible}
      header={
        <div className={classes.headerContainer}>
          <span className={classes.title}>Sắp xếp theo</span>
          <div className={classes.closeIcon} onClick={() => props.onVisibleChanged(false)}>
            <ModalCloseIcon color="#7A7A7A" />
          </div>
        </div>
      }
      defaultSnap={({ maxHeight }) => maxHeight}
    >
      <OrderByFilterContent {...props} />
    </BottomSheet>
  );
});
