import { createModel } from '@rematch/core';
import { cloneDeep, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { RootModel } from '.';
import { IBrand } from '../../core/models/brand';
import { IProvince } from '../../core/models/province';
import { brandService } from '../../services/brand-service';

export enum OVERLAY_MODAL {
  LOGIN,
  SELECT_PROVINCE,
}
export interface IAppContext {
  selectedProvince: IProvince | null;
  selectedBrand: IBrand | null;
  longitude: number | null;
  latitude: number | null;
  isSeenHomePopupBanner: boolean;
  isDisableWindowScroll: boolean;
  isSeenPopupBannerByBrand: {
    [id: string]: boolean;
  };
  recentSearchKeywords: string[];
  uuid: string;
  error: string | null;
  success: string | null;
  overlayModal: OVERLAY_MODAL | null;
  isShowBrandConfirm: boolean;
  delayAddProductSeconds: number;
  flagAddProductSuccess: boolean;
  addedFromHome: boolean;
  goToCheckout: boolean;
}

const initialState: IAppContext = {
  selectedProvince: null,
  selectedBrand: null,
  latitude: null,
  longitude: null,
  isDisableWindowScroll: false,
  isSeenHomePopupBanner: false,
  isSeenPopupBannerByBrand: {},
  recentSearchKeywords: [],
  uuid: uuidv4(),
  error: null,
  success: null,
  overlayModal: null,
  isShowBrandConfirm: false,
  delayAddProductSeconds: 0,
  flagAddProductSuccess: false,
  addedFromHome: false,
  goToCheckout: false,
};

export const appContext = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    setProvince(state, payload: IProvince) {
      return {
        ...state,
        selectedProvince: payload,
      };
    },
    setSuccess(state, payload: string | null) {
      return {
        ...state,
        success: payload,
      };
    },
    setError(state, payload: string | null) {
      return {
        ...state,
        error: payload,
      };
    },
    generateUuid(state, _) {
      return {
        ...state,
        uuid: uuidv4(),
      };
    },
    setPosition(state, payload: { lat: number; lng: number }) {
      return {
        ...state,
        longitude: payload.lng,
        latitude: payload.lat,
      };
    },
    setIsSeenHomePagePopupBanner(state, payload: boolean) {
      return {
        ...state,
        isSeenHomePopupBanner: payload,
      };
    },
    setIsDisableWindowScroll(state, payload: boolean) {
      return {
        ...state,
        isDisableWindowScroll: payload,
      };
    },
    setSelectedBrand(state, payload: IBrand | null) {
      return {
        ...state,
        selectedBrand: payload,
      };
    },
    setIsSeenBannerOfBrand(state, payload: string) {
      return {
        ...state,
        isSeenPopupBannerByBrand: {
          ...state.isSeenPopupBannerByBrand,
          [payload]: true,
        },
      };
    },
    resetIsSeenPopupBannerByBrand(state, _) {
      return {
        ...state,
        isSeenPopupBannerByBrand: {},
      };
    },
    addSearchKeyword(state, payload: string) {
      return {
        ...state,
        recentSearchKeywords: uniq([payload, ...(state.recentSearchKeywords || [])]),
      };
    },
    removeSearchKeyword(state, payload: number) {
      return {
        ...state,
        recentSearchKeywords: (state.recentSearchKeywords || []).filter(
          (_, index) => index != payload,
        ),
      };
    },
    resetSearchKeywords(state, _) {
      return {
        ...state,
        recentSearchKeywords: [],
      };
    },
    setOverlayModal(state, payload: OVERLAY_MODAL | null) {
      return {
        ...state,
        overlayModal: payload,
      };
    },
    setIsShowBrandConfirm(state, payload: boolean) {
      return {
        ...state,
        isShowBrandConfirm: payload,
      };
    },
    adjustDelayAddProductSeconds(state, payload: number) {
      const seconds = (state.delayAddProductSeconds || 0) + payload;
      return {
        ...state,
        delayAddProductSeconds: seconds > 0 ? seconds : 0,
      };
    },
    setDelayAddProductSeconds(state, payload: number) {
      return {
        ...state,
        delayAddProductSeconds: payload,
      };
    },
    setFlagAddProductSuccess(state, payload: boolean) {
      return {
        ...state,
        flagAddProductSuccess: payload,
      };
    },
    setAddedFromHome(state, payload: boolean) {
      return {
        ...state,
        addedFromHome: payload,
      };
    },
    setGoToCheckout(state, payload: boolean) {
      return {
        ...state,
        goToCheckout: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getSelectedBrand(_, state) {
      const response = await brandService.getSelectedBrand();
      if (response.data?.messageCode === 200) {
        dispatch.appContext.setSelectedBrand(
          response.data.result?.category
            ? { ...(response.data.result?.category || {}), province: response.data.result.province }
            : null,
        );
      }
    },
    async changeBrand(payload: { brand: IBrand }, state) {
      const response = await brandService.changedBrand({
        brandId: payload.brand.brandId,
        provinceId: state.appContext.selectedProvince?.id,
      });
    },
  }),
});
