import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useBrandSelectorStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0px',
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
  },
  container: {
    padding: 16,
  },
  provinceText: {
    fontWeight: 500,
    color: colors.textColor,
    fontSize: 16,
  },
  provinceContainer: {
    padding: '16px 0px',
    borderBottomWidth: 1,
    borderBottomColor: colors.dividerColor,
  },
  addressText: {
    color: colors.textSecondaryColor,
    lineHeight: 1.2,
    fontSize: 14,
  },
  textInputContainer: {
    height: 44,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  searchTextInput: {
    fontSize: 14,
    fontWeight: 300,
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 26,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  blankView: {
    width: 16,
    height: 16,
  },
  modalCloseIcon: {
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
