import React from 'react';

interface Props {
  className?: string;
}

export const CheckboxUncheckedIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="4" fill="white" stroke="#C0C0C0" />
    </svg>
  );
});
