import { makeStyles } from "@material-ui/styles";

export const useBrandListStyles = makeStyles({
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,auto)",
    gridGap: 12,
    padding: 16,
  },
  brandContainer: {
    background: "#EDEDED",
    borderRadius: 8,
    padding: 10,
    display: "grid",
    position: "relative",
    "&::before": {
      content: "''",
      paddingBottom: "100%",
      display: "block",
    },
  },

  brand: {
    position: "absolute",
    maxWidth: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    filter: "grayscale(100%)",
    WebkitFilter: "grayscale(100%)" /* Safari 6.0 - 9.0 */,
  },
});
