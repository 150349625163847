import { createModel } from "@rematch/core";
import { cloneDeep } from "lodash";
import { IProvince } from "../../core/models/province";
import { refService } from "../../services/reference-service";
import { RootModel } from ".";

export interface IReference {
  provinces: IProvince[];
}

const initialState: IReference = {
  provinces: [],
};

export const reference = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    updateProvinces(state, payload: IProvince[]) {
      return {
        ...state,
        provinces: payload,
      };
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async getProvinces(payload, state) {
      const response = await refService.getProvince({ latitude: state.appContext.latitude, longitude: state.appContext.longitude });
      dispatch.reference.updateProvinces(response.data?.result || []);

      if (!state.appContext.selectedProvince && state.appContext.latitude && state.appContext.longitude && response.data.result && response.data?.result[0]) {
        dispatch.appContext.setProvince(response.data.result[0]);
      }
    },
  }),
});
