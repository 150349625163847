import { makeStyles } from "@material-ui/styles";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { BREAK_POINTS } from "../core/contants/break-points";
import { colors } from "../core/contants/colors";
import { ChervonLeftIcon } from "./icons/chervon-left";

interface Props {
  prefix?: ReactElement;
  surfix?: ReactElement;
  title: string;
  onBackClick?: () => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    padding: "0px 16px",
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 2,
    height: 44,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: "none",
    },
  },
  iconContainer: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    flex: 1,
    color: "#000",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },
});

export const Header = React.memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        {props.prefix || (
          <div onClick={props.onBackClick ? props.onBackClick : history.goBack}>
            <ChervonLeftIcon color={colors.textColor} />
          </div>
        )}
      </div>
      <span className={classes.title}>{props.title}</span>
      <div className={classes.iconContainer}>{props.surfix}</div>
    </div>
  );
});
