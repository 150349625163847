import { useMediaQuery } from '@material-ui/core';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { AppRadioBox } from '../../../../components/radio';
import { SearchIcon } from '../../../../components/icons/search';
import { TextInput } from '../../../../components/text-input';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { IBrand } from '../../../../core/models/brand';
import { useBrandFilterStyles } from './brand-filter.style';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
interface Props {
  isVisible: boolean;
  onVisibleChanged: (value: boolean) => void;
  selectedId: string | null | undefined;
  onChanged: (brand: IBrand) => void;
  brands: IBrand[];
}

const BrandFilterContent = React.memo((props: Props) => {
  const [searchText, setSearchText] = useState('');
  const filteredBrands = useMemo(() => {
    if (!searchText) return props.brands.map((p) => ({ item: p }));

    const options = {
      keys: ['name'],
    };
    const fuse = new Fuse(props.brands, options);
    return fuse.search(searchText);
  }, [searchText, props.brands]);
  const classes = useBrandFilterStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  return (
    <div className={classes.container}>
      {!isDesktop && (
        <TextInput
          prefix={<SearchIcon className={classes.searchIcon} />}
          placeholder={'Tìm kiếm ...'}
          value={searchText}
          onChange={setSearchText}
          textInputContainerClassname={classes.textInputContainer}
          textInputClassname={classes.searchTextInput}
        />
      )}
      {filteredBrands.map((p) => (
        <div
          className={classes.provinceContainer}
          onClick={() => {
            props.onChanged(p.item);
            props.onVisibleChanged(false);
          }}
        >
          <AppRadioBox isChecked={p.item.brandId.toString() === props.selectedId?.toString()}>
            <span className={classes.provinceText}>{p.item.name}</span>
          </AppRadioBox>
        </div>
      ))}
    </div>
  );
});

export const BrandFilterBottomSheet = React.memo((props: Props) => {
  const classes = useBrandFilterStyles();
  return (
    <BottomSheet
      onDismiss={() => props.onVisibleChanged(false)}
      open={props.isVisible}
      header={
        <div className={classes.headerContainer}>
          <span className={classes.title}>Lọc theo thương hiệu</span>{' '}
          <div className={classes.closeIcon} onClick={() => props.onVisibleChanged(false)}>
            <ModalCloseIcon color="#7A7A7A" />
          </div>
        </div>
      }
      defaultSnap={({ maxHeight }) => maxHeight - maxHeight / 10}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <BrandFilterContent {...props} />
    </BottomSheet>
  );
});

export const BrandFilterPopOver = React.memo((props: React.PropsWithChildren<Props>) => {
  return (
    <Popover
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#fff'}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <BrandFilterContent {...props} />
        </ArrowContainer>
      )}
      isOpen={props.isVisible}
      onClickOutside={() => props.onVisibleChanged(false)}
      positions={['bottom']}
      padding={6}
      align="end"
      containerStyle={{
        maxHeight: '500px',
        padding: '0px',
        boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
        backgroundColor: '#fff',
        borderRadius: '6px',
      }}
    >
      {props.children as any}
    </Popover>
  );
});
