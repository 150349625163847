import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import { Container } from '../../components/container';
import { Header } from '../../components/header';
import { ProductItem } from '../../components/product-item';
import { Spinner } from '../../components/spinner';
import { IProduct } from '../../core/models/product';
import { productService } from '../../services/product-service';
import { RootState } from '../../store';
import { useDynamicProductStyles } from "./dynamic-product.style";

const DynamicProductScreen = React.memo(() => {
  const classes = useDynamicProductStyles();
  const [dynamicProducts, setDynamicProducts] = useState<any>([]);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 8;
  const appContext = useSelector((state: RootState) => state.appContext);

  const loadMore = () => {
    setPage((value) => value + 1);
  };
  const { tabSlug } = useParams<{ tabSlug: string }>();

  useEffect(() => {
    async function getDynamicProducts() {
      if (appContext.selectedProvince?.id) {
        setIsLoading(true);
        const response = await productService.getDynamicProducts({
          provinceId: appContext.selectedProvince?.id,
          position: 'home-page',
          perPage: -1,
          page: page,
          tabSlug: tabSlug,
        });
        if (response?.data?.result) {
          let dynamicData = response?.data?.result[0];
          setDynamicProducts(dynamicData);
          // if (!response.data.result || (dynamicData.listProductSorted.length || 0) < pageSize) {
          //   setIsEndOfList(true);
          // }
        }
        setIsLoading(false);
      }
    }

    getDynamicProducts();
  }, [appContext.selectedProvince, page]);

  const onPressProduct = (product: IProduct) => () => {};

  return (
    <Container>
      <Header title={dynamicProducts?.tabName} onBackClick={() => history.push('/')}></Header>

      {dynamicProducts?.tabName && (
        <div className={classes.titleContainer}>
          {dynamicProducts?.tabName} <div className={classes.inkBar} />
        </div>
      )}
      {isLoading && (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      )}
      <div className={classes.contentContainer}>
        {dynamicProducts?.listProductSorted?.map((product) => (
          <ProductItem product={product} onClick={onPressProduct(product)}/>
        ))}
      </div>

      {/*{<InfiniteScroll*/}
      {/*  dataLength={dynamicProducts?.listProductSorted?.length}*/}
      {/*  next={loadMore}*/}
      {/*  hasMore={!isEndOfList}*/}
      {/*  loader={*/}
      {/*    <div className={classes.spinnerContainer}>*/}
      {/*      <Spinner />*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*>*/}
      {/*  {dynamicProducts?.listProductSorted?.map((product) => (*/}
      {/*    <ProductItem product={product} onClick={onPressProduct(product)}/>*/}
      {/*  ))}*/}
      {/*</InfiniteScroll>}*/}
    </Container>
  );
});

export default DynamicProductScreen;
