import { useMediaQuery } from '@material-ui/core';
import { Col, Modal, Row } from 'antd';
import React, { Fragment } from 'react';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { useStyles } from './discount-food-modal.style';
import { CartSubtractIcon } from '../../../../components/icons/cart-subtract';
import { CartPlusIcon } from '../../../../components/icons/cart-plus';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { RaisedButton } from '../../../../components/raised-button';
import { formatCurrency } from '../../../../core/ultis/currency';
interface Props {
  isVisible: boolean;
  items: any[];
  onClickItem: (item: any) => void;
  onClose: () => void;
  denominationValue: number;
  type: number;
}
const DiscountFoodComponent = (props: Props): JSX.Element => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const renderItem = (item) => {
    const price = item.salePrice || item.regularPrice;
    let discountPrice = 0;
    if (props.type == 5) {
      discountPrice = price - (props.denominationValue * price) / 100;
    } else {
      discountPrice = price - props.denominationValue;
    }
    if (discountPrice < 0) {
      discountPrice = 0;
    }
    return (
      <Row className={classes.foodItemMobile}>
        <Col xs={6} sm={6} md={4}>
          <img className={classes.titleImage} src={item.thumbnail}></img>
        </Col>
        <Col xs={10} sm={10} md={14} className={classes.foodItemMobileNameContainer}>
          <div className={classes.foodName}>{item.name}</div>
          <div className={classes.priceAndQuantityContainer}>
            <div className={classes.priceContainer}>
              <div className={classes.lineThroughPrice}>
                {formatCurrency(item.salePrice || item.regularPrice)}
              </div>
              <div className={classes.colorPrice}>{formatCurrency(discountPrice)}</div>
            </div>
          </div>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={6}
          className={classes.foodItemMobileSubmitContainer}
          onClick={() => props.onClickItem(item)}
        >
          <div className={classes.applyButton}>Chọn món</div>
        </Col>
      </Row>
    );
  };
  const afterCloseModal = () => {
    setTimeout(() => {
      document.body.style.setProperty('overflow', 'unset', 'important');
    }, 500);
  }
  return (
    <Modal
      title=""
      // visible={props.isVisible}
      visible={props.isVisible}
      footer={null}
      // onCancel={props.onCancelModal}
      closeIcon={null}
      centered
      width={470}
      bodyStyle={{
        padding: isDesktop ? '16px' : '24px 16px 40px 16px',
      }}
      style={{ borderRadius: 8 }}
      closable={false}
      afterClose={afterCloseModal}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerTextContainer}>
          <div className={classes.headerTitleText}>Chọn món ưu đãi</div>
          <div className={classes.headerDescriptionText}>
            Hãy chọn một trong các món sau để nhận ưu đãi
          </div>
        </div>
      </div>
      <div className={classes.modalCloseIcon} onClick={props.onClose}>
        <ModalCloseIcon />
      </div>
      <Row gutter={isDesktop ? 16 : 0}>
        {(props.items || []).map((value: any, index: number) => (
          <Fragment key={value.wooId}>{renderItem(value)}</Fragment>
        ))}
      </Row>
      {/* <Row gutter={12} justify={'center'} className={classes.buttonContainer}>
        <Col md={7} className={classes.cancelButton}>
          <div>Hủy bỏ</div>
        </Col>
        <Col md={7} className={classes.submitButton}>
          <div>Chọn món</div>
        </Col>
      </Row> */}
    </Modal>
  );
};
const DiscountFoodModal = React.memo(DiscountFoodComponent);
export { DiscountFoodModal };
