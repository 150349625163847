import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IBrand } from '../core/models/brand';
import { IProduct } from '../core/models/product';

const getProducts = (params: {
  provinceId?: number;
  group?: string;
  page?: number;
  perPage?: number;
  tag?: string;
  orderBy?: string | null;
  brandId?: string | null;
  position?: string;
}) =>
  axios.get<IApiResponse<IProduct[]>>('/product', {
    params,
  });

const getDynamicProducts = (params: {
  provinceId: number;
  position: string;
  perPage?: number;
  page?: number;
  tabSlug?: string;
}) =>
  axios.get<IApiResponse<IProduct[]>>('/product/dynamic', {
    params,
  });

const getDetail = (params: { id: string }) =>
  axios.get<IApiResponse<IProduct>>(`/product/${params.id}`);

const getUpsells = (params: { id?: string }) =>
  axios.get<IApiResponse<IProduct[]>>(`/product/${params.id}/upsells`);

const searchProduct = (body: {
  name: string;
  brandId?: number | null;
  sort?: string;
  provinceId?: number;
  tag?: string | null;
}) =>
  axios.post<IApiResponse<{ products: IProduct[]; brands: IBrand[] }>>(
    '/product/search',
    body as any,
  );

export const productService = {
  getProducts,
  getDetail,
  getUpsells,
  searchProduct,
  getDynamicProducts,
};
