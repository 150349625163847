import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';

export const useCouponDetailStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: '16px 0px',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
    color: '#000',
  },
  closeIcon: {
    position: 'absolute',
    left: 24,
  },
  closeIconDesktop: {
    position: 'absolute',
    right: 24,
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  couponIcon: {
    marginTop: 6,
  },
  contentContainer: {
    padding: 16,
  },
  couponTitleContainer: {
    display: 'flex',
    gap: 12,
  },
  couponTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: '#000',
  },
  seperator: {
    borderColor: '#EDF2F9',
    margin: '20px 0px',
  },
  conditionTitle: {
    color: '#000',
    fontSize: 16,
    fontWeight: 700,
  },
  condition: {
    color: '#000',
    marginTop: 8,
  },
  applyBtn: {
    marginTop: 16,
    borderRadius: 100,
  },
});
