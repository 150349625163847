import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalSpinner } from '../../components/modal-spinner';
import { useQuery } from '../../core/hooks/use-query';
import { orderService } from '../../services/order-service';
import { NotificationModal } from '../verify-payment/notification-modal/notification-modal.component';
import { VerifyPaymentModal } from '../verify-payment/verify-payment-modal/verify-payment-modal.component';
import { RequestPaymentDesktop } from './request-payment-desktop/request-payment-desktop.component';
import { RequestPaymentMobile } from './request-payment-mobile/request-payment-mobile.component';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import * as Yup from 'yup';

// import { RequestPaymentDesktop } from './request-payment-desktop/request-payment-desktop.component';
import { useStyles } from './request-payment.style';
import { useMediaQuery } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { paymentMethodValues } from '../../core/contants/paymentMethodValues';
import moment from 'moment';
import { cartService } from '../../services/cart-service';
import { IFramePayment } from '../verify-payment/iframe-payment/iframe-payment.component';
import { Formik } from 'formik';
import {IGBizAccount} from "../../core/models/user";
import {userService} from "../../services/user-service";
import {formatCurrency} from "../../core/ultis/currency";
interface Props { }
const RequestPayment = (props: Props): JSX.Element => {
  const classes = useStyles();
  const formRef = useRef(null) as any;
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({} as any);
  const [currentMethod, setCurrentMethod] = useState('');
  const [verifyPaymentVisible, setVerifyPaymentVisible] = useState(false);
  const [currentPaymentRequest, setCurrentPaymentRequest] = useState({} as any);
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [iframePaymentSrc, setIframePaymentSrc] = useState('');
  const [customDatePickerVisible, setCustomDatePickerVisible] = useState(false);
  const [customTimePickerVisible, setCustomTimePickerVisible] = useState(false);
  const [blockTimes, setBlockTimes] = useState([] as any);
  const [gBizAccount, setGBizAccount] = useState<IGBizAccount>();
  const [disableGBizOption, setDisableGBizOption] = useState(false);
  const [isVisibleChooseVoucher, setIsVisibleChooseVoucher] = useState(true);

  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const dispatch = useDispatch<Dispatch>();

  const deliveryInfoSchema = Yup.object().shape({
    // address: Yup.mixed().when('onPickUpRestaurant', {
    //   is: false,
    //   then: Yup.mixed().required('Vui lòng chọn địa chỉ'),
    // }),
    deliveryTime: Yup.string().required('Vui lòng chọn thời gian'),
    deliveryDate: Yup.date().required('Vui lòng chọn thời gian'),
    paymentMethod: Yup.string().required('Vui lòng chọn hình thức thanh toán'),
    onPickUpRestaurant: Yup.boolean().required('Vui lòng chọn'),
    note: Yup.string(),
    companyName: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập'),
    }),
    taxCode: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string()
        .required('Vui lòng nhập'),
    }),
    invoiceAddress: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập'),
    }),
    invoiceEmail: Yup.string().when('haveBill', {
      is: true,
      then: Yup.string().required('Vui lòng nhập').email('Email không hợp lệ'),
    }),
    haveBill: Yup.boolean(),
  });

  async function getBizAcc() {
    const getBizAcc = await userService.gBizAccountInfo();
    if (getBizAcc?.data?.messageCode === 200) {
      setGBizAccount(getBizAcc?.data?.result);
    }
  }

  useEffect(() => {
    getData();
    getBizAcc();
  }, []);
  useEffect(() => {
    formRef?.current?.setFieldValue('onPickUpRestaurant', data?.delivery?.pickupAtRestaurant);
  }, [data?.delivery?.pickupAtRestaurant]);
  useEffect(() => {
    formRef?.current?.setFieldValue('note', data?.note ?? '');
  }, [data?.note]);
  useEffect(() => {
    // if (!cartState?.cartData?.deliveryTime && !timePickerValue) {
    if (blockTimes?.length) {
      formRef?.current?.setFieldValue('deliveryTime', blockTimes[0]);
    }
    // }
  }, [blockTimes]);
  // useEffect(() => {
  //   formRef?.current?.setFieldValue('deliveryDate', moment(data?.delivery?.date, 'DD/MM/YYYY'));
  // }, [data?.delivery?.date]);
  // useEffect(() => {
  //   if (data?.delivery?.time) {
  //     formRef?.current?.setFieldValue('deliveryTime', data?.delivery?.time);
  //   }
  // }, [data?.delivery?.time]);
  useEffect(() => {
    formRef?.current?.setFieldValue('paymentMethod', data?.paymentMethod?.code);
  }, [data?.paymentMethod?.code]);

  useEffect(() => {
    if (data?.customerInvoice?.info === 1) {
      formRef?.current?.setFieldValue('haveBill', true);
      formRef?.current?.setFieldValue('companyName', data?.customerInvoice?.name);
      formRef?.current?.setFieldValue('taxCode', data?.customerInvoice?.number);
      formRef?.current?.setFieldValue('invoiceAddress', data?.customerInvoice?.address);
      formRef?.current?.setFieldValue('invoiceEmail', data?.customerInvoice?.email);
    }
  }, [data?.customerInvoice]);

  const getData = async () => {
    if (id) {
      setIsLoading(true);
      const result = (await orderService.getDetail(id)) as any;
      setData({ ...result?.data?.result, shipping: result?.data?.result?.shippingDetail });
      getBlockTimes(result?.data?.result?.restaurant?.id, moment().format('HH:mm:ss'), result?.data?.result?.brandId, result?.data?.result?.provinceId);
      setCurrentMethod(result?.data?.result?.paymentMethod?.code);
      setIsLoading(false);
    }
  };
  const onSelectPaymentMethodClick = async (value: string) => {
    if (value == paymentMethodValues.G_BUSINESS) {
      setIsVisibleChooseVoucher(false);
      formRef?.current?.setFieldValue('haveBill', true);
      formRef?.current?.setFieldValue('companyName', gBizAccount?.company?.name);
      formRef?.current?.setFieldValue('taxCode', gBizAccount?.company?.taxCode);
      formRef?.current?.setFieldValue('invoiceAddress', gBizAccount?.company?.address);
      formRef?.current?.setFieldValue('invoiceEmail', gBizAccount?.company?.email);
    } else {
      setIsVisibleChooseVoucher(true);
      formRef?.current?.setFieldValue('companyName', '');
      formRef?.current?.setFieldValue('taxCode', '');
      formRef?.current?.setFieldValue('invoiceAddress', '');
      formRef?.current?.setFieldValue('invoiceEmail', '');
    }

    setIsLoading(true);
    setCurrentMethod(value);
    const calculateResult = await orderService?.calculateOrderTotal(id, {
      pickupAtRestaurant: formRef?.current?.values?.onPickUpRestaurant ? 1 : 0,
      recalculateShippingFee: true,
      shippingVendor: 'grab_express',
      paymentMethod: value,
    });
    setData({
      ...data,
      ...calculateResult?.data?.result,
    });
    setIsLoading(false);
  };
  const onRequestPayment = async (values: {
    deliveryTime: string;
    deliveryDate: moment.Moment;
    onPickUpRestaurant: boolean;
    note: string;
    companyName: string;
    taxCode: string;
    invoiceAddress: string;
    invoiceEmail: string;
    haveBill: boolean;
  }) => {
    if (currentMethod) {
      try {
        setIsLoading(true);
        const result = await orderService.requestPayment(data?.orderId, {
          paymentMethod: currentMethod,
          requestAmount: data?.totalOrder,
          note: values?.note ?? '',
          deliveryInfo: {
            pickupAtRestaurant: values?.onPickUpRestaurant ? 1 : 0,
            deliveryAddress: data?.delivery?.address,
            deliveryDate: moment(values?.deliveryDate).format('DD/MM/YYYY'),
            deliveryTime: values?.deliveryTime,
            recipientCellphone: data?.delivery?.recipientCellphone,
            recipientName: data?.delivery?.recipientName,
          },
          invoice: {
            info: values?.haveBill ? 1 : 0,
            name: values?.companyName,
            number: values?.taxCode,
            address: values?.invoiceAddress,
            email: values?.invoiceEmail,
          },
        });
        if (result?.data?.messageCode === 200) {
          switch (currentMethod) {
            case paymentMethodValues.COD:
              setNotificationModalVisible(true);
              setNotificationType('success-tracking');
              break;
            case paymentMethodValues.G_BUSINESS:
              let bizAcc = await userService.gBizAccountInfo();
              let messageNotice = result?.data?.message ?? '';
              if (bizAcc?.data?.messageCode === 200) {
                setGBizAccount(bizAcc?.data?.result);
                messageNotice += `<div style="margin-top: 20px; color: #1A1A1A; font-weight: 700;">`;
                messageNotice += `<div>Đã thanh toán: ${formatCurrency(data?.totalOrder)}</div>`;
                messageNotice += `<div>Hạn mức còn lại: ${formatCurrency(bizAcc?.data?.result?.balance || 0)}</div>`;
                messageNotice += `</div>`
              }
              setNotificationModalVisible(true);
              setNotificationType('success-tracking');
              setNotificationMessage(messageNotice);
              break;
            case paymentMethodValues.ZALOPAY:
              if (result?.data?.result?.response) {
                setCurrentPaymentRequest(result?.data?.result);
                setVerifyPaymentVisible(true);
              }
              break;
            case paymentMethodValues.VNPAY_BANK_ONLINE:
              if (result?.data?.result?.response) {
                // window.location.href = result?.data?.result?.requestPayment?.result?.response;
                window.location.href = result?.data?.result?.response;
              }
              break;
            case paymentMethodValues.VNPAY_BANK_ONLINE_INTERNATIONAL_CARD:
              if (result?.data?.result?.response) {
                window.location.href = result?.data?.result?.response;
              }
              break;
            case paymentMethodValues.VNPT_EPAY_BANK_ONLINE:
              if (result?.data?.result?.response) {
                setIframePaymentSrc(result?.data?.result?.response);
                // window.location.href = result?.data?.result?.requestPayment?.result?.response;
              }
              break;
            case paymentMethodValues.VNPAY:
              if (result?.data?.result?.response) {
                setCurrentPaymentRequest(result?.data?.result);
                setVerifyPaymentVisible(true);
              }
              break;
            case paymentMethodValues.VINID:
              if (result?.data?.result?.response) {
                setCurrentPaymentRequest(result?.data?.result);
                setVerifyPaymentVisible(true);
                // window.location.href = result?.data?.result?.requestPayment?.result?.response;
              }
              break;
            case paymentMethodValues.SHOPEE_PAY:
              if (result?.data?.result?.response) {
                setCurrentPaymentRequest(result?.data?.result);
                setVerifyPaymentVisible(true);
              }
              break;
            case paymentMethodValues.MOMO:
              if (result?.data?.result?.response) {
                setCurrentPaymentRequest(result?.data?.result);
                setVerifyPaymentVisible(true);
              }
              break;
            default:
            // code block
          }
        } else if (result.data.messageCode === 301) {
          setNotificationModalVisible(true);
          setNotificationType('invalid-time-request-payment');
          setNotificationMessage(result?.data?.message ?? '');
        } else {
          setNotificationModalVisible(true);
          setNotificationType('failed');
          setNotificationMessage(result?.data?.message ?? '' as any);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log('err', err);
      }
    }
  };
  const onSuccessCartButtonClick = (toTrackingScreen?: any) => {
    dispatch.cart.reset();
    if (isDesktop) {
      history.push(`/order/${id}`);
    } else {
      history.push(`/order/${id}/tracking`);
    }
  };

  const onFailedCartButtonClick = () => {
    setNotificationModalVisible(false);
  };

  const getBlockTimes = async (restaurantId: string, time: string, brandId: any, provinceId: any) => {
    setIsLoading(true)
    const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
      restaurantCode: restaurantId,
      orderTime: moment().format('HH:mm:ss') as any,
      brandId,
      provinceId
    });
    if (
      allowBlockTimeRes?.status === 200 &&
      (allowBlockTimeRes?.data?.result as any)?.length === 0
    ) {
      const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
        restaurantCode: restaurantId,
        orderTime: moment().add(1, 'days').startOf('day').format('HH:mm:ss') as any,
        brandId,
        provinceId
      });
      setBlockTimes(allowBlockTimeRes?.data?.result);
      formRef?.current?.setFieldValue('deliveryDate', moment().add(1, 'days'));
      // setDatePickerValue(moment().startOf('day'));
      // formRef?.current?.setFieldValue('deliveryDate', moment().add(1, 'days').startOf('day'));
    } else {
      setBlockTimes(allowBlockTimeRes?.data?.result);
    }
    setIsLoading(false)
  };
  const onDatePickerInputClick = () => {
    setCustomDatePickerVisible(true);
  };
  const onTimePickerInputClick = () => {
    setCustomTimePickerVisible(true);
  };
  const onCloseCustomDatePicker = () => {
    setCustomDatePickerVisible(false);
  };
  const onSubmitCustomDatePicker = (value: any) => {
    setCustomDatePickerVisible(false);
    setTimeout(() => {
      onChangeDatePickerValue(value);
    }, 500);
  };
  const onCloseCustomTimePicker = () => {
    setCustomTimePickerVisible(false);
  };
  const onSubmitCustomTimePicker = (value: any) => {
    setCustomTimePickerVisible(false);
  };
  const onChangeDatePickerValue = async (dateValue: Date) => {
    setIsLoading(true);
    const allowBlockTimeRes = await cartService.getAllowBlockOrderTime({
      restaurantCode: data?.restaurant?.id as any,
      orderTime: moment(dateValue).isAfter(moment(), 'day')
        ? '00:00:00'
        : (moment().format('HH:mm:ss') as any),
        brandId: data?.brandId,
        provinceId: data?.provinceId
    });
    setBlockTimes(allowBlockTimeRes?.data?.result);
    if ((allowBlockTimeRes?.data?.result as any)?.length) {
      formRef?.current?.setFieldValue('deliveryTime', (allowBlockTimeRes?.data?.result as any)[0]);
    }
    setIsLoading(false);
  };
  const onPickUpRestaurant = async (value: boolean) => {
    setIsLoading(true);
    formRef?.current?.setFieldValue('onPickUpRestaurant', value);
    const calculateResult = await orderService?.calculateOrderTotal(data?.orderId, {
      pickupAtRestaurant: value ? 1 : 0,
      recalculateShippingFee: true,
      shippingVendor: 'grab_express',
      paymentMethod: formRef?.current?.values?.paymentMethod,
    });
    setData({
      ...data,
      ...calculateResult?.data?.result,
    });
    setIsLoading(false);
  };
  const onFailedInvalidTimeClick = () => {
    getBlockTimes(data?.restaurant?.id, moment().format('HH:mm:ss'), data?.brandId, data?.provinceId)
    setNotificationModalVisible(false)
  }
  return (
    <div>
      <Formik
        innerRef={formRef}
        validateOnChange={false}
        // innerRef={formRef}
        initialValues={{
          // address: null,
          deliveryTime: '',
          deliveryDate: moment(),
          paymentMethod: '',
          note: '',
          onPickUpRestaurant: false,
          companyName: '',
          taxCode: '',
          invoiceAddress: '',
          invoiceEmail: '',
          haveBill: false,
        }}
        validationSchema={deliveryInfoSchema}
        onSubmit={(values) => {
          onRequestPayment(values);
        }}
      >
        {({ errors, touched, setFieldValue, values, handleSubmit, setValues, validateField }) => {
          return (
            <Fragment>
              {iframePaymentSrc ? (
                <IFramePayment src={iframePaymentSrc} />
              ) : (
                <Fragment>
                  <ModalSpinner visible={isLoading} />
                  <NotificationModal
                    visible={notificationModalVisible}
                    type={notificationType}
                    onSuccessButtonClick={onSuccessCartButtonClick}
                    onFailedButtonClick={onFailedCartButtonClick}
                    notificationMessage={notificationMessage}
                    onFailedInvalidTimeClick={onFailedInvalidTimeClick}
                  />
                  <VerifyPaymentModal
                    visible={verifyPaymentVisible}
                    type={currentMethod}
                    requestId={Number(id)}
                    qrCode={currentPaymentRequest?.response}
                    onCloseModal={() =>
                      setTimeout(() => {
                        setVerifyPaymentVisible(false);
                      }, 500)
                    }
                    setNotificationModalVisible={setNotificationModalVisible}
                    setNotificationType={setNotificationType}
                    notShowSuccessModelAfterClose
                  />
                  <RequestPaymentDesktop
                    data={data}
                    currentMethod={currentMethod}
                    onSelectPaymentMethodClick={onSelectPaymentMethodClick}
                    onRequestPayment={onRequestPayment}
                    setIsLoading={setIsLoading}
                    setData={setData}
                    customDatePickerVisible={customDatePickerVisible}
                    onCloseCustomDatePicker={onCloseCustomDatePicker}
                    onSubmitCustomDatePicker={onSubmitCustomDatePicker}
                    customTimePickerVisible={customTimePickerVisible}
                    onCloseCustomTimePicker={onCloseCustomTimePicker}
                    onSubmitCustomTimePicker={onSubmitCustomTimePicker}
                    blockTimes={blockTimes}
                    onDatePickerInputClick={onDatePickerInputClick}
                    onTimePickerInputClick={onTimePickerInputClick}
                    onPickUpRestaurant={onPickUpRestaurant}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                    validateField={validateField}
                    gBizAccount={gBizAccount}
                    disableGBizOption={disableGBizOption}
                    setDisableGBizOption={setDisableGBizOption}
                  />
                  <RequestPaymentMobile
                    data={data}
                    currentMethod={currentMethod}
                    onSelectPaymentMethodClick={onSelectPaymentMethodClick}
                    onRequestPayment={onRequestPayment}
                    setIsLoading={setIsLoading}
                    setData={setData}
                    customDatePickerVisible={customDatePickerVisible}
                    onCloseCustomDatePicker={onCloseCustomDatePicker}
                    onSubmitCustomDatePicker={onSubmitCustomDatePicker}
                    customTimePickerVisible={customTimePickerVisible}
                    onCloseCustomTimePicker={onCloseCustomTimePicker}
                    onSubmitCustomTimePicker={onSubmitCustomTimePicker}
                    blockTimes={blockTimes}
                    onDatePickerInputClick={onDatePickerInputClick}
                    onTimePickerInputClick={onTimePickerInputClick}
                    onPickUpRestaurant={onPickUpRestaurant}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                    validateField={validateField}
                    gBizAccount={gBizAccount}
                    disableGBizOption={disableGBizOption}
                    setDisableGBizOption={setDisableGBizOption}
                  />
                </Fragment>
              )}
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
};
const RequestPaymentScreen = React.memo(RequestPayment);
export { RequestPaymentScreen };
