import clsx from 'clsx';
import React from 'react';
import { AppRadioBox } from '../../../../components/radio';
import { formatCurrency } from '../../../../core/ultis/currency';
import { ModifierItemVM } from '../../models/modifier';
import { useModifierSelectorStyles } from './modifer-selector.style';

interface Props {
  title: string;
  items: ModifierItemVM[];
  selectedItemIds: string[];
  onChanged: (value: string) => void;
  type?: 'tag' | 'checkbox';
}

export const ModifierSelector = React.memo((props: Props) => {
  const classes = useModifierSelectorStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.title}</div>

      {(!props.type || props.type === 'tag') && (
        <div className={classes.tagModifierList}>
          {props.items.map((item) => (
            <div
              className={clsx(
                classes.tagModifierItem,
                props.selectedItemIds.some((id) => item.value.toString() === id.toString()) &&
                  classes.activeTagModifierItem,
              )}
              onClick={() => props.onChanged(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}

      {props.type === 'checkbox' && (
        <div className={classes.checkboxModifierList}>
          {props.items.map((item) => (
            <AppRadioBox
              isChecked={props.selectedItemIds.some((id) => item.value.toString() === id)}
              containerClassname={classes.checkboxContainer}
              onChanged={(_) => props.onChanged(item.value)}
            >
              <div className={classes.checkboxLabelContainer}>
                <span>{item.label}</span>
                {item.price && <span>{formatCurrency(item.price)}</span>}
              </div>
            </AppRadioBox>
          ))}
        </div>
      )}
    </div>
  );
});
