import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IBanner } from '../core/models/banner';

const getBanners = (params: { position: string; provinceId?: number; brandId?: string }) => {
  return axios.get<IApiResponse<IBanner[]>>('/home/banner', {
    params,
  });
};

export const bannerService = {
  getBanners,
};
