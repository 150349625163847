import React from "react";

interface Props {
  className?: string;
}

export const RadioCheckedIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#E96E34" />
      <rect x="4" y="10.5" width="2.12133" height="6.36398" rx="1.06066" transform="rotate(-45 4 10.5)" fill="white" />
      <rect x="14.5" y="6" width="2.12133" height="10.6066" rx="1.06066" transform="rotate(45 14.5 6)" fill="white" />
    </svg>
  );
});
