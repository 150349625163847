import React from "react";

interface Props {
  className?: string;
}

export const DocumentPrimaryIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z" fill="#E96E34" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08005 6.6499V6.6599C7.64905 6.6599 7.30005 7.0099 7.30005 7.4399C7.30005 7.8699 7.64905 8.2199 8.08005 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.08005ZM15.9201 12.7402H8.08005C7.64905 12.7402 7.30005 12.3902 7.30005 11.9602C7.30005 11.5302 7.64905 11.1792 8.08005 11.1792H15.9201C16.3501 11.1792 16.7001 11.5302 16.7001 11.9602C16.7001 12.3902 16.3501 12.7402 15.9201 12.7402ZM15.92 17.31H8.08002C7.78002 17.35 7.49002 17.2 7.33002 16.95C7.17002 16.69 7.17002 16.36 7.33002 16.11C7.49002 15.85 7.78002 15.71 8.08002 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
        fill="#E96E34"
      />
    </svg>
  );
});
