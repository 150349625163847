import { Formik } from 'formik';
import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { CartButton } from '../../../components/cart-button';
import { CartDivider } from '../../../components/cart-divider';
import { Header } from '../../../components/header';
import { CartTextInput } from '../../update-address/components/cart-text-input/cart-text-input.component';
import { useStyles } from './billing-info-mobile.style';
interface Props {
  setShowBillingInfo: (value: boolean) => void;
  setFieldValue: any;
  validateField: any;
  values: any;
  errors: any;
  onCloseBillingInfo: () => void;
  onSubmitBillingInfo: () => void;
  isDisableBill?: boolean;
}
const Billing = React.memo((props: Props) => {
  const classes = useStyles();
  const { setShowBillingInfo, setFieldValue, validateField, values, errors, onCloseBillingInfo, onSubmitBillingInfo, isDisableBill } = props;
  const formRef = useRef(null) as any;
  return (
    <div>
      <Header title="Thông tin nhận hóa đơn" onBackClick={onCloseBillingInfo} />
      <CartDivider />
      <Fragment>
        <div className={classes.container}>
          <div className={classes.inputTitle}>
            Mã số thuế <span className={classes.colorRed}>*</span>
          </div>
          <CartTextInput
            // placeholder={'1234567890'}
            onChange={(value: any) => {
              setFieldValue('taxCode', value);
              validateField('taxCode');
            }}
            value={values?.taxCode ?? ''}
            error={errors.taxCode}
            disabled={isDisableBill}
          />
          <div className={classes.inputTitle}>
            Tên tổ chức cá nhân nộp thuế <span className={classes.colorRed}>*</span>
          </div>
          <CartTextInput
            // placeholder={'Công ty TNHH ABC'}
            onChange={(value: any) => {
              setFieldValue('companyName', value);
              validateField('companyName');
            }}
            value={values?.companyName ?? ''}
            error={errors.companyName}
            disabled={isDisableBill}
          />
          <div className={classes.inputTitle}>
            Địa chỉ <span className={classes.colorRed}>*</span>
          </div>
          <CartTextInput
            // placeholder={'315 Trường Chinh'}
            onChange={(value: any) => {
              setFieldValue('invoiceAddress', value);
              validateField('invoiceAddress');
            }}
            value={values?.invoiceAddress ?? ''}
            error={errors.invoiceAddress}
            disabled={isDisableBill}
          />
          <div className={classes.inputTitle}>
            Email nhận hóa đơn điện tử <span className={classes.colorRed}>*</span>
          </div>
          <CartTextInput
            // placeholder={'abc@gmail.com'}
            onChange={(value: any) => {
              setFieldValue('invoiceEmail', value);
              validateField('invoiceEmail');
            }}
            value={values?.invoiceEmail ?? ''}
            error={errors.invoiceEmail}
            disabled={isDisableBill}
          />
        </div>
        {isDisableBill || (
            <div className={classes.updateButtonContainer}>
                <CartButton text="Cập nhật" type="submit" onClick={onSubmitBillingInfo} />
            </div>
        )}
      </Fragment>
    </div>
  );
});

const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({});

const BillingInfoMobile = React.memo(connect(mapState, mapDispatch)(Billing));
export { BillingInfoMobile };
