import React from "react";
interface Props {
  className?: any;
  onClick?: () => void;
}
export const NextIcon = React.memo((props: Props) => {
  const { onClick } = props;

  return (
    <svg
      onClick={onClick}
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.737055 0.111134L0.111111 0.737047C-0.0370369 0.885194 -0.0370369 1.1254 0.111111 1.27358L5.8239 7L0.111111 12.7264C-0.0370369 12.8746 -0.0370369 13.1148 0.111111 13.2629L0.737055 13.8889C0.885202 14.037 1.12541 14.037 1.27359 13.8889L7.8942 7.26828C8.04235 7.12013 8.04235 6.87992 7.8942 6.73174L1.27359 0.111134C1.12541 -0.0370448 0.885202 -0.0370448 0.737055 0.111134Z"
        fill="black"
      />
    </svg>
  );
});
