import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const FavoriteIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7648 21.358C9.47929 19.9463 7.35467 18.2818 5.42984 16.3947C4.07097 15.0474 3.0332 13.4024 2.3958 11.5852C1.25693 8.04127 2.58265 3.99111 6.28399 2.78871C8.23763 2.17518 10.3624 2.55181 11.9962 3.80125C13.6305 2.55321 15.7546 2.1767 17.7083 2.78871C21.4097 3.99111 22.7443 8.04127 21.6054 11.5852C20.9732 13.4006 19.9416 15.0456 18.5892 16.3947C16.6626 18.2797 14.5382 19.9441 12.2542 21.358L12.0051 21.5207L11.7648 21.358Z"
        fill={color || "white"}
      />
    </svg>
  );
});
