import { createModel } from '@rematch/core';
import { cloneDeep, remove, uniq } from 'lodash';
import { RootModel } from '.';
import { IProduct } from '../../core/models/product';
import { favoriteService } from '../../services/favorite-service';

export interface IProductStore {
  favorite: {
    byId: { [key: string]: IProduct };
    allIds: string[];
  };
}

const initialState: IProductStore = {
  favorite: {
    byId: {},
    allIds: [],
  },
};

export const product = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    reset(_, __) {
      return cloneDeep(initialState);
    },
    getFavoriteProductsSuccess(state, payload: { products: IProduct[] }) {
      return {
        ...state,
        favorite: {
          byId: payload.products.reduce((acc, product) => ({ ...acc, [product.id]: product }), {}),
          allIds: payload.products.map((p) => p.id),
        },
      };
    },
    addFavoriteSuccess(state, payload: { product: IProduct }) {
      return {
        ...state,
        favorite: {
          byId: {
            ...state.favorite.byId,
            [payload.product.id]: payload.product,
          },
          allIds: uniq([...state.favorite.allIds, payload.product.id]),
        },
      };
    },
    deleteFavoriteSuccess(state, payload: { product: IProduct }) {
      const {
        [payload.product.id]: {},
        ...rest
      } = state.favorite.byId;
      return {
        ...state,
        favorite: {
          byId: rest,
          allIds: remove(state.favorite.allIds, (id) => id !== payload.product.id),
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getFavoriteProducts(_, state) {
      try {
        const response = await favoriteService.getProducts({ perPage: 1000000 });
        dispatch.product.getFavoriteProductsSuccess({ products: response?.data?.result || [] });
      } catch (e) {}
    },
    async addFavorite(payload: { product: IProduct }, state) {
      const response = await favoriteService.addProduct(
        payload.product.parentId || payload.product.id,
      );
      if (response.data.messageCode === 200) {
        dispatch.product.addFavoriteSuccess({ product: payload.product });
      }
    },
    async deleteFavorite(payload: { product: IProduct }, state) {
      const response = await favoriteService.deleteProduct(
        payload.product.parentId || payload.product.id,
      );
      if (response.data.messageCode === 200) {
        dispatch.product.deleteFavoriteSuccess({ product: payload.product });
      }
    },
  }),
});
