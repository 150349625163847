import React from "react";

interface Props {
  className?: string;
}

export const CartCloseIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="15.1851" y="3.3335" width="2.0951" height="16.7608" rx="1.04755" transform="rotate(45 15.1851 3.3335)" fill="#C0C0C0"/>
      <rect x="16.6672" y="15.185" width="2.0951" height="16.7608" rx="1.04755" transform="rotate(135 16.6672 15.185)" fill="#C0C0C0"/>
      </svg>
    );
  }
);
