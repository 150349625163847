import React from "react";

interface Props {
  className?: string;
}

export const SearchPrimaryIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.4" cx="10.5992" cy="10.6532" rx="8.59922" ry="8.65324" fill="#E96E34" />
      <path
        d="M14.1269 6.59486C13.9682 6.71865 13.7616 6.76791 13.5557 6.7311C13.3498 6.69428 13.1626 6.57462 13.0379 6.40015C12.5875 5.86079 11.864 5.64852 11.2382 5.87209C10.8752 6.05202 10.4172 5.89295 10.2129 5.51606C10.0094 5.14751 10.1271 4.70117 10.4774 4.51346C10.5193 4.48708 10.5661 4.46909 10.6156 4.46039C11.8551 3.99477 13.3033 4.41971 14.1855 5.50784C14.3249 5.66769 14.3974 5.8737 14.3863 6.07865C14.3753 6.28359 14.2816 6.4699 14.1269 6.59486Z"
        fill="#E96E34"
      />
      <path
        d="M20.6747 21.9553C20.3406 21.9445 20.023 21.807 19.7854 21.5705L17.749 19.1902C17.3124 18.7909 17.2767 18.1123 17.6689 17.6689C17.8525 17.4831 18.1022 17.3787 18.3626 17.3787C18.6229 17.3787 18.8726 17.4831 19.0562 17.6689L21.6173 19.7181C21.9862 20.0957 22.1 20.6563 21.9079 21.1492C21.7158 21.6422 21.2536 21.9754 20.728 22L20.6747 21.9553Z"
        fill="#E96E34"
      />
    </svg>
  );
});
