import { colors } from './../../../core/contants/colors';
import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../core/contants/break-points';

export const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '430px',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: '0px',
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      width: '90vw',
      minHeight: '100%',
    },
  },
  title: {
    color: '#221F20',
    fontSize: 16,
    fontWeight: 700,
    marginTop: 25,
  },
  step: {
    color: '#221F20',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 12,
  },
  stepTitle: {
    color: '#221F20',
    fontSize: 14,
    fontWeight: 700,
    marginTop: 5,
  },
  note: {
    color: colors.primaryColor,
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'italic',
    // flex: 1,
  },
  noteContainer: {
    display: 'flex',
    width: '100%',
    marginTop: 20,
    gap: 6,
    marginBottom: 24,
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      marginBottom: 24,
    },
  },
  button: {
    width: '100%',
    marginTop: 20,
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
    color: '#221F20',
    textAlign: 'center',
    padding: '20px 16px',
    borderBottom: '1px solid #DFE2E6',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  qrCodeContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  contentContainer: {
    padding: '0px 16px',
  },
  qrCodeIcon: {
    width: "50%",
    height: "50%",
    [`@media (max-width: ${BREAK_POINTS.SM}px)`]: {
      width: "50%",
      height: "50%",
    },
  },
  qrCode: {
    marginLeft: 3,
    display: 'inherit'
  }
});
