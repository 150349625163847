import React from 'react';

interface Props {
  className?: string;
  color?: string;
}

export const CheckOutlinedIcon = React.memo(
  ({ className, color }: React.PropsWithChildren<Props>) => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.16522 12.5433C5.80123 12.5433 5.45188 12.3988 5.19397 12.1409L1.73335 8.67666C1.19926 8.14074 1.19926 7.27009 1.73518 6.736C2.26927 6.20008 3.13991 6.20008 3.67584 6.736L6.16522 9.22904L12.3256 3.06869C12.8615 2.53277 13.7285 2.53277 14.2644 3.06869C14.8003 3.60461 14.8003 4.47343 14.2644 5.00935L7.13463 12.1409C6.87856 12.3988 6.52921 12.5433 6.16522 12.5433Z"
          fill={color || 'white'}
        />
      </svg>
    );
  },
);
