import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CouponDetail } from '../../components/coupon-detail/coupon-detail.component';
import { ModalSpinner } from '../../components/modal-spinner';
import { paymentMethodValues } from '../../core/contants/paymentMethodValues';
import { IVoucher } from '../../core/models/ticket';
import { cartService } from '../../services/cart-service';
import { orderService } from '../../services/order-service';
import { voucherService } from '../../services/voucher-service';
import { Dispatch, RootState } from '../../store';
import { DiscountFoodModal } from '../verify-order/components/discount-food-modal/discount-food-modal.component';
import { DiscountGroupModal } from '../verify-order/components/discount-group-modal/discount-group-modal.component';
import { BillingInfo } from './billing-info/billing-info.component';
import { IFramePayment } from './iframe-payment/iframe-payment.component';
import { NotificationModal } from './notification-modal/notification-modal.component';
import { QrScanModal } from './qr-scan-modal/qrScanModal.component';
import { VerifyInfo } from './verify-info/verify-info.component';
import { VerifyPaymentModal } from './verify-payment-modal/verify-payment-modal.component';
import { useStyles } from './verify-payment.style';
import { NetCore } from "../../assets/netcore/netcore";
import {userService} from "../../services/user-service";
import {formatCurrency} from "../../core/ultis/currency";
import {IGBizAccount} from "../../core/models/user";
interface Props {
  cartState: any;
}
const VerifyPayment = (props: Props): JSX.Element => {
  const classes = useStyles();
  const appContext = useSelector((state: RootState) => state.appContext);
  const cart = useSelector((state: RootState) => state.cart);
  const [cookies, setCookies, removeCookies] = useCookies([
    'traffic-id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_location',
    'utm_term'
  ]);

  const { cartState } = props;

  const [currentStage, setCurrentStage] = useState('verify');
  const [cartData, setCartData] = useState({} as any);
  const [voucherData, setVoucherData] = useState([] as any);
  const [myVoucherData, setMyVoucherData] = useState([] as any);
  const [billingData, setBillingData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMethod, setCurrentMethod] = useState('');
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [orderData, setOrderData] = useState({} as any);
  const [verifyPaymentVisible, setVerifyPaymentVisible] = useState(false);
  const [currentPaymentRequest, setCurrentPaymentRequest] = useState({} as any);
  const [selectedVoucher, setSelectedVoucher] = useState({}) as any;
  const [voucherModalType, setVoucherModalType] = useState<string>();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [iframePaymentSrc, setIframePaymentSrc] = useState('');
  const [currentVoucherDetail, setCurrentVoucherDetail] = useState({} as any);
  const [voucherDetailVisible, setVoucherDetailVisible] = useState(false);
  const [disableGBizOption, setDisableGBizOption] = useState(false);
  const [gBizAccount, setGBizAccount] = useState<IGBizAccount>();
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const [isVisibleChooseVoucher, setIsVisibleChooseVoucher] = useState(true);

  async function getBizAcc() {
    const getBizAcc = await userService.gBizAccountInfo();
    if (getBizAcc?.data?.messageCode === 200) {
      setGBizAccount(getBizAcc?.data?.result);
    }
  }

  useEffect(() => {
    getData();
    getBizAcc();

    // getVoucherData();
  }, []);
  useEffect(() => {
    if (!couponModalVisible) {
      setTimeout(() => {
        document.body.style.setProperty('overflow', 'unset', 'important');
      }, 500);
    }
  }, [couponModalVisible]);

  useEffect(() => {
    if (gBizAccount) {
      const balanceAvail = gBizAccount.balance;
      if (balanceAvail < Math.round(cartData?.totals?.totalPaySum ?? 0) || cartData?.selectedVouchers?.length > 0) {
        setDisableGBizOption(true);
        if (currentMethod == paymentMethodValues.G_BUSINESS) {
          setCurrentMethod('');
          setIsVisibleChooseVoucher(true);
          dispatch.cart.setCartData({
            hasInvoice: 0,
            invoiceName: '',
            invoiceNumber: '',
            invoiceAddress: '',
            invoiceEmail: '',
          });
        }
      } else {
        setDisableGBizOption(false);
      }
    }
  }, [cartData]);

  const getData = async () => {
    setIsLoading(true);
    const cartRes = await dispatch.cart.getCartDetail(
      currentMethod ? { paymentMethod: currentMethod } : {},
    );
    setCartData(cartRes?.data?.result);
    let data = {
      brandName: appContext.selectedBrand?.name || '',
      restaurantName: '',
      restaurantTelephone: '',
      orderTotal: cartRes?.data?.result?.totals?.totalPriceWithoutShipping || 0,
    };
    NetCore.ncCheckout(data);
    setIsLoading(false);
  };
  const getVoucherData = async () => {
    setIsLoading(true);
    const voucherRes = await voucherService.getVouchersList();
    let mappedArray = [] as any;
    voucherRes?.data?.result?.forEach((value: any) => {
      value?.gifts.forEach((giftValue: any) => {
        delete value['gifts'];
        mappedArray.push({
          ...value,
          giftValue: giftValue,
        });
      });
    });
    setVoucherData(mappedArray);
    const myVoucherRes = await voucherService.getSavedVouchers();
    setMyVoucherData(myVoucherRes?.data?.result);
    setIsLoading(false);
  };

  const onApplyCoupon = async (voucher: IVoucher) => {
    try {
      setIsLoading(true);
      const result = await voucherService.applyVoucher({
        voucherCode: voucher.seriNo,
        partnerId: 0,
      });
      if (result?.data?.messageCode === 200) {
        setDisableGBizOption(true);
        NetCore.ncVoucherUsage({
          campaignCode: result?.data?.result?.code,
        });
        setCouponModalVisible(false);
        const cartRes = await dispatch.cart.getCartDetail(
          currentMethod ? { paymentMethod: currentMethod } : {},
        );
        setCartData(cartRes?.data?.result);
        if (cartRes?.data?.result) {
          dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
        }
        checkAffiliate(result.data);
      } else {
        dispatch.appContext.setError(result?.data?.message || '');
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onApplyVoucherClick = async (value: any) => {
    try {
      setIsLoading(true);
      if (value?.giftValue?.seriNo) {
        const result = await voucherService.applyVoucher({
          voucherCode: value?.giftValue?.seriNo,
          partnerId: value?.giftValue?.partnerId,
        });
        if (result?.data?.messageCode === 200) {
          setDisableGBizOption(true);
          NetCore.ncVoucherUsage({
            campaignCode: result?.data?.result?.code,
          });
          setCouponModalVisible(false);
          const cartRes = await dispatch.cart.getCartDetail(
            currentMethod ? { paymentMethod: currentMethod } : {},
          );
          setCartData(cartRes?.data?.result);
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          checkAffiliate(result.data);
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };
  const onCancelCoupon = async (value: any) => {
    setIsLoading(true);
    if (value?.giftValue?.seriNo || value?.code) {
      const result = await voucherService.removeVoucher(value?.giftValue?.seriNo || value?.code);
      const cartRes = await dispatch.cart.getCartDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      setCouponModalVisible(false);
    }
    setIsLoading(false);
  };
  const onClickBillingText = () => {
    setCurrentStage('billing');
  };
  const onReturnBilling = () => {
    setCurrentStage('verify');
  };
  const onUpdateBillingClick = (value: any) => {
    setBillingData(value);
    dispatch.cart.setCartData({
      hasInvoice: 1,
      invoiceName: value?.companyName,
      invoiceNumber: value?.taxCode,
      invoiceAddress: value?.address,
      invoiceEmail: value?.email,
    });
    setCurrentStage('verify');
  };
  const onQrCodeClick = () => {
    setCurrentStage('scan');
  };
  const onSubmitClick = async () => {
    setIsLoading(true);
    const result = await orderService.createOrder({
      ...cartState?.cartData,
      deliveryDate: moment(cartState?.cartData?.deliveryDate).format('DD/MM/YYYY'),
      paymentMethod: currentMethod,
      mo_traffic_id: cookies['traffic-id'],
      utm_source: cookies['utm_source'],
      utm_medium: cookies['utm_medium'],
      utm_campaign: cookies['utm_campaign'],
      utm_content: cookies['utm_content'],
      utm_location: cookies['utm_location'],
      utm_term: cookies['utm_term'],
    });
    if (result.data.messageCode === 200) {
      let voucherCode = cartState?.selectedVouchers
              .map(e => e.code)
              .join(),
          campaignCode = cartState?.selectedVouchers
              .map(e => e.campaign.id)
              .filter((value, index, self) => self.indexOf(value) === index)
              .join();

      let userInfo = {
        mobile: user.profile.cellphone,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        userLocation: cartData.deliveryAddress,
        companyName: cartData.invoiceName,
        companyAddress: cartData.invoiceAddress,
        companyEmail: cartData.invoiceEmail,
        phoneNumber: user.profile.cellphone,
        email: user.profile.email,
      };
      NetCore.ncContact(userInfo);
      let dataPurchase = {
        orderTotal: result?.data?.result.totals.totalPriceWithoutShipping,
        orderTotal2: result?.data?.result.totals.totalPaySum,
        isVoucherUse: voucherCode !== '',
        voucherCode: voucherCode,
        pickupTime: moment(cartData.deliveryDate).format('DD/MM/YYYY') + ' ' + cartData.deliveryTime,
        pickupAtStore: cartData?.pickupAtRestaurant || false,
        paymentMethod: currentMethod,
        campaignCode: campaignCode,
        brandName: appContext.selectedBrand?.name || '',
        restaurantName: cart.cartData?.selectedRestaurant?.restaurant?.name || '',
        restaurantTelephone: cart.cartData?.selectedRestaurant?.restaurant?.telephone || '',
        restaurantAddress: cart.cartData?.selectedRestaurant?.restaurant?.address || '',
        customerAddress: cartData?.customerAddress?.address,
        orderStatus: '',
      };
      NetCore.ncProductPurchase(dataPurchase);
      setOrderData(result.data.result);

      if (currentMethod == 'COD') {
        let dataOrderSuccess = {
          orderTotal: result?.data?.result.totals.totalPriceWithoutShipping,
          orderTotal2: result?.data?.result.totals.totalPaySum,
          isVoucherUse: voucherCode !== '',
          voucherCode: voucherCode,
          pickupTime: moment(cartData.deliveryDate).format('DD/MM/YYYY') + ' ' + cartData.deliveryTime,
          voucherValue: result?.data?.result.totals.totalCashVoucher + result?.data?.result.totals.totalDiscount,
          orderCode: result?.data?.result.id,
        };
        NetCore.ncOrderSuccess(dataOrderSuccess);
      }

      if (cookies['traffic-id']) {
        removeCookies('traffic-id');
      }
      if (cookies['utm_source']) {
        removeCookies('utm_source');
      }
      if (cookies['utm_medium']) {
        removeCookies('utm_medium');
      }
      if (cookies['utm_campaign']) {
        removeCookies('utm_campaign');
      }
      if (cookies['utm_content']) {
        removeCookies('utm_content');
      }
      if (cookies['utm_location']) {
        removeCookies('utm_location');
      }
      if (cookies['utm_term']) {
        removeCookies('utm_term');
      }
      switch (currentMethod) {
        case paymentMethodValues.COD:
          setNotificationModalVisible(true);
          setNotificationType('success-tracking');
          break;
        case paymentMethodValues.G_BUSINESS:
          let bizAcc = await userService.gBizAccountInfo();
          let messageNotice = result?.data?.message ?? '';
          if (bizAcc?.data?.messageCode === 200) {
            messageNotice += `<div style="margin-top: 20px; color: #1A1A1A; font-weight: 700;">`;
            messageNotice += `<div>Đã thanh toán: ${formatCurrency(result?.data?.result.totals.totalPaySum)}</div>`;
            messageNotice += `<div>Hạn mức còn lại: ${formatCurrency(bizAcc?.data?.result?.balance || 0)}</div>`;
            messageNotice += `</div>`
          }
          setNotificationModalVisible(true);
          setNotificationType('success-tracking');
          setNotificationMessage(messageNotice);
          break;
        case paymentMethodValues.ZALOPAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.VNPAY_BANK_ONLINE:
          if (result?.data?.result?.requestPayment?.result?.response) {
            window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPAY_BANK_ONLINE_INTERNATIONAL_CARD:
          if (result?.data?.result?.requestPayment?.result?.response) {
            window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPT_EPAY_BANK_ONLINE:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setIframePaymentSrc(result?.data?.result?.requestPayment?.result?.response);
            // window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.VNPAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.VINID:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
            // window.location.href = result?.data?.result?.requestPayment?.result?.response;
          }
          break;
        case paymentMethodValues.SHOPEE_PAY:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        case paymentMethodValues.MOMO:
          if (result?.data?.result?.requestPayment?.result?.response) {
            setCurrentPaymentRequest(result?.data?.result?.requestPayment);
            setVerifyPaymentVisible(true);
          }
          break;
        default:
        // code block
      }
    } else if (result.data.messageCode === 301) {
      setNotificationModalVisible(true);
      setNotificationType('invalid-time');
      setNotificationMessage(result?.data?.message ?? '');
    } else if (result.data.messageCode === 406) {
      setNotificationModalVisible(true);
      setNotificationType('redirect-cart');
      setNotificationMessage(
          Object.values((result?.data?.result as any) ?? {})?.[0] ??
          result?.data?.message ??
          ('' as any),
      );
    } else if (result.data.messageCode === 419) {
      setNotificationModalVisible(true);
      setNotificationType('gbiz-failed');
      setOrderData(result.data.result);
      setNotificationMessage(result?.data?.message ?? ('' as any));
    } else {
      setNotificationModalVisible(true);
      setNotificationType('failed');
      setNotificationMessage(result?.data?.message ?? '' as any);
    }

    setIsLoading(false);
  };
  const onSuccessCartButtonClick = (toTrackingScreen?: any) => {
    dispatch.cart.reset();
    if (toTrackingScreen == 're-pay') {
      history.push(`/request-payment/${orderData.id}`);
    } else if (toTrackingScreen) {
      history.push(`/order/${orderData.id}/tracking`);
    } else {
      history.push(`/order/${orderData.id}`);
    }
  };

  const onFailedCartButtonClick = () => {
    setNotificationModalVisible(false);
  };
  const onSubmitCodeInput = async (codeInput: any) => {
    try {
      setIsLoading(true);
      if (codeInput) {
        const result = await voucherService.applyVoucher({
          voucherCode: codeInput,
          // partnerId: 0,
        });
        if (result?.data?.messageCode === 200) {
          setDisableGBizOption(true);
          NetCore.ncVoucherUsage({
            campaignCode: result?.data?.result?.code,
          });
          const cartRes = await dispatch.cart.getCartDetail(
            currentMethod ? { paymentMethod: currentMethod } : {},
          );
          setCartData(cartRes?.data?.result);
          setCouponModalVisible(false);
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          checkAffiliate(result.data);
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onPickItemForVoucher = async (item: any) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      productId: item.wooId,
    });
    if (result?.data?.messageCode === 200) {
      NetCore.ncVoucherUsage({
        campaignCode: result?.data?.result?.code,
      });
      const cartRes = await dispatch.cart.getCartDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      checkAffiliate(result.data);
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onApplyBuyAGetBVoucher = async (value) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      ...value,
    });
    if (result?.data?.messageCode === 200) {
      NetCore.ncVoucherUsage({
        campaignCode: result?.data?.result?.code,
      });
      const cartRes = await dispatch.cart.getCartDetail(
        currentMethod ? { paymentMethod: currentMethod } : {},
      );
      setCartData(cartRes?.data?.result);
      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      checkAffiliate(result.data);
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onScanModalClose = () => {
    setCurrentStage('verify');
  };

  const renderStage = () => {
    switch (currentStage) {
      case 'verify':
        return (
          <VerifyInfo
            onClickBillingText={onClickBillingText}
            onQrCodeClick={onQrCodeClick}
            cartData={cartData}
            setCartData={setCartData}
            voucherData={voucherData}
            myVoucherData={myVoucherData}
            currentMethod={currentMethod}
            setCurrentMethod={setCurrentMethod}
            onSubmitClick={onSubmitClick}
            setIsLoading={setIsLoading}
            onApplyVoucherClick={onApplyVoucherClick}
            couponModalVisible={couponModalVisible}
            setCouponModalVisible={setCouponModalVisible}
            onCancelCoupon={onCancelCoupon}
            onSubmitCodeInput={onSubmitCodeInput}
            cartState={cartState}
            onApplyCoupon={onApplyCoupon}
            getVoucherData={getVoucherData}
            disableGBizOption={disableGBizOption}
            setDisableGBizOption={setDisableGBizOption}
            gBizAccount={gBizAccount}
            isVisibleChooseVoucher={isVisibleChooseVoucher}
            setIsVisibleChooseVoucher={setIsVisibleChooseVoucher}
          />
        );
      case 'billing':
        return (
          <BillingInfo
            onReturnBilling={onReturnBilling}
            onUpdateBillingClick={onUpdateBillingClick}
            currentMethod={currentMethod}
          />
        );
      case 'scan':
        return (
          <QrScanModal onScanQrCodeSuccess={onScanQrCodeSuccess} onModalClose={onScanModalClose} />
        );
    }
  };
  const onFailedInvalidTimeClick = () => {
    history.push(`/verify-order`);
    setNotificationModalVisible(false);
  };
  const onApplyDetailCoupon = (value: any) => {
    setVoucherDetailVisible(false);
    setCurrentStage('verify');
    setCouponModalVisible(false);
    onApplyCoupon(value);
  };
  const onScanQrCodeSuccess = async (data: any) => {
    if (!isLoading && !voucherDetailVisible ) {
      setIsLoading(true);
      const result = await voucherService.voucherCheck({ voucherCode: data });
      setIsLoading(false);
      if (result?.data?.messageCode === 200) {
        setCurrentVoucherDetail({seriNo: data , promotion: { 
          title: result?.data?.result?.name,
           endTime: result?.data?.result?.expiryTime, 
           description: result?.data?.result?.descriptionCondition } });
    
        setTimeout(() => {
          setVoucherDetailVisible(true);
        }, 300);
      } else {
        dispatch.appContext.setError(result?.data?.message || '');
      }
    }
 
  };

  const checkAffiliate = (dataObj) => {
    if (dataObj.result.denominationValue == 0 && dataObj.result.partnerId == 999) {
      dispatch.appContext.setSuccess(dataObj.message);
      setTimeout(function () {
        dispatch.appContext.setSuccess(null);
      }, 3000);
    }
  }

  return (
    <div>
      {iframePaymentSrc ? (
        <IFramePayment src={iframePaymentSrc} />
      ) : (
        <Fragment>
          <CouponDetail
            isVisible={voucherDetailVisible}
            coupon={currentVoucherDetail}
            onDismiss={() => setVoucherDetailVisible(false)}
            onApply={onApplyDetailCoupon}
            hideDiscountIcon
          />
          <NotificationModal
            visible={notificationModalVisible}
            type={notificationType}
            onSuccessButtonClick={onSuccessCartButtonClick}
            onFailedButtonClick={onFailedCartButtonClick}
            notificationMessage={notificationMessage}
            onFailedInvalidTimeClick={onFailedInvalidTimeClick}
          />
          <VerifyPaymentModal
            visible={verifyPaymentVisible}
            type={currentMethod}
            requestId={orderData?.id}
            qrCode={currentPaymentRequest?.result?.response}
            onCloseModal={() => setVerifyPaymentVisible(false)}
            setNotificationModalVisible={setNotificationModalVisible}
            setNotificationType={setNotificationType}
          />
          <ModalSpinner visible={isLoading} />

          <DiscountFoodModal
            isVisible={
              (selectedVoucher?.applyForItems || []).length > 0 &&
              voucherModalType === 'pick-product'
            }
            items={selectedVoucher?.applyForItems || []}
            onClickItem={onPickItemForVoucher}
            onClose={() => setSelectedVoucher({})}
            denominationValue={selectedVoucher?.denominationValue}
            type={selectedVoucher?.type}
          />
          <DiscountGroupModal
            isVisible={
              (selectedVoucher?.applyForItems || []).length > 0 &&
              voucherModalType === 'buy-a-get-b'
            }
            items={selectedVoucher?.applyForItems || []}
            isGetAllProductDiscount={selectedVoucher.isGetAllProductDiscount}
            isParallelCondition={selectedVoucher.isParallelCondition}
            // onClickItem={onPickItemForVoucher}
            onClose={() => setSelectedVoucher({})}
            onApply={onApplyBuyAGetBVoucher}
          />

          {renderStage()}
        </Fragment>
      )}
    </div>
  );
};
const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({});

const VerifyPaymentScreen = React.memo(connect(mapState, mapDispatch)(VerifyPayment));
export { VerifyPaymentScreen };
