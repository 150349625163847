import React from "react";
import { Link, RouterProps } from "react-router-dom";
import { BrandItem } from "../../components/brand-item";
import { Container } from "../../components/container";
import { Header } from "../../components/header";
import { useBrandListStyles } from "./brand-list.style";

interface Props extends RouterProps {}

export const BrandListScreen = React.memo((props: Props) => {
  const classes = useBrandListStyles();
  return (
    <Container>
      <Header title="Danh sách thương hiệu" />

      <div className={classes.contentContainer}>
        <Link to="/brand/12" className={classes.brandContainer}>
          <BrandItem className={classes.brand} />
        </Link>
        <Link to="/brand/12" className={classes.brandContainer}>
          <BrandItem className={classes.brand} />
        </Link>
        <Link to="/brand/12" className={classes.brandContainer}>
          <BrandItem className={classes.brand} />
        </Link>
        <Link to="/brand/12" className={classes.brandContainer}>
          <BrandItem className={classes.brand} />
        </Link>
        <Link to="/brand/12" className={classes.brandContainer}>
          <BrandItem className={classes.brand} />
        </Link>
      </div>
    </Container>
  );
});
