import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useQuantityControlStyles } from "./quantity-control.style";

interface Props {
  onIncrease: (e) => void;
  onDecrease: (e) => void;
  value: number;
}

export const QuantityControl = React.memo((props: Props) => {
  const classes = useQuantityControlStyles();
  return (
    <div className={classes.quantityEditorContainer}>
      <div className={classes.minusIconContainer} onClick={props.onDecrease}>
        <MinusOutlined className={classes.minusIcon} />
      </div>
      <div className={classes.quantityText}>{props.value}</div>
      <div className={classes.plusIconContainer} onClick={props.onIncrease}>
        <PlusOutlined className={classes.plusIcon} />
      </div>
    </div>
  );
});
