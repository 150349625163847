import React from 'react';
import { useStyles } from './iframe-payment.styles';
interface Props {
  src: string;
}
const IFramePaymentComponent = (props: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div style={{ minWidth: '100%', minHeight: '100%' }}>
      <iframe style={{
        display: 'block',
        width: '100%',
        border: 'none',
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '100vh'
      }} src={props.src} height="100%" width="100%" />
    </div>
  );
};
const IFramePayment = React.memo(
  (IFramePaymentComponent)
);
export { IFramePayment };