import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useAppLayoutStyles } from '../core/styles/layout';

interface Props {
  className?: string;
  id?: string;
  backgroundColor?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export const Container = React.memo((props: React.PropsWithChildren<Props>) => {
  const classes = { ...useStyles(), ...useAppLayoutStyles() };
  return (
    <div
      id={props.id}
      className={clsx(classes.container)}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className={clsx(classes.appContentContainer, props.className)}>{props.children}</div>
    </div>
  );
});
