import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';

export const useBrandDetailStyles = makeStyles({
  container: {
    backgroundColor: '#fff',
    position: 'relative',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'grid !important',
      gridTemplateColumns: '1fr 2fr',
      gridGap: 10,
      // paddingTop: 40,
      backgroundColor: '#F1F1F1',
      // gridTemplateRows: 'mansonry',
      paddingBottom: 30,
    },
  },

  header: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },

  titleText: {
    fontWeight: 700,
    color: '#161616',
    fontSize: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 24,
      lineHeight: '36px',
    },
  },

  contentContainer: {
    // overflow: 'hidden',
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      paddingTop: 44,
      maxWidth: 'calc(100vw * 2 / 3)',
    },
    [`@media (min-width: ${BREAK_POINTS.LG}px)`]: {
      maxWidth: 'calc(1144px * 2 / 3)',
    },
  },

  couponContainer: {
    margin: 16,
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: "none",
    },
  },
});
