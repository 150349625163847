import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useBrandGeneralInfoStyle = makeStyles({
  container: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      position: 'sticky',
      top: 64,
      alignSelf: 'start',
    },
  },
  firstBlock: {
    backgroundColor: '#fff',
  },
  couponContainer: {
    display: 'none',
    marginTop: 20,
    padding: '4px 16px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'block',
    },
  },
  logoContainer: {
    backgroundColor: '#F5F5F8',
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 48,
      width: 48,
      padding: 4,
    },
  },
  boldText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 14,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 16,
    },
  },
  addressText: {
    color: colors.textSecondaryColor,
    lineHeight: '20px',
    fontSize: 16,
    flex: 1,
    marginRight: 12,
  },

  locationInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 6,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 0,
    },
  },
  distanceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  openHourContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  openHourText: {
    color: colors.primaryColor,
    fontWeight: 500,
    marginTop: 3,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      color: colors.textSecondaryColor,
      fontWeight: 400,
    },
  },
  distanceText: {
    color: colors.accentColor,
    fontWeight: 500,
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 4,
  },
  arrowIcon: {
    color: '#7A7A7A',
    marginTop: 2,
  },
  addressContainer: {
    display: 'flex',
    marginTop: 5,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'none',
    },
  },
  namContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  brandInfoContainer: {
    padding: '16px 0px',
    margin: '0px 16px',
    display: 'grid',
    gridGap: 12,
    gridTemplateColumns: 'auto 1fr auto',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      // borderTop: '1px dashed #E4E4E4',
      borderBottom: '1px dashed #E4E4E4',
    },
  },
  backContainer: {
    display: 'none',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: 'auto 1fr',
      padding: '12px 0px',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  backIcon: {
    height: 20,
    width: 20,
  },
  backText: {
    color: '#6B6B6B',
    fontWeight: 500,
    // lineHeight: '17px',
  },
  tabContainer: {
    display: 'none',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'block',
      padding: '8px 0px 12px 0px',
    },
  },
  tabItem: {
    color: '#000',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '4px 16px',
    cursor: 'pointer',

    ':hover': {
      color: colors.primaryColor,
    },
  },
  tabItemActive: {
    color: colors.primaryColor,
    fontWeight: 700,

    '& img': {
      filter: `grayscale(0%) !important`,
    },

    '& div': {
      display: 'block',
    },
  },
  inkBar: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0px 3px 3px 0px',
    height: '100%',
    width: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    transition: '0.3s all ease-in-out',
    display: 'none',
  },
});
