import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { colors } from '../../../../core/contants/colors';

export const useSuggestionStyles = makeStyles({
  container: {
    marginTop: 20,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 60,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    fontWeight: 700,
    color: '#161616',
    fontSize: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 24,
    },
  },
  showAllText: {
    color: colors.accentColor,
    fontWeight: 500,
    fontSize: 15,
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 4,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 10,
    },
  },
  tag: {
    fontSize: 13,
    color: '#000',
    borderWidth: 1,
    borderColor: '#D8D8D8',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 12px',
    marginTop: 8,
    fontWeight: 400,
    marginRight: 8,
  },
  activeTag: {
    backgroundColor: colors.primaryColor,
    color: '#fff',
    borderColor: colors.primaryColor,
  },
  productItemContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gridGap: 12,
    marginTop: 8,
    minWidth: 0,
    minHeight: 0,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: 'repeat(4,1fr)',
      gridGap: 30,
      marginTop: 20,
    },
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 24,
    gridColumnStart: 1,
    gridColumnEnd: 3,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridColumnEnd: 5,
    },
  },
  filterRow: {
    display: 'grid',
    gridGap: 12,
    gridTemplateColumns: 'repeat(2,1fr)',
    marginTop: 20,
    marginBottom: 12,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  filterContainer: {
    display: 'flex',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: 6,
    padding: '12px 16px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#000',
    fontWeight: 500,
    fontSize: 14,
  },
  filterText: {
    marginLeft: 8,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
  orderByContainer: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'flex',
      gap: 20,
    },
  },
  brandFilterContainer: {
    display: 'flex',
    gap: 8,
    padding: '8px 16px',
    borderRadius: 8,
    border: '1px solid #D8D8D8',
    fontWeight: 500,
    fontSize: 16,
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
