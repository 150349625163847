import React from "react";
import { useRestaurantClosePopupStyles } from "./restaurant-close-popup.style";
import { RaisedButton } from "../../../../components/raised-button";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../../../../core/i18n";
import { Modal } from "antd";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export const RestaurantClosePopup = React.memo((props: Props) => {
  const classes = useRestaurantClosePopupStyles();
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);

  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.onClose}
      closeIcon={null}
      centered
      bodyStyle={{
        padding: 24,
      }}
      closable={false}
    >
      <div className={classes.container}>
        <div className={classes.title}>{t("restaurantClose")}</div>
        <div className={classes.description}>{t("restaurantCloseDescription")}</div>
        <RaisedButton className={classes.btn} onClick={props.onClose}>
          {t("preorder")}
        </RaisedButton>
      </div>
    </Modal>
  );
});
