import { Dropdown, Menu, Popover } from 'antd';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { I18N_NAMESPACES } from '../../core/i18n';
import { IProvince } from '../../core/models/province';
import { RootState } from '../../store';
import { AppRadioBox } from '../radio';
import { SearchIcon } from '../icons/search';
import { TextInput } from '../text-input';
import { useProvinceSelectorStyles } from './style';
import { ModalCloseIcon } from '../icons/modal-close';

interface Props {
  isVisible: boolean;
  onVisibleChanged: (value: boolean) => void;
  selectedId: number | null | undefined;
  onChanged: (province: IProvince) => void;
}

const ProvinceSelectorContent = React.memo((props: Props) => {
  const [searchText, setSearchText] = useState('');
  const provinces = useSelector((state: RootState) => state.reference.provinces);
  const filteredProvinces = useMemo(() => {
    if (!searchText) return provinces.map((p) => ({ item: p }));

    const options = {
      keys: ['name'],
    };
    const fuse = new Fuse(provinces, options);
    return fuse.search(searchText);
  }, [searchText, provinces]);
  const classes = useProvinceSelectorStyles();

  return (
    <div className={classes.container}>
      <TextInput
        prefix={<SearchIcon className={classes.searchIcon} />}
        placeholder={'Tìm kiếm ...'}
        value={searchText}
        onChange={setSearchText}
        textInputContainerClassname={classes.textInputContainer}
        textInputClassname={classes.searchTextInput}
      />
      {filteredProvinces.map((p) => (
        <div
          className={classes.provinceContainer}
          onClick={() => {
            props.onChanged(p.item);
            props.onVisibleChanged(false);
          }}
          key={p.item.id}
        >
          <AppRadioBox isChecked={p.item.id.toString() === props.selectedId?.toString()}>
            <span className={classes.provinceText}>{p.item.name}</span>
          </AppRadioBox>
        </div>
      ))}
    </div>
  );
});

export const ProvinceSelectorBottomSheet = React.memo((props: Props) => {
  const classes = useProvinceSelectorStyles();
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);

  return (
    <BottomSheet
      onDismiss={() => props.onVisibleChanged(false)}
      open={props.isVisible}
      header={
        <div className={classes.headerContainer}>
          <span className={classes.title}>{t('chooseProvince')}</span>
          <div className={classes.closeIcon} onClick={() => props.onVisibleChanged(false)}>
            <ModalCloseIcon color="#7A7A7A" />
          </div>
        </div>
      }
      defaultSnap={({ maxHeight }) => maxHeight - maxHeight / 10}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <ProvinceSelectorContent {...props} />
    </BottomSheet>
  );
});

export const ProvinceSelectorPopOver = React.memo((props: React.PropsWithChildren<Props>) => {
  return (
    <Popover
      // placement="bottomCenter"
      // arrow
      content={() => <ProvinceSelectorContent {...props} />}
      placement="bottom"
      visible={props.isVisible}
      onVisibleChange={props.onVisibleChanged}
      trigger="click"
    >
      {props.children as any}
    </Popover>
  );
});
