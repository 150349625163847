import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import React, { lazy, Suspense, useEffect, useMemo, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppFooter } from '../components/app-footer';
import { AppHeader } from '../components/app-header';
import { ConfirmModal } from '../components/confirm-modal/confirm-modal.component';
import { ErrorModal } from '../components/error-modal';
import { ModalSpinner } from '../components/modal-spinner';
import { ProvinceSelectorBottomSheet } from '../components/province-selector';
import { Spinner } from '../components/spinner';
import { BREAK_POINTS } from '../core/contants/break-points';
import { NET_CORE_INFO, UNSUPPORTED_PROVINCES } from '../core/environments/env';
import { useQuery } from '../core/hooks/use-query';
import { IProvince } from '../core/models/province';
import { showError } from '../core/ultis/toast';
import { AboutDetail } from '../screens/about-detail/about-detail.screen';
import { AtmPaymentScreen } from '../screens/atm-payment/atm-payment';
import { BrandDetail } from '../screens/brand-detail/brand-detail.screen';
import { BrandListScreen } from '../screens/brand-list/brand-list.screen';
import { CartScreen } from '../screens/cart/cart.screen';
import { CheckPaymentScreen } from '../screens/check-payment/check-payment.screen';
import { HomeScreen } from '../screens/home/home.screen';
import { LoginScreen } from '../screens/login/login.screen';
import { RequestPaymentScreen } from '../screens/request-payment/request-payment.screen';
import SearchScreen from '../screens/search/search.screen';
import { UpdateAddressScreen } from '../screens/update-address/update-address.screen';
import { VerifyOrderScreen } from '../screens/verify-order/verify-order.screen';
import { VerifyPaymentScreen } from '../screens/verify-payment/verify-payment.screen';
import { authenticationService } from '../services/authentication-service';
import { brandService } from '../services/brand-service';
import { Dispatch, RootState } from '../store';
import { OVERLAY_MODAL } from '../store/models/app-context';
import { NotFoundScreen } from "../screens/error/404.screen";
import DynamicProductScreen from "../screens/product-list/dynamic-product.screen";
import { SuccessModal } from "../components/success-modal";

const HotDealListScreen = lazy(() => import('../screens/hot-deal-list/hot-deal-list.screen'));
const OrderDetailScreen = lazy(() => import('../screens/order-detail/order-detail.screen'));
const OrderRatingScreen = lazy(() => import('../screens/order-rating/order-rating.screen'));
const OrderTrackingScreen = lazy(() => import('../screens/order-tracking/order-tracking.screen'));
const SearchDetailScreen = lazy(() => import('../screens/search-detail/search-detail.screen'));
const ProfileModule = lazy(() => import('./modules/profile/profile.module'));

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
    document.body.style.overflow = 'unset';
  }, [pathname]);

  return null;
};

const Fallback = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </div>
  );
};

export const App = () => {
  const dispatch = useDispatch<Dispatch>();
  const appContext = useSelector((state: RootState) => state.appContext);
  const user = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();

  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const query = useQuery() as any;
  const [_, setCookies] = useCookies([
    'traffic-id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_location',
    'utm_term'
  ]);

  useEffect(() => {
    dispatch.appContext.setIsSeenHomePagePopupBanner(false);
    dispatch.appContext.getSelectedBrand();
    dispatch.appContext.resetIsSeenPopupBannerByBrand();
    dispatch.appContext.setIsDisableWindowScroll(false);
    dispatch.appContext.setIsShowBrandConfirm(false);
    dispatch.appContext.setDelayAddProductSeconds(0);
    if (pathname !== '/verify-order') {
      dispatch.cart.getCartDetail({});
    }

    if (user.authentication.token) {
      dispatch.product.getFavoriteProducts();
      dispatch.brand.getFavoriteBrands();
    }

    if (!appContext.uuid) {
      dispatch.appContext.generateUuid();
    }

    navigator.geolocation.getCurrentPosition(function (position) {
      dispatch.appContext.setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    dispatch.reference.getProvinces();
  }, [appContext.longitude, appContext.latitude]);

  useEffect(() => {
    if (appContext.isDisableWindowScroll) {
      document.body.style.overflow = 'hidden';
    }

    if (!appContext.isDisableWindowScroll) {
      document.body.style.overflow = 'unset';
    }
  }, [appContext.isDisableWindowScroll, isDesktop]);

  useEffect(() => {
    const trafficId = query.get('traffic_id');
    const moUtmSource = query.get('mo_utm_source');
    if (trafficId) {
      setCookies('traffic-id', trafficId, {
        expires: moment().add(30, 'd').toDate(),
      });
    }
    const utmSource = query.get('utm_source');
    if (utmSource) {
      setCookies('utm_source', utmSource, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
    const utmMedium = query.get('utm_medium');
    if (utmMedium) {
      setCookies('utm_medium', utmMedium, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
    const utmCampaign = query.get('utm_campaign');
    if (utmCampaign) {
      setCookies('utm_campaign', utmCampaign, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
    const utmContent = query.get('utm_content');
    if (utmContent) {
      setCookies('utm_content', utmContent, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
    const utmLocation = query.get('utm_location');
    if (utmLocation) {
      setCookies('utm_location', utmLocation, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
    const utmTerm = query.get('utm_term');
    if (utmTerm) {
      setCookies('utm_term', utmTerm, {
        expires: moment().add(3, 'd').toDate(),
      });
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const reference = useSelector((state: RootState) => state.reference);

  const token = useMemo(() => query.get('token'), [query]);
  const provinceId = useMemo(() => query.get('provinceId'), [query]);
  const customerNumber = useMemo(() => query.get('customerNumber'), [query]);

  useEffect(() => {
    async function autoLogin() {
      setIsLoading(true);
      const response = await authenticationService.autoLogin({ token, customerNumber });
      if (response.data.messageCode === 200) {
        dispatch.user.setUser(response.data.result?.profile || {});
        dispatch.user.setAuthentication(response.data.result?.authentication || {});
        dispatch.product.getFavoriteProducts();
        dispatch.cart.getCartDetail({});
        dispatch.appContext.getSelectedBrand();

        const province = reference.provinces.find((p) => p.id.toString() === provinceId.toString());
        if (province) {
          dispatch.appContext.setProvince(province);
        }
      } else {
        showError(response.data.message);
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    if (reference.provinces.length > 0 && token) {
      autoLogin();
    }
  }, [reference]);

  const [switchProvinceError, setSwitchProvinceError] = useState<string | null>(null);
  const [processingProvince, setProcessingProvince] = useState<IProvince | null>(null);

  const onSelectProvince = async (province) => {
    if (UNSUPPORTED_PROVINCES.includes(province.id)) {
      dispatch.appContext.setError(
        'G-Delivery tạm dừng dịch vụ theo chỉ thị phòng chống dịch của thủ tướng chính phủ.\nVui lòng liên hệ số điện thoại 02473003077 để được hỗ trợ.\nXin lỗi Quý khách vì sự bất tiện này.',
      );
    }
    const response = await brandService.switchProvince(province.id);

    if (response?.data.messageCode === 200) {
      dispatch.appContext.setProvince(province);
    } else if (response.data?.messageCode === 409) {
      setProcessingProvince(province);
      setSwitchProvinceError(response.data?.message || '');
    }
  };

  const onConfirmSwitchProvince = async () => {
    if (!processingProvince) return;
    const response = await brandService.switchProvince(processingProvince.id, true);

    if (response.data?.messageCode === 200) {
      dispatch.appContext.setProvince(processingProvince);
      dispatch.cart.getCartDetail({});
      setProcessingProvince(null);
      setSwitchProvinceError(null);
    }
  };

  const usePrevLocation = (location) => {

    const prevLocRef = useRef(location)

    useEffect(()=>{

      prevLocRef.current = location

    },[location])

    return prevLocRef.current

  }

  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  useEffect(() => {
    if (document.readyState == 'complete' && typeof window['smartech'] !== "undefined") {
      if (prevLocation.pathname != location.pathname || performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
        window['smartech']('dispatch', 1, {
          "s^url": window.location.href,
          "s^title": document.title,
          "s^purl": document.referrer,
          "s^location": appContext.selectedProvince?.name || '',
        });
      }
    } else {
      document.onreadystatechange = () => {
        if (typeof window['smartech'] !== "undefined") {
          window['smartech']('create', NET_CORE_INFO.create);
          window['smartech']('register', NET_CORE_INFO.register);
          window['smartech']('identify', user.profile.cellphone || NET_CORE_INFO.identify);

          if (prevLocation.pathname != location.pathname || performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
            window['smartech']('dispatch', 1, {
              "s^url": window.location.href,
              "s^title": document.title,
              "s^purl": document.referrer,
              "s^location": appContext.selectedProvince?.name || '',
            });
          }
        }
      };
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <script src="../assets/js/smartech.js" type="text/javascript" />
      </Helmet>

      <ScrollToTop />
      <AppHeader />
      <Switch>
        <Route path="/order" component={OrderModule} />
        <Route
          path="/hot-deal"
          render={() => (
            <Suspense fallback={<Fallback />}>
              <HotDealListScreen />
            </Suspense>
          )}
          exact
        />
        <Route path="/about/:id" component={AboutDetail} exact />
        <Route path="/profile" render={(_) => <AuthenticatedRoute element={<ProfileModule />} />} />

        <Route path="/" component={HomeModule} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
      {(isDesktop || pathname === '/') && <AppFooter />}
      <ToastContainer
        position="bottom-center"
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoginScreen
        isVisible={appContext.overlayModal === OVERLAY_MODAL.LOGIN}
        close={() => dispatch.appContext.setOverlayModal(null)}
      />
      <ErrorModal />
      <SuccessModal />
      <ModalSpinner visible={isLoading} />
      <ProvinceSelectorBottomSheet
        onVisibleChanged={() => {
          dispatch.appContext.setOverlayModal(null);
        }}
        isVisible={
          !appContext.selectedProvince || appContext.overlayModal === OVERLAY_MODAL.SELECT_PROVINCE
        }
        selectedId={(appContext.selectedProvince || { id: null }).id}
        onChanged={onSelectProvince}
      ></ProvinceSelectorBottomSheet>
      <ConfirmModal
        isVisible={!!switchProvinceError}
        onClose={() => setSwitchProvinceError(null)}
        message={switchProvinceError || ''}
        onConfirm={onConfirmSwitchProvince}
      />
    </>
  );
};

const AuthenticatedRoute = React.memo(
  (props: { redirectRoute?: string; element: React.ReactNode }) => {
    const user = useSelector((state: RootState) => state.user);
    return user.authentication.token ? (
      <Suspense fallback={<Fallback />}>{props.element}</Suspense>
    ) : (
      <Redirect to={props.redirectRoute || '/'} />
    );
  },
);

const OrderModule = React.memo(() => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Redirect from="/order" to="/profile/order" exact />
      <Route
        path={`${match.url}/:id`}
        render={() => <AuthenticatedRoute element={<OrderDetailScreen />} />}
        exact
      />
      <Route
        path={`${match.url}/:id/rating`}
        render={() => <AuthenticatedRoute element={<OrderRatingScreen />} />}
        exact
      />
      <Route
        path={`${match.url}/:id/tracking`}
        render={() => <AuthenticatedRoute element={<OrderTrackingScreen />} />}
        exact
      />
      <Route path="*" component={NotFoundScreen} />
    </Switch>
  );
});

const HomeModule = React.memo(() => {
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const cart = useSelector((state: RootState) => state.cart);
  const query = useQuery() as any;
  const dispatch = useDispatch<Dispatch>();
  const [_, setCookies] = useCookies(['traffic-id']);
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <Switch>
        <Route path={`/`} component={HomeScreen} exact />
        <Route path={`/search`} component={SearchScreen} exact />
        <Route path={`/brand`} component={BrandListScreen} exact />
        <Route path={`/brand/:id`} component={BrandDetail} exact />
        <Route
          path={`/search-result`}
          render={() => (
            <Suspense fallback={<Fallback />}>
              <SearchDetailScreen />
            </Suspense>
          )}
          exact
        />
        <Route path={`/cart`} component={CartScreen} exact />
        <Route path={`/update-address`} component={UpdateAddressScreen} exact />

        <Route
          path={`/verify-order`}
          render={() => <AuthenticatedRoute element={<VerifyOrderScreen />} />}
          exact
        />
        <Route
          path={`/verify-payment`}
          render={(_) =>
            cart.cartItems.length > 0 && user.authentication.token ? (
              <VerifyPaymentScreen />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route path={`/check-payment`} component={CheckPaymentScreen} exact />
        <Route
          path={`/request-payment/:id`}
          render={() => <AuthenticatedRoute element={<RequestPaymentScreen />} />}
          exact
        />
        <Route path={`/atm-payment`} component={AtmPaymentScreen} exact />
        <Route path={`/danh-sach-san-pham/:tabSlug`} component={DynamicProductScreen} exact />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </>
  );
});
