import React from 'react';
import QrReader from 'react-qr-reader';
import { QrClose } from '../../../components/icons/qr-close';
import { QrInfo } from '../../../components/icons/qr-info';
import { useStyles } from './qrScanModal.style';
interface Props {
  onScanQrCodeSuccess:(data: any) => void;
  onModalClose: () => void;
}
export const QrScanModal = React.memo((props: Props) => {
  const classes = useStyles();
  const {onScanQrCodeSuccess, onModalClose} = props;
  const handleScan = (data: any) => {
    if (data) {
      onScanQrCodeSuccess(data)
    }
  };
  const handleError = (err: any) => {
    console.error(err);
  };
  return (
    <div>
      <div
        style={{
          background: '#000',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 30px',
        }}
      >
        <QrClose onClick={onModalClose}/>
        <div style={{ fontWeight: 700, fontSize: '16px', color: '#fff' }}>Quét QR Code</div>
        <QrInfo />
      </div>
      <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
    </div>
  );
});
