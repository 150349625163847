import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../core/contants/colors';

export const useStyles = makeStyles({
  imgTitle: {
    width: '72px',
    height: '72px',
    borderRadius: 12
  },
  couponItem: {
    display: 'flex',
    padding: '16px 0',
    borderBottom: `1px solid ${colors.dividerColor}`
  },
  rowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  couponInfoContainer: {
    marginLeft: '12px',
    width: '100%'
  },
  couponItemCount: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 10,
    fontWeight: 400,
    borderRadius: 100,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px'
  },
  date: {
    fontSize: 12,
    color: '#6B6B6B',
    fontWeight: 400,
    cursor: 'pointer'
  },
  title: {
    color: '#000',
    fontWeight: 700,
    fontSize: 15,
    marginBottom: 6,
    marginTop: 6,
    cursor: 'pointer'
  },
  cancelText: {
    color: colors.dangerColor,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer'
  },
  activeText: {
    color: colors.accentColor,
    fontSize: 14,
    fontWeight: 500,
    zIndex: 1,
    cursor: 'pointer'
  },
  noDataIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    padding: '45px 0'
  }
});