import { ClockCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { debounce } from 'lodash-es';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CartDivider } from '../../components/cart-divider';
import { SearchCloseIcon } from '../../components/icons/search-close';
import { SearchDetailIcon } from '../../components/icons/search-detai';
import { SearchFilterIcon } from '../../components/icons/search-filter';
import { TextInput } from '../../components/text-input';
import { ITag } from '../../core/models/tag';
import { productService } from '../../services/product-service';
import { tagService } from '../../services/tag-service';
import { Dispatch, RootState } from '../../store';

import { useStyles } from './search.style';

const SearchScreen = React.memo(() => {
  const classes = useStyles();
  const history = useHistory();

  const appContext = useSelector((state: RootState) => state.appContext);
  const dispatch = useDispatch<Dispatch>();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchText, setSearchInput] = useState('');
  const [tags, setTags] = useState<ITag[]>([]);

  const onRemoveKeyword = (index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch.appContext.removeSearchKeyword(index);
  };

  useEffect(() => {
    async function getTags() {
      if (appContext.selectedProvince?.id) {
        const response = await tagService.getTags({ provinceId: appContext.selectedProvince?.id });
        setTags(response?.data?.result || []);
      }
    }

    getTags();
  }, [appContext.selectedProvince]);

  const onSearch = ({
    searchText,
    addToHistory,
    brandId,
    tag,
  }: {
    searchText: string;
    addToHistory: boolean;
    brandId?: string;
    tag?: string;
  }) => {
    history.push({
      pathname: '/search-result',
      search: new URLSearchParams({
        'search-text': searchText,
        'brand-id': brandId || '',
        tag: tag || '',
      }).toString(),
    });

    if (addToHistory) {
      dispatch.appContext.addSearchKeyword(searchText);
    }
  };

  const renderPopularKeywords = () => {
    if (tags?.length) {
      return tags.map((value: ITag, index: number) => {
        return (
          <div
            key={index}
            className={classes.keywordItem}
            onClick={() => {
              onSearch({ tag: value?.slug || '', addToHistory: false, searchText });
            }}
          >
            {value?.name ? `#${value?.name}` : ''}
          </div>
        );
      });
    }
  };

  const renderRecentSearches = () => {
    return (appContext.recentSearchKeywords || []).map((keyword: string, index: number) => {
      return (
        <Row
          key={`${keyword}-${index}`}
          className={classes.searchItem}
          onClick={() => onSearch({ searchText: keyword, addToHistory: true })}
        >
          <Row className={classes.searchLeftItems}>
            <ClockCircleOutlined />
            <div className={classes.searchValue}>{keyword}</div>
          </Row>
          <div className={classes.removeKeywordContainer} onClick={onRemoveKeyword(index)}>
            <SearchCloseIcon className={classes.searchItemCloseIcon} />
          </div>
        </Row>
      );
    });
  };

  const getSuggestionsDebouce = useCallback(
    debounce(
      async (text) => {
        const response = await productService.searchProduct({
          name: text || '',
          provinceId: appContext?.selectedProvince?.id,
        });
        setSuggestions((response.data.result?.products || []).map((i) => i.name));
      },
      200,
      { maxWait: 600 },
    ),
    [],
  );

  const onSearchChange = (value: string) => {
    setSearchInput(value);
    getSuggestionsDebouce(value);
  };

  const renderSearchContent = () => {
    if (searchText) {
      return suggestions.map((suggestion: any, index: number) => {
        return (
          <div
            className={classes.searchContentValue}
            key={`${suggestion}-${index}`}
            onClick={() => onSearch({ searchText: suggestion, addToHistory: true })}
          >
            <Highlighter
              highlightClassName={classes.searchContentValueMatch}
              searchWords={searchText.split(' ')}
              autoEscape={true}
              // className={classes.searchContentValue}
              textToHighlight={suggestion}
            />
          </div>
        );
      });
    } else {
      return (
        <Fragment>
          <CartDivider />
          <Row className={classes.popularKeywordsContainer}>
            <div className={classes.title}>Từ khóa phổ biến</div>
            <Row className={classes.populateKeywordsItemsContainer}>{renderPopularKeywords()}</Row>
          </Row>
          <Row className={classes.recentSearchesContainer}>
            <Row className={classes.recentSearchesTitleContainer}>
              <div className={classes.title}>Tìm kiếm gần đây</div>
              <div
                className={classes.deleteSearch}
                onClick={dispatch.appContext.resetSearchKeywords}
              >
                Xóa lịch sử
              </div>
            </Row>
            <Row className={classes.populateKeywordsItemsContainer}>{renderRecentSearches()}</Row>
          </Row>
        </Fragment>
      );
    }
  };
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch({ searchText, addToHistory: true });
        }}
      >
        <Row className={classes.searchFieldContainer}>
          <Col span={20}>
            <TextInput
              onChange={onSearchChange}
              placeholder="Tìm kiếm ..."
              surfix={<SearchFilterIcon />}
              prefix={<SearchDetailIcon />}
              textInputContainerClassname={classes.searchInputContainer}
              textInputClassname={classes.searchInput}
            />
          </Col>
          <Col span={4} className={classes.cancelTextContainer} onClick={history.goBack}>
            <div>Hủy</div>
          </Col>
        </Row>
      </form>
      {renderSearchContent()}
    </div>
  );
});

export default SearchScreen;
