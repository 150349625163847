import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../core/contants/colors';

export const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 700,
  },
  selectDiscountCodeText: {
    color: colors.accentColor,
    fontSize: '14px',
    fontWeight: 500,
  },
  discountTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  discountContainer: {
    padding: '0 16px',
  },
  noDiscountCodeText: {
    color: colors.textGray,
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '16px',
  },
  priceContainer: {
    padding: '16px',
  },
  voucherCloseIcon: {
    position: 'absolute',
    top: -5,
    right: 0,
    cursor: 'pointer',
    zIndex: 1,
  },
  priceMainRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignContent: 'flex-end',
  },
  priceMainItemTitle: {
    fontSize: '14px',
    fontWeight: 400,
    alignSelf: 'center',
  },
  priceMainItemValue: {
    fontSize: '16px',
    fontWeight: 500,
  },
  priceSubRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '11px',
  },
  priceSubItemTitle: {
    color: '#6B6B6B',
    fontSize: '12px',
    fontWeight: 400,
    alignSelf: 'center',
  },
  priceSubItemValue: {
    color: '#6B6B6B',
    fontSize: '14px',
    fontWeight: 500,
  },
  priceDivider: {
    border: '1px dashed #D8D8D8',
    margin: '16px 0px',
  },
  totalPriceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    marginTop: '8px',
  },
  totalPriceTitle: {
    color: '#221F20',
    fontSize: '16px',
    fontWeight: 700,
    alignSelf: 'center',
  },
  totalPriceValue: {
    fontSize: '20px',
    fontWeight: 900,
    color: colors.primaryColor,
  },
  subRowLastItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '11px',
    marginBottom: '16px',
  },
  submitButton: {
    margin: '16px 16px 32px 16px',
  },
  submitButtonContainer: {
    bottom: 0,
    width: '100%',
    background: '#fff',
  },
  paymentMethodsContainer: {
    padding: '16px',
  },
  paymentMethodsListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '20px',
  },
  paymentMethodItem: {
    // flexBasis: 1,
    margin: '4px 0px',
  },
  checkBoxValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '14px',
  },
  paymentMethodName: {
    marginLeft: '11px',
    fontWeight: 500,
    color: '#000',
    fontSize: 14,
  },
  voucherListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  voucherItem: {
    background: '#EDEDED',
    padding: '8px 12px',
    color: colors.primaryColor,
    borderRadius: 100,
    marginRight: 15,
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 15,
    position: 'relative',
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 100,
    backgroundColor: '#7A7A7A',
    display: 'inline-block',
    alignSelf: 'center',
    marginRight: 8,
  },
  secondaryTitleText: {
    color: colors.textSecondaryColor,
    fontSize: 12,
    marginLeft: 12,
    fontWeight: 400,
  },
  secondaryValueText: {
    color: colors.textSecondaryColor,
    fontSize: 14,
    fontWeight: 500,
  },
  secondaryRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 0px',
  },
  gBizBalanceText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    color: '#6B6B6B',
    whiteSpace: 'nowrap'
  },
  disableGBiz: {
    opacity: 0.5,
  },
  noteForGBiz: {
    background: "rgba(239, 73, 73, 0.1)",
    borderRadius: 6,
    color: "#EF4949",
    padding: 8,
    margin: 16,
  },
});
