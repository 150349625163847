import React from 'react';
import { AppRadioBox } from '../../../../components/radio';
import { CartCloseIcon } from '../../../../components/icons/cart-close';
import { RaisedButton } from '../../../../components/raised-button';
import { TextInput } from '../../../../components/text-input';
import { useLoginStyles } from '../../login.style';
import { useLoginFirstStepStyles } from './first-step.style';

interface Props {
  close?: () => void;
  onNext?: (e: any) => void;
  phoneNumber: string;
  onChangePhoneNumber: (phone: string) => void;
  onChangeRecieveOtpType: (phone: string) => void;
  recieveOtpType: string;
  isLoading?: boolean;
}

export const LoginFirstStep = React.memo((props: Props) => {
  const classes = { ...useLoginStyles(), ...useLoginFirstStepStyles() };
  return (
    <form className={classes.container} onSubmit={props.onNext}>
      <div className={classes.header}>
        <div className={`${classes.iconContainer} ${classes.whiteBackground}`}></div>
        <div className={classes.title}>Đăng nhập tài khoản</div>
        <div className={classes.iconContainer} onClick={props.close}>
          <CartCloseIcon />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.instructionText}>
          Mã xác thực (OTP) sẽ được gửi đến số điện thoại của bạn
        </div>

        <hr className={classes.seperator} />

        <TextInput
          placeholder={'Nhập số điện thoại ...'}
          value={props.phoneNumber}
          onChange={props.onChangePhoneNumber}
          type="tel"
        />
        <div className={'flex justify-between my-4'}>
          <AppRadioBox
            isChecked={props.recieveOtpType === 'sms'}
            onChanged={(value) => props.onChangeRecieveOtpType('sms')}
          >
            <span className={classes.otpReceiveText}>Tin nhắn SMS</span>
          </AppRadioBox>
          <AppRadioBox
            isChecked={props.recieveOtpType === 'phone'}
            onChanged={(value) => props.onChangeRecieveOtpType('phone')}
          >
            <span className={classes.otpReceiveText}>Gọi điện thoại</span>
          </AppRadioBox>
        </div>

        <RaisedButton
          type="submit"
          className={classes.loginBtn}
          disabled={!/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(props.phoneNumber)}
          isLoading={props.isLoading}
        >
          <span className={classes.buttonText}>Đăng nhập</span>
        </RaisedButton>
      </div>
    </form>
  );
});
