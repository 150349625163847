import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IPromotion, ITicket, IVoucher } from '../core/models/ticket';

const getTickets = (params: { provinceId?: number; brandId?: string | null }) =>
  axios.get<IApiResponse<ITicket[]>>('/vouchers/tickets', { params });

const getTicketDetail = (id: string) =>
  axios.get<IApiResponse<IPromotion>>(`/vouchers/ticket/${id}/detail`);

const saveGift = (body: { voucherCode: string; value: number; promotionId: number }) =>
  axios.post<IApiResponse<null>>('/gift/save', body as any);

const getSavedVouchers = () => axios.get<IApiResponse<IVoucher[]>>('/gift/list');
const getVouchersList = () => axios.get<IApiResponse<IVoucher[]>>('/vouchers/list');

const applyVoucher = (body: { voucherCode: string; partnerId?: number; productId?: string }) =>
  axios.post<IApiResponse<any>>('/vouchers/apply', body as any);
const removeVoucher = (voucherId: string) =>
  axios.post<IApiResponse<null>>(`/vouchers/remove`, {
    voucherCode: voucherId,
  } as any);
  const voucherCheck = (body: { voucherCode: string }) =>
  axios.post<IApiResponse<any>>('/vouchers/check', body as any);
export const voucherService = {
  getTickets,
  saveGift,
  getSavedVouchers,
  getVouchersList,
  applyVoucher,
  removeVoucher,
  getTicketDetail,
  voucherCheck
};
