import { makeStyles, Modal } from '@material-ui/core';
import React, { Fragment } from 'react';
import { CartButton } from '../../../components/cart-button';
import { FailedIcon } from '../../../components/icons/failed';
import { SuccessIcon } from '../../../components/icons/success';
// import { colors } from '../core/contants/colors';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';
import { useMediaQuery } from '@material-ui/core';
import { BREAK_POINTS } from '../../../core/contants/break-points';

interface Props {
  //   size?: number;
  visible: boolean;
  type: string;
  notificationMessage?: string;
  onSuccessButtonClick: (isToTracking?: any) => void;
  onFailedButtonClick: () => void;
  onFailedInvalidTimeClick: () => void;
}

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: '32px 24px',
  },
  title: {
    color: '#14142B',
    fontSize: 18,
    fontWeight: 700,
    marginTop: 25,
    textAlign: 'center',
  },
  description: {
    color: '#868686',
    fontSize: 16,
    fontWeight: 400,
    marginTop: 5,
    textAlign: 'center',
  },
  button: {
    width: '70%',
    marginTop: 20,
  },
  homeButton: {
    width: '70%',
    marginTop: 10,
    color: '#000',
    border: '1px solid #D8D8D8',
    background: '#fff'
  }
});

export const NotificationModal = React.memo((props: Props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch<Dispatch>();
  const { visible, type, onSuccessButtonClick, onFailedButtonClick, notificationMessage, onFailedInvalidTimeClick } = props;
  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const onHomeButtonClick = () => {
    dispatch.cart.setCartData({});
    dispatch.cart.setCartItems([]);
    dispatch.cart.setTotals({
      shippingFee: 0,
      total: 0,
      totalPrice: 0,
      totalPriceWithoutShipping: 0,
    });
    history.push(`/`);
  }
  const onCartButtonClick = () => {
    window.location.href = '/cart';
  }
  const onTrackingClick = () => {
    dispatch.cart.setCartData({});
    dispatch.cart.setCartItems([]);
    dispatch.cart.setTotals({
      shippingFee: 0,
      total: 0,
      totalPrice: 0,
      totalPriceWithoutShipping: 0,
    });
    onSuccessButtonClick(false)

  }
  const onRepay = () => {
    dispatch.cart.setCartData({});
    dispatch.cart.setCartItems([]);
    dispatch.cart.setTotals({
      shippingFee: 0,
      total: 0,
      totalPrice: 0,
      totalPriceWithoutShipping: 0,
    });
    onSuccessButtonClick('re-pay');
  }

  const renderContent = () => {
    if (type === 'success') {
      return (
        <Fragment>
          <SuccessIcon />
          <div className={classes.title}>Đặt hàng thành công</div>
          <div className={classes.description}>Cảm ơn bạn đã đặt hàng trên G-Delivery</div>
          <CartButton
            text={'Theo dõi đơn hàng'}
            onClick={() => onTrackingClick()}
            containerStyle={classes.button}
          />
          <CartButton
            text={'Trang chủ'}
            onClick={() => onHomeButtonClick()}
            containerStyle={classes.homeButton}
          />
        </Fragment>
      );
    } else if (type === 'gbiz-failed') {
      return (
          <Fragment>
              <FailedIcon />
              <div className={classes.title}>Không thành công</div>
              <div className={classes.description}>{notificationMessage ? notificationMessage : 'Thanh toán bằng GBiz thất bại. Vui lòng thử lại!'}</div>
              <CartButton
                  text={'Thanh toán lại'}
                  onClick={() => onRepay()}
                  containerStyle={classes.button}
              />
          </Fragment>
      );
    } else if (type === 'failed') {
      return (
        <Fragment>
          <FailedIcon />
          <div className={classes.title}>Không thành công</div>
          <div className={classes.description}>{notificationMessage ? notificationMessage : 'Vui lòng kiểm tra thông tin đơn hàng và thử lại'}</div>
          <CartButton
            text={'Đồng ý'}
            onClick={onFailedButtonClick}
            containerStyle={classes.button}
          />
        </Fragment>
      );
    } else if (type === 'redirect-cart') {
      return (
        <Fragment>
          <FailedIcon />
          <div className={classes.title}>Không thành công</div>
          <div className={classes.description}>{notificationMessage ? notificationMessage : 'Vui lòng kiểm tra thông tin đơn hàng và thử lại'}</div>
          <CartButton
            text={'Giỏ hàng'}
            onClick={onCartButtonClick}
            containerStyle={classes.button}
          />
        </Fragment>
      );
    } else if (type === 'invalid-time' || type === 'invalid-time-request-payment') {
      return (
        <Fragment>
          <FailedIcon />
          <div className={classes.title}>Không thành công</div>
          <div className={classes.description}>{notificationMessage ? notificationMessage : 'Vui lòng kiểm tra thông tin đơn hàng và thử lại'}</div>
          <CartButton
            text={'Đồng ý'}
            onClick={onFailedInvalidTimeClick}
            containerStyle={classes.button}
          />
        </Fragment>
      );
    } else if (type === 'success-tracking') {
      return (
        <Fragment>
          <SuccessIcon />
          <div className={classes.title}>Đặt hàng thành công</div>
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: notificationMessage || 'Cảm ơn bạn đã đặt hàng trên G-Delivery' }}></div>
          <CartButton
            text={'Theo dõi đơn hàng'}
            onClick={() => onSuccessButtonClick(true)}
            containerStyle={classes.button}
          />
          <CartButton
            text={'Trang chủ'}
            onClick={() => onHomeButtonClick()}
            containerStyle={classes.homeButton}
          />
        </Fragment>
      );
    }
  };
  return (
    <Modal open={visible} >
      <div className={classes.modalContent} style={type === 'success-tracking' || type === 'success' ? { width: 340, height: 370 } : { width: 300, height: 300 }}>{renderContent()}</div>
    </Modal>
  );
});
