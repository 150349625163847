import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';

export const useEditAddressStyles = makeStyles({
  container: {
    // minHeight: "100vh",
  },
  deleteBtn: {
    color: colors.dangerColor,
    fontSize: 16,
    fontWeight: 700,
  },
  header: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E4E4E4',
    paddingBottom: 12,
  },
  closeIcon: {
    backgroundColor: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  iconPlaceholder: {
    width: 32,
    height: 32,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
  },
  spinnerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 24,
  },
});
