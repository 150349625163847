import { makeStyles } from "@material-ui/core";
import { BREAK_POINTS } from "../../../../core/contants/break-points";

export const useAddressFormStyles = makeStyles({
  contentContainer: {
    padding: "0px 16px",
    flex: 1,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: 16,
    },
  },
  textInputContainer: {
    marginTop: 16,
  },
  setAddressDefaultContainer: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between",
    marginTop: 20,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridRowStart: 5,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  setAsDefaultText: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontWeight: 500,
      color: "#000",
    },
  },
  submitBtn: {
    margin: 16,
  },
  addressDetailContainer: {
    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridRowStart: 4,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
});
