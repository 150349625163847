import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const APP_HEADER_HEIGHT = 64;

export const useAppHeaderStyles = makeStyles({
  container: {
    backgroundColor: colors.primaryColor,
    height: APP_HEADER_HEIGHT,
    width: '100%',
    display: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 5,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      display: 'block',
    },
  },
  localContentContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    height: '100%',
    alignItems: 'center',
    gridGap: 10,
  },
  logo: {
    width: 60,
    height: 40,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deliveryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  locationIcon: {
    height: 16,
    width: 16,
    objectFit: 'contain',
    color: '#fff',
    marginRight: 8,
  },
  locationText: {
    color: '#fff',
  },
  arrowDownIcon: {
    height: 16,
    width: 16,
    objectFit: 'contain',
    color: '#fff',
    marginLeft: 20,
  },
  searchTextInputContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: '0px 12px',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    flex: 1,
  },

  searchTextInput: {
    flex: 1,
    fontSize: 15,
    border: 'none',
    fontWeight: 300,
    color: '#000',
    lineHeight: '19px',
  },

  actionContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: 30,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionIcon: {},
  actionIconContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: 8,
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  badge: {
    height: 24,
    width: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#fff',
    marginLeft: 6,
    color: '#000',
    fontWeight: 500,
  },

  actionText: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  noticeAddCartSuccess: {
    display: 'flex',
    padding: 18,
    alignItems: 'center',
    width: 310,
    height: 60,
    fontWeight: 700,
    color: '#000',
    cursor: 'pointer',
    borderRadius: 6,
    background: '#FFFFFF',
  },
  noticeSuccess: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  blockNoticeSuccess: {
    position: 'absolute',
    top: '55px',
    right: '-22px',
    boxShadow: '0px 8px 50px rgb(0 0 0 / 6%)',
  },
  arrowUp: {
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid #FFFFFF',
    position: 'absolute',
    right: '17px',
    top: '-10px',
  }
});
