import React from 'react';

interface Props {
  className?: string;
}

export const CouponQRCodeIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93314 0.815146C8.93314 0.364783 8.56114 0 8.10296 0H8.10187L6.32477 0.00106975C3.93459 0.00320924 1.9895 1.91271 1.9895 4.25759V6.28583C1.9895 6.73512 2.3615 7.10097 2.81968 7.10097C3.27787 7.10097 3.65096 6.73512 3.65096 6.28583V4.25759C3.65096 2.81022 4.85096 1.63243 6.32587 1.63029L8.10296 1.62922C8.56223 1.62922 8.93314 1.26444 8.93314 0.815146ZM17.6657 0.000585938H15.9279C15.4697 0.000585938 15.0977 0.365369 15.0977 0.815732C15.0977 1.26503 15.4697 1.62874 15.9279 1.62874H17.6657C19.1461 1.62874 20.3504 2.80974 20.3504 4.26138V6.28641C20.3504 6.73571 20.7224 7.10156 21.1806 7.10156C21.6399 7.10156 22.0108 6.73571 22.0108 6.28641V4.26138C22.0108 1.91115 20.0624 0.000585938 17.6657 0.000585938ZM8.98742 4.08281H15.0136C15.6736 4.08281 16.3074 4.34276 16.7689 4.80596C17.2336 5.27344 17.4911 5.90138 17.49 6.555V7.91357C17.4845 8.07296 17.3547 8.2024 17.1921 8.20668H6.80669C6.64524 8.20133 6.51542 8.07189 6.51214 7.91357V6.555C6.50014 5.20177 7.60851 4.09672 8.98742 4.08281Z"
        fill="#E96E34"
      />
      <path
        d="M23.1698 9.86304H0.831273C0.373091 9.86304 0 10.2278 0 10.6782C0 11.1275 0.373091 11.4912 0.831273 11.4912H1.98873V15.3786C1.98873 17.7235 3.93491 19.633 6.32509 19.6352L8.10218 19.6362C8.56145 19.6362 8.93236 19.2715 8.93345 18.8211C8.93345 18.3718 8.56145 18.007 8.10327 18.007L6.32727 18.0059C4.85127 18.0038 3.65018 16.826 3.65018 15.3786V11.4912H6.51164V12.5727C6.50073 13.9259 7.608 15.032 8.98691 15.0449H15.0131C16.3931 15.032 17.5004 13.9259 17.4895 12.5727V11.4912H20.3509V15.3744C20.3509 16.8271 19.1465 18.007 17.6662 18.007H15.9284C15.4691 18.007 15.0971 18.3718 15.0971 18.8211C15.0971 19.2715 15.4691 19.6362 15.9284 19.6362H17.6662C20.0618 19.6362 22.0113 17.7257 22.0113 15.3744V11.4912H23.1698C23.628 11.4912 24 11.1275 24 10.6782C24 10.2278 23.628 9.86304 23.1698 9.86304Z"
        fill="#E96E34"
      />
    </svg>
  );
});
