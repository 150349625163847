import { Models } from '@rematch/core';
import { appContext } from './app-context';
import { brand } from './brand';
import { cart } from './cart';
import { product } from './product';
import { reference } from './reference';
import { user } from './user';

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  reference: typeof reference;
  appContext: typeof appContext;
  cart: typeof cart;
  product: typeof product;
  brand: typeof brand;
}
export const models: RootModel = { user, reference, appContext, cart, product, brand };
