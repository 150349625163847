import { useMediaQuery } from '@material-ui/core';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import React, { useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { ProductItem } from '../../components/product-item';
import { RaisedButton } from '../../components/raised-button';
import { Spinner } from '../../components/spinner';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { images } from '../../core/contants/images';
import { IProduct, IProductVariation } from '../../core/models/product';
import { formatCurrency } from '../../core/ultis/currency';
import { productService } from '../../services/product-service';
import { RootState } from '../../store';
import { ProductBanner } from './components/banner/banner.component';
import { ModifierSelector } from './components/modifer-selector/modifer-selector.component';
import { QuantityControl } from './components/quantity-control/quantity-control.component';
import { ModifierItemVM } from './models/modifier';
import { useProductDetailStyles } from './product-detail.style';
import ShowMoreText from 'react-show-more-text';
import { useHistory } from 'react-router';
import { uniq } from 'lodash-es';
import { CartFooter } from '../../components/cart-footer';
import { BottomSheet } from 'react-spring-bottom-sheet';

interface Props {
  product: IProduct | undefined;
  onClose: (e) => void;
  addToCart: (e, quantity) => void;
  selectedVariant?: IProductVariation;
  setSelectedVariant: (variant?: IProductVariation) => void;
  quickAddToCart: (e) => void;
  quickRemoveFromCart: (e) => void;
  isLoading: boolean;
  selectedModifiersById: { [key: string]: string[] };
  setSelectedModifiersById: (value: any) => void;
  selectedToppingIds: string[];
  setSelectedToppingIds: (value: any) => void;
}

const ProductDetailContent = React.memo(
  ({
    product,
    onClose,
    addToCart,
    setSelectedVariant,
    selectedVariant,
    quickAddToCart,
    quickRemoveFromCart,
    isLoading,
    selectedModifiersById,
    setSelectedModifiersById,
    selectedToppingIds,
    setSelectedToppingIds,
  }: Props) => {
    const classes = useProductDetailStyles();
    const history = useHistory();
    const [upsellProducts, setUpsellProducts] = useState<IProduct[]>([]);
    const cart = useSelector((state: RootState) => state.cart);
    const cartItem = useMemo(
      () =>
        cart.cartItems.find((i) =>
          selectedVariant
            ? i.productId === selectedVariant.variationId
            : i.productId === product?.id,
        ),
      [cart, product, selectedVariant],
    );
    const [quantity, setQuantity] = useState(cartItem?.quantity || 0);
    const [isLoadingUpsellProducts, setIsLoadingUpsellProducts] = useState(false);

    const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

    useEffect(() => {
      if (cartItem?.quantity) return;

      if (isDesktop) {
        setQuantity(1);
      } else {
        setQuantity(0);
      }
    }, [isDesktop]);

    useEffect(() => {
      async function getUpsellProducts() {
        setIsLoadingUpsellProducts(true);
        const response = await productService.getUpsells({
          id: selectedVariant?.variationId || product?.parentId || product?.id,
        });
        setIsLoadingUpsellProducts(false);
        setUpsellProducts(response.data.result || []);
      }
      getUpsellProducts();
    }, []);

    useEffect(() => {
      setQuantity(cartItem?.quantity || 0);
    }, [cartItem?.quantity]);

    const variants = useMemo(
      () =>
        (product?.availableVariations || []).reduce(
          (acc: ModifierItemVM[], item: IProductVariation) => {
            const variantItems = item.variations.map((v) => ({
              label: v.attrValue,
              value: item.variationId,
            }));

            return [...acc, ...variantItems];
          },
          [],
        ),
      [product],
    );

    const price = useMemo(
      () =>
        selectedVariant
          ? selectedVariant.salePrice
            ? selectedVariant.salePrice
            : selectedVariant.regularPrice
          : product?.salePrice
          ? product.salePrice
          : product?.regularPrice,
      [selectedVariant, product],
    );

    const salePrice = useMemo(
      () =>
        selectedVariant?.salePrice
          ? selectedVariant.regularPrice
          : product?.salePrice
          ? product.regularPrice
          : 0,
      [selectedVariant, product],
    );

    return (
      <div className={classes.container}>
        {isDesktop && (
          <ProductBanner bannerUrl={product?.thumbnail || images.foodThumbnail} onClose={onClose} />
        )}

        <div className={classes.contentContainer}>
          <div className={classes.brandContainer}>
            <img src={product?.brand?.minimizeLogo} className={classes.brandLogo} alt="" />
            <div className={classes.brandText}>{product?.brand?.name}</div>
          </div>

          <div className={classes.title}>{product?.name}</div>
          <div className={classes.unitText}>
            {product?.quantitative} {product?.textUnit}
          </div>

          {!!product?.description && (
            <div className={classes.descriptionContainer}>
              <div className={classes.subTitle}>Thành phần món ăn</div>
              <ShowMoreText lines={4} more="Xem thêm" less="Rút gọn">
                {parse(product?.description || '')}
              </ShowMoreText>
            </div>
          )}

          {product?.availableVariations && (
            <ModifierSelector
              onChanged={(value) =>
                setSelectedVariant(
                  product.availableVariations?.find((i) => i.variationId === value),
                )
              }
              title={product.availableVariations[0]?.variations[0]?.attrName}
              items={variants}
              selectedItemIds={[selectedVariant?.variationId || '']}
            />
          )}

          {!!product?.modifier &&
            product.modifier.length > 0 &&
            product.modifier.map((m) => (
              <ModifierSelector
                onChanged={(value) => {
                  setSelectedModifiersById((i) => ({
                    ...i,
                    [m.id]: [value],
                  }));
                }}
                title={`Chọn ${m.name?.toLowerCase()}`}
                items={(m.data || []).map((i) => ({
                  value: i.id.toString(),
                  label: i.name,
                }))}
                selectedItemIds={selectedModifiersById[m.id] || []}
              />
            ))}

          {!!product?.topping && (product?.topping?.data || []).length > 0 && (
            <ModifierSelector
              onChanged={(value) => {
                setSelectedToppingIds((ids) =>
                  ids.some((id) => id === value.toString())
                    ? ids.filter((id) => id !== value.toString())
                    : uniq([...ids, value.toString()]),
                );
              }}
              title={`Chọn thêm ${product.topping?.name?.toLowerCase()}`}
              items={(product?.topping?.data || []).map((i) => ({
                value: i.id.toString(),
                label: i.name,
                price: i.salePrice || i.regularPrice,
              }))}
              type="checkbox"
              selectedItemIds={selectedToppingIds}
            />
          )}

          {!isDesktop && (
            <div style={{ marginTop: 12 }}>
              <span className={classes.priceText}>Giá: {formatCurrency(price || 0)}</span>
              {(product?.salePrice && !selectedVariant) || selectedVariant?.salePrice ? (
                <span className={classes.discountText}>{formatCurrency(salePrice)}</span>
              ) : (
                <></>
              )}
            </div>
          )}

          {!isDesktop && (
            <QuantityControl
              value={quantity}
              onDecrease={(e) => {
                quickRemoveFromCart(e);
              }}
              onIncrease={(e) => {
                quickAddToCart(e);
              }}
            />
          )}

          {(isLoadingUpsellProducts || upsellProducts.length > 0) && (
            <div>
              <div className={classes.subTitle}>Ngon hơn khi ăn cùng</div>
              {isLoadingUpsellProducts ? (
                <div className={classes.loadingContainer}>
                  <Spinner />
                </div>
              ) : (
                <div className={classes.productSuggesstionContainer}>
                  {upsellProducts.map((product) => (
                    <ProductItem
                      product={product}
                      key={product.id}
                      layout="horizontal"
                      onClick={() => {}}
                      hideBrandName
                      className={classes.productItem}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {isDesktop && (
            <div className={classes.footer}>
              <div>
                <div>
                  <span className={classes.priceText}>Giá: {formatCurrency(price || 0)}</span>
                  {(product?.salePrice && !selectedVariant) || selectedVariant?.salePrice ? (
                    <span className={classes.discountText}>{formatCurrency(salePrice)}</span>
                  ) : (
                    <></>
                  )}
                </div>

                <QuantityControl
                  value={quantity}
                  onDecrease={() => {
                    setQuantity((v) => (v - 1 > 1 ? v - 1 : 1));
                  }}
                  onIncrease={() => {
                    setQuantity((v) => v + 1);
                  }}
                />
              </div>
              <RaisedButton onClick={(e) => addToCart(e, quantity)} isLoading={isLoading}>
                Thêm vào giỏ hàng
              </RaisedButton>
            </div>
          )}
        </div>
        {isLoading && (
          <div className={classes.loadingOverlay}>
            <Spinner />
          </div>
        )}
      </div>
    );
  },
);

interface ProductDetailBottomSheetProps extends Props {
  isVisible: boolean;
}

export const ProductDetailBottomSheet = React.memo((props: ProductDetailBottomSheetProps) => {
  const classes = useProductDetailStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const history = useHistory();

  const [product, setProduct] = useState(props.product);

  // useEffect(() => {
  //   async function getDetail() {
  //     if (!props?.product?.id && !props.product?.parentId && !props.product?.wooId) return;
  //     const response = await productService.getDetail({
  //       id: props.product?.parentId || props.product?.wooId || props?.product?.id,
  //     });
  //     setProduct(response.data.result);
  //   }
  //
  //   getDetail();
  // }, [props.product?.id]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        props.onClose(null);
      }
    });
  }, []);

  const onClose = (e?: any) => {
    history.goBack();
    props.onClose(e);
  };

  if (isDesktop) {
    return (
      <Modal
        visible={props.isVisible}
        style={{
          padding: 0,
        }}
        bodyStyle={{
          padding: 16,
        }}
        closeIcon={<div />}
        onCancel={onClose}
        title=""
        footer={null}
        centered
        destroyOnClose
      >
        <ProductDetailContent {...props} product={product} onClose={onClose} />
      </Modal>
    );
  }

  return (
    <BottomSheet
      open={props.isVisible}
      onDismiss={onClose}
      header={
        <ProductBanner bannerUrl={product?.thumbnail || images.foodThumbnail} onClose={onClose} />
      }
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight - maxHeight / 10}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
      sibling={
        <div
          className="custom-backdrop"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}
        />
      }
      footer={<CartFooter />}
    >
      <ProductDetailContent {...props} product={product} onClose={onClose} />
    </BottomSheet>
  );
});
