import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IPage } from '../../core/models/page';
import { pageService } from '../../services/page-service';
import parse from 'html-react-parser';
import { Container } from '../../components/container';
import { makeStyles } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';

const useStyles = makeStyles({
  container: {
    padding: 20,

    // [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
    //   padding: '20px 0px',
    // },
  },
});

export const AboutDetail = React.memo(() => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const [page, setPage] = useState<IPage>({ post_content: '' });

  useEffect(() => {
    async function getDetail() {
      const response = await pageService.getPageDetail(id);
      setPage(response.data.result || { post_content: '' });
    }

    getDetail();
  }, [id]);

  return (
    <Container className={classes.container}>
      <div>{parse(page.post_content)}</div>
    </Container>
  );
});
