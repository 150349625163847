import axios from 'axios';
import { store } from '../../store';
import { BASE_URL } from '../../core/environments/env';
import { tokenInterceptor } from './token.interceptor';

const instance = axios.create({
  // baseURL: 'http://118.71.251.188:59006/api/v1',
  baseURL: BASE_URL,
  // baseURL: 'https://uatbackend.gdelivery.vn/api/v1/',
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 403) {
      store.dispatch.user.reset();
      store.dispatch.product.reset();
      store.dispatch.cart.reset();
      store.dispatch.brand.reset();
    }
    return err;
  },
);

export default instance;
