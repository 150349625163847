import React from 'react';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { useProductBannerStyles } from './banner.style';

interface Props {
  bannerUrl: string;
  onClose: (e) => void;
}

export const ProductBanner = React.memo((props: Props) => {
  const classes = useProductBannerStyles();
  return (
    <div className={classes.thumbnailContainer}>
      <img src={props.bannerUrl} className={classes.thumbnail} alt="" />
      <div className={classes.closeIcon} onClick={props.onClose}>
        <ModalCloseIcon />
      </div>
    </div>
  );
});
