import Item from 'antd/lib/list/Item';
import clsx from 'clsx';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductItem } from '../../../../components/product-item';
import { Spinner } from '../../../../components/spinner';
import { IProduct } from '../../../../core/models/product';
import { ITag } from '../../../../core/models/tag';
import { useBrandDetailStyles } from '../../brand-detail.style';
import { useProductByCategoriesStyles } from './product-by-categories.style';

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
  onPressProduct: (product: IProduct) => void;
  products: IProduct[];
  tags: ITag[];
  selectedTag: ITag | undefined;
  onChangedTag: (tag: ITag) => void;
  onScrollToEnd: () => void;
  isEndOfList: boolean;
  isLoading: boolean;
}

export const ProductByCategories = React.memo((props: Props) => {
  const classes = { ...useBrandDetailStyles(), ...useProductByCategoriesStyles() };
  return (
    <section className={classes.container} id="product-by-categories" ref={props.containerRef}>
      <div id="category-header">
        <div className={classes.titleContainer}>
          <div className={classes.titleText}>Danh mục sản phẩm</div>
        </div>

        <div className={classes.tagContainer}>
          {props.tags.map((tag) => (
            <div
              key={tag.id}
              className={clsx(classes.tag, tag.id === props.selectedTag?.id && classes.activeTag)}
              onClick={() => props.onChangedTag(tag)}
            >
              {tag.name}
            </div>
          ))}
        </div>
      </div>

      <InfiniteScroll
        dataLength={props.products.length}
        next={props.onScrollToEnd}
        hasMore={!props.isEndOfList}
        loader={props.isLoading &&
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        }
      >
        <div className={classes.productContainer} id="product-categories-content">
          {props.products.map((product) => (
            <ProductItem
              key={product.id}
              product={product}
              onClick={() => props.onPressProduct(product)}
              hideBrandName
            />
          ))}
        </div>
      </InfiniteScroll>
    </section>
  );
});
