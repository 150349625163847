import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const FavoriteOutlinedIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.51968 15.5714C8.5131 15.5753 8.50664 15.5791 8.50029 15.5828C8.49471 15.5795 8.48903 15.5762 8.48327 15.5728C8.31497 15.4742 8.0714 15.3274 7.7739 15.1369C7.17833 14.7556 6.36966 14.2011 5.51755 13.5089C3.79598 12.1104 1.97015 10.2079 1.28929 8.08904C0.443692 5.44864 1.45633 2.56263 4.08109 1.71706L4.08133 1.71699C5.48385 1.26439 7.03443 1.5316 8.20053 2.40883C8.3805 2.54422 8.62878 2.54258 8.80695 2.40482C9.89963 1.55996 11.5105 1.26721 12.913 1.71715C15.5385 2.56314 16.5566 5.45004 15.712 8.08941L15.7112 8.09179C15.0539 10.1818 13.2305 12.0832 11.5017 13.4895C10.6465 14.1851 9.83334 14.7446 9.23395 15.1301C8.93455 15.3227 8.68924 15.4714 8.51968 15.5714Z"
          stroke={color || "white"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={color || "white"} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
});
