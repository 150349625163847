import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { CircleGreenTickFilledIcon } from '../../../../components/icons/circle-green-tick-filled';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { RaisedButton } from '../../../../components/raised-button';
import { ITicket } from '../../../../core/models/ticket';
import { voucherService } from '../../../../services/voucher-service';
import { Dispatch, RootState } from '../../../../store';
import { OVERLAY_MODAL } from '../../../../store/models/app-context';
import { useCouponListStyles } from './coupon-list.style';

interface Props {
  containerClassName?: string;
  tickets: ITicket[];
  onSaveGift: (ticket: ITicket) => void;
}

export const CouponList = React.memo((props: Props) => {
  const classes = useCouponListStyles();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<Dispatch>();

  if (!props.tickets || props.tickets.length === 0) return <></>;

  const onSaveGift = (ticket: ITicket) => async (e) => {
    e.preventDefault();
    props.onSaveGift(ticket);
  };

  return (
    <div className={clsx(classes.couponListContainer, props.containerClassName)}>
      {(props.tickets || []).map((ticket, index) => (
        <Fragment key={`${ticket.voucherCode}-${index}`}>
          <div className={classes.couponItem}>
            <img src={ticket.icon} className={classes.couponIcon} />
            <div className={classes.couponTitleContainer}>
              <div className={classes.couponTitle}>{ticket.title}</div>
              <div className={classes.couponDescription}>{ticket.description}</div>
            </div>
            <div className={classes.couponButtonContainer}>
              <RaisedButton
                onClick={onSaveGift(ticket)}
                className={classes.takeVoucherBtn}
                disabled={ticket.isRedeem}
              >
                {ticket.isRedeem ? 'Đã lưu' : 'Lấy mã'}
              </RaisedButton>
            </div>
          </div>

          {index !== props.tickets.length - 1 && <hr className={classes.seperator} />}
        </Fragment>
      ))}
    </div>
  );
});
