import { CheckOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@material-ui/core';
import { Col, Modal, Row } from 'antd';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { AppCheckbox } from '../../../../components/checkbox';
import { CartPlusIcon } from '../../../../components/icons/cart-plus';
import { CartSubtractIcon } from '../../../../components/icons/cart-subtract';
import { CheckFilledIcon } from '../../../../components/icons/check-filled';
import { CheckOutlinedIcon } from '../../../../components/icons/check-outlined';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { AppRadioBox } from '../../../../components/radio';
import { RaisedButton } from '../../../../components/raised-button';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { formatCurrency } from '../../../../core/ultis/currency';
import { useStyles } from './discount-group-modal.style';
interface Props {
  isVisible: boolean;
  items: any[];
  isGetAllProductDiscount: boolean;
  isParallelCondition: boolean;
  // onClickItem: (item: any) => void;
  onClose: () => void;
  onApply: (value: any) => void;
}

const DiscountGroupComponent = (props: Props): JSX.Element => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const groups = useMemo(
    () =>
      Object.entries(groupBy(props.items, 'indexOfLineProduct')).map(([key, value]) => ({
        indexOfLineProduct: key,
        value,
      })),
    [props.items],
  );

  const [quantityByProduct, setQuantityByProduct] = useState(
    props.items.reduce((acc, cur) => ({ ...acc, [cur.indexOfLineProduct + '_' + cur.id]: cur.minQuantityDiscount || 0 }), {}),
  );
  const [selectedGroupIds, setSelectedGroupIds] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    setQuantityByProduct(
      props.items.reduce((acc, cur) => ({ ...acc, [cur.indexOfLineProduct + '_' + cur.id]: cur.minQuantityDiscount || 0 }), {}),
    );
  }, [props.items]);

  useEffect(() => {
    if (!props.isGetAllProductDiscount && !props.isParallelCondition) return;

    if (groups.length === 1) {
      setSelectedGroupIds(groups.map((g) => g.indexOfLineProduct));

      const group = groups[0];
      if (group.value.length === 1) {
        onPickItem(group.value[0], group.indexOfLineProduct)();
      }
    }
  }, [groups]);

  const onIncreaseQuantity = (item: any) => {
    setQuantityByProduct((quantity) => ({
      ...quantity,
      [item.indexOfLineProduct + '_' + item.id]:
        quantity[item.id] + 1 > item.maxQuantityDiscount
          ? quantity[item.id]
          : quantity[item.id] + 1,
    }));
  };
  const onDecreaseQuantity = (item: any) => {
    setQuantityByProduct((quantity) => ({
      ...quantity,
      [item.indexOfLineProduct + '_' + item.id]:
        quantity[item.id] - 1 < item.minQuantityDiscount
          ? quantity[item.id]
          : quantity[item.id] - 1,
    }));
  };

  const onApply = () => {
    if (props.isGetAllProductDiscount) {
      const products = props.items
        .filter((i) => selectedGroupIds.some((id) => id === i.indexOfLineProduct.toString()))
        .map((p) => ({
          productId: p.id,
          quantity: quantityByProduct[p.indexOfLineProduct + '_' + p.id],
          lineProduct: p.indexOfLineProduct,
          rkCode: p.rkCode,
        }));

      props.onApply({ productIdsInfo: products });
    } else {
      const products = selectedItems
        .filter((i) => selectedGroupIds.some((id) => id === i.indexOfLineProduct.toString()))
        .map((p) => ({
          productId: p.id,
          quantity: quantityByProduct[p.indexOfLineProduct + '_' + p.id],
          lineProduct: p.indexOfLineProduct,
          rkCode: p.rkCode,
        }));

      props.onApply({ productIdsInfo: products });
    }
  };

  const onSelectProduct = (item) => () => {
    props.onApply({
      productId: item.id,
      quantityDiscount: quantityByProduct[item.id],
      indexOfLineProduct: item.indexOfLineProduct,
    });
  };

  const onPickItem = (item, indexOfLineProduct) => () => {
    const isSelected = selectedItems.some(
      (i) => i.id === item.id && i.indexOfLineProduct == indexOfLineProduct,
    );
    setSelectedItems((v) =>
      isSelected
        ? v.filter((i) => !(i.id === item.id && i.indexOfLineProduct == indexOfLineProduct))
        : [...v.filter((i) => i.indexOfLineProduct != indexOfLineProduct), item],
    );
  };
  const afterCloseModal = () => {
    setTimeout(() => {
      document.body.style.setProperty('overflow', 'unset', 'important');
    }, 500);
  };
  const renderItem = (item: any, indexOfLineProduct?: string) => {
    const isSelected = selectedItems.some(
      (i) => i.id === item.id && i.indexOfLineProduct == indexOfLineProduct,
    );
    return (
      <Col md={8} sm={12} xs={12} className={classes.foodItemContainer}>
        <div className={classes.foodItem}>
          <img className={classes.titleImage} src={item.thumbnail}></img>

          <div className={classes.foodName}>{item.name}</div>
          <div className={classes.priceAndQuantityContainer}>
            <div className={classes.priceContainer}>
              <div className={classes.lineThroughPrice}>
                {formatCurrency(item.salePrice || item.regularPrice)}
              </div>
              <div className={classes.colorPrice}>{formatCurrency(item.priceAfterDiscount)}</div>
            </div>
            <div className={classes.quantityContainer}>
              {/*<div*/}
              {/*  style={{ cursor: 'pointer', userSelect: 'none' }}*/}
              {/*  onClick={() => onDecreaseQuantity(item)}*/}
              {/*>*/}
              {/*  <CartSubtractIcon className={classes.adjustQuantityIcon} />*/}
              {/*</div>*/}
              <div className={classes.quantityText}>SL: {quantityByProduct[item.indexOfLineProduct + '_' + item.id]}</div>
              {/*<div*/}
              {/*  style={{ cursor: 'pointer', userSelect: 'none' }}*/}
              {/*  onClick={() => onIncreaseQuantity(item)}*/}
              {/*>*/}
              {/*  <CartPlusIcon className={classes.adjustQuantityIcon} />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {!props.isGetAllProductDiscount && !props.isParallelCondition && (
          <div style={{ marginTop: 12, width: '80%' }}>
            <RaisedButton onClick={onSelectProduct(item)}>Chọn món</RaisedButton>
          </div>
        )}
        {!props.isGetAllProductDiscount && props.isParallelCondition && (
          <div
            className={clsx(classes.pickBtn, isSelected && classes.activePickBtn)}
            onClick={onPickItem(item, indexOfLineProduct)}
          >
            {isSelected ? (
              <>
                <CheckOutlinedIcon />
                <div>Đã chọn</div>
              </>
            ) : (
              <div>Chọn món</div>
            )}
          </div>
        )}
      </Col>
    );
  };
  return (
    <Modal
      title=""
      // visible={props.isVisible}
      visible={props.isVisible}
      footer={null}
      // onCancel={props.onCancelModal}
      closeIcon={null}
      centered
      width={isDesktop ? 520 : 420}
      bodyStyle={{
        padding: isDesktop ? '16px' : '24px 16px 40px 16px',
      }}
      style={{ borderRadius: 8 }}
      closable={false}
      afterClose={() => afterCloseModal()}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerTextContainer}>
          <div className={classes.headerTitleText}>Chọn món ưu đãi</div>
          <div className={classes.headerDescriptionText}>
            Hãy chọn một trong các món sau để nhận ưu đãi
          </div>
        </div>
      </div>
      <div className={classes.modalCloseIcon} onClick={props.onClose}>
        <ModalCloseIcon />
      </div>
      {!props.isGetAllProductDiscount && !props.isParallelCondition ? (
        <Row gutter={isDesktop ? [16, 24] : [16, 16]} className={classes.itemInfoContainer}>
          {props.items.map((value: any, index: number) => (
            <Fragment key={value.wooId}>{renderItem(value)}</Fragment>
          ))}
        </Row>
      ) : (
        groups.map((groupValue: any, groupIndex: number) => {
          const isSelected = selectedGroupIds.some((id) => id === groupValue.indexOfLineProduct);
          return (
            <div className={classes.itemContainer}>
              <div
                className={classes.itemCheckBoxContainer}
                onClick={() =>
                  setSelectedGroupIds((value) =>
                    props.isParallelCondition
                      ? isSelected
                        ? value.filter((i) => i != groupValue.indexOfLineProduct)
                        : [...value, groupValue.indexOfLineProduct]
                      : isSelected
                      ? []
                      : [groupValue.indexOfLineProduct],
                  )
                }
              >
                {props.isParallelCondition ? (
                  <AppCheckbox isChecked={isSelected}></AppCheckbox>
                ) : (
                  <AppRadioBox isChecked={isSelected}></AppRadioBox>
                )}
                <div className={`${classes.checkBoxTitle}`}>Chọn nhóm ưu đãi {groupIndex + 1}</div>
              </div>
              <Row
                gutter={isDesktop ? [16, 24] : [16, 16]}
                className={classes.itemInfoContainer}
                key={groupIndex}
              >
                {groupValue.value.map((value: any, index: number) => (
                  <Fragment key={value.wooId}>
                    {renderItem(value, groupValue.indexOfLineProduct)}
                  </Fragment>
                ))}
              </Row>
            </div>
          );
        })
      )}

      {(props.isGetAllProductDiscount || props.isParallelCondition) && (
        <Row gutter={12} justify={'center'} className={classes.buttonContainer}>
          <Col md={7} xs={10} sm={7} className={classes.cancelButton} onClick={props.onClose}>
            <div>Hủy bỏ</div>
          </Col>
          <Col md={7} xs={10} sm={7} className={classes.submitButton} onClick={onApply}>
            <div>Chọn món</div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
const DiscountGroupModal = React.memo(DiscountGroupComponent);
export { DiscountGroupModal };
