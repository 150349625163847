import { store } from '../../store';

export const tokenInterceptor = (config) => {
  const token = store.getState().user?.authentication?.token;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    const uuid = store.getState()?.appContext?.uuid;
    config.params = {
      ...(config.params || {}),
      identifyId: uuid,
    };
  }

  return config;
};
