import { Modal } from 'antd';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { showError } from '../../core/ultis/toast';
import { authenticationService } from '../../services/authentication-service';
import { cartService } from '../../services/cart-service';
import { Dispatch, RootState } from '../../store';
import { LoginFirstStep } from './components/first-step/first-step.component';
import { LoginSecondStep } from './components/second-step/second-step.component';
import { NetCore } from "../../assets/netcore/netcore";

interface Props {
  isVisible: boolean;
  close: () => void;
}

export const LoginScreen = React.memo((props: Props) => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [recieveOtpType, setRecieveOtpType] = useState<string>('sms');
  const [isLoading, setIsLoading] = useState(false);
  const [isCountDown, setIsCountDown] = useState(0);

  const dispatch = useDispatch<Dispatch>();
  const appContext = useSelector((state: RootState) => state.appContext);

  const onRequestOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await authenticationService.requestOtp({
      cellphone: phoneNumber,
      method: recieveOtpType,
    });
    if (response.data.messageCode === 200) {
      setStep(2);
    } else {
      showError(response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCountDown > 0) {
      setTimeout(function () {
        setIsCountDown(isCountDown - 1);
      }, 1000);
    }
  }, [isCountDown])

  const onResendOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isCountDown == 0) {
      setIsCountDown(60);

      const response = await authenticationService.requestOtp({
        cellphone: phoneNumber,
        method: recieveOtpType,
        forceResend: true,
      });
      if (response.data.messageCode === 200) {
        setStep(2);
      } else {
        showError(response.data.message);
      }
    }

    setIsLoading(false);
  };

  const reset = () => {
    setIsLoading(false);
    setStep(1);
    setPhoneNumber('');
    setOtp('');
  };

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await authenticationService.login({
      cellphone: phoneNumber,
      otp: otp,
    });
    if (response.data.messageCode === 200) {
      let data = {
        userId: response.data.result?.authentication?.customerNumber || '',
        mobile: phoneNumber,
      }
      NetCore.ncLoginSuccess(data);
      dispatch.user.setUser(response.data.result?.profile || {});
      dispatch.user.setAuthentication(response.data.result?.authentication || {});

      const moveCartResponse = await cartService.moveCartToUser({ identifyId: appContext.uuid });
      dispatch.product.getFavoriteProducts();
      dispatch.cart.getCartDetail({});
      dispatch.appContext.getSelectedBrand();

      reset();
      props.close();
    } else {
      showError(response.data.message);
      setIsLoading(false);
    }
  };

  const getChildByStep = () => {
    switch (step) {
      case 1:
        return (
          <LoginFirstStep
            close={props.close}
            onNext={onRequestOtp}
            recieveOtpType={recieveOtpType}
            onChangeRecieveOtpType={setRecieveOtpType}
            phoneNumber={phoneNumber}
            onChangePhoneNumber={setPhoneNumber}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <LoginSecondStep
            onLogin={onLogin}
            close={props.close}
            onBack={() => setStep(1)}
            isCountDown={isCountDown}
            onChangeOtp={setOtp}
            otp={otp}
            phoneNumber={phoneNumber}
            isLoading={isLoading}
            onResendOtp={onResendOtp}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.close}
      closeIcon={null}
      centered
      width={327}
      bodyStyle={{
        padding: 12,
      }}
      closable={false}
    >
      <div>{getChildByStep()}</div>
    </Modal>
  );
});
