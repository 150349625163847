import { createModel } from '@rematch/core';
import { cloneDeep } from 'lodash';
import { IAuthentication, IUserProfile } from '../../core/models/user';
import { authenticationService } from '../../services/authentication-service';
import { userService } from '../../services/user-service';
import { RootModel } from './';

interface IUserState {
  profile: IUserProfile;
  authentication: IAuthentication;
}

const initialState: IUserState = {
  profile: {},
  authentication: {},
};

export const user = createModel<RootModel>()({
  state: cloneDeep(initialState), // initial state
  reducers: {
    // handle state changes with pure functions
    reset(_, __) {
      return cloneDeep(initialState);
    },
    setUser(state, payload: IUserProfile) {
      return {
        ...state,
        profile: payload,
      };
    },
    setAuthentication(state, payload: IAuthentication) {
      return {
        ...state,
        authentication: payload,
      };
    },
    updateProfileSuccess(state, payload: IUserProfile) {
      return {
        ...state,
        profile: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async login(payload: { phoneNumber: string; otp: string }, state) {
      const response = await authenticationService.login({
        cellphone: payload.phoneNumber,
        otp: payload.otp,
      });
      if (response.data?.messageCode === 200) {
        dispatch.user.setUser(response.data.result?.profile || {});
        dispatch.user.setAuthentication(response.data.result?.authentication || {});
        dispatch.product.getFavoriteProducts();
      }
    },
    async logout(_, state) {
      await authenticationService.logout();

      dispatch.user.reset();
      dispatch.product.reset();
      dispatch.brand.reset();
      dispatch.cart.reset();
    },
    async updateProfile(payload: { fullName?: string; email?: string; gender?: number }, state) {
      const response = await userService.updateProfile(payload);
      if (response.data.messageCode === 200) {
        dispatch.user.updateProfileSuccess(response.data.result || {});
      }
    },
  }),
});
