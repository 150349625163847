import { Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useStyles } from './custom-timepicker-modal.style';
interface Props {
  visible: boolean;
  onCancelModal: () => void;
  onSubmitModal: (value: any) => void;
  value?: any;
  blockTimes: any;
}

export const CustomTimePickerModal = React.memo((props: Props) => {
  const classes = useStyles();
  const { visible, onCancelModal, onSubmitModal, value, blockTimes } = props;
  const [currentValue, setCurrentValue] = useState('');
  const onCancelButtonClick = () => {
    setCurrentValue('');
    onCancelModal();
  };

  const onSubmitButtonClick = () => {
    onSubmitModal(currentValue);
  };

  const onTimeClick = (value: any) => {
    setCurrentValue(value);
  };
  const afterModalClose = () => {
    setCurrentValue('')
    document.body.style.setProperty('overflow', 'unset', 'important');
  }

  const renderTime = () => {
    return (blockTimes || []).map((time: any, index: number) => {
      return (
        <Col
          md={6}
          sm={7}
          xs={7}
          className={
            time?.disabled
              ? classes.timeValueDisabled
              : time === currentValue
                ? classes.timeValueActive
                : time === value && !currentValue ?
                  classes.timeValueActive
                  : classes.timeValueNormal
          }
          onClick={() => onTimeClick(time)}
        >
          {time}
        </Col>
      );
    });
  };

  return (
    <div className="custom-time-picker-modal">
      <Modal
        title=""
        visible={visible}
        footer={null}
        onCancel={onCancelModal}
        closeIcon={null}
        closable={false}
        className={classes.modal}
        bodyStyle={{ padding: '16px 12px' }}
        afterClose={afterModalClose}
      // wrapClassName={classes.contentModal}
      >
        <div className={classes.title}>Chọn giờ giao</div>
        <Row className={classes.timeValueContainer}>{renderTime()}</Row>
        <div className={classes.buttonContainer}>
          <div className={classes.cancelButton} onClick={onCancelButtonClick}>
            Hủy bỏ
          </div>
          <div className={classes.okButton} onClick={onSubmitButtonClick}>
            Đồng ý
          </div>
        </div>
      </Modal>
    </div>
  );
});
