import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { images } from '../../core/contants/images';

interface Props {
  className?: string;
}

const useStyles = makeStyles({
  icon: {
    height: 24,
    width: 24,
  },
});

export const MomoIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  return <img src={images.momoColor} className={clsx(classes.icon, className)} />;
});
