import React from 'react';

interface Props {
  className?: string;
}

export const AtmPaymentBackground = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg
      width="374"
      height="86"
      viewBox="0 0 374 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-1" width="375" height="86" fill="#E96E34" />
      <g opacity="0.4">
        <rect
          x="83"
          y="9"
          width="18"
          height="68"
          rx="3"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <rect
          x="14"
          y="18"
          width="76"
          height="51"
          rx="4"
          fill="#E96E34"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <rect x="16" y="27" width="72" height="13" fill="white" />
        <rect
          x="68.75"
          y="47.75"
          width="14.5"
          height="12.5"
          rx="2.25"
          stroke="white"
          strokeWidth="1.5"
        />
        <rect x="69" y="50" width="5" height="2" fill="white" />
        <rect x="77" y="50" width="7" height="2" fill="white" />
        <rect x="69" y="53" width="5" height="2" fill="white" />
        <rect x="77" y="53" width="7" height="2" fill="white" />
        <rect x="69" y="56" width="5" height="2" fill="white" />
        <rect x="77" y="56" width="7" height="2" fill="white" />
      </g>
    </svg>
  );
});
