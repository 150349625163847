import { Modal } from 'antd';
import React from 'react';
import { DeleteCartItemWarningIcon } from '../../../../components/icons/delete-cart-item-warning';
import { RaisedButton } from '../../../../components/raised-button';
import { useStyles } from './delete-item-modal.style';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onCancelButtonClick: () => void;
  onOkButtonClick: (value: any) => void;
  value: any;
}

export const DeleteItemModal = React.memo((props: Props) => {
  const classes = useStyles();
  const { onCancelButtonClick, onOkButtonClick, value } = props;
  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.onClose}
      closeIcon={null}
      centered
      closable={false}
      width={327}
      bodyStyle={{
        padding: '24px 36px',
      }}
      // wrapClassName={classes.container}
    >
      <div className={classes.container}>
        <DeleteCartItemWarningIcon className={classes.icon} />
        <div className={classes.description}>Bạn có chắc chắn muốn xoá {value?.name ?? ''}</div>
        <div className={classes.footer}>
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn} onClick={() => onCancelButtonClick()}>
              Hủy bỏ
            </RaisedButton>
          </div>
          <div style={{ width: 20 }} />
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn2} onClick={() => onOkButtonClick(value)}>
              Đồng ý
            </RaisedButton>
          </div>
        </div>
      </div>
    </Modal>
  );
});
