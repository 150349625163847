import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RaisedButton } from '../raised-button';
import { IBrand } from '../../core/models/brand';
import { RootState } from '../../store';
import { useBrandConfirmStyles } from './brand-confirm.style';

interface Props {
  isVisible: boolean;
  onClose: (e) => void;
  overrideBrand: IBrand;
  onOverride: (e) => void;
}

export const BrandConfirmDialog = React.memo((props: Props) => {
  const classes = useBrandConfirmStyles();
  const appContext = useSelector((state: RootState) => state.appContext);

  return (
    <Modal
      title=""
      visible={props.isVisible}
      footer={null}
      onCancel={props.onClose}
      closeIcon={null}
      centered
      closable={false}
    >
      <div>
        <div className={classes.title}>Thông báo</div>
        <div className={classes.description}>
          Trong giỏ hàng của bạn đang có sản phẩm của {appContext.selectedBrand?.name} tại{' '}
          {appContext.selectedBrand?.province?.name}, tiếp tục mua?
        </div>
        <div className={classes.footer}>
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn} onClick={props.onClose}>
              {appContext.selectedBrand?.name}
            </RaisedButton>
          </div>
          <div style={{ flex: 1 }}>
            <RaisedButton className={classes.btn2} onClick={props.onOverride}>
              {props.overrideBrand?.name}
            </RaisedButton>
          </div>
        </div>
      </div>
    </Modal>
  );
});
