import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';

export const useStyles = makeStyles({
  container: {},
  searchFieldContainer: {
    padding: '12px 16px',
    display: 'flex',
  },
  searchInputContainer: {
    // width: '80%',
    borderRadius: 8,
    backgroundColor: '#F5F5F8',
    height: 44,
    padding: '0 18px',
    border: '1px solid #E0E0E0',
  },
  searchInput: {
    backgroundColor: '#F5F5F8',
  },
  cancelTextContainer: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.primaryColor,
    alignSelf: 'center',
    textAlign: 'right',
    // width: '20%'
  },
  popularKeywordsContainer: {
    padding: '16px',
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  populateKeywordsItemsContainer: {
    marginTop: 12,
    width: '100%',
  },
  keywordItem: {
    padding: '7px 12px',
    backgroundColor: '#FAFAFB',
    marginRight: 8,
    borderRadius: 100,
    cursor: 'pointer',
  },
  recentSearchesContainer: {
    padding: 16,
  },
  searchItem: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 0',
  },
  searchLeftItems: {
    alignItems: 'center',
    display: 'flex',
  },
  searchValue: {
    color: '#6B6B6B',
    fontWeight: 400,
    fontSize: 16,
    marginLeft: 12,
  },
  removeKeywordContainer: {
    padding: 8,
  },
  searchItemCloseIcon: {
    justifySelf: 'flex-end',
  },
  recentSearchesTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  deleteSearch: {
    color: colors.primaryColor,
    fontWeight: 500,
    fontSize: 14,
  },
  searchContentValue: {
    fontWeight: 400,
    fontSize: 16,
    color: '#6B6B6B',
    padding: '12px 16px',
    border: '1px solid #EDF2F9',
  },
  searchContentValueMatch: {
    color: '#000',
    fontWeight: 700,
    padding: 0,
    backgroundColor: 'transparent',
  },
  searchContentValueMatchRead: {
    color: '#6B6B6B',
    fontWeight: 700,
  },
});
