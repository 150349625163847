import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const QrInfo = React.memo(
  ({ className, color }: React.PropsWithChildren<Props>) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1906 18.3387C23.337 16.4993 24 14.3273 24 11.9995C24 5.37169 18.6278 0 12 0C5.37216 0 0 5.37169 0 11.9995C0 18.6293 5.37216 24 12 24C14.3278 24 16.5003 23.3367 18.34 22.1897L23.5997 23.5988L22.1906 18.3387ZM12 5.59631C12.8318 5.59631 13.5067 6.2707 13.5067 7.10302C13.5067 7.93486 12.8323 8.60972 12 8.60972C11.1677 8.60972 10.4933 7.93533 10.4933 7.10302C10.4933 6.2707 11.1682 5.59631 12 5.59631ZM14.6366 18.4042H9.36337V16.1438H10.4933V11.6237H9.36337V9.36384H13.5068V11.6237V16.1438H14.6367V18.4042H14.6366Z"
          fill="white"
        />
      </svg>
    );
  }
);
