import homeHeaderMask from '../../assets/images/home-header-mask.png';
import banner from '../../assets/images/banner.png';
import foodThumbnail from '../../assets/images/food-thumbnail.png';
import brandLogo from '../../assets/images/brand-logo.png';
import cartDiscountBg from '../../assets/images/cart-discount-bg.png';
import rectangleBackground from '../../assets/images/rectangle-background.png';
import chef from '../../assets/images/chef.png';
import orderArrived from '../../assets/images/order-arrived.png';
import orderDelivering from '../../assets/images/order-delivering.png';
import logo from '../../assets/images/logo.svg';
import empty from '../../assets/images/empty.png';
import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import cash from '../../assets/images/cash.png';
import momo from '../../assets/images/momo.png';
import facebook from '../../assets/images/facebook.png';
import youtube from '../../assets/images/youtube.png';
import goldenSpoon from '../../assets/images/golden-spoon.png';
import verified from '../../assets/images/verified.png';
import hotDealLabel from '../../assets/images/label-hot-deal.png';
import bestSellerLabel from '../../assets/images/label-best-seller.png';
import newProductLabel from '../../assets/images/label-new-product.png';
import couponThumbnail from '../../assets/images/coupon-thumbnail.png';
import deliveryBox from '../../assets/images/delivery-box.png';
import giftBox from '../../assets/images/gift-box.png';
import quality from '../../assets/images/quality.png';
import shoppingList from '../../assets/images/shopping-list.png';
import coin from '../../assets/images/coin.png';
import zalo from '../../assets/images/zalo.png';
import vnpay from '../../assets/images/vnpay.png';
import clipboard from '../../assets/images/clipboard.svg';
import bestSeller from '../../assets/images/best-seller.svg';
import bestChoice from '../../assets/images/best-choice.png';
import new1 from '../../assets/images/new.svg';
import premiumQuality from '../../assets/images/premium-quality.svg';
import logoSquare from '../../assets/images/logo-square.png';
import momoColor from '../../assets/images/momo-color.png';
import avatarPlaceholder from '../../assets/images/avatar-placeholder.png';
import noticeSuccess from '../../assets/images/notice-success.svg';

export const images = {
  homeHeaderMask,
  banner,
  foodThumbnail,
  brandLogo,
  cartDiscountBg,
  rectangleBackground,
  chef,
  orderArrived,
  orderDelivering,
  logo,
  empty,
  visa,
  mastercard,
  cash,
  momo,
  facebook,
  youtube,
  goldenSpoon,
  verified,
  hotDealLabel,
  couponThumbnail,
  deliveryBox,
  giftBox,
  quality,
  shoppingList,
  coin,
  zalo,
  vnpay,
  bestSellerLabel,
  newProductLabel,
  new1,
  clipboard,
  bestChoice,
  bestSeller,
  premiumQuality,
  logoSquare,
  momoColor,
  avatarPlaceholder,
  noticeSuccess,
};
