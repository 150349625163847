import { makeStyles } from '@material-ui/core';

export const useBrandSliderStyles = makeStyles({
  slider: {
    padding: '0px 16px',
    marginBottom: 20,
    marginTop: 3,
    width: '100%',
  },
  banner: {
    width: '100%',
    cursor: 'pointer',
  },
  arrowContainer: {
    width: 40,
    height: 40,
    borderRadius: 100,
    background: '#fff',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,

    '&::before': {
      content: "''",
    },

    '&:hover': {
      background: '#fff',
    },
  },

  dots: {
    bottom: 0,
    '& li.slick-active button::before': {
      color: '#fff',
    },
    '& li': {
      '& button::before': {
        fontSize: 10,
        color: '#fff',
      },
    },
  },
});
