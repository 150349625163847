import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';

export const useLoginStyles = makeStyles({
  container: {
    // width: 327,
  },
  contentContainer: {
    padding: '0px 4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    flex: 1,
    textAlign: 'center',
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EDEDED',
    userSelect: 'none',
  },
  whiteBackground: {
    backgroundColor: '#fff',
  },
  instructionText: {
    color: colors.textSecondaryColor,
    marginTop: 6,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 400,
    padding: '0px 8px',
  },
  seperator: {
    marginTop: 24,
    marginBottom: 20,
    borderColor: colors.dividerColor,
  },
  buttonText: {
    fontWeight: 800,
    fontSize: 16,
  },
});
