import React, { Fragment } from 'react';
import { NoVoucherDataIcon } from '../../../components/icons/no-voucher-data';
import { useStyles } from './coupon-tab.style';

interface Props {
  data: any;
  onCouponClick: (value: any) => void;
  selectedVouchers: any;
  onCancelCoupon: (value: any) => void;
  onApplyVoucherClick: (value: any) => void;
}

export const CouponTab = React.memo((props: Props) => {
  const { data, onCouponClick,selectedVouchers,  onCancelCoupon, onApplyVoucherClick } = props;
  const classes = useStyles();
  const selectedVouchersCode = selectedVouchers?.length ? selectedVouchers.map((value: any) => {
    return value?.code ?? ''
  }) : []
  const renderCoupon = () => {
    if (data?.length) {
      return data.map((value: any, index: number) => {
        const isSelectedVoucher = selectedVouchersCode.indexOf(value?.giftValue?.serialNo) !== -1
        const convertCurrentStatus = () => {
          if (isSelectedVoucher) {
              return <div className={classes.cancelText} onClick={() => onCancelCoupon(value)}>Hủy áp dụng</div>;
            // code block
          } else {
            return <div className={classes.activeText} onClick={() => onApplyVoucherClick(value)}>Sử dụng ngay</div>;
          }
        };
        return (
          <div className={classes.couponItem} key={index}>
            <img src={value?.promotionThumbnail} className={classes.imgTitle} onClick={() => {if (!isSelectedVoucher) onCouponClick(value)}} />
            <div className={classes.couponInfoContainer}>
              <div className={classes.rowSpaceBetween} onClick={() => {if (!isSelectedVoucher) onCouponClick(value)}}>
                {/* <div className={classes.couponItemCount}>Còn lại {value?.count ?? 0} mã</div> */}
                <div className={classes.date} onClick={() => {if (!isSelectedVoucher) onCouponClick(value)}}>Hạn đến {value?.endDate}</div>
              </div>
              <div className={classes.title} onClick={() => {if (!isSelectedVoucher) onCouponClick(value)}}>{value?.promotionTitle ?? ''}</div>
              {convertCurrentStatus()}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className={classes.noDataIconContainer}>
        <NoVoucherDataIcon/>

        </div>
      )
    }
  };
  return <Fragment>{renderCoupon()}</Fragment>;
});
