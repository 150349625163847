import { makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import { colors } from '../core/contants/colors';

interface Props {
  //   size?: number;
  visible: boolean;
}

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 100,
    height: 100,
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 10,
  },
  spinner: {
    animation: '$spinEffect 2s 0s infinite linear',
    background: 'transparent !important',
    width: 25,
    height: 25,
    borderRadius: '100%',
    border: '3px solid',
    borderColor: colors.primaryColor,
    borderBottomColor: '#ededed',
    display: 'inline-block',
    animationFillMode: 'both',
  },

  '@keyframes spinEffect': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(180deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

export const ModalSpinner = React.memo((props: Props) => {
  const classes = useStyles(props);
  const { visible } = props;
  return (
    <Modal open={visible}>
      <div className={classes.modalContent}>
        <span className={classes.spinner} />
      </div>
    </Modal>
  );
});
