import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../core/contants/colors';

export const useModifierSelectorStyles = makeStyles({
  container: {
    marginTop: 16,
  },
  title: {
    color: '#000',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
  },
  tagModifierList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagModifierItem: {
    backgroundColor: colors.smoke,
    padding: '10px 16px',
    color: '#000',
    textAlign: 'center',
    borderRadius: 100,
    marginRight: 16,
    marginTop: 12,
    cursor: 'pointer',
    userSelect: 'none',
  },
  activeTagModifierItem: {
    backgroundColor: colors.primaryColor,
    color: '#fff',
  },
  checkboxContainer: {
    width: '100%',
    marginTop: 12,
  },
  checkboxModifierList: {
    marginTop: 6,
  },
  checkboxLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    color: '#000',
    fontWeight: 500,
  },
});
