import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IPage } from '../core/models/page';

const getList = () => axios.get<IApiResponse<{ title: string; alias: string }[]>>('/post/list');
const getPageDetail = (id: string) => axios.get<IApiResponse<IPage>>(`/post/${id}`);

export const pageService = {
  getList,
  getPageDetail,
};
