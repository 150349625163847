import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';

const gap = 40;

export const useAppFooterStyles = makeStyles({
  container: {
    // display: "none",
    // [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
    //   display: "block",
    // },
  },
  firstBlock: {
    backgroundColor: '#323130',
    padding: '24px 16px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: '60px 0',
    },
  },
  firstBlockContentContainer: {
    display: 'grid',
    gridGap: gap,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  title: {
    fontSize: 20,
    color: '#fff',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: 24,
  },
  normalText: {
    color: '#fff',
    opacity: 0.7,
    fontWeight: 300,
    lineHeight: '30px',
  },
  secondBlock: {
    backgroundColor: '#262626',
    padding: '30px 0',
  },
  secondBlockContentContainer: {
    display: 'grid',
    gridGap: gap,
    padding: '16px 16px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gridGap: gap * 3,
      gridTemplateColumns: '3fr 1fr',
    },
  },
  paymentMethodContainer: {
    display: 'flex',
    // gridTemplateColumns: 'repeat(4, auto)',
    gridGap: 14,
  },
  paymentMethod: {
    height: 31,
    width: 48,
  },
  socialMediaRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialIcon: {
    height: 16,
    width: 16,
  },
  supportContentContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 12,
  },
  subTitle: {
    fontWeight: 700,
    color: '#FFFFFF',
    lineHeight: '30px',
  },
  companyName: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 700,
  },
  verifiedLogo: {
    width: 121,
    height: 46,
  },
  copyRightText: {
    fontSize: 12,
    color: '#fff',
    opacity: 0.7,
    lineHeight: '30px',
    marginTop: 8,
  },
});
