import React from "react";

interface Props {
  className?: string;
}

export const AtmIcon = React.memo(({ className }: React.PropsWithChildren<Props>) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="21" height="15" rx="2.5" stroke="#221F20"/>
    <rect x="13" y="3" width="6" height="2" rx="1" fill="#E96E34"/>
    <path d="M6.99997 12.9999C6.75464 12.9999 6.55553 12.8319 6.55553 12.625V8.87577C6.55553 8.25565 5.95731 7.751 5.22221 7.751C4.4871 7.751 3.88888 8.25565 3.88888 8.87577V12.625C3.88888 12.8319 3.68977 12.9999 3.44444 12.9999C3.19911 12.9999 3 12.8319 3 12.625V8.87577C3 7.84248 3.99644 7.00116 5.22221 7.00116C6.44798 7.00116 7.44441 7.84248 7.44441 8.87577V12.625C7.44441 12.8319 7.2453 12.9999 6.99997 12.9999Z" fill="#221F20"/>
    <path d="M6.99997 10.7502H3.44444C3.19911 10.7502 3 10.5822 3 10.3753C3 10.1683 3.19911 10.0004 3.44444 10.0004H6.99997C7.2453 10.0004 7.44441 10.1683 7.44441 10.3753C7.44441 10.5822 7.2453 10.7502 6.99997 10.7502Z" fill="#221F20"/>
    <path d="M10.5556 12.9998C10.3103 12.9998 10.1111 12.8318 10.1111 12.6249V7.37596C10.1111 7.169 10.3103 7.00104 10.5556 7.00104C10.8009 7.00104 11 7.169 11 7.37596V12.6249C11 12.8318 10.8009 12.9998 10.5556 12.9998Z" fill="#221F20"/>
    <path d="M12.3335 7.75089H8.778C8.53267 7.75089 8.33356 7.58292 8.33356 7.37596C8.33356 7.169 8.53267 7.00104 8.778 7.00104H12.3335C12.5788 7.00104 12.778 7.169 12.778 7.37596C12.778 7.58292 12.5788 7.75089 12.3335 7.75089Z" fill="#221F20"/>
    <path d="M18.5556 12.9998C18.3103 12.9998 18.1112 12.8318 18.1112 12.6248V8.61393L17.1476 9.83317C16.9823 10.0416 16.5734 10.0416 16.4081 9.83317L15.4445 8.61393V12.6248C15.4445 12.8318 15.2454 12.9998 15.0001 12.9998C14.7548 12.9998 14.5557 12.8318 14.5557 12.6248V7.37594C14.5557 7.21097 14.6846 7.06475 14.8712 7.01676C15.0605 6.96952 15.2605 7.03101 15.3699 7.16748L16.7779 8.94911L18.1858 7.16748C18.2943 7.03026 18.4943 6.96727 18.6845 7.01676C18.8712 7.06475 19.0001 7.21097 19.0001 7.37594V12.6248C19.0001 12.8318 18.801 12.9998 18.5556 12.9998Z" fill="#221F20"/>
    </svg>
    
  );
});
