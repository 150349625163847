import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { BREAK_POINTS } from '../core/contants/break-points';
import { images } from '../core/contants/images';
import { IBrand } from '../core/models/brand';

interface Props {
  className?: string;
  brand?: IBrand;
}

const useStyles = makeStyles({
  brandContainer: {
    height: 64,
    width: 64,
    borderRadius: 12,
    backgroundColor: '#EDEDED',
    placeSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: "center",

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 96,
      width: 96,
    },
  },
  logo: {
    // filter: "grayscale(100%)",
    // WebkitFilter: "grayscale(100%)" /* Safari 6.0 - 9.0 */,
  },
});

export const BrandItem = React.memo((props: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.brandContainer, props.className)}>
      <img className={classes.logo} src={props.brand?.logoBW || images.brandLogo} alt="" />
    </div>
  );
});
