import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../contants/break-points';

export const useAppLayoutStyles = makeStyles({
  appContentContainer: {
    width: '100%',
    margin: '0 auto',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      maxWidth: 'none',
      padding: '0px 20px',
    },

    // [`@media (min-width: ${BREAK_POINTS.MD}px)`]: {
    //   paddingLeft: "5%",
    //   paddingRight: "5%",
    // },

    [`@media (min-width: ${BREAK_POINTS.LG}px)`]: {
      maxWidth: '1144px',
    },
  },
});
