import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';

export const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 700
  },
  selectDiscountCodeText: {
    color: colors.accentColor,
    fontSize: '14px',
    fontWeight: 500
  },
  discountTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '16px 0'
  },
  discountContainer: {
    padding: '0 16px'
  },
  noDiscountCodeText: {
    color: colors.textGray,
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '16px'
  },
  priceContainer: {
    padding: '16px'
  },
  priceMainRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '11px',
    alignContent: 'flex-end',
  },
  priceMainItemTitle: {
    fontSize: '14px',
    fontWeight: 400,
    alignSelf: 'center'
  },
  priceMainItemValue: {
    fontSize: '16px',
    fontWeight: 500,
  },
  priceSubRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '11px',
  },
  priceSubItemTitle: {
    color: '#6B6B6B',
    fontSize: '12px',
    fontWeight: 400,
    alignSelf: 'center'
  },
  priceSubItemValue: {
    color: '#6B6B6B',
    fontSize: '14px',
    fontWeight: 500,
  },
  priceDivider: {
    border: '1px dashed #D8D8D8',
    marginBottom: '16px'
  },
  totalPriceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
  },
  totalPriceTitle: {
    color: '#221F20',
    fontSize: '16px',
    fontWeight: 700,
    alignSelf: 'center'
  },
  totalPriceValue: {
    fontSize: '20px',
    fontWeight: 900,
    color: colors.primaryColor
  },
  subRowLastItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '11px',
    marginBottom: '16px'
  },
  submitButton: {
    margin: '16px 16px 32px 16px'
  },
  submitButtonContainer: {
    bottom: 0,
    width: '100%',
    background: '#fff',
  },
  paymentMethodsContainer: {
    padding: '16px'
  },
  paymentMethodsListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '20px'
  },
  paymentMethodItem: {
    // flexBasis: 1,
  },
  checkBoxValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '14px'
  },
  paymentMethodName: {
    marginLeft: '11px'
  },
});