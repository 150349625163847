import { AxiosError, AxiosResponse } from 'axios';
import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { ICart, IPickUpAtRestaurant } from '../core/models/cart';

const addToCart = (body: {
  productId: string;
  quantity?: number;
  override?: boolean;
  longitude?: number | null;
  latitude?: number | null;
  toppingIds?: number[];
  modifier?: { categoryId: number; data: { id: number }[] }[];
}) =>
  axios.post<IApiResponse<ICart>>('/cart/add', body as any, {
    params: body.override ? { force: body.override } : {},
  });

const quickAddToCart = (body: {
  productId: string;
  override?: boolean;
  longitude?: number | null;
  latitude?: number | null;
  toppingIds?: number[];
  lineItemId?: string;
  modifier?: { categoryId: number; data: { id: number }[] }[];
}) =>
  axios.post<IApiResponse<ICart>>('/cart/quick/add', body as any, {
    params: body.override ? { force: body.override } : {},
  });

const quickRemoveFromCart = (body: {
  productId?: string;
  lineItemId?: string;
  modifier?: { categoryId: number; data: { id: number }[] }[];
  toppingIds?: number[];
}) => axios.post<IApiResponse<ICart>>('/cart/quick/remove', body as any);

const removeItem = (params: { productId?: string; lineItemId?: string }) =>
  axios.get<IApiResponse<ICart>>(`/cart/${params.productId}/remove`, { params });

const setPickUpAtRestaurant = (body: { pickupAtRestaurant: boolean }) =>
  axios.post<IApiResponse<IPickUpAtRestaurant>>('restaurant/pickup', body as any);

const getAllowBlockOrderTime = (params?: {
  orderTime: string;
  restaurantCode: string;
  brandId?: any;
  provinceId?: any;
}) =>
  axios.get<never, AxiosResponse<IApiResponse<ICart>>>(
    `/restaurant/${params?.restaurantCode}/order-time?time=${params?.orderTime}${
      params?.brandId ? '&brandId=' + params?.brandId : ''
    }${params?.provinceId ? '&provinceId=' + params?.provinceId : ''}`,
  );
const getDetail = (params?: {
  shippingVendor?: string;
  recalculateShippingFee?: number;
  paymentMethod?: string;
}) => {
  return axios.get<never, AxiosResponse<IApiResponse<ICart>>>('/cart/list', {
    params: {
      shippingVendor: params?.shippingVendor || 'grab_express',
      recalculateShippingFee: params?.recalculateShippingFee || 1,
      paymentMethod: params?.paymentMethod,
    },
  });
};

const changeAddress = (body: any) => axios.post('/cart/pick/address', body);

const getShippingRates = (body: any) => axios.post('/shipping/rates', body);
const getNearestRestaurant = (body: {
  latitude: number;
  longitude: number;
  categoryId: any;
  brandId: any;
  provinceId?: number;
  wardId?: number;
  districtId?: number;
}) => axios.post('/restaurant/nearest', body);
const moveCartToUser = (params: any) =>
  axios.get<IApiResponse<null>>('/instead/customer/cart', { params });
const calculateCart = () => axios.get('/cart/calculate');

export const cartService = {
  addToCart,
  quickAddToCart,
  quickRemoveFromCart,
  getDetail,
  setPickUpAtRestaurant,
  getAllowBlockOrderTime,
  changeAddress,
  moveCartToUser,
  removeItem,
  getShippingRates,
  getNearestRestaurant,
  calculateCart,
};
