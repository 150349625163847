import React from "react";

interface Props {
  className?: string;
}

export const VnptEpayIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <img src="https://s3-alpha-sig.figma.com/img/ec08/923c/7837a666974dfa1b8ba8cec813e4f1b4?Expires=1632096000&Signature=ZRr61B89t-3D24~JG1gS3qhIDZ~baCRce0iVrNtNeduiwj353blE288jferlqErc8H7I8cwUQ5nRLy-OUJNnaDvpv4tTND0EVbSU8K~erD6XMC2xZnlUeZrx2gn4W-IR-kP9xhciprsVsjxx1OV6uHZwqLqd-KR--f3XER797w616Q1OZXLShxNAoojzmzn3u9fcUb~tIu1VS~Gm2~mjBxhWsiAdcXuX~qJRley5vEnUiGlpRqTARTDu6P8LuEIObcnyKokf1vxH0f3Wbi2az-3X-eC4OC8qm3nt02iN6Zo~1UgRhYLZpdtj68QxaLf0DTrtRiQG12RKXNh15u4tiA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" width="108px" height="24px"/>
    );
  }
);
