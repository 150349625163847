import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { ITag } from '../core/models/tag';

const getTags = (params: { provinceId?: number }) =>
  axios.get<IApiResponse<ITag[]>>('/tag', { params });

export const tagService = {
  getTags,
};
