import React from "react";
interface Props {
  className?: any;
}
export const SearchCloseIcon = React.memo((props: Props) => {
  return (
    <svg
      className={props.className ? props.className : ""}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.645917 0.645917C0.692363 0.599354 0.747538 0.562411 0.808283 0.537204C0.869028 0.511998 0.93415 0.499023 0.999917 0.499023C1.06568 0.499023 1.13081 0.511998 1.19155 0.537204C1.2523 0.562411 1.30747 0.599354 1.35392 0.645917L3.99992 3.29292L6.64592 0.645917C6.6924 0.599429 6.74759 0.562553 6.80833 0.537394C6.86907 0.512234 6.93417 0.499285 6.99992 0.499285C7.06566 0.499285 7.13076 0.512234 7.1915 0.537394C7.25224 0.562553 7.30743 0.599429 7.35392 0.645917C7.4004 0.692405 7.43728 0.747594 7.46244 0.808333C7.4876 0.869073 7.50055 0.934173 7.50055 0.999917C7.50055 1.06566 7.4876 1.13076 7.46244 1.1915C7.43728 1.25224 7.4004 1.30743 7.35392 1.35392L4.70692 3.99992L7.35392 6.64592C7.4004 6.69241 7.43728 6.74759 7.46244 6.80833C7.4876 6.86907 7.50055 6.93417 7.50055 6.99992C7.50055 7.06566 7.4876 7.13076 7.46244 7.1915C7.43728 7.25224 7.4004 7.30743 7.35392 7.35392C7.30743 7.40041 7.25224 7.43728 7.1915 7.46244C7.13076 7.4876 7.06566 7.50055 6.99992 7.50055C6.93417 7.50055 6.86907 7.4876 6.80833 7.46244C6.74759 7.43728 6.6924 7.40041 6.64592 7.35392L3.99992 4.70692L1.35392 7.35392C1.30743 7.40041 1.25224 7.43728 1.1915 7.46244C1.13076 7.4876 1.06566 7.50055 0.999917 7.50055C0.934173 7.50055 0.869073 7.4876 0.808333 7.46244C0.747594 7.43728 0.692405 7.40041 0.645917 7.35392C0.599429 7.30743 0.562553 7.25224 0.537394 7.1915C0.512234 7.13076 0.499285 7.06566 0.499285 6.99992C0.499285 6.93417 0.512234 6.86907 0.537394 6.80833C0.562553 6.74759 0.599429 6.69241 0.645917 6.64592L3.29292 3.99992L0.645917 1.35392C0.599354 1.30747 0.562411 1.2523 0.537204 1.19155C0.511998 1.13081 0.499023 1.06568 0.499023 0.999917C0.499023 0.93415 0.511998 0.869029 0.537204 0.808283C0.562411 0.747538 0.599354 0.692363 0.645917 0.645917Z"
        fill="#040C22"
      />
    </svg>
  );
});
