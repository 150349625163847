import { AxiosResponse } from 'axios';
import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IAuthentication, IUserProfile } from '../core/models/user';

const requestOtp = (body: { cellphone: string; method: string; forceResend?: boolean }) =>
  axios.post<any, AxiosResponse<IApiResponse<null>>>('/authentication/request-otp', body);

const login = (body: { cellphone: string; otp: string }) =>
  axios.post<
    { cellphone: string; otp: string },
    AxiosResponse<IApiResponse<{ profile: IUserProfile; authentication: IAuthentication }>>
  >('/authentication/login', body);

const logout = () => axios.get<IApiResponse<null>>('/customer/logout');

const autoLogin = (body: { token: string; customerNumber?: string }) =>
  axios.post<
    { token: string; customerNumber?: string },
    AxiosResponse<IApiResponse<{ profile: IUserProfile; authentication: IAuthentication }>>
  >('/authentication/auto-login', body);

export const authenticationService = {
  requestOtp,
  login,
  logout,
  autoLogin,
};
