import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IUserProfile } from '../core/models/user';

const updateProfile = (body: any) =>
  axios.put<IApiResponse<IUserProfile>>('/customer/profile', body);

const gBizAccountInfo = (body?: any) =>
  axios.get<IApiResponse<any>>('/customer/gbiz-account', body);

export const userService = {
  updateProfile,
  gBizAccountInfo
};
