import clsx from 'clsx';
import { debounce } from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { images } from '../../core/contants/images';
import { UNSUPPORTED_PROVINCES } from '../../core/environments/env';
import { I18N_NAMESPACES } from '../../core/i18n';
import { IBrand } from '../../core/models/brand';
import { IProduct } from '../../core/models/product';
import { IProvince } from '../../core/models/province';
import { useAppLayoutStyles } from '../../core/styles/layout';
import { LoginScreen } from '../../screens/login/login.screen';
import { brandService } from '../../services/brand-service';
import { productService } from '../../services/product-service';
import { Dispatch, RootState, store } from '../../store';
import { ConfirmModal } from '../confirm-modal/confirm-modal.component';
import { ArrowDownIcon } from '../icons/arrow-down';
import { CartFilledIcon } from '../icons/cart-filled';
import { LocationIcon } from '../icons/locations';
import { ProfileFilledIcon } from '../icons/profile-filled';
import { SearchIcon } from '../icons/search';
import { ProvinceSelectorPopOver } from '../province-selector';
import { TextInput } from '../text-input';
import { ProfileMenuPopOver } from './profile-menu-pop-over';
import { SearchResultPopOver } from './search-result-pop-over';
import { useAppHeaderStyles } from './style';
import {Popover} from "antd";
import {useMediaQuery} from "@material-ui/core";
import {BREAK_POINTS} from "../../core/contants/break-points";
import "./custom.css";

export const AppHeader = React.memo(() => {
  const classes = { ...useAppHeaderStyles(), ...useAppLayoutStyles() };
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation([I18N_NAMESPACES.HOME]);
  const [visibleProvinceSelector, setVisibleProvinceSelector] = useState(false);
  const [isVisibleLogin, setIsVisibleLogin] = useState(false);
  const [isVisibleSearchResult, setIsVisibleSearchResult] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [switchProvinceError, setSwitchProvinceError] = useState<string | null>(null);
  const [processingProvince, setProcessingProvince] = useState<IProvince | null>(null);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const user = useSelector((state: RootState) => state.user);
  const cartLength = useSelector((state: RootState) => store.select.cart.cartLength(state));
  const appContext = useSelector((state: RootState) => state.appContext);

  const onLogin = useCallback(() => {
    setIsVisibleLogin(true);
  }, []);

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
    searchProductDebounce(text);
  };

  const searchProductDebounce = useCallback(
    debounce(
      async (text) => {
        const response = await productService.searchProduct({
          name: text || '',
          provinceId: appContext.selectedProvince?.id,
        });
        setProducts(response?.data?.result?.products || []);
        setBrands(response?.data?.result?.brands || []);
      },
      200,
      { maxWait: 600 },
    ),
    [],
  );

  const onSearchSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/search-result',
      search: new URLSearchParams({ 'search-text': searchText || '' }).toString(),
    });
    setProducts([]);
    setIsVisibleSearchResult(false);
  };

  const onSelectProvince = async (province) => {
    if (UNSUPPORTED_PROVINCES.includes(province.id)) {
      dispatch.appContext.setError(
        'G-Delivery tạm dừng dịch vụ theo chỉ thị phòng chống dịch của thủ tướng chính phủ.\nVui lòng liên hệ số điện thoại 02473003077 để được hỗ trợ.\nXin lỗi Quý khách vì sự bất tiện này.',
      );
    }
    const response = await brandService.switchProvince(province.id);

    if (response?.data.messageCode === 200) {
      dispatch.appContext.setProvince(province);
    } else if (response.data?.messageCode === 409) {
      setProcessingProvince(province);
      setSwitchProvinceError(response.data?.message || '');
    }
  };

  const onConfirmSwitchProvince = async () => {
    if (!processingProvince) return;
    const response = await brandService.switchProvince(processingProvince.id, true);

    if (response.data?.messageCode === 200) {
      dispatch.appContext.setProvince(processingProvince);
      dispatch.cart.getCartDetail({});
      setProcessingProvince(null);
      setSwitchProvinceError(null);
    }
  };

  useEffect(() => {
    if (appContext.flagAddProductSuccess) {
      setTimeout(() => {
        dispatch.appContext.setFlagAddProductSuccess(false);
      }, 3000);
    }
  }, [appContext.flagAddProductSuccess]);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.appContentContainer, classes.localContentContainer)}>
        <div className={classes.locationContainer}>
          <Link to="/">
            <img src={images.logo} alt="" className={classes.logo} />
          </Link>

          {!['/cart', '/verify-order'].includes(pathname) && (
            <ProvinceSelectorPopOver
              selectedId={appContext.selectedProvince?.id}
              onChanged={onSelectProvince}
              isVisible={visibleProvinceSelector}
              onVisibleChanged={setVisibleProvinceSelector}
            >
              <div
                className={classes.deliveryContainer}
                onClick={() => setVisibleProvinceSelector(true)}
              >
                <LocationIcon className={classes.locationIcon} />
                <div className={classes.locationText}>
                  {t('deliveryTo')}: <b>{appContext.selectedProvince?.name}</b>
                </div>
                <ArrowDownIcon className={classes.arrowDownIcon} />
              </div>
            </ProvinceSelectorPopOver>
          )}
        </div>

        <ConfirmModal
          isVisible={!!switchProvinceError}
          onClose={() => setSwitchProvinceError(null)}
          message={switchProvinceError || ''}
          onConfirm={onConfirmSwitchProvince}
        />

        {/* <form onSubmit={onSearchSubmit}> */}
        <SearchResultPopOver
          products={products}
          brands={brands}
          isVisible={isVisibleSearchResult && (products.length > 0 || brands.length > 0)}
          searchText={searchText || ''}
          onVisibleChange={setIsVisibleSearchResult}
        >
          <form className={classes.searchTextInputContainer} onSubmit={onSearchSubmit}>
            <SearchIcon />
            <TextInput
              containerClassname={classes.searchTextInput}
              textInputContainerClassname={classes.searchTextInput}
              placeholder={t('searchPlaceholder')}
              value={searchText}
              onChange={onSearchTextChanged}
              onFocus={() => setIsVisibleSearchResult(true)}
              // onSubmit={onSearchSubmit}
              // onBlur={() => setIsVisibleSearchResult(false)}
            />
          </form>
        </SearchResultPopOver>
        {/* </form> */}

        <div className={classes.actionContainer}>
          <Link to="/cart">
            <div className={classes.actionIconContainer}>
              <CartFilledIcon className={classes.actionIcon}/>
              <span className={classes.actionText}>Giỏ hàng</span>

              <div className={classes.badge}>{cartLength || 0}</div>
              {isDesktop && appContext.flagAddProductSuccess && (
                <div className={classes.blockNoticeSuccess}>
                  <div className={classes.arrowUp}></div>
                  <div className={classes.noticeAddCartSuccess}>
                    <img src={images.noticeSuccess} className={classes.noticeSuccess} alt=""/>
                    Đã thêm sản phẩm vào giỏ hàng
                  </div>
                </div>
              )}
            </div>
          </Link>

          {user.authentication.token ? (
            <ProfileMenuPopOver>
              <div className={classes.actionIconContainer}>
                <ProfileFilledIcon className={classes.actionIcon} />
                <span className={classes.actionText}>Xin chào, {user.profile.fullName}</span>
                <ArrowDownIcon />
              </div>
            </ProfileMenuPopOver>
          ) : (
            <div className={classes.actionIconContainer} onClick={onLogin}>
              <ProfileFilledIcon className={classes.actionIcon} />
              <span className={classes.actionText}>Đăng nhập</span>
            </div>
          )}
        </div>
      </div>

      <LoginScreen
        isVisible={isVisibleLogin}
        close={() => {
          setIsVisibleLogin(false);
        }}
      />
    </div>
  );
});
