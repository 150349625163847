import { Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CartButton } from '../../../../components/cart-button';
import { CartDivider } from '../../../../components/cart-divider';
import { Header } from '../../../../components/header';
import { CalendarIcon } from '../../../../components/icons/calendar';
import { TimeIcon } from '../../../../components/icons/time';
import { TextInput } from '../../../../components/text-input';
import { cartService } from '../../../../services/cart-service';
import { Dispatch, RootState } from '../../../../store';
import { useStyles } from './delivery-info.style';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppRadioBox } from '../../../../components/radio';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACES } from '../../../../core/i18n';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GG_API_KEY } from '../../../../core/environments/env';

interface Props {
  selectedAddress: any;
  cartData: any;
  setCartData: any;
  selectedRestaurant: any;
  blockTimes: any;
  customDatePickerVisible: boolean;
  datePickerValue: any;
  customTimePickerVisible: boolean;
  isPlastic: boolean;
  timePickerValue: any;
  setIsPlastic: (value: boolean) => void;
  onChangeDatePickerValue: (value: Date) => void;
  onChangeAddressClick: () => void;
  onDatePickerInputClick: () => void;
  onTimePickerInputClick: () => void;
  onCloseCustomDatePicker: () => void;
  onSubmitCustomDatePicker: (value: any) => void;
  onCloseCustomTimePicker: () => void;
  onSubmitCustomTimePicker: (value: any) => void;
  onSubmitClick: (formValues: any) => void;
  onPickUpRestaurant: (value: boolean) => void;
  onOpenChangeRestaurantModal: () => void;
  getNearestRestaurant: (selectedAddress: any) => void;
}
const Info = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACES.PROFILE]);
  const {
    selectedAddress,
    cartData,
    selectedRestaurant,
    onChangeAddressClick,
    onDatePickerInputClick,
    onTimePickerInputClick,
    onSubmitClick,
    onPickUpRestaurant,
    datePickerValue,
    isPlastic,
    setIsPlastic,
    timePickerValue,
    onOpenChangeRestaurantModal,
    getNearestRestaurant,
  } = props;
  const classes = useStyles();
  const formRef = useRef(null) as any;
  const user = useSelector((state: RootState) => state.user);
  const deliveryInfoWithoutAddressSchema = Yup.object().shape({
    address: Yup.mixed(),
    // .when('onPickUpRestaurant', {
    // is: false,
    // then: Yup.mixed().required('Vui lòng chọn địa chỉ'),
    // }),
    deliveryTime: Yup.string().required('Vui lòng chọn thời gian'),
    deliveryDate: Yup.string().required('Vui lòng chọn thời gian'),
    onPickUpRestaurant: Yup.boolean(),
    phone: Yup.string().when('onPickUpRestaurant', {
      is: false,
      then: Yup.string().required('Số điện thoại là bắt buộc')
        .length(10, 'Số điện thoại cần có 10 số'),
    }),

    name: Yup.string().when('onPickUpRestaurant', {
      is: false,
      then: Yup.string().required('Tên là bắt buộc').min(3, 'Tên phải có ít nhất 3 kí tự'),
    }),
    longitude: Yup.number().nullable().when('onPickUpRestaurant', {
      is: false,
      then: Yup.number().nullable().required('Vui lòng chọn địa chỉ từ danh sách'),
    }),
  });
  const deliveryInfoSchema = Yup.object().shape({
    address: Yup.mixed().when('onPickUpRestaurant', {
      is: false,
      then: Yup.mixed().required('Vui lòng chọn địa chỉ'),
    }),
    deliveryTime: Yup.string().required('Vui lòng chọn thời gian'),
    deliveryDate: Yup.string().required('Vui lòng chọn thời gian'),
    onPickUpRestaurant: Yup.boolean(),
  });
  useEffect(() => {
    if (!_.isEmpty(selectedAddress)) {
      formRef?.current?.setFieldValue('address', selectedAddress);
      formRef?.current?.setFieldError('address', '');
    }
  }, [selectedAddress]);
  useEffect(() => {
    if (cartData?.pickupAtRestaurant) {
      formRef?.current?.setFieldError('address', '');
    }
    formRef?.current?.setFieldValue('onPickUpRestaurant', cartData?.pickupAtRestaurant)
  }, [cartData?.pickupAtRestaurant]);

  useEffect(() => {
    if (datePickerValue) {
      formRef?.current?.setFieldValue('deliveryDate', datePickerValue);
    }
    formRef?.current?.validateField('deliveryDate');
  }, [datePickerValue]);
  useEffect(() => {
    if (timePickerValue) {
      formRef?.current?.setFieldValue('deliveryTime', timePickerValue || timePickerValue);
    }
  }, [timePickerValue]);
  useEffect(() => {
    if (timePickerValue) {
      formRef?.current?.setFieldError('deliveryTime', '');
    }
  }, [timePickerValue]);
  const { ref } = usePlacesWidget({
    apiKey: GG_API_KEY,
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      formRef?.current?.setFieldValue('latitude', lat);
      formRef?.current?.setFieldValue('longitude', lng);
      formRef?.current?.setFieldValue('address', place.formatted_address);
      formRef?.current?.setFieldValue('googleMapPlaceId', place.place_id);
      const splittedAddress = (place.formatted_address ?? '').split(', ');
      let address = ""
      if (splittedAddress.length === 6) {
        address = `${splittedAddress[0]} ${splittedAddress[1]}`;

      } else if (splittedAddress.length === 5) {
        address = splittedAddress[0];
      } else if (splittedAddress.length === 4) {
      }
      getNearestRestaurant({ latitude: lat, longitude: lng, addressLine1: address })
      formRef?.current?.validateField('longitude')
    },
    options: {
      types: [],
      componentRestrictions: { country: 'vn' },
    },
  });
  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        validateOnChange={false}
        // innerRef={formRef}
        initialValues={{
          address: null,
          deliveryTime: '',
          deliveryDate: moment(),
          onPickUpRestaurant: false,
          name: user?.profile?.fullName ?? '',
          phone: user?.profile?.cellphone ?? '',
          longitude: '',
        }}
        validationSchema={_.isEmpty(selectedAddress) ? deliveryInfoWithoutAddressSchema : deliveryInfoSchema}
        onSubmit={(values) => {
          onSubmitClick(values);
        }}
      >
        {({ errors, touched, setFieldValue, values, handleSubmit, setValues, validateField }) => {
          return (
            <Fragment>
              <Header title="Xác nhận đơn hàng" />
              <div className={classes.container}>
                <CartDivider parentPadding={16} />
                {_.isEmpty(selectedAddress) ? (
                  <div style={{ marginTop: 16 }}>
                    <div className={classes.addressLabelContainer}>
                      <div
                        className={classes.labelTextContainer}
                        onClick={() => {
                          setFieldValue('onPickUpRestaurant', false);
                          onPickUpRestaurant(false);
                          validateField('address');
                        }}
                      >
                        <AppRadioBox isChecked={!cartData?.pickupAtRestaurant}></AppRadioBox>
                        <div className={classes.labelText}>Giao hàng tới</div>
                      </div>

                      <div className={classes.changeAddressText} onClick={onChangeAddressClick}>
                        Thay đổi
                      </div>
                    </div>
                    <TextInput
                      title={t('fullname')}
                      value={values.name}
                      error={errors.name}
                      onChange={(value: any) => setFieldValue('name', value)}
                      containerClassname={classes.textInputContainer}
                    />
                    <TextInput
                      title={t('phoneNumber')}
                      value={values.phone}
                      error={errors.phone}
                      type="tel"
                      onChange={(value: any) => setFieldValue('phone', value)}
                      containerClassname={classes.textInputContainer}
                    />
                    <TextInput
                      title={'Địa chỉ'}
                      // value={values.address}
                      error={errors.address || errors.longitude}
                      onChange={(value: any) => {
                        setFieldValue('address', value);
                        setFieldValue('latitude', null);
                        setFieldValue('longitude', null);

                      }}
                      containerClassname={clsx(
                        classes.textInputContainer,
                        classes.addressDetailContainer,
                      )}
                      textInputRef={ref}
                      placeholder="Nhập địa chỉ"
                    />
                  </div>
                ) : (
                  <div className={classes.addressContainer}>
                    <div className={classes.addressLabelContainer}>
                      <div
                        className={classes.labelTextContainer}
                        onClick={() => {
                          setFieldValue('onPickUpRestaurant', false);
                          onPickUpRestaurant(false);
                          validateField('address');
                        }}
                      >
                        <AppRadioBox isChecked={!cartData?.pickupAtRestaurant}></AppRadioBox>
                        <div className={classes.labelText}>Giao hàng tới</div>
                      </div>

                      <div className={classes.changeAddressText} onClick={onChangeAddressClick}>
                        Thay đổi
                      </div>
                    </div>

                    <Fragment>
                      <div className={classes.addressText} style={{ marginTop: 8 }}>
                        {!_.isEmpty(selectedAddress)
                          ? `${selectedAddress?.name ?? ''} - ${selectedAddress?.phone ?? ''}`
                          : ''}
                      </div>
                      <div className={classes.addressText} style={{ marginTop: 8 }}>
                        {!_.isEmpty(selectedAddress)
                          ? `${selectedAddress?.addressLine1}, ${selectedAddress?.wardName}, ${selectedAddress?.districtName}, ${selectedAddress?.provinceName}`
                          : ''}
                      </div>
                    </Fragment>
                  </div>
                )}

                <div className={classes.addressContainer}>
                  <div className={classes.addressLabelContainer}>
                    <div
                      className={classes.labelTextContainer}
                      onClick={() => {
                        setFieldValue('onPickUpRestaurant', true);
                        onPickUpRestaurant(true);
                        validateField('address');
                      }}
                    >
                      <AppRadioBox isChecked={cartData?.pickupAtRestaurant}></AppRadioBox>
                      <div className={classes.labelText}>
                        <>
                          Nhận hàng tại <span className={classes.noFee}>(Không mất phí)</span>
                        </>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => onOpenChangeRestaurantModal()}>
                    <div className={classes.restaurantNameContainer}>
                      <div className={classes.addressText}>
                        {!_.isEmpty(selectedRestaurant)
                          ? `${selectedRestaurant?.restaurant?.name} - ${selectedRestaurant?.restaurant?.telephone}`
                          : ''}
                      </div>
                      <div className={classes.changeAddressText}>Thay đổi</div>
                    </div>
                    <div className={classes.addressText} style={{ marginTop: 8 }}>
                      {!_.isEmpty(selectedRestaurant)
                        ? `${selectedRestaurant?.restaurant?.address}`
                        : ''}
                    </div>
                  </div>
                </div>
                {errors?.address ? (
                  <div style={{ color: 'red', marginBottom: 10 }}>{errors?.address}</div>
                ) : (
                  <></>
                )}
                <CartDivider parentPadding={16} />
                <div className={classes.timeContainer}>
                  <div className={classes.labelText}>Thời gian nhận hàng</div>
                  <TextInput
                    containerClassname={classes.dateInput}
                    textInputClassname={classes.textInput}
                    onClick={onDatePickerInputClick}
                    value={datePickerValue ? moment(datePickerValue).format('DD/MM/YYYY') : ''}
                    surfix={<CalendarIcon />}
                    placeholder={'Chọn ngày giao'}
                  />
                  {errors?.deliveryDate ? (
                    <div style={{ color: 'red', marginTop: 3 }}>{errors?.deliveryDate}</div>
                  ) : (
                    <></>
                  )}
                  <TextInput
                    containerClassname={classes.timeInput}
                    textInputClassname={classes.textInput}
                    onClick={onTimePickerInputClick}
                    value={timePickerValue}
                    surfix={<TimeIcon />}
                    placeholder={'Chọn giờ giao'}
                    onBlur={() => validateField('deliveryTime')}
                  />
                  {errors?.deliveryTime ? (
                    <div style={{ color: 'red', marginTop: 3 }}>{errors?.deliveryTime}</div>
                  ) : (
                    <></>
                  )}
                </div>

                <CartDivider parentPadding={16} />
                <div className={classes.plasticContainer}>
                  <div className={classes.labelText}>Bạn có cần dụng cụ ăn uống nhựa không?</div>
                  <div className={classes.switchContainer}>
                    <Switch
                      className={classes.switch}
                      onChange={() => setIsPlastic(!isPlastic)}
                      checked={isPlastic}
                    />
                    <div className={classes.switchText}>{isPlastic ? 'Có' : 'Không'}</div>
                  </div>
                </div>
              </div>
              <div className={classes.updateButtonContainer}>
                <div onClick={() => handleSubmit()}>
                  <CartButton text="Tiếp tục" />
                </div>
              </div>
            </Fragment>
          );
        }}
      </Formik>
    </Fragment>
  );
});

const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({});

const DeliveryInfo = React.memo(connect(mapState, mapDispatch)(Info));
export { DeliveryInfo };
