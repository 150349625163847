import { AxiosResponse } from 'axios';
import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IBrand, IBrandInfoDto, IRestaurant } from '../core/models/brand';
import { IProvince } from '../core/models/province';
import { ITag } from '../core/models/tag';

const getBrandByProvince = (provinceId: number) =>
  axios.get<IApiResponse<IBrand[]>>('/home/brand', {
    params: {
      provinceId,
    },
  });

const getBrandInfo = ({ provinceId, brandId }: { brandId: string; provinceId?: number }) =>
  axios.get<IApiResponse<IBrandInfoDto>>(`/brand/${brandId}`, {
    params: {
      provinceId,
    },
  });

const getRestaurants = (params: {
  brandId?: string;
  provinceId?: number;
  longitude?: number | null;
  latitude?: number | null;
}) => axios.get<IApiResponse<IRestaurant[]>>('/brand/restaurant', { params });

const getSelectedRestaurant = () =>
  axios.get<IApiResponse<IRestaurant>>('/restaurant/selected', {});

const changedRestaurant = (
  id: string,
  params?: { longitude?: number | null; latitude?: number | null },
) => axios.get<IApiResponse<IRestaurant>>(`/restaurant/${id}/change`, { params });

const changedBrand = (params?: {
  brandId?: string | null;
  provinceId?: number | null;
  override?: boolean;
}) => axios.get<IApiResponse<{ category: IBrand }>>(`/brand/set/selected`, { params });

const getSelectedBrand = () =>
  axios.get<IApiResponse<{ category: IBrand; province: IProvince }>>('/brand/get/selected');

const getGroups = (params: { brandId?: string; provinceId?: number }) =>
  axios.get<IApiResponse<ITag[]>>('/brand/product-group', { params });

const switchProvince = (provinceId: number, force?: boolean) =>
  axios.post<{ provinceId: number }, AxiosResponse<IApiResponse<null>>>(
    '/brand/switch-province',
    { provinceId },
    {
      params: {
        force,
      },
    },
  );

export const brandService = {
  getBrandByProvince,
  getBrandInfo,
  getRestaurants,
  changedRestaurant,
  getSelectedRestaurant,
  changedBrand,
  getSelectedBrand,
  getGroups,
  switchProvince,
};
