import { useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartButton } from '../../../../components/cart-button';
import { CartDivider } from '../../../../components/cart-divider';
import { Header } from '../../../../components/header';
import { AddressPlusIcon } from '../../../../components/icons/address-plus';
import { RadioCheckedIcon } from '../../../../components/icons/radio-checked';
import { RadioUncheckedIcon } from '../../../../components/icons/radio-unchecked';
import { BREAK_POINTS } from '../../../../core/contants/break-points';
import { customerService } from '../../../../services/customer-service';
import { useStyles } from './change-address.style';
interface Props {
  selectedAddress: any;
  customerAddresses: any;
  setSelectedAddress: (address: any) => void;
  setCurrentStage: (stage: string) => void;
  onAddAddressPress: () => void;
}
export const ChangeAddress = React.memo((props: Props) => {
  const {
    selectedAddress,
    customerAddresses,
    setSelectedAddress,
    setCurrentStage,
    onAddAddressPress,
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([] as any);
  const [tempSelectedAddress, setTempSelectedAddress] = useState({} as any);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  useEffect(() => {
    getAddress();
  }, [customerAddresses]);
  const getAddress = async () => {
    setIsLoading(true);
    setData(customerAddresses);
    setTempSelectedAddress(selectedAddress);
    setIsLoading(false);
  };
  const onHeaderBackClick = () => {
    setCurrentStage('delivery');
  };
  const onSubmitClick = () => {
    setSelectedAddress(tempSelectedAddress);
    setCurrentStage('delivery');
  };
  const renderAddress = () => {
    return data.map((value: any, index: number) => {
      return (
        <div className={classes.addressBoxContainer} onClick={() => setTempSelectedAddress(value)}>
          {value.id === tempSelectedAddress.id ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
          <div className={classes.infoContainer}>
            <div className={classes.nameAndPhoneNumberContainer}>
              <div className={classes.nameAndPhoneNumberText}>
                {!_.isEmpty(value) ? `${value?.name} - ${value?.phone}` : ''}
              </div>
              {value.isDefault ? (
                <div className={classes.defaultText}>Mặc định</div>
              ) : (
                <Fragment></Fragment>
              )}
            </div>
            <div className={classes.addressText}>
              {!_.isEmpty(value)
                ? `${value?.addressLine1}, ${value?.wardName}, ${value?.districtName}, ${value?.provinceName}`
                : ''}
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <Fragment>
      <Header title="Thay đổi địa chỉ" onBackClick={onHeaderBackClick} />
      <CartDivider />
      <div className={classes.container}>{renderAddress()}</div>
      {/* {isDesktop ? ( */}
        <div className={classes.addNewAddressContainer} onClick={onAddAddressPress}>
          <AddressPlusIcon />
          <div className={classes.addNewAddressText}>Thêm địa chỉ mới</div>
        </div>
      {/* ) : (
        <Link to={'/profile/address/add'}>
          <div className={classes.addNewAddressContainer}>
            <AddressPlusIcon />
            <div className={classes.addNewAddressText}>Thêm địa chỉ mới</div>
          </div>
        </Link>
      )} */}

      <div onClick={onSubmitClick}>
        <CartButton text={'Đồng ý'} containerStyle={classes.continueButton} />
      </div>
    </Fragment>
  );
});
