import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../core/contants/colors';

export const useStyles = makeStyles({
  container: {
    padding: '0px 16px 16px 16px',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 26,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankView: {
    width: 32,
    height: 32,
  },
  optionsContainer: {
    display: 'flex',
    marginTop: '16px',
  },
  optionActive: {
    background: colors.primaryColor,
    width: '50%',
    padding: '11px 0',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionInactive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#EDEDED',
    width: '50%',
    padding: '11px 0',
    textAlign: 'center',
    color: colors.textSecondaryColor,
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '8px',
  },
  searchInputContainer: {
    display: 'flex',
    position: 'relative',
  },
  searchInput: {
    border: '1px solid #D8D8D8',
    borderRadius: '8px 0 0 8px',
    padding: '12px 16px',
    flexGrow: 3,
  },
  searchSubmit: {
    background: colors.primaryColor,
    flexGrow: 1,
    borderRadius: '0 8px 8px 0',
    fontWeight: 700,
    fontSize: 14,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
  },
  qrCodeContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    right: '25%',
    top: '50%',
    transform: 'translate(-50%, -45%)',
  },
  qrCodeText: {
    fontWeight: 500,
    fontSize: 9,
    color: colors.primaryColor,
  },
  modalCloseIcon: {
    background: '#EDEDED',
    width: 32,
    height: 32,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
