import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../core/contants/colors";
interface Props {
  parentPadding?: number;
}

export const CartDivider = React.memo((props: Props) => {
  const useStyles = makeStyles({
    cartDivider: {
      height: 5,
      minWidth: "100vw",
      backgroundColor: colors.dividerColor,
      marginLeft: props.parentPadding ? `-${props.parentPadding}px` : 0,
      boxSizing: "border-box",
    },
  });
  const classes = useStyles();
  return <div className={classes.cartDivider}></div>;
});
