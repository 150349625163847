import React from 'react';
export const SearchFilterIcon = React.memo(() => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.32 11.3579H13M2.12 7L1 7.02936M2.12 7C2.12 7.88366 2.83634 8.6 3.72 8.6C4.60365 8.6 5.32 7.88366 5.32 7C5.32 6.11634 4.60365 5.4 3.72 5.4C2.83634 5.4 2.12 6.11634 2.12 7ZM5.77932 7.02941H13M7.53397 2.70082L1 2.70082M13 2.70082H11.32M1 11.3579H7.53397M10.92 11.4C10.92 12.2837 10.2037 13 9.32 13C8.43634 13 7.72 12.2837 7.72 11.4C7.72 10.5163 8.43634 9.8 9.32 9.8C10.2037 9.8 10.92 10.5163 10.92 11.4ZM10.92 2.6C10.92 3.48366 10.2037 4.2 9.32 4.2C8.43634 4.2 7.72 3.48366 7.72 2.6C7.72 1.71634 8.43634 1 9.32 1C10.2037 1 10.92 1.71634 10.92 2.6Z"
        stroke="#6B6C6F"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
});
