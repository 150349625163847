import { useMediaQuery } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { CouponQRCodeIcon } from '../../../components/icons/coupon-qr-code';
import { ModalCloseIcon } from '../../../components/icons/modal-close';
import { BREAK_POINTS } from '../../../core/contants/break-points';
import { CouponDetail } from '../../../components/coupon-detail/coupon-detail.component';
import { CouponTab } from '../coupon-tab/coupon-tab.component';
import { MyWalletTab } from '../my-wallet-tab/my-wallet-tab.component';
import { useStyles } from './coupon-modal.style';
import { Modal } from 'antd';
import { voucherService } from '../../../services/voucher-service';

interface Props {
  isVisible: boolean;
  onDismiss: () => void;
  onQrCodeClick: () => void;
  voucherData: any;
  myVoucherData: any;
  onApplyVoucherClick: (value: any) => void;
  onApplyCoupon: (value: any) => void;
  selectedVouchers: any;
  onCancelCoupon: (value: any) => void;
  onSubmitCodeInput: (codeInput: string) => void;
}

export const CouponModal = React.memo((props: Props) => {
  const [currentStage, setCurrentStage] = useState('coupon-tab');
  const [tabShow, setTabShow] = useState(true);
  const [codeInput, setCodeInput] = useState('');
  const classes = useStyles();
  const {
    onQrCodeClick,
    voucherData,
    myVoucherData,
    onApplyVoucherClick,
    isVisible,
    selectedVouchers,
    onCancelCoupon,
    onSubmitCodeInput,
    onApplyCoupon,
  } = props;
  const [currentVoucherDetail, setCurrentVoucherDetail] = useState({} as any);
  const [voucherDetailVisible, setVoucherDetailVisible] = useState(false);
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  useEffect(() => {
    if (currentStage === 'coupon-detail') {
      setTabShow(false);
    } else {
      setTabShow(true);
    }
  }, [currentStage]);
  useEffect(() => {
    if (!isVisible) setCurrentStage('coupon-tab');
  }, [isVisible]);
  const onCouponClick = async (value: any) => {
    if (currentStage === 'coupon-tab') {
      setCurrentVoucherDetail({seriNo: value?.giftValue?.seriNo, promotion: { title: value?.promotionTitle, endTime: value?.endDate, description: value?.promotionContent } });
      setTimeout(() => {
        setVoucherDetailVisible(true);
      }, 300);
    } else {
      const result = await voucherService.getTicketDetail(value.promotionId);
      setCurrentVoucherDetail(value);
      setTimeout(() => {
        setVoucherDetailVisible(true);
      }, 300);
    }

  };
  const onCouponTabChange = () => {
    setCurrentStage('coupon-tab');
  };
  const onMyWalletTabChange = () => {
    setCurrentStage('my-wallet-tab');
  };

  const onClickSubmitCodeInput = () => {
    onSubmitCodeInput(codeInput);
    setCodeInput('');
  };

  const renderStage = () => {
    switch (currentStage) {
      case 'coupon-tab':
        return (
          <CouponTab
            data={voucherData}
            onCouponClick={onCouponClick}
            onCancelCoupon={onCancelCoupon}
            onApplyVoucherClick={onApplyVoucherClick}
            selectedVouchers={selectedVouchers}
          />
        );
      case 'my-wallet-tab':
        return <MyWalletTab
          data={myVoucherData}
          onApplyCoupon={onApplyCoupon}
          onCouponClick={onCouponClick}
        // onCancelCoupon={onCancelCoupon}
        />;
      // case 'coupon-detail':
      //   return (

      //   );
      default:
      // code block
    }
  };
  const renderTabTitleAndSearchInput = () => {
    if (tabShow) {
      return (
        <Fragment>
          <div className={classes.searchInputContainer}>
            <input
              className={classes.searchInput}
              value={codeInput}
              onChange={(e) => setCodeInput(e.target.value)}
              placeholder="Nhập mã giảm giá ..."
            ></input>
            {!isDesktop ? (
              <div className={classes.qrCodeContainer} onClick={onQrCodeClick}>
                <CouponQRCodeIcon />
                <div className={classes.qrCodeText}>Quét QR</div>
              </div>
            ) : (
              <Fragment></Fragment>
            )}
            <div className={classes.searchSubmit} onClick={onClickSubmitCodeInput}>
              Áp dụng
            </div>
          </div>
          <div className={classes.optionsContainer}>
            <div
              className={
                currentStage === 'coupon-tab' ? classes.optionActive : classes.optionInactive
              }
              style={{ marginRight: '7px' }}
              onClick={onCouponTabChange}
            >
              Mã giảm giá
            </div>
            <div
              className={
                currentStage === 'my-wallet-tab' ? classes.optionActive : classes.optionInactive
              }
              style={{ marginLeft: '7px' }}
              onClick={onMyWalletTabChange}
            >
              Mã đã lưu
            </div>
          </div>
        </Fragment>
      );
    } else return <Fragment></Fragment>;
  };
  const onModalCloseIconClick = () => {
    if (currentStage === 'coupon-detail') {
      setCurrentStage('coupon-tab');
    } else {
      props.onDismiss();
    }
  };
  const onApplyDetailCoupon = (value: any) => {
    setVoucherDetailVisible(false);
    onApplyCoupon(value);
  };
  const afterClose = () => {
    setTimeout(() => {
      document.body.style.setProperty('overflow', 'unset', 'important');
    }, 500);
  };
  const renderComponent = () => {
    if (isDesktop) {
      return (
        <Modal
          title=""
          visible={props.isVisible}
          footer={null}
          onCancel={props.onCancelCoupon}
          closeIcon={null}
          centered
          width={470}
          bodyStyle={{
            padding: isDesktop ? '16px 0px' : '24px 16px 16px 16px',
          }}
          style={{ borderRadius: 8 }}
          closable={false}
          afterClose={afterClose}
        >
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <div className={classes.blankView}></div>

              <span className={classes.title}>Chọn mã giảm giá</span>
              <div className={classes.modalCloseIcon} onClick={onModalCloseIconClick}>
                <ModalCloseIcon />
              </div>
            </div>
            {renderTabTitleAndSearchInput()}
            {renderStage()}
          </div>
        </Modal>
      );
    } else {
      return (
        <BottomSheet
          onDismiss={props.onDismiss}
          open={props.isVisible}
          header={null}
          // open={true}
          defaultSnap={({ maxHeight }) => {
            return maxHeight;
          }}
          blocking={false}
          style={{ zIndex: 100 }}
        >
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <div onClick={onModalCloseIconClick}>
                <ModalCloseIcon />
              </div>
              <span className={classes.title}>Chọn mã giảm giá</span>
              <div className={classes.blankView}></div>
            </div>
            {renderTabTitleAndSearchInput()}
            {renderStage()}
          </div>
        </BottomSheet>
      );
    }
  };
  return (
    <Fragment>
      <CouponDetail
        isVisible={voucherDetailVisible}
        coupon={currentVoucherDetail}
        onDismiss={() => setVoucherDetailVisible(false)}
        onApply={onApplyDetailCoupon}
      />
      {renderComponent()}
    </Fragment>
  );
});
