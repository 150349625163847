export const BASE_URL = 'https://backend.gdeli.vn/api/v1/';
export const UNSUPPORTED_PROVINCES = [43, 12];
export const GG_API_KEY = 'AIzaSyDT4lPQm6sJ1xxuQ6BJLEK12QP-8JmLWfc';

export const NET_CORE_INFO = {
    env: 'PROD',
    create: 'ADGMOT35CHFLVDHBJNIG50K968ATNH2U64O9DCMJJB5NUPEKAJIG',
    register: 'afc4eb11ee01fb9041b2432ff05c51cc',
    identify: '',
};
