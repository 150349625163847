import React from 'react';
export const SearchDetailIcon = React.memo(() => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="6.99237"
        cy="7.32587"
        r="5.99237"
        stroke="#6B6C6F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1602 11.8047L13.5095 14.1479"
        stroke="#6B6C6F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
