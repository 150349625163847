import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../../core/contants/colors";

export const useCouponPopupStyles = makeStyles({
  container: {},
  contentContainer: {
    padding: "20px 16px 24px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "#000",
    fontWeight: 700,
    fontSize: 16,
  },
  description: {
    lineHeight: "17px",
    marginTop: 12,
    color: colors.textSecondaryColor,
    textAlign: "center",
  },

  saveBtn: {
    padding: "12px 32px",
    marginTop: 20,
  },
  bannerContainer: {
    position: "relative",
    cursor: "pointer",
  },
  closeIconContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    border: "2px solid #FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 13,
    right: 16,
  },
});
