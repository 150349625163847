import { Tabs } from "antd";
import React, { useState } from "react";
import { Header } from "../../components/header";
import { GoldenGateIcon } from "../../components/icons/golden-gate";
import { VnptEpayIcon } from "../../components/icons/vnpt-epay";
import { useStyles } from "./atm-payment.style";
import { AtmTab } from "./component/atm-tab/atm-tab.component";
export const AtmPaymentScreen = React.memo(() => {
  const classes = useStyles();
  return (
    <div>
      <Header title="Thanh toán đơn hàng"  />
      <Tabs defaultActiveKey="1" tabBarStyle={{margin: 0}}>
        <Tabs.TabPane tab={<div style={{display: 'flex', justifyContent: 'center'}}><VnptEpayIcon/></div>}  key="1">
        </Tabs.TabPane>
        <Tabs.TabPane tab={<div style={{display: 'flex', justifyContent: 'center'}}><GoldenGateIcon/></div>}  key="2">
        </Tabs.TabPane>
        <Tabs.TabPane tab="THẺ ATM" key="3">
          <AtmTab/>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
});
