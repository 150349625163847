import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  modal: {
    borderRadius: 16,
  },
  contentModal: {
    padding: 16
  },
  monthText: {
    fontWeight: 700,
    fontSize: 18
  },
  timeValueContainer: {
    justifyContent: 'center'
  },
  selectMonthContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  },
  activeDate: {
    background: colors.primaryColor,
    color: '#fff',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8
  },
  dateInMonth: {
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  inActiveDate: {
    background: '#EDEDED',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0 16px 0'
  },
  cancelButton: {
    borderRadius: 8,
    border: '1px solid #D8D8D8',
    fontWeight: 700,
    fontSize: 16,
    padding: '10px 35px',
    width: '47%',
    textAlign: 'center'
  },
  okButton: {
    borderRadius: 8,
    fontWeight: 700,
    fontSize: 16,
    background: colors.primaryColor,
    color: '#fff',
    padding: '10px 35px',
    width: '47%',
    textAlign: 'center'
  }, 
  timeValueDisabled: {
    background: '#EDEDED',
    color: '#A5A5A5',
    fontWeight: 500,
    fontSize: 12,
    padding: '8px 10px',
    borderRadius: 4,
    textAlign: 'center'
  },
  timeValueActive: {
    background: colors.primaryColor,
    color: '#fff',
    fontWeight: 500,
    fontSize: 12,
    padding: '8px 10px',
    width: '29%',
    margin: 3,
    borderRadius: 4,
    textAlign: 'center'
  },
  timeValueNormal: {
    background: '#EDEDED',
    fontWeight: 500,
    fontSize: 12,
    padding: '8px 10px',
    width: '29%',
    margin: 3,
    borderRadius: 4,
    textAlign: 'center'
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'center'
  }
});