import React, {Fragment, useEffect, useState} from 'react';
import { paymentMethods, paymentMethodValues } from '../../../core/contants/paymentMethodValues';
import { useStyles } from './request-payment-mobile.style';
import { AtmIcon } from '../../../components/icons/atm-icon';
import { VisaIcon } from '../../../components/icons/visa-icon';
import { MomoIcon } from '../../../components/icons/momo-icon';
import { VnPayIcon } from '../../../components/icons/vnpay-icon';
import { CashIcon } from '../../../components/icons/cash-icon';
import { ZaloPayIcon } from '../../../components/icons/zalo-pay-icon';
import { ShopeePayIcon } from '../../../components/icons/shopee-pay';
import { formatCurrency } from '../../../core/ultis/currency';
import { Header } from '../../../components/header';
import { CartDivider } from '../../../components/cart-divider';
import { CartButton } from '../../../components/cart-button';
import { Col, Row } from 'antd';
import { AppRadioBox } from '../../../components/radio';
import { BillingInfoMobile } from '../billing-info-mobile/billing-info-mobile.component';
import { TextInput } from '../../../components/text-input';
import { CalendarIcon } from '../../../components/icons/calendar';
import { TimeIcon } from '../../../components/icons/time';
import moment from 'moment';
import _ from 'lodash';
import {GBusinessIcon} from "../../../components/icons/g-business";
interface Props {
  onSelectPaymentMethodClick: (value: string) => void;
  onRequestPayment: (data: any) => void;
  data: any;
  currentMethod: string;
  setIsLoading: (value: boolean) => void;
  setData: (value: any) => void;
  customDatePickerVisible: boolean;
  onCloseCustomDatePicker: () => void;
  onSubmitCustomDatePicker: (value: any) => void;
  customTimePickerVisible: boolean;
  onCloseCustomTimePicker: () => void;
  onSubmitCustomTimePicker: (value: any) => void;
  blockTimes: string[];
  onDatePickerInputClick: () => void;
  onTimePickerInputClick: () => void;
  onPickUpRestaurant: (value: boolean) => void;
  values: any;
  errors: any;
  setFieldValue: any;
  handleSubmit: any;
  validateField: any;
  gBizAccount: any;
  setDisableGBizOption: (value: boolean) => void;
  disableGBizOption: boolean;
}
const RequestPayment = (props: Props): JSX.Element => {
  const {
    onSelectPaymentMethodClick,
    data,
    currentMethod,
    onRequestPayment,
    setIsLoading,
    setData,
    customDatePickerVisible,
    onCloseCustomDatePicker,
    onSubmitCustomDatePicker,
    customTimePickerVisible,
    onCloseCustomTimePicker,
    onSubmitCustomTimePicker,
    blockTimes,
    onDatePickerInputClick,
    onTimePickerInputClick,
    onPickUpRestaurant,
    values,
    errors,
    setFieldValue,
    handleSubmit,
    validateField,
    gBizAccount,
    setDisableGBizOption,
    disableGBizOption
  } = props;
  const classes = useStyles();
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [isDisableBill, setIsDisableBill] = useState(false);

  useEffect(() => {
    if (currentMethod === paymentMethodValues.G_BUSINESS) {
      setIsDisableBill(true);
    } else {
      setIsDisableBill(false);
    }
  }, [currentMethod]);

  const renderPaymentMethods = () => {
    return paymentMethods.map((value: any, index: number) => {
      if (value?.value == paymentMethodValues.G_BUSINESS) {
        if (gBizAccount) {
          const balanceAvail = gBizAccount.balance;
          if (balanceAvail < Math.round(data?.totalOrder ?? 0) || data?.campaignDetail?.length > 0 || data?.voucherDetail?.length > 0) {
            setDisableGBizOption(true);
          }

          return (
            <Col
                span={24}
                key={index}
                className={`${classes.paymentMethodItem} ${disableGBizOption && classes.disableGBiz}`}
                onClick={() => {
                  if (!disableGBizOption) {
                    onSelectPaymentMethodClick(value?.value)
                  }
                }}
            >
              <AppRadioBox isChecked={currentMethod === value?.value ? true : false} disabled={disableGBizOption || false}>
                <div className={classes.checkBoxValue}>
                  {value.icon()}
                  <div className={classes.paymentMethodName}>
                    <div>{value.name}</div>
                    <div className={classes.gBizBalanceText}>Hạn mức còn lại {formatCurrency(balanceAvail)}</div>
                  </div>
                </div>
              </AppRadioBox>
            </Col>
          );
        }
      } else {
        return (
            <Col
                span={12}
                key={index}
                className={classes.paymentMethodItem}
                onClick={() => onSelectPaymentMethodClick(value?.value)}
            >
              <AppRadioBox isChecked={currentMethod === value?.value ? true : false}>
                <div className={classes.checkBoxValue}>
                  {value.icon()}
                  <div className={classes.paymentMethodName}>{value.name}</div>
                </div>
              </AppRadioBox>
            </Col>
        );
      }
    });
  };
  const onSubmitBillingInfo = () => {
    validateField('taxCode');
    validateField('companyName');
    validateField('invoiceAddress');
    validateField('invoiceEmail');
    setTimeout(() => {
      if (
        errors?.taxCode ||
        errors?.companyName ||
        errors?.invoiceAddress ||
        errors?.invoiceEmail ||
        !values?.taxCode ||
        !values?.companyName ||
        !values?.invoiceAddress ||
        !values?.invoiceEmail
      ) {
      } else {
        setShowBillingInfo(false);
      }
    }, 500);
  };
  const onCloseBillingInfo = () => {
    if (values?.companyName || values?.haveBill) {
    } else {
      setFieldValue('haveBill', false);
    }
    setShowBillingInfo(false);
  };
  const onOpenBillingInfo = () => {
    setShowBillingInfo(true);
    setFieldValue('haveBill', true);
  };
  return (
    <div className={classes.mobileLayout}>
      {showBillingInfo ? (
        <BillingInfoMobile
          setShowBillingInfo={setShowBillingInfo}
          setFieldValue={setFieldValue}
          validateField={validateField}
          values={values}
          errors={errors}
          onCloseBillingInfo={onCloseBillingInfo}
          onSubmitBillingInfo={onSubmitBillingInfo}
          isDisableBill={isDisableBill}
        />
      ) : (
        <Fragment>
          <Header title="Xác nhận thanh toán" />
          <CartDivider />
          {/* <div className={classes.deliveryOptionsContainer}>
            <div
              onClick={() => {
                setFieldValue('onPickUpRestaurant', false);
                onPickUpRestaurant(false);
              }}
              className={
                !values?.onPickUpRestaurant
                  ? classes.deliveryOptionsActive
                  : classes.deliveryOptionsInactive
              }
              style={{ marginRight: '7px' }}
            >
              {!values?.onPickUpRestaurant ? (
                <div className={classes.dot}></div>
              ) : (
                <Fragment></Fragment>
              )}{' '}
              Giao tận nơi
            </div>
            <div
              onClick={() => {
                setFieldValue('onPickUpRestaurant', true);
                onPickUpRestaurant(true);
              }}
              className={
                values?.onPickUpRestaurant
                  ? classes.deliveryOptionsActive
                  : classes.deliveryOptionsInactive
              }
              style={{ marginLeft: '7px' }}
            >
              {values?.onPickUpRestaurant ? (
                <div className={classes.dot}></div>
              ) : (
                <Fragment></Fragment>
              )}
              Nhận tại nhà hàng
            </div>
          </div> */}
          {!values?.onPickUpRestaurant && (
              <div className={classes.addressContainer}>
                <div className={classes.addressLabelContainer}>
                  <div
                      className={classes.labelTextContainer}
                      onClick={() => {
                        setFieldValue('onPickUpRestaurant', false);
                        onPickUpRestaurant(false);
                      }}
                  >
                    <AppRadioBox isChecked={!values?.onPickUpRestaurant}></AppRadioBox>
                    <div className={classes.labelText}>Giao hàng tới</div>
                  </div>
                </div>

                <Fragment>
                  <div className={classes.addressText} style={{ marginTop: 8 }}>
                    {data?.delivery?.recipientName ?? ''} {data?.delivery?.recipientCellphone ?? ''}
                    <br />
                    {[data?.delivery?.address, data?.delivery?.address2].filter((i) => !!i).join(', ')}
                  </div>
                </Fragment>
              </div>
          )}
          <div className={classes.addressContainer}>
            <div className={classes.addressLabelContainer}>
              <div
                className={classes.labelTextContainer}
                onClick={() => {
                  setFieldValue('onPickUpRestaurant', true);
                  onPickUpRestaurant(true);
                }}
              >
                <AppRadioBox isChecked={values?.onPickUpRestaurant}></AppRadioBox>
                <div className={classes.labelText}>
                  <>
                    Nhận hàng tại <span className={classes.noFee}>(Không mất phí)</span>
                  </>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.addressText} style={{ marginTop: 8 }}>
                {data?.restaurant?.restaurant?.name ?? ''}
                <br />
                {data?.restaurant?.restaurant?.address ?? ''}
              </div>
            </div>
          </div>
          <CartDivider parentPadding={16} />
          <div className={classes.timeContainer}>
            <div className={classes.labelText}>Thời gian nhận hàng</div>
            <TextInput
              containerClassname={classes.dateInput}
              textInputClassname={classes.textInput}
              onClick={onDatePickerInputClick}
              value={values?.deliveryDate ? moment(values?.deliveryDate).format('DD/MM/YYYY') : ''}
              surfix={<CalendarIcon />}
              placeholder={'Chọn ngày giao'}
            />
            {errors?.deliveryDate ? (
              <div style={{ color: 'red', marginTop: 3 }}>{errors?.deliveryDate}</div>
            ) : (
              <></>
            )}
            <TextInput
              containerClassname={classes.timeInput}
              textInputClassname={classes.textInput}
              onClick={onTimePickerInputClick}
              value={values?.deliveryTime}
              surfix={<TimeIcon />}
              placeholder={'Chọn giờ giao'}
              onBlur={() => validateField('deliveryTime')}
            />
            {errors?.deliveryTime ? (
              <div style={{ color: 'red', marginTop: 3 }}>{errors?.deliveryTime}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.paymentMethodsContainer}>
            <div className={classes.title}>Phương thức thanh toán</div>
            <Row className={classes.paymentMethodsListContainer}>{renderPaymentMethods()}</Row>
          </div>
          <CartDivider />
          <div className={classes.discountContainer}>
            <div className={classes.discountTitleContainer}>
              <div className={classes.title}>Mã giảm giá</div>
            </div>
            <div className={classes.voucherListContainer}>
              {data?.voucherDetail?.length ? (
                data?.voucherDetail.map((value: any, index: number) => {
                  return (
                    <div className={classes.voucherItem} key={index}>
                      <span>{value?.name}</span>
                    </div>
                  );
                })
              ) : (
                <div className={classes.noDiscountCodeText}>Bạn chưa thêm mã giảm giá nào</div>
              )}
            </div>
          </div>
          <CartDivider />
          <div className={classes.priceContainer}>
            <div className={classes.priceMainRow}>
              <div className={classes.priceMainItemTitle}>Tạm tính</div>
              <div className={classes.priceMainItemValue}>
                {formatCurrency(Math.round(data?.totalPriceWithoutShipping ?? 0))}
              </div>
            </div>
            {data?.totalDiscount ? (
              <div className={classes.priceMainRow}>
                <div className={classes.priceMainItemTitle}>Giảm giá</div>
                <div className={classes.priceMainItemValue}>
                  - {formatCurrency(Math.round(data?.totalDiscount ?? 0))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {data?.voucherDetail?.length ? (
                <Fragment>
                  {data?.voucherDetail?.map((voucher) => (
                      <div className={classes.secondaryRow} key={`campaign-${voucher.code}`}>
                        <div className={classes.secondaryTitleText}>
                          <div className={classes.circle}></div>
                          <span>{voucher.name}</span>
                        </div>
                        <div className={classes.secondaryValueText}>
                          -{formatCurrency(voucher.discount)}
                        </div>
                      </div>
                  ))}
                  <hr style={{ borderTop: '1px dashed #E4E4E4', marginTop: 18 }} />
                </Fragment>
            ) : (
                <></>
            )}
            {/* <div className={classes.priceSubRow}> */}
            {/* <div className={classes.priceSubItemTitle}>
            Giảm giá chương trình A
          </div>
          <div className={classes.priceSubItemValue}>- 99.000đ</div>
        </div>
        <div className={classes.priceSubRow}>
          <div className={classes.priceSubItemTitle}>Giảm giá campaign B</div>
          <div className={classes.priceSubItemValue}>- 100.000đ</div>
        </div> */}
            <div className={classes.priceMainRow}>
              <div className={classes.priceMainItemTitle}>Phí vận chuyển</div>
              <div className={classes.priceMainItemValue}>
                + {formatCurrency(Math.round(data?.shipping?.price ?? 0))}
              </div>
            </div>
            <div className={classes.priceMainRow}>
              <div className={classes.priceMainItemTitle}>Tổng tiền trước VAT</div>
              <div className={classes.priceMainItemValue}>
                {formatCurrency(Math.round(data?.totalBeforeVAT ?? 0))}
              </div>
            </div>
            <div className={classes.priceMainRow}>
              <div className={classes.priceMainItemTitle}>VAT</div>
              <div className={classes.priceMainItemValue}>
                {formatCurrency(Math.round(data?.totalVAT ?? 0))}
              </div>
            </div>
            <div className={classes.priceMainRow}>
              <div className={classes.priceMainItemTitle}>Tổng tiền sau VAT</div>
              <div className={classes.priceMainItemValue}>
                {formatCurrency(Math.round(data?.totalAfterVAT ?? 0))}
              </div>
            </div>
            <hr className={classes.priceDivider} />
            {data?.totalDiscountVoucher ? (
              <div className={classes.priceMainRow}>
                <div className={classes.priceMainItemTitle}>Voucher</div>
                <div className={classes.priceMainItemValue}>
                  - {formatCurrency(Math.round(data?.totalDiscountVoucher ?? 0))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {data?.campaignDetail?.length ? (
                <Fragment>
                  {data?.campaignDetail?.map((voucher) => (
                      <div className={classes.secondaryRow} key={`voucher-${voucher.code}`}>
                        <div className={classes.secondaryTitleText}>
                          <div className={classes.circle}></div>
                          <span>{voucher.name}</span>
                        </div>
                        <div className={classes.secondaryValueText}>
                          -{formatCurrency(voucher.discount)}
                        </div>
                      </div>
                  ))}
                </Fragment>
            ) : (
                <></>
            )}

            {/* <div className={classes.priceSubRow}>
          <div className={classes.priceSubItemTitle}>Voucher đối tác X</div>
          <div className={classes.priceSubItemValue}>- 50.000đ</div>
        </div>
        <div className={`${classes.priceSubRow}, ${classes.subRowLastItem}`}>
          <div className={classes.priceSubItemTitle}>
            Đối tác Y tặng voucher
          </div>
          <div className={classes.priceSubItemValue}>- 200.000đ</div>
        </div> */}
            <div className={classes.totalPriceRow}>
              <div className={classes.totalPriceTitle}>Tổng tiền phải trả</div>
              <div className={classes.totalPriceValue}>
                {formatCurrency(Math.round(data?.totalOrder ?? 0))}
              </div>
            </div>
          </div>
          <CartDivider />
          <div className={classes.discountContainer}>
            <div className={classes.discountTitleContainer}>
              <div className={classes.title}>Nhận hóa đơn điện tử</div>
              <div className={classes.selectDiscountCodeText} onClick={onOpenBillingInfo}>
                Chi tiết
              </div>
            </div>
          </div>
          <CartDivider />
          {gBizAccount && (
              <div className={classes.noteForGBiz}>
                * Thanh toán bằng tài khoản doanh nghiệp sẽ không được áp dụng các chương trình giảm giá cho KH cá nhân và không được tích lũy.
              </div>
          )}
          <div className={classes.submitButtonContainer}>
            <CartButton
              text={'Thanh toán lại & Đặt hàng'}
              containerStyle={classes.submitButton}
              onClick={() => handleSubmit()}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
const RequestPaymentMobile = React.memo(RequestPayment);
export { RequestPaymentMobile };
