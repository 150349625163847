import axios from '../core/http';
import { IApiResponse } from '../core/models/api-response';
import { IComment, IRate } from '../core/models/rating';

const getRates = () => axios.get<IApiResponse<IRate[]>>('/rating/allow-points');
const getComments = (params: { point: number }) =>
  axios.get<IApiResponse<IComment[]>>('/rating/comments', { params });

const sendRating = (
  id: string,
  body: { points?: number; comment?: string; comments?: { id: number; comment: string }[] },
) => axios.post<IApiResponse<null>>(`/rating/order/${id}`, body as any);

export const ratingService = {
  getRates,
  getComments,
  sendRating,
};
