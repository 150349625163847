import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { colors } from '../../../../core/contants/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D8D8D8',
    padding: '0px 12px',
    alignItems: 'center',
  },
  input: {
    padding: '17px 0px',
    marginLeft: 8,
    flex: 1,
  },
  error: {
    fontSize: 12,
    color: colors.dangerColor,
  },
  disabledBackground: {
    backgroundColor: colors.smoke,
  },
});

export const CartTextInput = React.memo((props: any) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    if (props.customOnChange) {
      props.customOnChange(e);
    } else if (props.onChange) {
      props.onChange(e.target.value as string);
    }
  };
  return (
    <div
      className={`${classes.container} ${props.containerClassname} ${
        props?.disabled ? classes.disabledBackground : ''
      }`}
    >
      {props.prefix || <div />}
      <input
        className={`${classes.input} ${props.textInputClassname} `}
        placeholder={props.placeholder}
        value={props.value}
        {...props}
        onChange={handleChange}
      />
      <div>{props.rightIcon ? props.rightIcon() : ''}</div>
      {props.error && <span className={classes.error}>{props.error}</span>}
    </div>
  );
});
