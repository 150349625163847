import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { colors } from '../core/contants/colors';
import { Spinner } from './spinner';

interface Props {
  onClick?: (e) => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const useStyles = makeStyles({
  container: {
    padding: '10px 12px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    cursor: 'pointer',
    fontSize: 16,
    flex: 1,
  },
  active: {
    backgroundColor: colors.primaryColor,
    color: '#fff',
  },
  disabled: {
    backgroundColor: '#EDEDED',
    color: '#A5A5A5',
  },
});

export const RaisedButton = React.memo((props: React.PropsWithChildren<Props>) => {
  const classes = useStyles();

  const onClick = useCallback(
    (e) => {
      if (props.disabled || !props.onClick || props.isLoading) return;

      props.onClick(e);
    },
    [props.disabled, props.onClick],
  );

  return (
    <div style={{ display: 'flex' }}>
      <button
        className={clsx(
          classes.container,
          props.disabled ? classes.disabled : classes.active,
          props.className,
        )}
        onClick={onClick}
        type={props.type}
        disabled={props.disabled}
      >
        {props.isLoading ? (
          <Spinner color={'#fff'} borderBottomColor={colors.primaryColor} />
        ) : (
          props.children
        )}
      </button>
    </div>
  );
});
