import { makeStyles } from '@material-ui/styles';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';

export const useProductDetailStyles = makeStyles({
  container: {
    width: '100vw',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      width: 'auto',
      overflow: 'auto',
    },
  },
  contentContainer: {
    padding: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      padding: 0,
      marginTop: 16,
    },
  },

  brandLogo: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  brandText: {
    fontWeight: 500,
    color: '#000',
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 16,
    lineHeight: '28px',
  },
  unitText: {
    color: colors.accentColor,
  },
  priceText: {
    color: colors.primaryColor,
    fontSize: 18,
    fontWeight: 'bold',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 20,
    },
  },
  discountText: {
    color: colors.textSecondaryColor,
    textDecorationLine: 'line-through',
    marginLeft: 8,
  },
  subTitle: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 16,
  },
  descriptionContainer: {
    marginTop: 0,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      marginTop: 12,
    },
  },
  description: {
    color: colors.textSecondaryColor,
    fontWeight: 400,
    fontSize: 14,
  },
  productSuggesstionContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: 12,
    overflow: 'auto',
    paddingBottom: 10,
  },
  productItem: {
    width: '80vw',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      width: '250px',
    },
  },

  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 12,
    marginTop: 16,
  },
  bottomSheet: {},
  loadingContainer: {
    width: '100%',
    display: 'flex',
    height: 112,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
