import { BREAK_POINTS } from '../../core/contants/break-points';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../core/contants/colors';
import { images } from '../../core/contants/images';

export const useProductItemStyles = makeStyles({
  thumbnailContainer: {
    position: 'relative',
    width: '100%',

    // "&:after": {
    //   paddingBottom: "100% !important",
    //   content: "",
    //   display: "block",
    // },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 4,
    zIndex: -1,
  },
  thumbnailHorizontal: {
    height: 112,
  },
  favoriteIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 16,
    width: 16,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 24,
      width: 24,
    },
  },
  itemContainer: {
    borderRadius: 8,
    padding: 12,
    boxShadow: '0px 8px 50px rgba(0, 0, 0, 0.06)',
    cursor: 'pointer',
    minWidth: 0,
    position: 'relative',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      backgroundColor: '#fff',
    },
  },
  itemRowContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.3fr',
    gridGap: 12,
  },
  title: {
    fontWeight: 700,
    color: '#000',
    fontSize: 13,
    lineHeight: '15px',
    height: 30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  brandLogo: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  brandContainer: {
    marginTop: 8,
    display: 'flex',
  },
  brandText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#000',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    // line-clamp: 2;
    '-webkit-box-orient': 'vertical',
  },
  discountPriceText: {
    color: '#6B6B6B',
    fontSize: 13,
    textDecorationLine: 'line-through',
    height: 13,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 14,
      height: 14,
    },
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 28,
  },
  price: {
    color: colors.primaryColor,
    fontWeight: 800,
    lineHeight: '18px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 16,
    },
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addIcon: {
    fontSize: 28,
    color: colors.primaryColor,
  },
  verticalTitleContainer: {
    marginTop: 8,
  },
  brandPlaceholder: {
    height: 4,
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 12,
    height: 32,
    width: 32,
    color: '#fff',
    fontWeight: 800,
    fontSize: 11,
    textAlign: 'center',
    objectFit: 'contain',
    backgroundSize: 'contain',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '12px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 40,
      width: 40,
    },
  },
  hotDealLabel: {
    backgroundImage: `url(${images.hotDealLabel})`,
  },
  bestSellerLabel: {
    backgroundImage: `url(${images.bestSellerLabel})`,
    fontSize: 8,
    lineHeight: '10px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 11,
      lineHeight: '12px',
    },
  },
  newProductLabel: {
    backgroundImage: `url(${images.newProductLabel})`,
    fontSize: 8,
    lineHeight: '10px',

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      fontSize: 11,
      lineHeight: '12px',
    },
  },
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      gap: 10,
    },
  },
  quantityIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E5E5E5',
    height: 24,
    width: 24,
    borderRadius: 100,
    color: colors.primaryColor,
    fontWeight: 700,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 28,
      width: 28,
    },
  },
  quantityIcon: {
    fontSize: 14,
  },
  quantity: {
    fontWeight: 500,
    textAlign: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchItemContainer: {
    display: 'grid',
    gridTemplateColumns: '48px 1fr',
    gridGap: 10,
    padding: '12px 0px',
    margin: '0px 12px',
    minWidth: 350,
    borderBottom: '1px solid #EDF2F9',
  },
  searchThumnail: {
    height: 48,
    width: 48,
    borderRadius: 3,
  },
  productId: {
    position: 'absolute',
    top: '-65px',
  }
});
