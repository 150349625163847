import React, { useMemo } from 'react';
import { images } from '../../../../core/contants/images';
import { useAdvantageListStyles } from './advantage-list.style';

export const AdvantageList = React.memo(() => {
  const classes = useAdvantageListStyles();
  const items = useMemo(
    () => [
      { label: 'Đặt hàng', description: 'Nhanh và dễ dàng', image: images.shoppingList },
      { label: 'Từ hệ thống', description: 'Hơn 400 nhà hàng', image: images.quality },
      { label: 'Giao hàng', description: 'Mọi nơi bạn muốn', image: images.deliveryBox },
      { label: 'Hàng ngàn', description: 'Ưu đãi hấp dẫn nhất', image: images.giftBox },
    ],
    [],
  );
  return (
    <div className={classes.container}>
      {items.map((i, index) => (
        <div className={classes.itemContainer} key={i.label}>
          <img src={i.image} className={classes.icon} />
          <div className={classes.labelContainer}>
            <div className={classes.label}>{i.label}</div>
            <div className={classes.description}>{i.description}</div>
          </div>

          {index < items.length - 1 && <div className={classes.seperator} />}
        </div>
      ))}
    </div>
  );
});
