import React, { Fragment, useState } from "react";
import { AtmPaymentBackground } from "../../../../components/icons/atm-payment-background";
import { useStyles } from "./atm-tab.style";
export const AtmTab = React.memo(() => {
  const classes = useStyles();
  return (
    <Fragment>
      <AtmPaymentBackground />
      <div>
        <div>Mã đơn hàng: #G2209h3</div>
        <div>Nhà cung cấp dịch vụ: G-Delivery</div>
        <div>Giá trị thanh toán: 1.002.900đ</div>
      </div>
    </Fragment>
  );
});
