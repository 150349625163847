import Fuse from 'fuse.js';
import React, { Fragment, useMemo, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { AppRadioBox } from '../../../../components/radio';
import { SearchIcon } from '../../../../components/icons/search';
import { TextInput } from '../../../../components/text-input';
import { IRestaurant } from '../../../../core/models/brand';
import { useBrandSelectorStyles } from './restaurant-selector.style';
import { useMediaQuery } from '@material-ui/core';
import { Modal } from 'antd';
import { ModalCloseIcon } from '../../../../components/icons/modal-close';
import { BREAK_POINTS } from '../../../../core/contants/break-points';

interface Props {
  isVisible: boolean;
  onDismiss: () => void;
  restaurants: IRestaurant[];
  selectedRestaurant: IRestaurant | undefined;
  onChangedRestaurant: (restaurant: IRestaurant) => void;
}

export const RestaurantSelector = React.memo((props: Props) => {
  const classes = useBrandSelectorStyles();
  const [searchText, setSearchText] = useState('');
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);

  const filteredRestaurants = useMemo(() => {
    if (!searchText) return props.restaurants.map((p) => ({ item: p }));

    const options = {
      keys: ['restaurant.name', 'restaurant.address'],
    };
    const fuse = new Fuse(props.restaurants, options);
    return fuse.search(searchText);
  }, [searchText, props.restaurants]);

  return (
    <Fragment>
      {isDesktop ? (
        <Modal
          title=""
          visible={props.isVisible}
          footer={null}
          onCancel={props.onDismiss}
          closeIcon={null}
          centered
          width={470}
          bodyStyle={{
            padding: isDesktop ? '16px 0px' : '24px 16px 16px 16px',
          }}
          style={{ borderRadius: 8 }}
          closable={false}
        >
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <div className={classes.blankView}></div>

              <span className={classes.title}>Chọn nhà hàng giao</span>
              <div className={classes.modalCloseIcon} onClick={props.onDismiss}>
                <ModalCloseIcon />
              </div>
            </div>
            <TextInput
              prefix={<SearchIcon className={classes.searchIcon} />}
              placeholder={'Tìm kiếm ...'}
              onChange={setSearchText}
              value={searchText}
              textInputContainerClassname={classes.textInputContainer}
              textInputClassname={classes.searchTextInput}
            />
            {filteredRestaurants.map((i) => (
              <div className={classes.provinceContainer}>
                <AppRadioBox
                  isChecked={props.selectedRestaurant?.id === i.item.id}
                  onChanged={(_) => props.onChangedRestaurant(i.item)}
                >
                  <div>
                    <div className={classes.provinceText}>{i.item.restaurant.name}</div>
                    <div className={classes.addressText}>{i.item.restaurant.address}</div>
                  </div>
                </AppRadioBox>
              </div>
            ))}
          </div>
        </Modal>
      ) : (
        <BottomSheet
          onDismiss={props.onDismiss}
          open={props.isVisible}
          header={
            <div className={classes.headerContainer}>
              <span className={classes.title}>Chọn nhà hàng giao</span>
              <div className={classes.closeIcon} onClick={props.onDismiss}>
                <ModalCloseIcon color="#7A7A7A" />
              </div>
            </div>
          }
          defaultSnap={({ maxHeight }) => maxHeight - maxHeight / 10}
          snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
        >
          <div className={classes.container}>
            <TextInput
              prefix={<SearchIcon className={classes.searchIcon} />}
              placeholder={'Tìm kiếm ...'}
              onChange={setSearchText}
              value={searchText}
              textInputContainerClassname={classes.textInputContainer}
              textInputClassname={classes.searchTextInput}
            />
            {filteredRestaurants.map((i) => (
              <div className={classes.provinceContainer}>
                <AppRadioBox
                  isChecked={props.selectedRestaurant?.id === i.item.id}
                  onChanged={(_) => props.onChangedRestaurant(i.item)}
                >
                  <div>
                    <div className={classes.provinceText}>{i.item.restaurant.name}</div>
                    <div className={classes.addressText}>{i.item.restaurant.address}</div>
                  </div>
                </AppRadioBox>
              </div>
            ))}
          </div>
        </BottomSheet>
      )}
    </Fragment>
  );
});
