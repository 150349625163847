import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff'
  },
  icon: {
    marginTop: 72
  },
  text: {
    color: '#000',
    fontWeight: 500,
    fontSize: 14,
    marginTop: 24
  },
  continueButton: {
    padding: '15px 40px',
    marginTop: 30,
    marginBottom: 60
  }
});