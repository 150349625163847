import { makeStyles } from '@material-ui/core';
import { Popover } from 'antd';
import { slice } from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { colors } from '../../core/contants/colors';
import { IBrand } from '../../core/models/brand';
import { IProduct } from '../../core/models/product';
import { ProductItem } from '../product-item';

interface Props {
  products: IProduct[];
  brands: IBrand[];
  isVisible: boolean;
  searchText: string;
  onVisibleChange: (value: boolean) => void;
}

const useStyles = makeStyles({
  seeMoreContainer: {
    padding: '12px 12px',
    textAlign: 'center',
    color: colors.accentColor,
  },
  logoContainer: {
    backgroundColor: '#F5F5F8',
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,

    [`@media (min-width: ${BREAK_POINTS.SM}px)`]: {
      height: 48,
      width: 48,
      padding: 4,
    },
  },
  brandContainer: {
    display: 'flex',
    gap: 10,
    padding: '12px 0px',
    margin: '0px 12px',
    alignItems: 'center',
    borderBottom: '1px solid #EDF2F9',
  },
  brandName: {
    color: '#000',
    fontWeight: 500,
  },
});

export const SearchResultPopOver = React.memo((props: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  const history = useHistory();

  const onSeeMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push({
      pathname: '/search-result',
      search: new URLSearchParams({ 'search-text': props.searchText }).toString(),
    });
  };

  const searchResult = useMemo(
    () => [
      ...props.brands.map((b) => ({ ...b, type: 'brand' })),
      ...props.products.map((p) => ({ ...p, type: 'product' })),
    ],
    [props.brands, props.products],
  );

  return (
    <Popover
      content={() => (
        <div style={{ minWidth: '300px' }}>
          {props.brands.map((brand) => (
            <Link to={`brand/${brand.brandId}`}>
              <div className={classes.brandContainer}>
                <div className={classes.logoContainer}>
                  <img src={brand.logo} />
                </div>
                <div>
                  <div className={classes.brandName}>{brand.name}</div>
                  <div style={{ fontSize: 12, color: '#6B6B6B' }}>Thương hiệu</div>
                </div>
              </div>
            </Link>
          ))}
          {slice(props.products, 0, 5).map((product) => (
            <ProductItem product={product} layout="search" />
          ))}

          {props.products.length > 5 && (
            <div className={classes.seeMoreContainer} onClick={onSeeMore}>
              Xem thêm <b>{props.products.length - 5}</b> kết quả với từ khoá{' '}
              <b>“{props.searchText}”</b>
            </div>
          )}
        </div>
      )}
      trigger="focus"
      placement="bottom"
      visible={props.isVisible}
      onVisibleChange={props.onVisibleChange}
    >
      {props.children}
    </Popover>
  );
});
