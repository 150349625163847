import React from 'react';

interface Props {
  className?: string;
  onClick?: any;
}

export const VoucherCloseIcon = React.memo(
  ({ className, onClick }: React.PropsWithChildren<Props>) => {
    return (
      <svg
        onClick={onClick}
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="8" fill="#EF4949" />
        <rect
          x="11.1108"
          y="4"
          width="1.25702"
          height="10.0561"
          rx="0.628508"
          transform="rotate(45 11.1108 4)"
          fill="white"
        />
        <rect
          x="12"
          y="11.1108"
          width="1.25702"
          height="10.0561"
          rx="0.628508"
          transform="rotate(135 12 11.1108)"
          fill="white"
        />
      </svg>
    );
  },
);
