
import React from "react";

interface Props {
  className?: string;
}

export const AddressPlusIcon = React.memo(
  ({ className }: React.PropsWithChildren<Props>) => {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" width="2" height="16" rx="1" fill="#248FED"/>
      <rect x="16" y="7" width="2" height="16" rx="1" transform="rotate(90 16 7)" fill="#248FED"/>
      </svg>
    );
  }
);
