import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const FavoritePrimaryIcon = React.memo(({ className, color }: React.PropsWithChildren<Props>) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M11.7648 21.358C9.47929 19.9463 7.35467 18.2818 5.42984 16.3947C4.07097 15.0475 3.0332 13.4024 2.3958 11.5852C1.25693 8.04127 2.58265 3.99111 6.28399 2.78871C8.23763 2.17518 10.3624 2.55181 11.9962 3.80125C13.6305 2.55321 15.7546 2.1767 17.7083 2.78871C21.4097 3.99111 22.7443 8.04127 21.6054 11.5852C20.9732 13.4006 19.9416 15.0456 18.5892 16.3947C16.6626 18.2797 14.5382 19.9441 12.2542 21.358L12.0051 21.5207L11.7648 21.358Z"
        fill="#E96E34"
      />
      <path
        d="M18.2263 9.50676C18.0253 9.49382 17.838 9.39331 17.7084 9.2288C17.5788 9.06429 17.5183 8.85022 17.5411 8.63675C17.5628 7.93363 17.1628 7.29363 16.5458 7.04462C16.1546 6.93783 15.9177 6.51414 16.0155 6.09631C16.1084 5.68519 16.4934 5.42956 16.8803 5.5221C16.9291 5.5302 16.9762 5.5479 17.019 5.5743C18.256 6.05035 19.0569 7.33157 18.9932 8.73245C18.9912 8.94478 18.9084 9.14713 18.7638 9.29312C18.6192 9.4391 18.4253 9.51621 18.2263 9.50676Z"
        fill="#E96E34"
      />
    </svg>
  );
});
