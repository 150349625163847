import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Header } from '../../components/header';
import { CartItem } from './components/cart-item/cart-item.component';
import { useStyles } from './cart.style';
import { CartDivider } from '../../components/cart-divider';
import { DiscountField } from './components/discount-field/discount-field.component';
import { Input, Row, Col } from 'antd';
import { TotalMoneyField } from './components/total-money-field/total-money-field';
import { cartService } from '../../services/cart-service';
import { formatCurrency } from '../../core/ultis/currency';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { ModalSpinner } from '../../components/modal-spinner';
import { NoCartData } from './components/no-data/no-data.component';
import { DeleteItemModal } from './components/delete-item-modal/delete-item-modal.component';
import { LoginScreen } from '../login/login.screen';
import { Container } from '../../components/container';
import { CartStep1 } from '../../components/icons/cart-step-1';
import { CartSubtractIcon } from '../../components/icons/cart-subtract';
import { CartPlusIcon } from '../../components/icons/cart-plus';
import { useMediaQuery } from '@material-ui/core';
import { BREAK_POINTS } from '../../core/contants/break-points';
import { voucherService } from '../../services/voucher-service';
import { CartCloseIcon } from '../../components/icons/cart-close';
import { CouponModal } from '../verify-payment/coupon-modal/coupon-modal.component';
import { VoucherCloseIcon } from '../../components/icons/voucher-close';
import { DiscountFoodModal } from '../verify-order/components/discount-food-modal/discount-food-modal.component';
import { DiscountGroupModal } from '../verify-order/components/discount-group-modal/discount-group-modal.component';
import { IVoucher } from '../../core/models/ticket';
import { ModalCloseIcon } from '../../components/icons/modal-close';
const { TextArea } = Input;
interface Props {
  cartState: any;
  cartReducer: any;
}
const CartComponent = React.memo((props: Props) => {
  const { cartState, cartReducer } = props;
  const classes = useStyles();
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [data, setData] = useState(cartState?.cartItems) as any;
  const [cartNote, setCartNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItemModalVisible, setDeleteItemModalVisible] = useState(false);
  const [currentSubtractItem, setCurrentSubtractItem] = useState({} as any);
  const [isVisibleLogin, setIsVisibleLogin] = useState(false);

  const dispatch = useDispatch<Dispatch>();
  const appContext = useSelector((state: RootState) => state.appContext);
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width:${BREAK_POINTS.SM}px)`);
  const [cartTotalData, setCartTotalData] = useState({} as any);
  const [voucherData, setVoucherData] = useState([] as any);
  const [myVoucherData, setMyVoucherData] = useState([] as any);
  const [cartData, setCartData] = useState({} as any);
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({}) as any;
  const [voucherModalType, setVoucherModalType] = useState<string>();

  const discounts = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type !== 1),
    [cartState?.selectedVouchers],
  );

  const vouchers = useMemo(
    () => (cartState?.selectedVouchers || []).filter((i) => i.type === 1),
    [cartState?.selectedVouchers],
  );

  useEffect(() => {
    setData(cartState?.cartItems);
    setCartTotalData(cartState?.totals);
    getData();
  }, [cartState?.cartItems, isDesktop]);
  const getData = async () => {};
  useEffect(() => {
    dispatch.cart.setCartData({
      paymentMethod: '',
      recipientCellphone: '',
      recipientName: '',
      deliveryTime: '',
      deliveryDate: '',
      pickupAtRestaurant: '0',
      note: '',
      noteForDriver: '',
      cutleryTool: '0',
      deliveryAddress: '',
      wardId: '',
      wardName: '',
      districtId: '',
      districtName: '',
      provinceId: '',
      provinceName: '',
      hasInvoice: 0,
      invoiceName: '',
      invoiceNumber: '',
      invoiceAddress: '',
      invoiceEmail: '',
    });
  }, []);
  const getVoucherData = async () => {
    if (user.authentication?.token) {
      const voucherRes = await voucherService.getVouchersList();
      let mappedArray = [] as any;
      voucherRes?.data?.result?.forEach((value: any) => {
        value?.gifts.forEach((giftValue: any) => {
          delete value['gifts'];
          mappedArray.push({
            ...value,
            giftValue: giftValue,
          });
        });
      });
      setVoucherData(mappedArray);
      const myVoucherRes = await voucherService.getSavedVouchers();
      setMyVoucherData(myVoucherRes?.data?.result);
    }
  };
  const getTotalDiscountValue = () => {
    let regularPriceTotal = 0;
    let salePriceTotal = 0;
    data?.forEach((value: any) => {
      regularPriceTotal += value?.regularPrice * value?.quantity;
      salePriceTotal += value?.salePrice
        ? value?.salePrice * value?.quantity
        : value?.regularPrice * value?.quantity;
    });
    return regularPriceTotal - salePriceTotal;
  };
  const onSubtractItem = async (item: any) => {
    if (item?.quantity - 1 === 0) {
      setCurrentSubtractItem(item);
      setDeleteItemModalVisible(true);
    } else {
      setIsLoading(true);
      const res = await cartReducer.quickRemoveFromCart({
        lineItemId: item?.lineItemId,
        productId: item?.productId,
      });
      if (res?.status === 200) {
        setData(res?.data?.result);
      }
      setIsLoading(false);
    }
  };
  const onDeleteItem = (item: any) => {
    setCurrentSubtractItem(item);
    setDeleteItemModalVisible(true);
  };
  const onConfirmDeleteItem = async (item: any) => {
    setIsLoading(true);
    const res = await cartReducer.removeItem({
      lineItemId: item?.lineItemId,
      productId: item?.productId,
    });
    if (res?.status === 200) {
      setData(res?.data?.result?.items);
    }
    setIsLoading(false);
    setDeleteItemModalVisible(false);
  };
  const onPlusItem = async (item: any) => {
    setIsLoading(true);
    const res = await cartReducer.quickAddToCart({
      productId: item?.productId,
      override: false,
      lineItemId: item?.lineItemId,
    });

    if (res?.data?.messageCode === 200) {
      setData(res?.data?.result?.items);
    }
    setIsLoading(false);
  };
  const renderCartItem = () => {
    return data?.map((value: any, index: number) => {
      if (index < 5) {
        return (
          <CartItem
            item={value}
            onPlusItem={onPlusItem}
            onSubtractItem={onSubtractItem}
            onDeleteItem={onDeleteItem}
            key={index}
          />
        );
      } else {
        if (isSeeMore) {
          return (
            <CartItem
              item={value}
              onPlusItem={onPlusItem}
              onSubtractItem={onSubtractItem}
              onDeleteItem={onDeleteItem}
              key={index}
            />
          );
        } else {
          return <Fragment></Fragment>;
        }
      }
    });
  };
  const renderSeeMoreText = () => {
    if (data?.length > 5 && !isSeeMore) {
      return (
        <div className={classes.seeMoreText} onClick={onSeeMoreTextPress}>
          Xem thêm {data?.length - 5} món nữa
        </div>
      );
    }
  };
  const onCancelDeleteItem = () => {
    setDeleteItemModalVisible(false);
    setCurrentSubtractItem({});
  };

  const onSeeMoreTextPress = () => {
    setIsSeeMore(true);
  };

  const onContinueClick = () => {
    dispatch.cart.setCartData({ note: cartNote });
    if (user.authentication?.token) {
      dispatch.appContext.setGoToCheckout(true);
      history.push(`/verify-order`);
    } else {
      setIsVisibleLogin(true);
    }
  };

  const onApplyCoupon = async (voucher: IVoucher) => {
    try {
      setIsLoading(true);
      const result = await voucherService.applyVoucher({
        voucherCode: voucher.seriNo,
        partnerId: 0,
      });
      if (result?.data?.messageCode === 200) {
        setCouponModalVisible(false);
        const cartRes = await cartService.getDetail();
        if (cartRes?.data?.result) {
          dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
        }
      } else {
        dispatch.appContext.setError(result?.data?.message || '');
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onApplyVoucherClick = async (value: any) => {
    try {
      setIsLoading(true);
      if (value?.giftValue?.seriNo) {
        const result = await voucherService.applyVoucher({
          voucherCode: value?.giftValue?.seriNo,
          partnerId: value?.giftValue?.partnerId,
        });
        if (result?.data?.messageCode === 200) {
          setCouponModalVisible(false);
          const cartRes = await cartService.getDetail();
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onSubmitCodeInput = async (codeInput: any) => {
    try {
      setIsLoading(true);
      if (codeInput) {
        const result = await voucherService.applyVoucher({
          voucherCode: codeInput,
          // partnerId: 0,
        });
        if (result?.data?.messageCode === 200) {
          const cartRes = await cartService.getDetail();
          if (cartRes?.data?.result) {
            dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
          }
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 406) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('pick-product');
          setCouponModalVisible(false);
        } else if (result?.data?.messageCode === 407 || result.data.messageCode === 408) {
          setSelectedVoucher(result.data.result);
          setVoucherModalType('buy-a-get-b');
          setCouponModalVisible(false);
        } else {
          dispatch.appContext.setError(result?.data?.message || '');
        }
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onPickItemForVoucher = async (item: any) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      productId: item.wooId,
    });
    if (result?.data?.messageCode === 200) {
      const cartRes = await cartService.getDetail();
      setCartData(cartRes?.data?.result);
      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onApplyBuyAGetBVoucher = async (value) => {
    setIsLoading(true);
    const result = await voucherService.applyVoucher({
      voucherCode: selectedVoucher.code,
      partnerId: selectedVoucher.partnerId,
      ...value,
    });
    if (result?.data?.messageCode === 200) {
      const cartRes = await cartService.getDetail();
      setCartData(cartRes?.data?.result);
      setCouponModalVisible(false);
      setSelectedVoucher({});
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
    } else {
      dispatch.appContext.setError(result?.data?.message || '');
    }

    setIsLoading(false);
  };

  const onCloseCouponModal = () => {
    setCouponModalVisible(false);
  };

  const onCancelCoupon = async (value: any) => {
    setIsLoading(true);
    if (value?.giftValue?.seriNo || value?.code) {
      const result = await voucherService.removeVoucher(value?.giftValue?.seriNo || value?.code);
      const cartRes = await cartService.getDetail();
      if (cartRes?.data?.result) {
        dispatch.cart.getCartDetailSuccess(cartRes?.data?.result);
      }
      setCouponModalVisible(false);
    }
    setIsLoading(false);
  };

  const renderCartItemDesktop = () => {
    return data?.map((value: any, index: number) => {
      return (
        <Row className={classes.cartItemContainer} key={index}>
          <Col md={7}>
            <div className={classes.cartName}>{value?.name ?? ''}</div>
          </Col>
          <Col md={5} className={classes.cartBrandContainer}>
            <img
              src={appContext.selectedBrand?.minimizeLogo}
              className={classes.brandLogo}
              alt=""
            />
            <div className={classes.cartHeaderTitle}>{appContext?.selectedBrand?.name}</div>
          </Col>
          <Col md={3}>
            <div className={classes.quantityContainer}>
              <div style={{ cursor: 'pointer' }} onClick={() => onSubtractItem(value)}>
                <CartSubtractIcon className={classes.adjustQuantityIcon} />
              </div>
              <div className={classes.quantityText}>{value?.quantity ?? ''}</div>
              <div style={{ cursor: 'pointer' }} onClick={() => onPlusItem(value)}>
                <CartPlusIcon className={classes.adjustQuantityIcon} />
              </div>
            </div>
          </Col>
          <Col md={1}></Col>

          <Col md={3}>
            <div className={classes.priceContainer}>
              {value?.regularPrice && value?.salePrice ? (
                <div className={classes.lineThroughPrice}>
                  {formatCurrency(value?.regularPrice)}
                </div>
              ) : (
                <Fragment></Fragment>
              )}
              <div className={classes.colorPrice}>
                {formatCurrency(value?.salePrice || value?.regularPrice)}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={classes.cartTotalPrice}>
              {formatCurrency((value?.salePrice || value?.regularPrice) * value?.quantity)}
            </div>
          </Col>
          <Col md={2} className={classes.closeIconCol}>
            <div className={classes.closeIconContainer} onClick={() => onDeleteItem(value)}>
              <CartCloseIcon />
            </div>
          </Col>
        </Row>
      );
    });
  };
  return (
    <Fragment>
      <DiscountFoodModal
        isVisible={
          (selectedVoucher?.applyForItems || []).length > 0 && voucherModalType === 'pick-product'
        }
        items={selectedVoucher?.applyForItems || []}
        onClickItem={onPickItemForVoucher}
        onClose={() => setSelectedVoucher({})}
        denominationValue={selectedVoucher?.denominationValue}
        type={selectedVoucher?.type}
      />
      <DiscountGroupModal
        isVisible={
          (selectedVoucher?.applyForItems || []).length > 0 && voucherModalType === 'buy-a-get-b'
        }
        items={selectedVoucher?.applyForItems || []}
        isGetAllProductDiscount={selectedVoucher.isGetAllProductDiscount}
        isParallelCondition={selectedVoucher.isParallelCondition}
        // onClickItem={onPickItemForVoucher}
        onClose={() => setSelectedVoucher({})}
        onApply={onApplyBuyAGetBVoucher}
      />
      <CouponModal
        onApplyVoucherClick={onApplyVoucherClick}
        isVisible={couponModalVisible}
        onDismiss={onCloseCouponModal}
        onQrCodeClick={() => {}}
        voucherData={voucherData}
        myVoucherData={myVoucherData}
        selectedVouchers={cartState?.selectedVouchers}
        onCancelCoupon={onCancelCoupon}
        onSubmitCodeInput={onSubmitCodeInput}
        onApplyCoupon={onApplyCoupon}
      />
      <DeleteItemModal
        isVisible={deleteItemModalVisible}
        onClose={() => onCancelDeleteItem()}
        onCancelButtonClick={() => onCancelDeleteItem()}
        onOkButtonClick={(item) => onConfirmDeleteItem(item)}
        value={currentSubtractItem}
      />
      <ModalSpinner visible={isLoading} />
      <LoginScreen
        isVisible={isVisibleLogin}
        close={() => {
          setIsVisibleLogin(false);
        }}
      />
      <div className={classes.mobileLayout}>
        <Header
          title="Giỏ hàng của bạn"
          prefix={
            <div onClick={history.goBack}>
              <ModalCloseIcon />
            </div>
          }
        />
        {data?.length ? (
          <div className={classes.container}>
            <CartDivider />
            <div className={classes.cartItemsContainer}>{renderCartItem()}</div>
            {renderSeeMoreText()}
            <Link to={`/brand/${appContext.selectedBrand?.brandId}`}>
              <div className={classes.otherButton}>Đặt thêm món khác</div>
            </Link>
            <CartDivider />
            {getTotalDiscountValue() ? (
              <DiscountField value={formatCurrency(getTotalDiscountValue())} />
            ) : (
              <Fragment></Fragment>
            )}
            <CartDivider />
            <div className={classes.noteText}>Ghi chú đơn hàng</div>
            <div className={classes.noteInput}>
              <TextArea
                rows={4}
                placeholder="Nhập ghi chú của bạn ở đây ..."
                onChange={(e: any) => setCartNote(e?.target?.value)}
              />
            </div>
            <TotalMoneyField
              total={formatCurrency(cartState?.totals?.totalPriceWithoutShipping)}
              onContinueClick={onContinueClick}
            />
          </div>
        ) : (
          <NoCartData />
        )}
      </div>
      <div className={classes.desktopLayout}>
        <Container className={classes.desktopContainer} backgroundColor="#EDEDED">
          {data?.length ? (
            <Row>
              <Col md={16}>
                <CartStep1 className={classes.step} />
                <Row className={classes.cartListContainer}>
                  <Col
                    md={7}
                    className={`${classes.cartHeaderContainer} ${classes.priceHeaderContainer}`}
                  >
                    <div className={classes.cartHeaderTitle}>TÊN MÓN</div>
                  </Col>
                  <Col md={5} className={classes.cartHeaderContainer}>
                    <div className={classes.cartHeaderTitle}>THƯƠNG HIỆU</div>
                  </Col>
                  <Col md={3} className={classes.cartHeaderContainer}>
                    <div className={classes.cartHeaderTitle}>SỐ LƯỢNG</div>
                  </Col>
                  <Col md={1}></Col>
                  <Col
                    md={3}
                    className={`${classes.cartHeaderContainer} ${classes.priceHeaderContainer}`}
                  >
                    <div className={classes.cartHeaderTitle}>GIÁ</div>
                  </Col>
                  <Col md={3} className={classes.cartHeaderContainer}>
                    <div className={classes.cartHeaderTitle}>TỔNG CỘNG</div>
                  </Col>
                </Row>
                {renderCartItemDesktop()}
              </Col>
              <Col md={1}></Col>
              <Col md={7} className={classes.voucherContainer}>
                {/* <div className={`${classes.voucherItem} ${classes.noMargin}`}>
                  <div className={classes.voucherTitle}>Mã giảm giá, voucher</div>
                  <div
                    className={classes.chooseVoucher}
                    onClick={() => setCouponModalVisible(true)}
                  >
                    Chọn mã
                  </div>
                </div>
                <div className={classes.voucherListContainer}>
                  {cartState?.selectedVouchers?.length ? (
                    cartState?.selectedVouchers.map((value: any, index: number) => {
                      return (
                        <div className={classes.voucherAppliedItem} key={index}>
                          <span>{value?.code}</span>
                          <div
                            onClick={() => {
                              onCancelCoupon(value);
                            }}
                            className={classes.voucherCloseIcon}
                          >
                            <VoucherCloseIcon />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={classes.noDiscountCodeText}>Bạn chưa thêm mã giảm giá nào</div>
                  )}
                </div>
                <hr style={{ border: '1px dashed #E4E4E4' }} /> */}
                {getTotalDiscountValue() ? (
                  <DiscountField value={formatCurrency(getTotalDiscountValue())} />
                ) : (
                  <Fragment></Fragment>
                )}
                <div className={`${classes.voucherItem}`}>
                  <div
                    className={classes.priceMainItemTitle}
                    style={{ fontWeight: 700, color: '#000', fontSize: 16 }}
                  >
                    Tạm tính
                  </div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0))}
                  </div>
                </div>
                {/* {!!cartTotalData?.totalDiscount && (
                  <div className={`${classes.voucherItem}`}>
                    <div className={classes.priceMainItemTitle}>Giảm giá</div>
                    <div className={classes.priceMainItemValue}>
                      - {formatCurrency(Math.round(cartTotalData?.totalDiscount ?? 0))}
                    </div>
                  </div>
                )}
                {discounts.map((discount) => (
                  <div className={classes.secondaryRow} key={`discount-${discount.code}`}>
                    <div className={classes.secondaryTitleText}>
                      <div className={classes.circle}></div>
                      <span>{discount.code}</span>
                    </div>
                    <div className={classes.secondaryValueText}>
                      -{formatCurrency(discount.denominationValue)}
                    </div>
                  </div>
                ))}
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>Phí vận chuyển</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(Math.round(cartTotalData?.shipping?.price ?? 0))}
                  </div>
                </div>
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>Tổng tiền trước VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(
                      Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0) -
                        Math.round(cartTotalData?.totalDiscount ?? 0) +
                        Math.round(cartTotalData?.shipping?.price ?? 0),
                    )}
                  </div>
                </div>
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(Math.round(cartTotalData?.totalTax ?? 0))}
                  </div>
                </div>
                <div className={`${classes.voucherItem}`}>
                  <div className={classes.priceMainItemTitle}>Tổng tiền sau VAT</div>
                  <div className={classes.priceMainItemValue}>
                    {formatCurrency(
                      Math.round(cartTotalData?.totalPriceWithoutShipping ?? 0) -
                        Math.round(cartTotalData?.totalDiscount ?? 0) +
                        Math.round(cartTotalData?.shipping?.price ?? 0) +
                        Math.round(cartTotalData?.totalTax ?? 0),
                    )}
                  </div>
                </div>
                {!!cartTotalData?.totalCashVoucher && (
                  <>
                    <hr style={{ border: '1px dashed #E4E4E4', marginTop: 18 }} />
                    <div className={`${classes.voucherItem}`}>
                      <div className={classes.priceMainItemTitle}>Voucher</div>
                      <div className={classes.priceMainItemValue}>
                        - {formatCurrency(Math.round(cartTotalData?.totalCashVoucher ?? 0))}
                      </div>
                    </div>
                  </>
                )}
                {vouchers.map((voucher) => (
                  <div className={classes.secondaryRow} key={`voucher-${voucher.code}`}>
                    <div className={classes.secondaryTitleText}>
                      <div className={classes.circle}></div>
                      <span>{voucher.code}</span>
                    </div>
                    <div className={classes.secondaryValueText}>
                      -{formatCurrency(voucher.denominationValue)}
                    </div>
                  </div>
                ))}
                <hr style={{ border: '1px dashed #E4E4E4', marginTop: 18 }} />
                <div className={`${classes.voucherItem} ${classes.totalContainer}`}>
                  <div className={classes.priceTotalTitle}>Tổng tiền phải trả</div>
                  <div className={classes.priceTotalValue}>
                    {formatCurrency(Math.round(cartTotalData?.totalPaySum ?? 0))}
                  </div>
                </div> */}
                <div
                  className={classes.buttonOrderOther}
                  onClick={() => {
                    history.push(
                      appContext.selectedBrand?.brandId
                        ? `/brand/${appContext.selectedBrand?.brandId}`
                        : '/',
                    );
                  }}
                >
                  Đặt thêm món khác
                </div>
                <div className={classes.buttonContinue} onClick={onContinueClick}>
                  Tiếp tục
                </div>
              </Col>
            </Row>
          ) : (
            <NoCartData />
          )}
        </Container>
      </div>
    </Fragment>
  );
});

const mapState = (rootState: any) => ({
  cartState: rootState.cart,
});

const mapDispatch = (rootReducer: any) => ({
  cartReducer: rootReducer.cart,
});

const CartScreen = React.memo(connect(mapState, mapDispatch)(CartComponent));
export { CartScreen };
