import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../core/contants/colors';

export const useStyles = makeStyles({
  modal: {
    borderRadius: 16,
  },
  monthText: {
    fontWeight: 700,
    fontSize: 18,
  },
  selectMonthContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  activeDate: {
    background: colors.primaryColor,
    color: '#fff',
    // width: 40,
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  dateInMonth: {
    // width: 40,
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  inActiveDate: {
    background: '#EDEDED',
    // width: 40,
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  cancelButton: {
    borderRadius: 8,
    border: '1px solid #D8D8D8',
    fontWeight: 700,
    fontSize: 16,
    padding: '10px 35px',
    width: '47%',
    textAlign: 'center',
  },
  okButton: {
    borderRadius: 8,
    fontWeight: 700,
    fontSize: 16,
    background: colors.primaryColor,
    color: '#fff',
    padding: '10px 35px',
    width: '47%',
    textAlign: 'center',
  },
  dateInMonthDisabled: {
    color: 'rgba(0, 0, 0, 0.25)',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  icon: {
    cursor: 'pointer',
  },
});
